import { Button, Grid, Input, InputProps, TokenGroup, TokenGroupProps } from '@amzn/awsui-components-react';
import React, { useState } from 'react';

type TokenListInputProps = {
  values?: string[];
  inputProps: Omit<InputProps, 'value' | 'onChange'>;
  onAdd: (tokenValues: string[]) => void;
  tokenGroupProps: Omit<TokenGroupProps, 'alignment' | 'items'>;
  disabled?: boolean;
};

const TokenListInput: React.FC<TokenListInputProps> = ({
  values = [],
  inputProps,
  onAdd,
  tokenGroupProps,
  disabled,
}) => {
  const [inputVal, setInputVal] = useState('');

  return (
    <>
      <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
        <Input
          disabled={disabled}
          {...inputProps}
          onChange={({ detail }) => setInputVal(detail.value)}
          value={inputVal}
        />
        <Button
          disabled={disabled}
          iconName="add-plus"
          onClick={(e) => {
            e.preventDefault();
            if (inputVal.length > 0) {
              onAdd([...values, inputVal]);
              setInputVal('');
            }
          }}
        >
          Add
        </Button>
      </Grid>
      <TokenGroup {...tokenGroupProps} items={values.map((val) => ({ label: val, disabled }))} alignment="horizontal" />
    </>
  );
};

export default TokenListInput;
