import { Artifact } from '@amzn/d2d-bff-schema';
import { useAppSelector } from '../../store/store';
import { useDispatch } from 'react-redux';
import { downloadArtifactVersion } from '../../store/artifactv2';
import React, { useEffect, useState } from 'react';
import { Link, Spinner, Table, TableProps } from '@amzn/awsui-components-react';
import { clickDownloadLink, formatArtifactsV2StringDate } from './artifactsV2Utils';

interface ArtifactV2FileVersionsTableProps {
  artifact?: Artifact;
  header?: JSX.Element;
  variant?: TableProps.Variant;
}

export const ArtifactV2FileVersionsTable: React.FC<ArtifactV2FileVersionsTableProps> = ({
  artifact,
  header,
  variant,
}) => {
  const dispatch = useDispatch();

  const { artifactDownloadLoading, artifactDownloadLink, getArtifactByIdLoading } = useAppSelector(
    (state) => state.artifactV2Store
  );

  const [currentDownloadArtifactVersionId, setCurrentDownloadArtifactVersionId] = useState<string | undefined>();
  const [isDownloadLinkClickable, setIsDownloadLinkClickable] = useState<boolean>(false);

  const downloadHandler = (artifactId: string, artifactVersionId: string): void => {
    dispatch(downloadArtifactVersion({ input: { artifactId, artifactVersionId } }));
    setIsDownloadLinkClickable(true);
    setCurrentDownloadArtifactVersionId(artifactVersionId);
  };

  useEffect(() => {
    if (artifactDownloadLink && isDownloadLinkClickable) {
      clickDownloadLink(artifactDownloadLink);
      setIsDownloadLinkClickable(false); // Only allow the download url to be used once (the link is still stored in state, but here we explicitly only allow one download per link)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifactDownloadLink]);

  const ARTIFACTV2_VERSION_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<{
    fileName: string;
    createdAt: string;
    artifactId: string;
    artifactVersionId: string;
  }>[] = [
    {
      id: 'fileName',
      header: 'File Name',
      cell: (item) => (
        <>
          <Link
            onFollow={(e) => {
              e.preventDefault();
              downloadHandler(item.artifactId, item.artifactVersionId);
            }}
          >
            {item.fileName}
          </Link>
          {item.artifactVersionId === currentDownloadArtifactVersionId && artifactDownloadLoading === 'pending' && (
            <Spinner></Spinner>
          )}
        </>
      ),
      sortingField: 'fileId',
    },
    {
      id: 'createdAt',
      header: 'Date Uploaded',
      cell: (item) => {
        return item.createdAt ? formatArtifactsV2StringDate(item.createdAt) : '-';
      },
      sortingField: 'createdAt',
    },
  ];

  return (
    <Table
      header={header}
      columnDefinitions={ARTIFACTV2_VERSION_COLUMN_DEFINITIONS}
      items={
        artifact?.files.map((file) => ({
          fileName: file.name,
          createdAt: file.uploadDate,
          artifactVersionId: file.cvAttachmentId,
          artifactId: artifact?.id,
        })) ?? []
      }
      loading={getArtifactByIdLoading === 'pending'}
      resizableColumns={true}
      variant={variant ?? 'container'}
    />
  );
};
