import { Link } from 'react-router-dom';
import moment from 'moment';
import { PC_STATUS } from 'utils/constants';
import { formatDateFromUnix } from '../../utils/dateFormat';

export const TRADE_CLASSIFICATIONS_TABLE_PREFERENCES_KEY = 'tradeclassifications-table-preferences';

export const COLUMN_DEFINITIONS = [
  {
    id: 'part',
    header: 'Part',
    cell: ({ nonPlmPartName, ipn, countryISO2, assetId }) => {
      const assetIdEncoded = encodeURIComponent(assetId);
      return ipn ? (
        <Link to={`/classification/${countryISO2}/${assetIdEncoded}`}>{ipn}</Link>
      ) : (
        <Link to={`/classification/${countryISO2}/${assetIdEncoded}`}>{nonPlmPartName}</Link>
      );
    },
  },

  {
    id: 'mpn',
    header: 'MPN',
    cell: (e) => e.mpn,
    sortingField: 'mpn',
  },
  {
    id: 'ipnDescription',
    header: 'IPN Description',
    cell: (e) => e.ipnDescription,
    sortingField: 'ipnDescription',
  },
  {
    id: 'countryISO2Format',
    header: 'Country',
    cell: (e) => e.countryISO2Format,
    sortingField: 'countryISO2Format',
  },

  {
    id: 'hts',
    header: 'HTS Code',
    cell: (e) => e.hts,
    sortingField: 'hts',
  },
  {
    id: 'ecn',
    header: 'ECN',
    cell: (e) => e.ecn,
    sortingField: 'ecn',
  },
  {
    id: 'assetType',
    header: 'AWS Part or NonPLM',
    cell: (e) => e.assetType,
    sortingField: 'assetType',
  },
  {
    id: 'description',
    header: 'NonPLM Description',
    cell: (e) => e.description,
    sortingField: 'description',
  },
  {
    id: 'justification',
    header: 'Justification',
    cell: (e) => e.justification,
    sortingField: 'justification',
  },
  {
    id: 'modifiedAt',
    header: 'Modified At',
    cell: (e) => {
      return formatDateFromUnix(moment.unix(e.modifiedAt));
    },
    sortingField: 'modifiedAt',
  },
  {
    id: 'modifiedBy',
    header: 'Modified By',
    cell: (e) => e.modifiedBy,
    sortingField: 'modifiedBy',
  },
  {
    id: 'changeNotes',
    header: 'Change Notes',
    cell: (e) => e.changeNotes,
    sortingField: 'changeNotes',
  },
  {
    id: 'exportApprovalRequired',
    header: 'Export Approval Required',
    cell: (e) => e.exportApprovalRequired,
    sortingField: 'exportApprovalRequired',
  },
  {
    id: 'exportReportingRequirement',
    header: 'Export Reporting Requirement',
    cell: (e) => e.exportReportingRequirement,
    sortingField: 'exportReportingRequirement',
  },
  {
    id: 'exportReportingRequirementType',
    header: 'Export Reporting Requirement Type',
    cell: (e) => e.exportReportingRequirementType,
    sortingField: 'exportReportingRequirementType',
  },
  {
    id: 'importApprovalRequired',
    header: 'Import Approval Required',
    cell: (e) => e.importApprovalRequired,
    sortingField: 'importApprovalRequired',
  },
  {
    id: 'importRequirementsCompleted',
    header: 'Import Requirements Complete',
    cell: (e) => e.importRequirementsCompleted,
    sortingField: 'importRequirementsCompleted',
  },
  {
    id: 'pcStatus',
    header: 'PC Status',
    cell: (e) => {
      const getStatus = PC_STATUS.find((a) => a.value === e.pcStatus?.toLowerCase());
      return getStatus?.label;
    },
    sortingField: 'pcStatus',
  },
  {
    id: 'programs',
    header: 'Programs',
    cell: (e) => {
      const programs = e.programsFormatted?.map((program, index) => {
        return <li key={index}>{program}</li>;
      });
      return programs;
    },
    sortingField: 'programs',
  },
  {
    id: 'localLanguageDescription',
    header: 'Local Language Description',
    cell: (e) => e.localLanguageDescription,
    sortingField: 'localLanguageDescription',
  },
];

export const CLASS_FILTERING_PROPERTIES = [
  {
    key: 'countryISO2Format',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Country',
    groupValuesLabel: 'Country value',
  },
  {
    key: 'ipn',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'IPN',
    groupValuesLabel: 'IPN values',
  },
  {
    key: 'nonPlmPartName',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'NonPLM',
    groupValuesLabel: 'NonPLM values',
  },
  {
    key: 'mpn',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'MPN',
    groupValuesLabel: 'MPN value',
  },
  {
    key: 'ecn',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'ECN',
    groupValuesLabel: 'ECN value',
  },
  {
    key: 'hts',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'HTS Code',
    groupValuesLabel: 'HTS Code',
  },
  {
    key: 'pcStatus',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'PC Status',
    groupValuesLabel: 'PC Status value',
  },
  {
    key: 'programsFormatted',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Progams',
    groupValuesLabel: 'Programs value',
  },
  {
    key: 'ipnDescription',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'IPN Description',
    groupValuesLabel: 'Ipn Description value',
  },
  {
    key: 'assetType',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'AWS or NonPLM Part',
    groupValuesLabel: 'Part Type value',
  },
  {
    key: 'description',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'NonPLM Description',
    groupValuesLabel: 'NonPLM Description value',
  },
  {
    key: 'justification',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Justification',
    groupValuesLabel: 'Justification value',
  },
  {
    key: 'modifiedAt',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Modified At',
    groupValuesLabel: 'Modified At value',
  },
  {
    key: 'modifiedBy',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Modified By',
    groupValuesLabel: 'Modified By value',
  },
  {
    key: 'changeNotes',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Change Notes',
    groupValuesLabel: 'Change Notes value',
  },
  {
    key: 'exportApprovalRequired',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Export Approval Required',
    groupValuesLabel: 'Export Approval Required value',
  },
  {
    key: 'exportReportingRequirement',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Export Reporting Requirement',
    groupValuesLabel: 'Export Reporting Requirement value',
  },
  {
    key: 'exportReportingRequirementType',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Export Reporting Requirement Type',
    groupValuesLabel: 'Export Reporting Requirement Type value',
  },
  {
    key: 'importApprovalRequired',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Import Approval Required',
    groupValuesLabel: 'Import Approval Required value',
  },
  {
    key: 'importRequirementsCompleted',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Import Requirements Completed',
    groupValuesLabel: 'Import Requirements Completed value',
  },
  {
    key: 'localLanguageDescription',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Local Language Description',
    groupValuesLabel: 'Local Language Description value',
  },
];

export const VISIBLE_CONTENT = ['part', 'mpn', 'ipnDescription', 'countryISO2Format', 'hts', 'ecn'];

export const VISIBLE_CONTENT_PREFERENCE = {
  title: 'Select visible content',
  options: [
    {
      label: 'Trade Classifications',
      options: [
        { id: 'part', label: 'Part', editable: false },
        { id: 'mpn', label: 'MPN' },
        {
          id: 'ipnDescription',
          label: 'IPN Description',
        },
        { id: 'countryISO2Format', label: 'Country' },
        { id: 'ecn', label: 'ECN' },
        { id: 'hts', label: 'HTS Code' },
        { id: 'pcStatus', label: 'PC Status' },
        { id: 'programs', label: 'Programs' },
        { id: 'assetType', label: 'AWS or NonPLM' },
        { id: 'description', label: 'NonPLM Description' },
        { id: 'justification', label: 'Justification' },
        { id: 'modifiedAt', label: 'Modified At' },
        { id: 'modifiedBy', label: 'Modified By' },
        { id: 'changeNotes', label: 'Change Notes' },
        { id: 'exportApprovalRequired', label: 'Export Approval Required' },
        { id: 'exportReportingRequirement', label: 'Export Reporting Requirement' },
        { id: 'exportReportingRequirementType', label: 'Export Reporting Requirement Type' },
        { id: 'importApprovalRequired', label: 'Import Approval Required' },
        { id: 'importRequirementsCompleted', label: 'Import Requirements Complete' },
        { id: 'localLanguageDescription', label: 'Local Language Description' },
      ],
    },
  ],
};
