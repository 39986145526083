import { ColumnLayout, Container, Header, SpaceBetween, Spinner, Table } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { PccPart, PccPartIdentifierType, PccPartSource } from '@amzn/d2d-bff-schema';
import ValueWithLabel from '../ValueWithLabel';
import { getOptionByValue } from '../../utils/formUtils';
import { PCC_PART_SOURCES } from '../../utils/constants';
import { formatDateFromUnixYearToSecond } from '../../utils/dateFormat';
import { useAppSelector } from '../../store/store';
import useCollectionWithFilterType from '../../hooks/useCollectionWithFilterType';
import { CLASSIFICATION_COLUMN_DEFINITIONS, CLASSIFICATION_FILTERING_PROPERTIES } from './index';
import { useDebouncedIPNSearch } from '../../hooks/useDebounce/useDebounce';
import usePCCCodesAPI from '../../hooks/useApiDataFetch';

interface PCCDetailPageProps {
  pccPart?: PccPart;
}

const PcClassificationDetails: React.FC<PCCDetailPageProps> = ({ pccPart }) => {
  const { getLoading, pccCodesLoading, formattedPccCodes } = useAppSelector((state) => state.pcClassificationStore);

  usePCCCodesAPI();

  const [filter, { items, collectionProps }] = useCollectionWithFilterType(
    pccPart?.pcClassifications.map((pcClassification) => ({
      pcCategory:
        getOptionByValue(pcClassification?.pcCategory, formattedPccCodes ?? [])?.label ?? pcClassification?.pcCategory,
      createdAt: formatDateFromUnixYearToSecond(pcClassification.createdAt),
      createdBy: pcClassification.createdBy,
    })) ?? [],
    { type: 'property', filteringProperties: CLASSIFICATION_FILTERING_PROPERTIES, customGroupFilters: [] },
    { pageSize: 100 }
  );
  const [partDescription, setPartDescription] = useState('');
  const { ipnInfoList, ipnsLoading } = useAppSelector((state) => state.townsendStore);
  const setTownsendSearchTerm = useDebouncedIPNSearch();

  useEffect(() => {
    if (pccPart?.partSource === PccPartSource.AGILE_PLM && pccPart?.partIdentifierType === PccPartIdentifierType.IPN) {
      if (ipnInfoList && ipnInfoList?.length > 0) {
        const ipnItem = ipnInfoList?.find((item) => item.ipn === pccPart?.partIdentifierValue ?? '');
        setPartDescription(ipnItem?.description ?? '');
        return;
      }
      setTownsendSearchTerm(pccPart?.partIdentifierValue);
      return;
    }
    setPartDescription(pccPart?.partDescription ?? '');
  }, [
    ipnInfoList,
    pccPart?.partDescription,
    pccPart?.partIdentifierType,
    pccPart?.partIdentifierValue,
    pccPart?.partSource,
    setTownsendSearchTerm,
  ]);

  const pccPartDetails = (
    <>
      <Container header={<Header>Part</Header>}>
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Part Source">
              {getOptionByValue(pccPart?.partSource, PCC_PART_SOURCES)?.label ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Description">
              {(ipnsLoading === 'pending' && pccPart?.partSource === PccPartSource.AGILE_PLM ? (
                <Spinner size="large" />
              ) : (
                partDescription
              )) ?? '-'}
            </ValueWithLabel>
          </SpaceBetween>

          <SpaceBetween size="l">
            <ValueWithLabel label="Part Identifier Type">{pccPart?.partIdentifierType}</ValueWithLabel>
            <ValueWithLabel label="Part Identifier Value">{pccPart?.partIdentifierValue}</ValueWithLabel>
          </SpaceBetween>

          <SpaceBetween size="l">
            <ValueWithLabel label="Created Date">
              {pccPart?.createdAt ? formatDateFromUnixYearToSecond(pccPart?.createdAt) : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Created By">{pccPart?.createdBy ?? '-'}</ValueWithLabel>
            <ValueWithLabel label="Last Classified Date">
              {pccPart?.lastClassifiedAt ? formatDateFromUnixYearToSecond(pccPart?.lastClassifiedAt) : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Last Classified By">{pccPart?.lastClassifiedBy ?? '-'}</ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>

      <Table
        header={<Header>Classifications</Header>}
        columnDefinitions={CLASSIFICATION_COLUMN_DEFINITIONS}
        items={items}
        loading={getLoading === 'pending' || pccCodesLoading === 'loading'}
        {...collectionProps}
        filter={filter}
      />
    </>
  );

  return <SpaceBetween size="l">{pccPartDetails}</SpaceBetween>;
};

export default PcClassificationDetails;
