import React, { useEffect } from 'react';
import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Flashbar,
  Header,
  PropertyFilterProps,
  SpaceBetween,
  Spinner,
  TableProps,
} from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import PCCDetailContent from './PcClassificationDetails';
import { Link, useParams } from 'react-router-dom';
import { flashbarItemsCreator } from 'utils/formUtils';
import { useAppSelector } from 'store/store';
import { useDispatch } from 'react-redux';
import { setPCClassificationDetailFlashbarItems } from 'store/pcClassification/pcClassificationSlice';
import useValidatePccPartParams from '../../hooks/useValidatePccPartParams/useValidatePccPartParams';
import { Maybe } from '@amzn/d2d-bff-schema';
import { FILTER_OPERATORS } from '../../utils/constants';

export const CLASSIFICATION_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: 'pcCategory',
    operators: FILTER_OPERATORS,
    propertyLabel: 'Category',
    groupValuesLabel: 'Category values',
  },
  {
    key: 'createdAt',
    operators: FILTER_OPERATORS,
    propertyLabel: 'Created Date',
    groupValuesLabel: 'Created Date values',
  },
  {
    key: 'createdBy',
    operators: FILTER_OPERATORS,
    propertyLabel: 'Created By',
    groupValuesLabel: 'Created By values',
  },
];

/** Interface for PC classification data used in UI tables. */
export interface PcClassificationTableData {
  pcCategory?: string;
  createdAt: string; // NOTE with 'DD MMM YYYY, hh:mm:ss' format
  createdBy: string;
}

export const CLASSIFICATION_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Maybe<PcClassificationTableData>>[] = [
  {
    id: 'category',
    header: 'Category',
    cell: (item) => item?.pcCategory ?? '-',
    sortingField: 'pcCategory',
  },
  {
    id: 'createdAt',
    header: 'Created Date',
    cell: (item) => item?.createdAt ?? '-',
    sortingField: 'createdAt',
  },
  {
    id: 'createdBy',
    header: 'Created By',
    cell: (item) => item?.createdBy ?? '-',
    sortingField: 'createdBy',
  },
];

const PcClassificationDetailLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { partSource, partIdentifierType, partIdentifierValue } = useParams<string>();
  const { isLoading, pccPart } = useValidatePccPartParams(partSource, partIdentifierType, partIdentifierValue);
  const { pcClassificationDetailFlashbarItems } = useAppSelector((state) => state.pcClassificationStore);

  // clear detail page flashbar items on component unmount
  useEffect(() => {
    return () => {
      dispatch(setPCClassificationDetailFlashbarItems([]));
    };
  }, [dispatch]);

  return (
    <AppLayout
      contentType="table"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={
        <Header
          variant="h1"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Link to={`/classifications`}>
                <Button>Search</Button>
              </Link>
              <Link
                to={`/edit/classification/pc/${pccPart?.partSource}/${pccPart?.partIdentifierType}/${pccPart?.partIdentifierValue}`}
              >
                <Button>Edit</Button>
              </Link>
            </SpaceBetween>
          }
        >
          Part Details
        </Header>
      }
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Classifications', href: `/classifications` },
            { text: 'Part Details', href: '#' },
          ]}
        />
      }
      content={isLoading ? <Spinner size="large" /> : <PCCDetailContent pccPart={pccPart} />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar
          items={flashbarItemsCreator(
            pcClassificationDetailFlashbarItems,
            dispatch,
            setPCClassificationDetailFlashbarItems
          )}
        />
      }
      stickyNotifications
      toolsHide
    />
  );
};

export default PcClassificationDetailLayout;
