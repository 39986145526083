import { useEffect } from 'react';
import { listPCCCodesAction } from '../../store/pcClassification';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { UseFormReset } from 'react-hook-form/dist/types/form';
import { PccPart } from '@amzn/d2d-bff-schema';
import { pccPartApiSchemaToFormSchema } from '../../components/PcClassificationCommon';

// TODO: Refactor this into a reusable api data fetcher

/**
 * Props for resetting a form's fields once data loads from an API
 */
export type UsePCCCodesAPIFormProps = {
  pccPart: PccPart | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetFunction: UseFormReset<any>;
};

/**
 * @param formProps the pcc part and the reset function to reset the values on a form once the data loads
 * @returns void
 */
const usePCCCodesAPI = (formProps?: UsePCCCodesAPIFormProps): void => {
  const dispatch = useAppDispatch();
  const { pccCodesLoading, formattedPccCodes } = useAppSelector((state) => state.pcClassificationStore);

  /** A hook to fetch pcc codes from API */
  useEffect(() => {
    if (pccCodesLoading === 'pending') {
      dispatch(listPCCCodesAction());
    }
  }, [dispatch, pccCodesLoading]);

  /**
   * A hook to update a form when the pcc codes are fetched
   * this hook is specific to pc classifications, but it
   * could be generalized to all forms
   */
  useEffect(() => {
    if (formProps?.resetFunction) {
      const refreshedFormValues = formProps.pccPart
        ? pccPartApiSchemaToFormSchema(formattedPccCodes).parse(formProps.pccPart)
        : undefined;
      formProps.resetFunction(refreshedFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedPccCodes]);
};

export default usePCCCodesAPI;
