import { Artifact, Changelog } from '@amzn/d2d-bff-schema';
import { Box, Container, ExpandableSection, Header, Table, TableProps } from '@amzn/awsui-components-react';
import {
  DEFAULT_EMPTY_FIELD_VALUE,
  formatArtifactsV2StringDate,
  getHistoryTableTransformedLabel,
  getPartFamilyLabel,
} from '../artifactsV2Utils';
import { SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { artifactsV2KeyToLabel } from '../artifactsV2Contants';

interface ArtifactV2FileVersionsTableProps {
  artifact?: Artifact;
  collapsible?: boolean;
}

const ARTIFACTV2_HISTORY_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Changelog>[] = [
  {
    id: 'dateModified',
    header: 'Date',
    cell: (item) => formatArtifactsV2StringDate(item?.modifiedAt) ?? '-',
  },
  {
    id: 'alias',
    header: 'Alias',
    cell: (item) => item.modifiedBy,
  },
  {
    id: 'changeFields',
    header: 'Changes',
    cell: (item) => (
      <SpaceBetween size={'s'}>
        {/** Display all files that were added */}
        {item?.files?.after.map((fileData) => {
          return (
            <Box variant="div" key={fileData.checksum}>
              Version: {fileData.name}
            </Box>
          );
        })}
        {/** Display if the artifact was archived */}
        {item?.archived && `Archived: ${item?.archived.after}`}
        {/** Display all metadata fields that were completely removed */}
        {item?.metadata?.before
          .filter((metadata) => !item.metadata?.after.find((afterMetadata) => afterMetadata.key === metadata.key))
          .map((removeItem) => {
            return (
              <Box variant="div" key={removeItem.key}>
                {artifactsV2KeyToLabel.get(removeItem.key) ?? removeItem.key}:{' -'}
              </Box>
            );
          })}
        {/** Display all metadata fields that had items added or removed */}
        {item?.metadata?.after.map((metadata) => {
          return (
            <Box variant="div" key={metadata.key}>
              {artifactsV2KeyToLabel.get(metadata.key) ?? metadata.key}:{' '}
              {metadata.value.length
                ? getHistoryTableTransformedLabel(metadata.key, metadata.value)
                : DEFAULT_EMPTY_FIELD_VALUE}
            </Box>
          );
        })}
        {/* /!** Display all partFamilies fields that were completely removed *!/*/}
        {item?.partFamilies ? (
          <Box variant={'span'} key={'part-families-label'}>
            Part Families:
          </Box>
        ) : (
          ''
        )}
        {item?.partFamilies?.before
          .filter(
            (partFamily) =>
              !item.partFamilies?.after.find(
                (afterPartFamily) => afterPartFamily.userPattern === partFamily.userPattern
              )
          )
          .map((removeItem) => {
            return (
              <Box key={removeItem.userPattern}>
                {removeItem.userPattern}
                {removeItem.replacementCharacters.map((replacement) => (
                  <li key={replacement.character}>
                    {replacement.character}:{' '}
                    {replacement.characterClasses
                      .map((characterClass) => getPartFamilyLabel(characterClass))
                      .join(', ')}
                  </li>
                ))}
              </Box>
            );
          })}
        {/** Display allpartFamilies fields that had items added or removed */}
        {item?.partFamilies?.after.map((partFamily) => {
          return (
            <Box key={partFamily.userPattern}>
              {partFamily.userPattern}
              <ul>
                {partFamily.replacementCharacters.map((replacement) => (
                  <li key={replacement.character}>
                    {replacement.character}:{' '}
                    {replacement.characterClasses
                      .map((characterClass) => getPartFamilyLabel(characterClass))
                      .join(', ')}
                  </li>
                ))}
              </ul>
            </Box>
          );
        })}
      </SpaceBetween>
    ),
  },
];

export const ArtifactV2HistoryTable: React.FC<ArtifactV2FileVersionsTableProps> = ({ artifact, collapsible }) => {
  return collapsible ? (
    <Container>
      <ExpandableSection headerText={'Artifact History'}>
        <Table
          columnDefinitions={ARTIFACTV2_HISTORY_COLUMN_DEFINITIONS}
          items={artifact?.changelog.slice().reverse() ?? []}
          empty={'No artifact history yet'}
          loading={false}
          variant={'borderless'}
        />
      </ExpandableSection>
    </Container>
  ) : (
    <Table
      header={<Header>History</Header>}
      columnDefinitions={ARTIFACTV2_HISTORY_COLUMN_DEFINITIONS}
      items={artifact?.changelog.slice().reverse() ?? []}
      loading={false}
    />
  );
};
