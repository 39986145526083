import { D2DGraphQLError, EnumDisplayMapping, FlashbarItemsProps, LoadingState } from '../../types';
import {
  Artifact,
  CreateArtifactOutput,
  CreateIngressArtifactOutput,
  DownloadArtifactVersionOutput,
  GetArtifactByIdInput,
  IdentifierPage,
  MutationCreateArtifactArgs,
  MutationCreateIngressArtifactArgs,
  MutationDownloadArtifactVersionArgs,
  MutationUpdateArtifactArgs,
  PartIdentifierType,
  QueryListIdentifiersByPrefixArgs,
  QuerySearchArtifactsArgs,
  UpdateArtifactOutput,
} from '@amzn/d2d-bff-schema';
import {
  Draft,
  PayloadAction,
  SerializedError,
  createAsyncThunk,
  createSlice,
  miniSerializeError,
} from '@reduxjs/toolkit';
import {
  makeCreateArtifactRequest,
  makeCreateIngressArtifactRequest,
  makeDownloadArtifactVersionRequest,
  makeGetArtifactByIdRequest,
  makeListIdentifiersByPrefixRequest,
  makeSearchArtifactRequest,
  makeUpdateArtifactRequest,
} from '../../_services/api/artifactV2';
import { isGraphQLResult, processArtifactsGqlErrors } from '../../utils/errorHandler';
import { toRumSafeString } from '../../utils/rumUtils';
import { flattenObjectsInArray } from '../../utils/export';
import { getRumClient } from '../../analytics/rum';

export type StatusType = 'pending' | 'loading' | 'finished' | 'error';

export type ArtifactsV2State = {
  artifactIngressUploadS3Url?: string;
  artifactDownloadLink?: string;
  artifactDownloadLoading: LoadingState;
  artifactIngressID?: string;
  getArtifactByIdLoading: LoadingState;
  artifact?: Artifact;
  exactSearchResults: Artifact[];
  relatedSearchResults: Artifact[];
  exactSearchResultsLoading: LoadingState;
  relatedSearchResultsLoading: LoadingState;
  createLoading: LoadingState;
  updateLoading: LoadingState;
  artifactV2SearchFlashbarItems: FlashbarItemsProps[];
  artifactV2DetailFlashbarItems: FlashbarItemsProps[];
  artifactV2CreateFlashbarItems: FlashbarItemsProps[];
  artifactV2EditFlashbarItems: FlashbarItemsProps[];
  artifactV2Errors: SerializedError[];
  unknownArtifactV2Error?: SerializedError;

  // Part Identifiers prefix search
  ipns: EnumDisplayMapping[];
  ipnsLoading: StatusType;
  ipnsNextToken?: string;
  ipnsErrorMessage?: string;

  superSkus: EnumDisplayMapping[];
  superSkusLoading: StatusType;
  superSkusNextToken?: string;
  superSkusErrorMessage?: string;

  projectExternals: EnumDisplayMapping[];
  projectExternalsLoading: StatusType;
  projectExternalsNextToken?: string;
  projectExternalErrorMessage?: string;

  partIdentifiersLoadingError?: SerializedError;
};

export const initialArtifactsV2State: ArtifactsV2State = {
  exactSearchResults: [],
  relatedSearchResults: [],
  getArtifactByIdLoading: 'idle',
  createLoading: 'idle',
  updateLoading: 'idle',
  exactSearchResultsLoading: 'idle',
  relatedSearchResultsLoading: 'idle',
  artifactV2SearchFlashbarItems: [],
  artifactV2DetailFlashbarItems: [],
  artifactV2CreateFlashbarItems: [],
  artifactV2EditFlashbarItems: [],
  artifactV2Errors: [],
  ipns: [],
  ipnsLoading: 'pending',
  superSkus: [],
  superSkusLoading: 'pending',
  projectExternals: [],
  projectExternalsLoading: 'pending',
  artifactDownloadLoading: 'idle',
};

export const getArtifactByIdAction = createAsyncThunk<
  Artifact | undefined,
  GetArtifactByIdInput,
  { rejectValue: D2DGraphQLError[] }
>('artifactsV2/getArtifactById', async (getArtifactByIdInput: GetArtifactByIdInput, { rejectWithValue }) => {
  try {
    const res = await makeGetArtifactByIdRequest(getArtifactByIdInput);
    if (res.data?.getArtifactById) {
      return res.data.getArtifactById;
    } else {
      throw new Error(`Unexpected response format from getArtifactByIdAction: ${JSON.stringify(res)}`);
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<Artifact>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

export const searchArtifactAction = createAsyncThunk<
  Artifact[] | undefined,
  QuerySearchArtifactsArgs,
  { rejectValue: D2DGraphQLError[] }
>('artifactsV2/searchArtifacts', async (searchArgs: QuerySearchArtifactsArgs, { rejectWithValue }) => {
  try {
    const res = await makeSearchArtifactRequest(searchArgs);
    if (res.data?.searchArtifacts) {
      return res.data.searchArtifacts;
    } else {
      throw new Error(`Unexpected response format from searchArtifactAction: ${JSON.stringify(res)}`);
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<Artifact>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

export const createIngressArtifactAction = createAsyncThunk<
  CreateIngressArtifactOutput | undefined,
  MutationCreateIngressArtifactArgs,
  { rejectValue: D2DGraphQLError[] }
>('artifactsV2/createIngressArtifact', async (fileInfo: MutationCreateIngressArtifactArgs, { rejectWithValue }) => {
  try {
    const res = await makeCreateIngressArtifactRequest(fileInfo.file);
    if (res.data?.createIngressArtifact) {
      return res.data.createIngressArtifact;
    } else {
      throw new Error(`Unexpected response format from createIngressArtifact: ${JSON.stringify(res)}`);
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<IdentifierPage>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

export const listPartIdentifierByPrefixAction = createAsyncThunk<
  IdentifierPage | undefined,
  QueryListIdentifiersByPrefixArgs & { isFirstPageSearch: boolean },
  { rejectValue: D2DGraphQLError[] }
>('artifactsV2/listIdentifiersByPrefix', async (input: QueryListIdentifiersByPrefixArgs, { rejectWithValue }) => {
  try {
    const res = await makeListIdentifiersByPrefixRequest(input);
    if (res.data?.listIdentifiersByPrefix) {
      return res.data.listIdentifiersByPrefix;
    } else {
      throw new Error(`Unexpected response format from listPartIdentifierByPrefixAction: ${JSON.stringify(res)}`);
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<IdentifierPage>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

export const downloadArtifactVersion = createAsyncThunk<
  DownloadArtifactVersionOutput | undefined,
  MutationDownloadArtifactVersionArgs,
  { rejectValue: D2DGraphQLError[] }
>('artifactsV2/downloadArtifactVersion', async (input: MutationDownloadArtifactVersionArgs, { rejectWithValue }) => {
  try {
    const res = await makeDownloadArtifactVersionRequest(input);
    if (res.data?.downloadArtifactVersion) {
      return res.data.downloadArtifactVersion;
    } else {
      throw new Error(`Unexpected response format from downloadArtifactVersion: ${JSON.stringify(res)}`);
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<IdentifierPage>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

export const updateArtifactV2Action = createAsyncThunk<
  UpdateArtifactOutput,
  MutationUpdateArtifactArgs,
  { rejectValue: D2DGraphQLError[] }
>('artifactsV2/updateArtifactV2', async (input: MutationUpdateArtifactArgs, { rejectWithValue }) => {
  try {
    const res = await makeUpdateArtifactRequest(input);
    if (res.data?.updateArtifact) {
      return res.data.updateArtifact;
    } else {
      throw new Error(`Unexpected response format from makeUpdateArtifactRequest: ${JSON.stringify(res)}`);
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<IdentifierPage>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

export const createArtifactV2Action = createAsyncThunk<
  CreateArtifactOutput,
  MutationCreateArtifactArgs,
  { rejectValue: D2DGraphQLError[] }
>('artifactsV2/createArtifactV2', async (input: MutationCreateArtifactArgs, { rejectWithValue }) => {
  try {
    const res = await makeCreateArtifactRequest(input);
    if (res.data?.createArtifact) {
      return res.data.createArtifact;
    } else {
      throw new Error(`Unexpected response format from makeCreateArtifactRequest: ${JSON.stringify(res)}`);
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<IdentifierPage>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

const { reducer, actions } = createSlice({
  name: 'artifactsV2',
  initialState: initialArtifactsV2State,
  reducers: {
    setArtifact: (state, { payload }: PayloadAction<Artifact>) => {
      state.artifact = payload;
    },
    setArtifactV2DetailFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.artifactV2DetailFlashbarItems = payload;
    },
    setArtifactV2CreateFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.artifactV2CreateFlashbarItems = payload;
    },
    setArtifactV2EditFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.artifactV2EditFlashbarItems = payload;
    },
    setArtifactV2SearchFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.artifactV2SearchFlashbarItems = payload;
    },
    resetLoadingStatus: (state) => {
      state.getArtifactByIdLoading = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArtifactByIdAction.pending, (state) => {
        state.getArtifactByIdLoading = 'pending';
      })
      .addCase(getArtifactByIdAction.fulfilled, (state, { payload }) => {
        if (!payload) {
          throw new Error('Undefined payload after receiving fulfilled case for getArtifactByIdAction');
        }
        state.artifact = payload;
        state.getArtifactByIdLoading = 'fulfilled';
      })
      .addCase(getArtifactByIdAction.rejected, (state, { payload, error }) => {
        handleRejectedAction(state, payload, error, 'artifactV2SearchFlashbarItems', 'getArtifactByIdLoading');
      })
      .addCase(searchArtifactAction.pending, (state, { meta }) => {
        if (meta.arg.includeHorizontallyRelatedMatches) {
          state.relatedSearchResultsLoading = 'pending';
        } else {
          state.exactSearchResultsLoading = 'pending';
        }
      })
      .addCase(searchArtifactAction.rejected, (state, { payload, meta, error }) => {
        if (meta.arg.includeHorizontallyRelatedMatches) {
          handleRejectedAction(state, payload, error, 'artifactV2SearchFlashbarItems', 'relatedSearchResultsLoading');
        } else {
          handleRejectedAction(state, payload, error, 'artifactV2SearchFlashbarItems', 'exactSearchResultsLoading');
        }
      })
      .addCase(searchArtifactAction.fulfilled, (state, { payload, meta }) => {
        if (meta.arg.includeHorizontallyRelatedMatches) {
          state.relatedSearchResults = payload ?? [];
          state.relatedSearchResultsLoading = 'idle';
        } else {
          state.exactSearchResults = payload ?? [];
          state.exactSearchResultsLoading = 'idle';
        }
      })
      .addCase(listPartIdentifierByPrefixAction.pending, (state, { meta }) => {
        state.partIdentifiersLoadingError = undefined;
        switch (meta.arg.input.idType) {
          case PartIdentifierType.IPN:
            state.ipnsLoading = 'loading';
            return;
          case PartIdentifierType.AWS_EXTERNAL_PROJECT_NAME:
            state.projectExternalsLoading = 'loading';
            return;
          case PartIdentifierType.SUPER_SKU:
            state.superSkusLoading = 'loading';
            return;
        }
      })
      .addCase(listPartIdentifierByPrefixAction.fulfilled, (state, { payload, meta }) => {
        if (!payload) {
          throw new Error('Undefined payload after receiving fulfilled case for listPartIdentifierByPrefixAction');
        }

        const partType = meta.arg.input.idType;

        const identifiers =
          payload.identifiers?.map((identifier) => {
            return {
              // TODO: Remove non-null assertion when we implement Relationship Service endpoint
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value: identifier.id!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              label: identifier.id!,
            };
          }) ?? [];
        const statusState: StatusType = payload.nextToken ? 'pending' : 'finished';
        switch (partType) {
          case PartIdentifierType.AWS_EXTERNAL_PROJECT_NAME:
            state.projectExternals = meta.arg.isFirstPageSearch
              ? [...identifiers] // reset search results
              : [...state.projectExternals, ...identifiers]; // extend search results
            state.projectExternalsLoading = statusState;
            state.projectExternalsNextToken = payload.nextToken ?? undefined;
            break;
          case PartIdentifierType.IPN:
            state.ipns = meta.arg.isFirstPageSearch
              ? [...identifiers] // reset search results
              : [...state.ipns, ...identifiers]; // extend search results
            state.ipnsLoading = statusState;
            state.ipnsNextToken = payload.nextToken ?? undefined;
            break;
          case PartIdentifierType.SUPER_SKU:
            state.superSkus = meta.arg.isFirstPageSearch
              ? [...identifiers] // reset search results
              : [...state.superSkus, ...identifiers]; // extend search results
            state.superSkusLoading = statusState;
            state.superSkusNextToken = payload.nextToken ?? undefined;
        }
      })
      .addCase(listPartIdentifierByPrefixAction.rejected, (state, { payload, meta, error }) => {
        // When there is an error on a dropdown search, we will show the error directly in the dropdown component
        const partType = meta.arg.input.idType;
        const errorType = payload ? payload[0].errorType : undefined;

        const generalErrorMessage = 'Error: Please contact GTPC Tech for assistance';

        switch (partType) {
          case PartIdentifierType.AWS_EXTERNAL_PROJECT_NAME:
            state.projectExternalErrorMessage =
              errorType && errorType.toString() === 'InvalidParameter'
                ? 'Only letters, numbers and spaces are allowed'
                : generalErrorMessage;
            state.projectExternalsLoading = 'error';
            break;
          case PartIdentifierType.IPN:
            state.ipnsErrorMessage =
              errorType && errorType.toString() === 'InvalidParameter'
                ? 'Only numbers and dashes are allowed'
                : generalErrorMessage;
            state.ipnsLoading = 'error';
            break;
          case PartIdentifierType.SUPER_SKU:
            state.superSkusErrorMessage =
              errorType && errorType.toString() === 'InvalidParameter'
                ? 'Only letters and underscores are allowed'
                : generalErrorMessage;
            state.superSkusLoading = 'error';
        }
      })
      .addCase(createIngressArtifactAction.fulfilled, (state, { payload }) => {
        state.artifactIngressUploadS3Url = payload?.uploadUrl ?? undefined;
        state.artifactIngressID = payload?.ingressArtifact.ingressArtifactID;
      })
      .addCase(createIngressArtifactAction.rejected, (state, { payload, error }) => {
        state.artifactIngressUploadS3Url = undefined;
        state.artifactIngressID = undefined;
        handleRejectedAction(state, payload, error, 'artifactV2CreateFlashbarItems');
      })
      .addCase(downloadArtifactVersion.pending, (state, { payload, meta }) => {
        state.artifactDownloadLoading = 'pending';
      })
      .addCase(downloadArtifactVersion.fulfilled, (state, { payload, meta }) => {
        state.artifactDownloadLink = payload?.presignedUrl;
        state.artifactDownloadLoading = 'fulfilled';
      })
      .addCase(downloadArtifactVersion.rejected, (state, { payload, error }) => {
        state.artifactDownloadLoading = 'rejected';
        handleRejectedAction(state, payload, error, 'artifactV2DetailFlashbarItems');
      })
      .addCase(updateArtifactV2Action.pending, (state) => {
        state.updateLoading = 'pending';
      })
      .addCase(updateArtifactV2Action.fulfilled, (state, { payload }) => {
        state.artifact = payload.artifact ?? undefined;
        state.updateLoading = 'fulfilled';
      })
      .addCase(updateArtifactV2Action.rejected, (state, { payload, error }) => {
        state.updateLoading = 'rejected';
        handleRejectedAction(state, payload, error, 'artifactV2EditFlashbarItems');
      })
      .addCase(createArtifactV2Action.pending, (state) => {
        state.createLoading = 'pending';
      })
      .addCase(createArtifactV2Action.fulfilled, (state, { payload }) => {
        state.artifact = payload.artifact ?? undefined;
        state.createLoading = 'fulfilled';
      })
      .addCase(createArtifactV2Action.rejected, (state, { payload, error }) => {
        state.createLoading = 'rejected';
        handleRejectedAction(state, payload, error, 'artifactV2CreateFlashbarItems');
      });
  },
});

export const {
  setArtifactV2DetailFlashbarItems,
  setArtifactV2CreateFlashbarItems,
  setArtifactV2EditFlashbarItems,
  setArtifactV2SearchFlashbarItems,
} = actions;

export default reducer;

/* "Private" functions: */

/**
 * Handle all concerns related to any general rejected ArtifactsV2 action, including setting up state and logging
 * the error to analytics.
 * @param state The Redux state, which may be mutated by this function.
 * @param payload The errors, if any, from a request to server.
 * @param error The error
 * @param flashbarItemsField The field within state which should be set with flashbar items
 * @param stateFieldRejected The field within state which should be set to rejected
 * @returns void
 */
const handleRejectedAction = (
  state: Draft<ArtifactsV2State>,
  payload: D2DGraphQLError[] | undefined,
  error: SerializedError,
  flashbarItemsField:
    | 'artifactV2DetailFlashbarItems'
    | 'artifactV2SearchFlashbarItems'
    | 'artifactV2CreateFlashbarItems'
    | 'artifactV2EditFlashbarItems',
  stateFieldRejected?: 'getArtifactByIdLoading' | 'exactSearchResultsLoading' | 'relatedSearchResultsLoading'
): void => {
  // Log to analytics/RUM
  try {
    const rumSafeString = toRumSafeString(flattenObjectsInArray(payload));
    getRumClient()?.recordError(new Error(`Request to server was rejected. Payload: ${rumSafeString}`));
  } catch (e) {
    console.error('Failed to send to rum', e);
  }
  // Update State
  if (payload) {
    state[flashbarItemsField] = processArtifactsGqlErrors(payload);
    if (payload) state.artifactV2Errors = payload;
  } else {
    state.unknownArtifactV2Error = miniSerializeError(error);
  }
  if (stateFieldRejected) {
    state[stateFieldRejected] = 'rejected';
  }
};
