import { useEffect, useState } from 'react';
import { ArtifactFormInputs } from '../../components/ArtifactCommon';

type ArtifactFormError = { message: string; hasError: boolean };

const DEFAULT_FORM_ERROR = { message: '', hasError: false };

type ArtifactFormFieldRequiredValidator = (form: Partial<ArtifactFormInputs>) => boolean;

const requiredValidators: Record<string, ArtifactFormFieldRequiredValidator> = {
  otherSourceName: ({ otherSourceName, source }) => {
    return source?.value === 'OTHER' && !otherSourceName;
  },
};

const useValidationArtifactForm = (
  artifactFormInputs: Partial<ArtifactFormInputs>,
  isSubmitted: boolean
): {
  otherSourceNameValidation: ArtifactFormError;
} => {
  const { source, otherSourceName } = artifactFormInputs;

  const [otherSourceNameValidation, setOtherSourceNameValidation] = useState(DEFAULT_FORM_ERROR);

  useEffect(() => {
    if (isSubmitted && requiredValidators.otherSourceName(artifactFormInputs)) {
      setOtherSourceNameValidation({
        hasError: true,
        message: `Other Source Name is mandatory when source is selected as 'Other'`,
      });
    } else {
      setOtherSourceNameValidation({ hasError: false, message: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, otherSourceName, isSubmitted]);

  return {
    otherSourceNameValidation,
  };
};

export default useValidationArtifactForm;

/**
 *
 * @param form Inputs from user for a Artifact form
 * @return true if 1 or more errors are found for the given form. False otherwise.
 */
export const hasErrors = (form: Partial<ArtifactFormInputs>): boolean => {
  return Object.entries(requiredValidators).some((entry) => entry[1](form));
};
