import { createAsyncThunk, createSlice, miniSerializeError } from '@reduxjs/toolkit';
import { formatClassification, formatESResults } from 'utils/classificationHelpers';
import { makeCreateRequest, makeGetRequest, makeSearchRequest, makeUpdateRequest } from '_services/api/classification';
import { FULFILLED, PENDING, REJECTED } from '../../utils/constants';
import { isGraphQLResult } from '../../utils/errorHandler';
import { createErrorItem } from '../../utils/formUtils';

export const initialTradeClassificationState = {
  classification: [],
  classificationLoading: '',
  classifications: [],
  classificationsLoading: '',
  createError: false,
  classificationCreateFlashbarItems: [],
  USClassification: [],
  USClassificationError: null,
  USClassificationLoading: '',
  error: null,
  relatedClassifications: [],
  relatedClassificationsLoading: '',
  relatedClassificationsError: null,
};

export const createClassificationAction = createAsyncThunk(
  'classification/createClassificationAction',
  async ({ newClassification }, { rejectWithValue }) => {
    try {
      return await makeCreateRequest(newClassification);
    } catch (e) {
      const gqlResult = isGraphQLResult(e);
      if (gqlResult && gqlResult.errors) {
        return rejectWithValue(gqlResult.errors);
      } else {
        throw e;
      }
    }
  }
);

export const getClassificationAction = createAsyncThunk(
  'classification/getClassificationAction',
  async ({ ic, assetId }) => {
    const assetIdDecoded = decodeURIComponent(assetId);
    const classification = await makeGetRequest(ic, assetIdDecoded);
    const formatGQL = classification.data.getClassification;
    return formatClassification(formatGQL);
  }
);

// On details page, if there is a U.S. classification for part
// we link to the U.S. classification under U.S. ECN for Part header
export const getUsEcnAction = createAsyncThunk('classification/getUsEcnAction', async ({ ic, assetId }) => {
  const assetIdDecoded = decodeURIComponent(assetId);
  const classification = await makeGetRequest(ic, assetIdDecoded);
  const formatGQL = classification.data.getClassification;
  return formatClassification(formatGQL);
});

export const updateClassificationAction = createAsyncThunk(
  'classification/updateClassificationAction',
  async ({ updatedClassification }) => {
    return makeUpdateRequest(updatedClassification);
  }
);

// OpenSearch - search all Classifications
export const searchClassificationsAction = createAsyncThunk(
  'classification/searchClassificationsAction',
  async (searchTerm) => {
    const classifications = await makeSearchRequest(searchTerm);
    const formatGQL = classifications.data.searchClassifications;
    const formattedClassifications = formatESResults(formatGQL);
    return formattedClassifications;
  }
);
// OpenSearch - search all Classifications & filter for related ones
export const searchRelatedClassificationsAction = createAsyncThunk(
  'classification/relatedClassificationsAction',
  async ({ searchTerm, ic, location }) => {
    const classifications = await makeSearchRequest(searchTerm);
    const formatGQL = classifications.data.searchClassifications;
    const formattedClassifications = formatESResults(formatGQL);
    const relatedClassifications = [];
    formattedClassifications.forEach((element) => {
      // on details page, check for other classifications with the same assetId
      if (location === 'classification') {
        if (element.assetId === searchTerm) {
          relatedClassifications.push(element);
        }
      } else if (location === 'create') {
        // on create page, check if the user has entered a country & IPN or nonPlmPartName
        // that match a classification already in the system
        if (element.ipn === searchTerm && element.countryISO2 === ic) {
          relatedClassifications.push(element);
        } else if (element.nonPlmPartName === searchTerm && element.countryISO2 === ic) {
          relatedClassifications.push(element);
        }
      }
    });

    return relatedClassifications;
  }
);

const { reducer, actions } = createSlice({
  name: 'classification',
  initialState: initialTradeClassificationState,
  reducers: {
    setClassifications: (state, { payload }) => {
      state.classifications = payload ?? [];
    },

    clearRelatedClassifications: (state) => {
      state.relatedClassifications = [];
    },

    setClassificationCreateFlashbarItems: (state, { payload }) => {
      state.classificationCreateFlashbarItems = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // create
      .addCase(createClassificationAction.pending, (state) => {
        state.classificationLoading = PENDING;
      })
      .addCase(createClassificationAction.fulfilled, (state, { payload }) => {
        state.classification = payload;
        state.classificationLoading = FULFILLED;
        state.error = null;
      })
      .addCase(createClassificationAction.rejected, (state, { payload, error }) => {
        if (
          payload &&
          payload.find(
            (returnedError) => returnedError.errorType === '400' && returnedError.message.includes('already exists')
          )
        ) {
          state.classificationCreateFlashbarItems = [
            createErrorItem('A Classification with that country and ipn/mpn already exists'),
          ];
        } else {
          state.classificationCreateFlashbarItems = [
            createErrorItem(
              'Oops! Something went wrong on our end! Please contact the GTPC tech team for further assistance'
            ),
          ];
        }

        state.classification = [];
        state.error = miniSerializeError(error);
        state.createError = true;
        state.classificationLoading = REJECTED;
      })
      // get single
      .addCase(getClassificationAction.pending, (state) => {
        state.classificationLoading = PENDING;
      })
      .addCase(getClassificationAction.fulfilled, (state, { payload }) => {
        state.classification = payload;
        state.classificationLoading = FULFILLED;
      })
      .addCase(getClassificationAction.rejected, (state, { error }) => {
        state.classification = [];
        if (Object.keys(error).length === 0) {
          state.error = 'Error retrieving classification for these Country + Part parameters';
        } else {
          state.error = miniSerializeError(error);
        }
        state.classificationLoading = REJECTED;
      })
      // get US ECN
      .addCase(getUsEcnAction.pending, (state) => {
        state.USClassificationLoading = PENDING;
      })
      .addCase(getUsEcnAction.fulfilled, (state, { payload }) => {
        state.USClassification = payload;
        state.USClassificationLoading = FULFILLED;
      })
      .addCase(getUsEcnAction.rejected, (state, { error }) => {
        state.USClassification = [];
        state.USClassififcationError = miniSerializeError(error);
        state.USClassificationLoading = REJECTED;
      })
      // update single
      .addCase(updateClassificationAction.pending, (state) => {
        state.classificationLoading = 'pending';
      })
      .addCase(updateClassificationAction.fulfilled, (state) => {
        state.classificationLoading = 'fulfilled';
      })
      .addCase(updateClassificationAction.rejected, (state, { error }) => {
        state.error = miniSerializeError(error);
        state.classificationLoading = 'rejected';
      })

      // OpenSearch all
      .addCase(searchClassificationsAction.pending, (state) => {
        state.classificationsLoading = 'pending';
      })
      .addCase(searchClassificationsAction.fulfilled, (state, { payload }) => {
        state.classifications = payload;
        state.classificationsLoading = 'fulfilled';
      })
      .addCase(searchClassificationsAction.rejected, (state, { error }) => {
        state.classifications = [];
        state.error = miniSerializeError(error);
        state.classificationsLoading = 'rejected';
      })
      // OpenSearch relatedSearch
      .addCase(searchRelatedClassificationsAction.pending, (state) => {
        state.relatedClassificationsLoading = 'pending';
      })
      .addCase(searchRelatedClassificationsAction.fulfilled, (state, { payload }) => {
        state.relatedClassifications = payload;
        state.relatedClassificationsLoading = 'fulfilled';
      })
      .addCase(searchRelatedClassificationsAction.rejected, (state, { error }) => {
        state.relatedClassifications = [];
        state.relatedClassificationsError = miniSerializeError(error);
        state.relatedClassificationsLoading = 'rejected';
      });
  },
});

export const { clearRelatedClassifications, setClassificationCreateFlashbarItems, setClassifications } = actions;

export default reducer;
