import { Amplify, Auth } from 'aws-amplify';
import { beta, gamma, local, prod } from '../../stage_config';

/**
 * Grabs configuration based on the application URL and configures AWS amplify Auth/Api modules
 * @return {Promise<void>} an empty promise
 */
export async function amplifyInit(): Promise<void> {
  const origin = window.location.origin;
  let awsconfig = null;

  // get environment config for amplify at runtime
  if (process.env.NODE_ENV === 'development') {
    awsconfig = local;
  } else if (origin.includes('local')) {
    awsconfig = local;
  } else {
    if (origin.includes('beta')) {
      awsconfig = beta;
    } else if (origin.includes('gamma')) {
      awsconfig = gamma;
    } else {
      awsconfig = prod;
    }
  }
  // https://docs.amplify.aws/lib/graphqlapi/authz/q/platform/js/#cognito-user-pools
  Amplify.configure({
    ...awsconfig,
    API: {
      ...awsconfig.API,
      graphql_headers: async () => {
        const session = await Auth.currentSession();
        return {
          Authorization: session.getIdToken().getJwtToken(),
        };
      },
    },
  });
}
