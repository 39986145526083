import { optionDefinitionSchema } from 'utils/formUtils';
import { AuthorizationType, Commodity, Country, Currency, Source, TransactionDirection } from '@amzn/d2d-bff-schema';
import * as z from 'zod';
import { COMMODITY, COUNTRIES, LM_AUTHORIZATION_TYPE, SOURCE, TRANSACTION_DIRECTION } from 'utils/constants';
import { POLARIS_DATE_PATTERN, formatFromEpoch } from 'utils/dateFormat';
import { getUnixTime, parse } from 'date-fns';

export const lmFormSchema = z.object({
  licenseNumber: z.string().nonempty(),
  transactionDirection: optionDefinitionSchema,
  issuingCountry: optionDefinitionSchema,
  allowedImportCountries: z.array(optionDefinitionSchema).optional().nullable(),
  allowedExportCountries: z.array(optionDefinitionSchema).optional().nullable(),
  effectiveDate: z.string().min(10).optional(),
  expirationDate: z.string().optional(),
  hts: z.array(z.string().nonempty()).optional(),
  ipn: z.array(optionDefinitionSchema).optional(),
  mpn: z.array(z.string().nonempty()).optional(),
  eccn: z.array(z.string().nonempty()).optional(),
  unitLimit: z.number().nonnegative().optional().nullable(),
  valueLimit: z.number().nonnegative().optional().nullable(),
  valueCurrency: z
    .nativeEnum(Currency)
    .optional()
    .refine((value) => value, { message: 'Required' }),
  validEndUser: z.string().optional().nullable(),
  singleUse: z.boolean().default(false),
  consignee: z.string().optional().nullable(),
  notes: z.string().optional().nullable(),
  manualReviewRequired: z.boolean().default(false),
  authorizationType: optionDefinitionSchema.optional().nullable(),
  techControlPlanRequired: z.boolean().default(false),
  applicationNumber: z.string().optional().nullable(),
  externalId: z.string().optional().nullable(),
  nonPlmPartNames: z.array(z.string().nonempty()).optional(),
  disabled: z.boolean().default(false),
  commodity: optionDefinitionSchema.optional().nullable(),
  applicationDate: z.string(),
  exporter: z.string(),
  source: optionDefinitionSchema,
  otherSourceName: z.string().optional().nullable(),
});

export const lmApiSchema = lmFormSchema.extend({
  transactionDirection: z.string(),
  issuingCountry: z.string(),
  allowedImportCountries: z.array(z.string()).optional().nullable(),
  allowedExportCountries: z.array(z.string()).optional().nullable(),
  effectiveDate: z.number().optional().nullable(),
  expirationDate: z.number().optional().nullable(),
  applicationDate: z.number().optional().nullable(),
  singleUse: z.boolean().default(false),
  authorizationType: z.string().optional().nullable(),
  ipn: z.array(z.string().nonempty()).nullable().optional(),
  mpn: z.array(z.string().nonempty()).nullable().optional(),
  hts: z.array(z.string().nonempty()).nullable().optional(),
  eccn: z.array(z.string().nonempty()).nullable().optional(),
  nonPlmPartNames: z.array(z.string().nonempty()).nullable().optional(),
  valueCurrency: z.nativeEnum(Currency),
  disabled: z.boolean().default(false),
  commodity: z.string().optional().nullable(),
  exporter: z.string().nullable(),
  source: z.string(),
  otherSourceName: z.string().optional().nullable(),
});

export type LicenseFormInputs = z.infer<typeof lmFormSchema>;

export const lmApiSchemaToFormSchema = lmApiSchema.transform((license) => {
  return {
    ...license,
    effectiveDate: license.effectiveDate ? formatFromEpoch(license.effectiveDate, POLARIS_DATE_PATTERN) : undefined,
    expirationDate: license.expirationDate ? formatFromEpoch(license.expirationDate, POLARIS_DATE_PATTERN) : undefined,
    applicationDate: license.applicationDate
      ? formatFromEpoch(license.applicationDate, POLARIS_DATE_PATTERN)
      : undefined,
    transactionDirection: TRANSACTION_DIRECTION.find((td) => td.value === license.transactionDirection),
    issuingCountry: COUNTRIES.find((country) => country.value === license.issuingCountry),
    allowedImportCountries: COUNTRIES.filter((country) =>
      license.allowedImportCountries?.includes(country.value.toString())
    ),
    allowedExportCountries: COUNTRIES.filter((country) =>
      license.allowedExportCountries?.includes(country.value.toString())
    ),
    authorizationType: LM_AUTHORIZATION_TYPE.find((ia) => ia.value === license.authorizationType),
    ipn: license.ipn?.map((value) => ({
      value,
      label: value,
    })),
    mpn: license.mpn ?? [],
    eccn: license.eccn ?? [],
    hts: license.hts ?? [],
    nonPlmPartNames: license.nonPlmPartNames ?? [],
    commodity: COMMODITY.find((commodity) => commodity.value === license.commodity),
    exporter: license.exporter ? license.exporter : undefined,
    source: SOURCE.find((source) => source.value === license.source),
    otherSourceName: license.otherSourceName,
  };
});

export const lmFormSchemaToApiSchema = lmFormSchema.transform((license) => {
  return {
    ...license,
    effectiveDate: license.effectiveDate
      ? getUnixTime(parse(license.effectiveDate, POLARIS_DATE_PATTERN, new Date()))
      : undefined,
    expirationDate: license.expirationDate
      ? getUnixTime(parse(license.expirationDate, POLARIS_DATE_PATTERN, new Date()))
      : undefined,
    applicationDate: getUnixTime(parse(license.applicationDate, POLARIS_DATE_PATTERN, new Date())),
    transactionDirection: license.transactionDirection?.value as TransactionDirection,
    issuingCountry: license.issuingCountry.value as Country,
    allowedImportCountries: license.allowedImportCountries?.map((c) => c.value as Country),
    allowedExportCountries: license.allowedExportCountries?.map((c) => c.value as Country),
    authorizationType: license.authorizationType?.value as AuthorizationType,
    ipn: license.ipn?.map((c) => c.value),
    unitLimit: license?.unitLimit ?? undefined,
    valueLimit: license?.valueLimit ?? undefined,
    // Value Currency is required, above schema needs to mark optional so that zod doesn't return its own, not user friendly error message
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    valueCurrency: license.valueCurrency!,
    validEndUser: license?.validEndUser ?? undefined,
    consignee: license?.consignee ?? undefined,
    notes: license?.notes ?? undefined,
    applicationNumber: license?.applicationNumber ?? undefined,
    externalId: license?.externalId ?? undefined,
    commodity: license?.commodity?.value as Commodity,
    source: license.source?.value as Source,
    otherSourceName: license.otherSourceName ?? undefined,
  };
});

export { default } from './LicenseManagerCommon';
