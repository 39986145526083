import React from 'react';
import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import LMCreateContent from './LicenseManagerCreate';
import { flashbarItemsCreator } from 'utils/formUtils';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { setLMCreateFlashbarItems } from 'store/licenseManager';

const LicenseManagerCreateLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { lmCreateFlashbarItems } = useAppSelector((state) => state.licenseManagerStore);

  return (
    <AppLayout
      contentType="form"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={<Header variant="h1">Create License</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'License Manager', href: `/license-manager` },
            { text: 'Create', href: '#' },
          ]}
        />
      }
      content={<LMCreateContent />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar items={flashbarItemsCreator(lmCreateFlashbarItems, dispatch, setLMCreateFlashbarItems)} />
      }
      stickyNotifications
      toolsHide
    />
  );
};

export default LicenseManagerCreateLayout;
