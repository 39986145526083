import { Container, FormField, Header, Select, SpaceBetween } from '@amzn/awsui-components-react';

import PcClassificationCreate from '../PcClassificationCreate';
import TradeClassificationCreate from '../TradeClassificationCreate';
import React, { useState } from 'react';
import { EnumDisplayMapping } from 'types';
import { CLASSIFICATION_TYPES } from 'utils/constants';

const ClassificationCreate: React.FC = () => {
  const [classificationTypeSelection, setClassificationTypeSelection] = useState<Partial<EnumDisplayMapping> | null>(
    null
  );

  const tradeClassificationCreate = <TradeClassificationCreate />;

  const pcClassificationCreate = <PcClassificationCreate />;

  return (
    <SpaceBetween size="l">
      <Container header={<Header>Classification Type</Header>}>
        <SpaceBetween size="l">
          <FormField label="Classification Type" description="Choose the type of classification to create">
            <Select
              filteringType="auto"
              selectedOption={classificationTypeSelection}
              options={CLASSIFICATION_TYPES}
              onChange={(e) => {
                setClassificationTypeSelection(e.detail.selectedOption);
              }}
              placeholder="Choose a classification type"
            />
          </FormField>
        </SpaceBetween>
      </Container>
      {classificationTypeSelection?.filteringTags?.includes('trade') && tradeClassificationCreate}
      {classificationTypeSelection?.filteringTags?.includes('pc') && pcClassificationCreate}
    </SpaceBetween>
  );
};

export default ClassificationCreate;
