import { EnumDisplayMapping } from '../../types';

export const TABLE_SIZE_PREFERENCES = {
  title: 'Select page size',
  options: [
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ],
};

export enum ClassificationsSearchModes {
  PC_CLASSIFICATIONS = 'PC_CLASSIFICATIONS',
  TRADE_CLASSIFICATIONS = 'TRADE_CLASSIFICATIONS',
}
export const CLASSIFICATIONS_SEARCH_MODES_LABELS: EnumDisplayMapping[] = [
  { value: ClassificationsSearchModes.PC_CLASSIFICATIONS, label: 'PC Classifications' },
  { value: ClassificationsSearchModes.TRADE_CLASSIFICATIONS, label: 'Trade Classifications' },
];
