import React from 'react';
import {
  Box,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  Table,
  TableProps,
} from '@amzn/awsui-components-react';
import useCollectionWithFilterType from 'hooks/useCollectionWithFilterType';
import { PAGINATION_ARIA_LABELS, getSizeCookie } from './tableConfig';
import { FilterType } from 'hooks/useCollectionWithFilterType/useCollectionWithFilterType';
import useLocalStorage from 'hooks/useLocalStorage';
import { TABLE_SIZE_PREFERENCES } from '../TradeClassificationsTable/TradeClassificationsTableConfig';

export const EmptyState: React.FC<{
  title: string;
  subtitle: string;
  action: JSX.Element | null;
}> = ({ title, subtitle, action }) => {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      <div>{action}</div>
    </Box>
  );
};

type CollectionHooksTableProps<T> = {
  allItems: T[];
  visibleContent?: string[];
  localStorageKey?: string[];
  visibleContentPreference?: CollectionPreferencesProps.VisibleContentPreference;
  loading: boolean;
  loadingText?: string;
  headerTitle?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnDefinitions: readonly TableProps.ColumnDefinition<any>[];
  disableHeader?: boolean;
  header?: React.ReactNode;
  empty?: React.ReactNode;
  filterOptions?: FilterType;
  selectionType?: TableProps.SelectionType;
  resizeableColumns: boolean;
  preferenceLocalStorageKey: string;
};

export const CollectionHooksTable = <T extends Record<string, unknown>>({
  allItems,
  visibleContentPreference,
  visibleContent,
  loading,
  loadingText,
  headerTitle,
  columnDefinitions,
  disableHeader,
  header,
  empty,
  filterOptions = { type: 'text', filteringProperties: [], selectFilters: [] },
  resizeableColumns = true,
  selectionType,
  preferenceLocalStorageKey,
}: CollectionHooksTableProps<T>): JSX.Element => {
  const [preferences, setPreferences] = useLocalStorage<CollectionPreferencesProps.Preferences<T>>(
    preferenceLocalStorageKey,
    {
      pageSize: getSizeCookie(),
      visibleContent: visibleContent,
    }
  );

  const [filter, collection] = useCollectionWithFilterType(allItems, filterOptions, preferences);

  const { collectionProps, items, paginationProps, filteredItemsCount } = collection;

  const count = `(${filteredItemsCount})`;
  const renderedHeader = disableHeader ? null : <Header counter={count}>{headerTitle}</Header>;

  return (
    <Table
      {...collectionProps}
      wrapLines
      selectionType={selectionType}
      loading={loading}
      loadingText={loadingText}
      header={header ? header : renderedHeader}
      columnDefinitions={columnDefinitions}
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_ARIA_LABELS} />}
      empty={collectionProps?.empty || empty}
      filter={filter}
      resizableColumns={resizeableColumns}
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          visibleContentPreference={visibleContentPreference}
          pageSizePreference={TABLE_SIZE_PREFERENCES}
          onConfirm={({ detail }) => {
            setPreferences(detail);
            document.cookie = `sizePreference=${detail.pageSize}`;
          }}
        />
      }
    />
  );
};
