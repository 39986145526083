import { Button, ColumnLayout, Container, Header, Pagination, SpaceBetween, Table } from '@amzn/awsui-components-react';
import React from 'react';
import { License, Usage } from '@amzn/d2d-bff-schema';
import ValueWithLabel from 'components/ValueWithLabel';
import FileUploadTable from 'components/FileUploadTable';
import { getOptionByValue } from 'utils/formUtils';
import { COMMODITY, COUNTRIES, LM_AUTHORIZATION_TYPE, SOURCE, TRANSACTION_DIRECTION } from 'utils/constants';
import { formatDateFromUnix } from 'utils/dateFormat';
import { useAppSelector } from 'store/store';
import { LICENSE_FILTERING_PROPERTIES, USAGE_COLUMN_DEFINITIONS } from '.';
import useCollectionWithFilterType from 'hooks/useCollectionWithFilterType';
import { Link } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';

interface LMDetailPageProps {
  license?: License;
}

const D2D_FILES_SERVICE_NAME = 'd2dlicense';

const LicenseManagerDetails: React.FC<LMDetailPageProps> = ({ license }) => {
  const { getLoading } = useAppSelector((state) => state.licenseManagerStore);

  const [filter, { items, collectionProps, paginationProps }] = useCollectionWithFilterType(
    license?.usages?.filter((usage: Usage) => !usage.canceled) || [],
    { type: 'property', filteringProperties: LICENSE_FILTERING_PROPERTIES, customGroupFilters: [] },
    { pageSize: 15 }
  );
  const detailsHeader = <Header>License</Header>;
  // const linkedHeader = (
  //   <Header
  //     actions={
  //       <SpaceBetween direction="horizontal" size="xs">
  //         <Button>Add Linked License</Button>
  //       </SpaceBetween>
  //     }
  //   >
  //     Linked Licenses
  //   </Header>
  // );

  const usagesHeader = (
    <Header
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <SpaceBetween direction="horizontal" size="xs">
            <Link to={`/license/${license?.licenseId}/create/usage?cc=${license?.valueCurrency}`}>
              <Button className="icon-btn-align" variant="primary">
                Create Usage
              </Button>
            </Link>
          </SpaceBetween>
        </SpaceBetween>
      }
    >
      {license?.licenseNumber} Usages
    </Header>
  );
  const totalsHeader = <Header>Current Usage Totals &amp; Limits</Header>;

  // fileSetId is licenseId
  const fileUploadTable = <FileUploadTable serviceName={D2D_FILES_SERVICE_NAME} fileSetId={license?.licenseId} />;

  const licenseDetails = (
    <>
      <Container header={detailsHeader}>
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="License Number">{license?.licenseNumber}</ValueWithLabel>
            <ValueWithLabel label="Application Number">{license?.applicationNumber ?? '-'}</ValueWithLabel>
            <ValueWithLabel label="Transaction Direction">
              {getOptionByValue(license?.transactionDirection, TRANSACTION_DIRECTION)?.label ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Authorization Type">
              {getOptionByValue(license?.authorizationType, LM_AUTHORIZATION_TYPE)?.label ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Commodity">
              {getOptionByValue(license?.commodity, COMMODITY)?.label ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label="External Id">{license?.externalId ?? '-'}</ValueWithLabel>
            <ValueWithLabel label="IPN">
              {license?.ipn?.length ? license?.ipn.map((ipn) => <li key={ipn}>{ipn}</li>) : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="HTS">
              {license?.hts?.length ? license?.hts.map((hts) => <li key={hts}>{hts}</li>) : '-'}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Application Date">
              {license?.applicationDate ? formatDateFromUnix(license?.applicationDate) : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Effective Date">
              {license?.effectiveDate ? formatDateFromUnix(license?.effectiveDate) : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Issuing Country">
              {getOptionByValue(license?.issuingCountry, COUNTRIES)?.label ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Export To Countries">
              {/* TODO: Refactor this label when implementing Import Authorization functionality */}
              {license?.allowedImportCountries?.map((c) => <li key={c}>{c}</li>) ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Export From Countries">
              {/* TODO: Refactor this label when implementing Import Authorization functionality */}
              {license?.allowedExportCountries?.map((c) => <li key={c}>{c}</li>) ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label="ECCN">
              {license?.eccn?.length ? license?.eccn.map((eccn) => <li key={eccn}>{eccn}</li>) : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Consignee">{license?.consignee ?? '-'}</ValueWithLabel>
            <ValueWithLabel label="Valid End User">{license?.validEndUser ?? '-'}</ValueWithLabel>
            {license?.transactionDirection !== 'EXPORT' && (
              <ValueWithLabel label="MPN">
                {license?.mpn?.length ? license?.mpn.map((mpn) => <li key={mpn}>{mpn}</li>) : '-'}
              </ValueWithLabel>
            )}
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Expiration Date">
              {license?.expirationDate ? formatDateFromUnix(license?.expirationDate) : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Single Use">{license?.singleUse ? 'Yes' : 'No'}</ValueWithLabel>
            <ValueWithLabel label="Value Currency">{license?.valueCurrency ?? '-'}</ValueWithLabel>
            <ValueWithLabel label="Non-Agile Part Name">
              {license?.nonPlmPartNames?.length
                ? license?.nonPlmPartNames.map((name) => <li key={name}>{name}</li>)
                : '-'}
            </ValueWithLabel>
            <ValueWithLabel label="Source">{getOptionByValue(license?.source, SOURCE)?.label ?? '-'}</ValueWithLabel>
            <ValueWithLabel label="Other Source Name">{license?.otherSourceName ?? '-'}</ValueWithLabel>
            <ValueWithLabel label="Disabled">{license?.disabled ? 'Yes' : 'No'}</ValueWithLabel>
            <ValueWithLabel label="License Notes">{license?.notes ?? '-'}</ValueWithLabel>
            <ValueWithLabel label="Exporter">{license?.exporter ?? '-'}</ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
      {/* <Container header={linkedHeader}></Container> */}
      {fileUploadTable}

      <Table
        header={usagesHeader}
        columnDefinitions={USAGE_COLUMN_DEFINITIONS}
        items={items}
        loading={getLoading === 'pending'}
        {...collectionProps}
        filter={filter}
        pagination={<Pagination {...paginationProps} />}
      />

      <Container header={totalsHeader}>
        <ColumnLayout columns={4} variant="text-grid">
          <ValueWithLabel label="Total Count">{license?.usageCount}</ValueWithLabel>
          <ValueWithLabel label="Total Value">
            {getSymbolFromCurrency(license?.valueCurrency ?? '') ?? ''}
            {license?.usageValue} {license?.valueCurrency}
          </ValueWithLabel>
          <ValueWithLabel label="Unit Limit">
            {license?.usageCount}/{license?.unitLimit ?? '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Value Limit">
            {license?.usageValue}/{license?.valueLimit ?? '-'}
          </ValueWithLabel>
        </ColumnLayout>
      </Container>
    </>
  );

  return <SpaceBetween size="l">{licenseDetails}</SpaceBetween>;
};

export default LicenseManagerDetails;
