import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { PccPart } from '@amzn/d2d-bff-schema';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getPCCPartAction } from 'store/pcClassification/pcClassificationSlice';

const useValidatePccPartParams = (
  partSource?: string,
  partIdentifierType?: string,
  partIdentifierValue?: string
): { pccPart?: PccPart; isLoading: boolean; isError: boolean } => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pccPart, getLoading } = useAppSelector((state) => state.pcClassificationStore);

  useEffect(() => {
    const asyncGetPccPart = async (): Promise<void> => {
      if (partSource && partIdentifierType && partIdentifierValue) {
        const res = await dispatch(
          getPCCPartAction({
            partSource,
            partIdentifierType,
            partIdentifierValue,
          })
        );
        if (res?.meta?.requestStatus === 'rejected') {
          navigate('/classifications');
        }
      }
    };
    asyncGetPccPart();
  }, [dispatch, partSource, partIdentifierType, partIdentifierValue, navigate]);

  return {
    isLoading: getLoading !== 'fulfilled' && getLoading !== 'rejected',
    isError: getLoading === 'rejected' && !pccPart,
    pccPart,
  };
};

export default useValidatePccPartParams;
