import { useEffect } from 'react';
import { FlashbarItemsProps } from '../../types';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

const useClearFlashbarItems = (setFlashbarItems: ActionCreatorWithPayload<FlashbarItemsProps[], string>): void => {
  const dispatch = useDispatch();
  // clear create page flashbar items on component unmount
  useEffect(() => {
    return () => {
      dispatch(setFlashbarItems([]));
    };
  }, [dispatch, setFlashbarItems]);
};

export default useClearFlashbarItems;
