import { Artifact, CharacterClass, FieldType, PartFamily } from '@amzn/d2d-bff-schema';
import { EnumDisplayMapping } from '../../types';
import { ARTIFACTSV2_COUNTRIES, ARTIFACT_MENU_OPTIONS, ARTIFACT_SUBTYPE_MENU_OPTIONS } from '../../utils/constants';
import { StatusIndicator } from '@amzn/awsui-components-react';
import moment from 'moment';

export const DEFAULT_EMPTY_FIELD_VALUE = '-';

/**
 * Parses metadata values into JSX elements. Dates are formatted into their string representation, arrays are displayed as <li> lists.
 * If the key does not exist in the data, a value of '-' is returned. If the key does not exist in the schema, the raw value is returned.
 * @param key The map key
 * @param artifact The artifact to parse
 * @returns the JSX element of the value
 */
export const parseMetadataValue = (key: string, artifact?: Artifact): string[] | undefined => {
  if (!artifact) {
    return undefined;
  }
  const metadataField = artifact.metadata.data.find((field) => field.key === key);
  const metadataSchema = artifact.metadata.schema?.find((field) => field.key === key);

  if (!metadataField) {
    // If key does not exist, return undefined
    return undefined;
  }

  if (!metadataSchema || !metadataSchema.type) {
    // if no schema exists or if the schema does not have a type, return the raw value.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return [metadataField.value.join(',')];
  }

  const hasValue = metadataField.value.length > 0;

  switch (metadataSchema.type) {
    case FieldType.STRING:
      return [hasValue ? metadataField.value[0] : '-'];
    case FieldType.INTEGER:
      return [hasValue ? metadataField.value[0] : '-'];
    case FieldType.STRING_ARRAY:
      // Parse JSON array and return <li> list of elements
      return metadataField.value;
    case FieldType.DATE:
      // Format string date into UI string representation
      return [formatArtifactsV2StringDate(metadataField.value[0])];
    default:
      console.error(`Field Type not implemented: ${metadataSchema.type}`);
      return [metadataField.value.join(',')];
  }
};

export const getLabel = (value: string | undefined, values: EnumDisplayMapping[]): string => {
  if (!value) {
    return DEFAULT_EMPTY_FIELD_VALUE;
  }
  const label = values.find((item) => item.value === value);
  return label?.label ? label.label : value;
};

export const getArtifactsV2MetadataValue = (key: string, artifact?: Artifact): string[] | undefined => {
  if (!artifact) {
    return undefined;
  }
  return artifact?.metadata.data.find((metadataField) => metadataField.key === key)?.value;
};

/**
 * This date formatter takes a UTC date string representation and returns a string formatted to be the exact same time
 * as the provided UTC string, no matter what timezone the client is in
 * @param dateString UTC date string representation (e.g.
 * @param formatString The way to format the returned date string (e.g. 'YYYY/MM/DD'). Use 'moment' format syntax.
 * @returns Date in string representation
 */
export const formatArtifactsV2StringDate = (dateString: string, formatString = 'YYYY/MM/DD'): string => {
  return moment(dateString).utc().format(formatString);
};

/**
 * Converts the partFamilies API object into string representations
 * Each part family is separated by a newline character
 * Example:
 * API object: "partFamilies": [
 *     {
 *         "userPattern": "ABC-X-10",
 *         "replacementCharacters": [
 *             {
 *                 "character": "X",
 *                 "characterClass": "SINGLE_NUMERIC"
 *             }
 *         ]
 *     }
 * ]
 * Formatted: ABC-X-10 where X is 0-9 (single)
 * @param partFamily the part families to convert to string displays
 * @returns string formatted part families as a string
 */
export const formatPartFamilyDisplay = (partFamily: PartFamily[]): string => {
  return partFamily
    .map((partFamily) => {
      return `${partFamily.userPattern} where ${partFamily.replacementCharacters
        .map((replacement) => {
          return `${replacement.character} is ${replacement.characterClasses
            .map((characterClass) => getPartFamilyLabel(characterClass))
            .join(', ')}`;
        })
        .join(' and ')}`;
    })
    .join('\n');
};

export const clickDownloadLink = (url: string): void => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getHistoryTableTransformedLabel = (key: string, label: string[]): string => {
  if (!label.length) {
    return DEFAULT_EMPTY_FIELD_VALUE;
  }

  switch (key) {
    case 'expiration':
      return formatArtifactsV2StringDate(label[0]);
    case 'applicableCountries':
      return label.map((country) => getLabel(country, ARTIFACTSV2_COUNTRIES)).join(', ');
    case 'type':
      return label.map((type) => getLabel(type, ARTIFACT_MENU_OPTIONS)).join(', ');
    case 'subtype':
      return label.map((type) => getLabel(type, ARTIFACT_SUBTYPE_MENU_OPTIONS)).join(', ');
    default:
      return label.join(', ');
  }
};

export const getPartFamilyLabel = (characterClass: CharacterClass): string => {
  switch (characterClass) {
    case CharacterClass.ALPHA:
      return 'A-Z';
    case CharacterClass.NUMERIC:
      return '0-9';
    case CharacterClass.BLANK:
      return '<Blank>';
    case CharacterClass.SPACE:
      return '<Space>';
    case CharacterClass.NUMBER_SIGN:
      return '#';
    case CharacterClass.HYPHEN:
      return '-';
    case CharacterClass.ZERO:
      return '0';
    case CharacterClass.ONE:
      return '1';
    case CharacterClass.TWO:
      return '2';
    case CharacterClass.THREE:
      return '3';
    case CharacterClass.FOUR:
      return '4';
    case CharacterClass.FIVE:
      return '5';
    case CharacterClass.SIX:
      return '6';
    case CharacterClass.SEVEN:
      return '7';
    case CharacterClass.EIGHT:
      return '8';
    case CharacterClass.NINE:
      return '9';
    default:
      return characterClass as string;
  }
};

export const getExpirationDateLabel = (expiration: string): JSX.Element => {
  const formattedExpirationDate = formatArtifactsV2StringDate(expiration);
  return new Date(expiration) < new Date() ? (
    <StatusIndicator type="error">{formattedExpirationDate}</StatusIndicator>
  ) : (
    <span>{formattedExpirationDate}</span>
  );
};
