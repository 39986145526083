import React from 'react';
import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import { createArtifactsReadonlyWarningItem, flashbarItemsCreator } from 'utils/formUtils';
import ArtifactV2Create from './ArtifactV2Create';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { setArtifactV2CreateFlashbarItems } from '../../../store/artifactv2';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import { FeatureFlag } from '../../../utils/constants';

const ArtifactV2CreateLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { artifactV2CreateFlashbarItems } = useAppSelector((state) => state.artifactV2Store);
  const artifactV2Available = useFeatureFlag(FeatureFlag.ARTIFACTS_V2, true).boolValue ?? true;

  const flashBarItemsWithHardcodedMessage = [...artifactV2CreateFlashbarItems];
  const readonlyWarning = createArtifactsReadonlyWarningItem(artifactV2Available);
  if (!flashBarItemsWithHardcodedMessage.find((items) => items.header === readonlyWarning.header)) {
    flashBarItemsWithHardcodedMessage.push(readonlyWarning);
  }

  return (
    <AppLayout
      contentType="form"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={<Header variant="h1">Create Artifact</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Artifacts', href: `/artifacts/v2` },
            { text: 'Create', href: '#' },
          ]}
        />
      }
      content={<ArtifactV2Create />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar
          items={flashbarItemsCreator(flashBarItemsWithHardcodedMessage, dispatch, setArtifactV2CreateFlashbarItems)}
        />
      }
      stickyNotifications
    />
  );
};

export default ArtifactV2CreateLayout;
