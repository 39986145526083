import { CollectionPreferencesProps, TableProps } from '@amzn/awsui-components-react';
import { IndexedPcClassification } from '@amzn/d2d-bff-schema';
import PolarisLink from '../PolarisLink';
import { formatDateTimeFromString } from '../../utils/dateFormat';
import React from 'react';
import { EnumDisplayMapping } from '../../types';
import { getOptionByValue } from '../../utils/formUtils';
import { PC_CLASSIFICATION_STATUS, PC_PART_SOURCE } from '../../utils/constants';

export const PC_CLASSIFICATIONS_TABLE_PREFERENCES_KEY = 'pcclassifications-table-preferences';

export type IdSafeColumnDefinition<T> = TableProps.ColumnDefinition<T> & { id: string };

export const getPcClassificationsColumnDefinitions = (
  pccCodes?: EnumDisplayMapping[]
): IdSafeColumnDefinition<IndexedPcClassification>[] => {
  return [
    {
      id: 'partIdentifierValue',
      header: 'Part',
      cell: (item) => (
        <PolarisLink
          to={`/classification/pc/${item.partSource}/${item.partIdentifierType}/${item.partIdentifierValue}`}
        >
          {item.partIdentifierValue}
        </PolarisLink>
      ),
      sortingField: 'partIdentifierValue',
    },
    {
      id: 'partSource',
      header: 'Source',
      cell: (item) => getOptionByValue(item.partSource, PC_PART_SOURCE)?.label ?? item.partSource,
      sortingField: 'partSource',
    },
    {
      id: 'classificationStatus',
      header: 'Status',
      cell: (e) => getOptionByValue(e.classificationStatus, PC_CLASSIFICATION_STATUS)?.label ?? e.classificationStatus,
      sortingField: 'classificationStatus',
    },
    {
      id: 'lastClassifiedAt',
      header: 'Last Classified Date',
      cell: (e) => (e.lastClassifiedAt ? formatDateTimeFromString(e.lastClassifiedAt) : ''),
      sortingField: 'lastClassifiedAt',
    },
    {
      id: 'lastClassifiedBy',
      header: 'Last Classified By',
      cell: (e) => e.lastClassifiedBy,
      sortingField: 'lastClassifiedBy',
    },
    {
      id: 'classificationCodes',
      header: 'Classifications',
      cell: (e) => {
        return e.classificationCodes?.map((category: string, index: number) => {
          return <li key={index}>{pccCodes ? getOptionByValue(category, pccCodes)?.label ?? category : category}</li>;
        });
      },
    },
  ];
};

// TODO: uncomment and correct PC_CLASSIFICATIONS_FILTERING_PROPERTIES when filtering is developed
// export const PC_CLASSIFICATIONS_FILTERING_PROPERTIES: FilteringProps = [
//   {
//     key: 'partId',
//     operators: ['=', '!=', ':', '!:'],
//     propertyLabel: 'Part',
//     groupValuesLabel: 'Part values',
//   },
//   {
//     key: 'classificationStatus',
//     operators: ['=', '!=', ':', '!:'],
//     propertyLabel: 'Status',
//     groupValuesLabel: 'Status values',
//   },
//   {
//     key: 'classifiedAt',
//     operators: ['=', '!=', ':', '!:'],
//     propertyLabel: 'Auto Classified Timestamp',
//     groupValuesLabel: 'Auto Classified Timestamp values',
//   },
//   {
//     key: 'classificationCodes',
//     operators: ['=', '!=', ':', '!:'],
//     propertyLabel: 'Classification',
//     groupValuesLabel: 'Classification values',
//   },
//   {
//     key: 'modifiedBy',
//     operators: ['=', '!=', ':', '!:'],
//     propertyLabel: 'Manually Updated By',
//     groupValuesLabel: 'Manually Updated By',
//   },
//   {
//     key: 'modifiedAt',
//     operators: ['=', '!=', ':', '!:'],
//     propertyLabel: 'Manually Updated At',
//     groupValuesLabel: 'Manually Updated At',
//   },
// ];

export const PC_CLASSIFICATIONS_VISIBLE_CONTENT: string[] = getPcClassificationsColumnDefinitions().map((col) => {
  return col.id;
});

export const PC_CLASSIFICATIONS_CONTENT_PREFERENCE: CollectionPreferencesProps.VisibleContentPreference = {
  title: 'Select visible content',
  options: [
    {
      label: 'PC Classifications',
      options: getPcClassificationsColumnDefinitions().map((col, i) => ({
        id: col.id,
        label: col.header as string,
        editable: i > 0, // Disable edit on first column
      })),
    },
  ],
};
