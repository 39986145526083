// D2DBFF local development config
export const local = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_uJNc29r2t',
    userPoolWebClientId: '3k585bp75occ2162gui0lfhffl',
  },
  oauth: {
    domain: 'd2dbff-userpool-domain-beta.auth.us-west-2.amazoncognito.com',
    redirectSignIn: `http://localhost:3000/`,
    redirectSignOut: `http://localhost:3000/`,
    scope: ['openid'],
    responseType: 'code',
  },
  // https://docs.amplify.aws/lib/graphqlapi/create-or-re-use-existing-backend/q/platform/js/#using-amazoncognitouserpools
  API: {
    name: 'BFF2',
    aws_appsync_graphqlEndpoint: 'https://us-west-2.beta.d2d-bff.api.gtpc-tech.aws.dev/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
};
// D2DBFF beta config
export const beta = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_uJNc29r2t',
    userPoolWebClientId: '3k585bp75occ2162gui0lfhffl',
  },
  oauth: {
    domain: 'd2dbff-userpool-domain-beta.auth.us-west-2.amazoncognito.com',
    redirectSignIn: 'https://beta.d2d.app.gtpc-tech.aws.dev/',
    redirectSignOut: 'https://beta.d2d.app.gtpc-tech.aws.dev/',
    scope: ['openid'],
    responseType: 'code',
  },
  // https://docs.amplify.aws/lib/graphqlapi/create-or-re-use-existing-backend/q/platform/js/#using-amazoncognitouserpools
  API: {
    name: 'BFF2',
    aws_appsync_graphqlEndpoint: 'https://us-west-2.beta.d2d-bff.api.gtpc-tech.aws.dev/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
};

export const gamma = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_FOasLBAkW',
    userPoolWebClientId: '6lqidj4ve9ugrsb4q1qh9u7ugf',
  },
  oauth: {
    domain: 'd2dbff-userpool-domain-gamma.auth.us-west-2.amazoncognito.com',
    redirectSignIn: 'https://gamma.d2d.app.gtpc-tech.aws.dev/',
    redirectSignOut: 'https://gamma.d2d.app.gtpc-tech.aws.dev/',
    scope: ['openid'],
    responseType: 'code',
  },
  // https://docs.amplify.aws/lib/graphqlapi/create-or-re-use-existing-backend/q/platform/js/#using-amazoncognitouserpools
  API: {
    name: 'BFF2',
    aws_appsync_graphqlEndpoint: 'https://us-west-2.gamma.d2d-bff.api.gtpc-tech.aws.dev/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
};

export const prod = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_BmOfO0Qp8',
    userPoolWebClientId: '1avjbdp46vfdel9ak9b8eu7453',
  },
  oauth: {
    domain: 'd2dbff-userpool-domain-prod.auth.us-west-2.amazoncognito.com',
    redirectSignIn: 'https://d2d.app.gtpc-tech.aws.dev/',
    redirectSignOut: 'https://d2d.app.gtpc-tech.aws.dev/',
    scope: ['openid'],
    responseType: 'code',
  },
  // https://docs.amplify.aws/lib/graphqlapi/create-or-re-use-existing-backend/q/platform/js/#using-amazoncognitouserpools
  API: {
    name: 'BFF2',
    aws_appsync_graphqlEndpoint: 'https://us-west-2.prod.d2d-bff.api.gtpc-tech.aws.dev/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
};
