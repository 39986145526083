/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import { APPLICANT_TYPES, SOURCE, STATUS_OPTIONS } from '../../utils/constants';
import './artifact-details-view.css';

// TODO: refactor this to use a correct type. Right now there are too many 'view model' type properties that don't exist in the Asset type
const ArtifactDetailsView: React.FC<{ artifact: any }> = ({ artifact }) => {
  let addFields;
  let addNonPlmFields;
  if (artifact?.nonPlmPart) {
    addNonPlmFields = (
      <>
        <div>
          <div>Manufacturer Name:</div>
          {/* TODO: remove these divs/p tags and use ValueWithLabel instead */}
          <p className="authorization-details-cell">{artifact.nonPlmPart?.manufacturerName}</p>
        </div>
        <div>
          <div>Model Number:</div>
          <p className="authorization-details-cell">{artifact.nonPlmPart?.manufacturerPartId}</p>
        </div>
        <div>
          <div>Non-Agile Part Description:</div>
          <p className="authorization-details-cell">{artifact.nonPlmPart?.description}</p>
        </div>
      </>
    );
  }

  if (artifact?.recordType === 'compliance') {
    const applicantTypeLabel = APPLICANT_TYPES.find((at) => at.value === artifact?.applicantType)?.label;
    addFields = (
      <>
        <div>
          <div>Applicant Type:</div>
          <p className="authorization-details-cell">{applicantTypeLabel}</p>
        </div>
        <div>
          <div>Applicant Name:</div>
          <p className="authorization-details-cell">{artifact?.applicantName}</p>
        </div>
        <div>
          <div>Issuing Body:</div>
          <p className="authorization-details-cell">{artifact?.issuingBody}</p>
        </div>
        <div>
          <div>Standards:</div>
          <ul className="authorization-details-cell">
            {artifact?.standardsFormatted &&
              artifact?.standardsFormatted.map((standard: string, index: number) => {
                return <li key={index}>{standard}</li>;
              })}
          </ul>
        </div>
      </>
    );
  }

  const getStatus = STATUS_OPTIONS.find((a) => a.value === artifact?.authorizationStatus);
  const authorizationStatus = getStatus?.label;
  const getSource = SOURCE.find((source) => source.value === artifact?.source);

  const source = getSource?.label;

  return (
    <div>
      <ColumnLayout borders="horizontal" columns={4} variant="text-grid">
        <div>
          <div>Supersku Name:</div>
          <p className="authorization-details-cell">{artifact?.superskuName}</p>
        </div>
        <div>
          <div>AWS Project External:</div>
          <p className="authorization-details-cell">{artifact?.awsProjectExternal}</p>
        </div>
      </ColumnLayout>
      <ColumnLayout borders="horizontal" columns={4} variant="text-grid">
        <div>
          <div>Government Tracking:</div>
          <p className="authorization-details-cell">{artifact?.governmentTrackingNumber}</p>
        </div>
        <div>
          <div>External ID:</div>
          <p className="authorization-details-cell">{artifact?.externalId}</p>
        </div>
        <div>
          <div>Status:</div>
          <p className="authorization-details-cell">{authorizationStatus}</p>
        </div>
        <div>
          <div>Effective Date:</div>
          <p className="authorization-details-cell">{artifact?.startDateFormatted}</p>
        </div>
        <div>
          <div>Expiration Date:</div>
          <p className="authorization-details-cell">{artifact?.expirationDateFormatted}</p>
        </div>
        <div>
          <div>Notes:</div>
          <p className="authorization-details-cell">{artifact?.authorizationNotes}</p>
        </div>
        <div>
          <div>Active:</div>
          <p className="authorization-details-cell">{artifact?.activeDisplay}</p>
        </div>
        {addFields}
        {addNonPlmFields}
        <div>
          <div>Source:</div>
          <p className="authorization-details-cell">{source}</p>
          {artifact.source === 'OTHER' && (
            <>
              <div>Other Source Name:</div>
              <p className="authorization-details-cell">{artifact?.otherSourceName}</p>
            </>
          )}
        </div>
      </ColumnLayout>
    </div>
  );
};

export default ArtifactDetailsView;
