import { CollectionPreferencesProps, TableProps } from '@amzn/awsui-components-react';
import { IndexedLicense } from '@amzn/d2d-bff-schema';
import { FilteringProps } from 'hooks/useCollectionWithFilterType/useCollectionWithFilterType';
import useLocalStorage from 'hooks/useLocalStorage';
import { useLayoutEffect, useState } from 'react';
import { EnumDisplayMapping } from 'types';
import { ArtifactsSearchResult, AssetViewModel, SearchResultFilter } from 'types/artifacts';
import { ARTIFACT_SEARCH_MODES, COUNTRIES, MARKET_ACCESS_COUNTRIES } from 'utils/constants';
import { CollectionHooksTable } from '../Table';
import {
  COMBINED_COLUMN_DEFINITIONS,
  COMBINED_CONTENT_PREFERENCE,
  COMBINED_FILTERING_PROPERTIES,
  COMBINED_VISIBLE_CONTENT,
} from './artifactsTableConfig';

/*
  Would lke to avoid this 'any' but since we don't know which definitions we'll be using at compile time and the two
  types are not compatible so TS thinks there is insufficient overlap. Since we don't need to know which columns these are per se
  have this as TableProps.ColumnDefinition<any>
*/
type SearchModeConfig = {
  visibleContent: string[];
  visibleContentPreference: CollectionPreferencesProps.VisibleContentPreference;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnDefinitions: TableProps.ColumnDefinition<any>[];
  filteringProperties?: FilteringProps;
};

const SEARCH_MODE_MAP: Record<string, SearchModeConfig> = {
  artifacts: {
    visibleContent: COMBINED_VISIBLE_CONTENT,
    visibleContentPreference: COMBINED_CONTENT_PREFERENCE,
    columnDefinitions: COMBINED_COLUMN_DEFINITIONS,
    filteringProperties: COMBINED_FILTERING_PROPERTIES,
  },
  authorizations: {
    visibleContent: COMBINED_VISIBLE_CONTENT,
    visibleContentPreference: COMBINED_CONTENT_PREFERENCE,
    columnDefinitions: COMBINED_COLUMN_DEFINITIONS,
    filteringProperties: COMBINED_FILTERING_PROPERTIES,
  },
  combined: {
    visibleContent: COMBINED_VISIBLE_CONTENT,
    visibleContentPreference: COMBINED_CONTENT_PREFERENCE,
    columnDefinitions: COMBINED_COLUMN_DEFINITIONS,
    filteringProperties: COMBINED_FILTERING_PROPERTIES,
  },
};

const ARTIFACT_SEARCH_MODE_KEY = 'artifact-search-mode';
const COMBINED_VISIBLE_CONTENT_KEY = 'artifacts-authorizations-table-preferences';

const ArtifactsTable: React.FC<{
  artifacts: AssetViewModel[];
  licenses: IndexedLicense[];
  combinedArtifactSearchResults: ArtifactsSearchResult[];
  artifactsLoading: boolean;
}> = ({ artifacts, artifactsLoading, licenses, combinedArtifactSearchResults }) => {
  const artifactSearchModeLocalStorage = useLocalStorage<Partial<EnumDisplayMapping>>(
    ARTIFACT_SEARCH_MODE_KEY,
    ARTIFACT_SEARCH_MODES[0]
  );

  const [currentTableConfig, setCurrentTableConfig] = useState<SearchModeConfig>(SEARCH_MODE_MAP.combined);
  const [tableItems, setTableItems] = useState<Record<string, unknown>[]>(combinedArtifactSearchResults);
  const [artifactSearchMode] = artifactSearchModeLocalStorage;

  const { columnDefinitions, filteringProperties } = currentTableConfig;

  useLayoutEffect(() => {
    if (artifactSearchMode?.value === 'artifacts') {
      setTableItems(
        combinedArtifactSearchResults.filter((artifact) => artifact.searchFilter === SearchResultFilter.asset)
      );
      setCurrentTableConfig(SEARCH_MODE_MAP.artifacts);
    } else if (artifactSearchMode.value === 'authorizations') {
      setTableItems(
        combinedArtifactSearchResults.filter((artifact) => artifact.searchFilter === SearchResultFilter.license)
      );
      setCurrentTableConfig(SEARCH_MODE_MAP.authorizations);
    } else if (artifactSearchMode.value === 'artifacts_authorizations') {
      setTableItems(combinedArtifactSearchResults);
      setCurrentTableConfig(SEARCH_MODE_MAP.combined);
    }
  }, [artifactSearchMode, artifacts, licenses, combinedArtifactSearchResults]);

  return (
    <CollectionHooksTable
      key={artifactSearchMode?.value ?? ''}
      headerTitle="Search Results"
      allItems={tableItems}
      preferenceLocalStorageKey={COMBINED_VISIBLE_CONTENT_KEY}
      visibleContentPreference={COMBINED_CONTENT_PREFERENCE}
      visibleContent={COMBINED_VISIBLE_CONTENT}
      resizeableColumns={true}
      columnDefinitions={columnDefinitions}
      loading={artifactsLoading}
      loadingText="Loading search results..."
      filterOptions={{
        type: 'property',
        filteringProperties,
        customGroupFilters: [
          {
            key: 'country',
            values: COUNTRIES.filter((c) => MARKET_ACCESS_COUNTRIES.includes(c.value)),
            display: 'Market Access Countries Only',
            byValue: false,
          },
        ],
        // TODO: uncomment when we release new search
        selectFilters: [{ options: ARTIFACT_SEARCH_MODES, useStateRes: artifactSearchModeLocalStorage }],
      }}
      empty="Matched artifacts will be displayed here"
    />
  );
};

export default ArtifactsTable;
