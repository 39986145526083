import { useEffect } from 'react';

const useOnEnter = (onEnterFunc: () => void, inputNames: string[] = []): void => {
  useEffect(() => {
    const listener = (event: KeyboardEvent): void => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (document.activeElement && inputNames.includes((document.activeElement as HTMLInputElement)?.name)) {
          onEnterFunc();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [inputNames, onEnterFunc]);
};

export default useOnEnter;
