import React from 'react';
import { ReactComponent as DashboardImage } from 'assets/images/DASHBOARD-GRAPHIC.svg';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';

import './loading.css';
import { Box } from '@amzn/awsui-components-react';

const Loading = (): React.ReactElement => {
  const hasDarkmodeClass = document.body.classList.contains('awsui-polaris-dark-mode');
  return (
    <>
      <Box className="loading">
        <DashboardImage className="loading-image mt-40" stroke={hasDarkmodeClass ? '#ffffff' : '#111111'} />
        <div className="loading-message">Loading</div>
        <div className="loading-icon">
          <Spinner size="large" />
        </div>
      </Box>
    </>
  );
};

export default Loading;
