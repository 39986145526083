import { SerializedError, createAsyncThunk, createSlice, miniSerializeError } from '@reduxjs/toolkit';
import { searchTownsendByIpn } from '_services/api/townsend';
import { GraphQLError } from 'graphql';
import { PolarisLoadingStatus } from '../../types';

export type TownsendState = {
  ipnInfoList?: {
    ipn?: string;
    description?: string;
    entityClasses?: string[];
    lifeCycleState?: string;
    entityType?: string;
  }[];
  ipnsLoading: PolarisLoadingStatus;
  ipnsError?: SerializedError;
  ipnsValidateLoading?: PolarisLoadingStatus;
  ipnsValidateError?: SerializedError;
  ipnsValidatedValue?: { ipn?: string; description?: string; label?: string };
};

export const initialState: TownsendState = {
  ipnInfoList: [],
  ipnsLoading: 'pending',
  ipnsError: undefined,
  ipnsValidateLoading: 'finished',
};

export const townsendIpnSearch = createAsyncThunk<
  { ipn?: string; description?: string }[] | undefined,
  string,
  {
    rejectValue: GraphQLError[];
  }
>('townsend/townsendIpnSearch', async (ipn: string, { rejectWithValue }) => {
  const townsendRes = await searchTownsendByIpn(ipn);
  if (townsendRes.data && Object.keys(townsendRes).length > 0 && !townsendRes.errors) {
    const gqlResponse = townsendRes.data.searchPLM[0].items;
    const filteredResponse = gqlResponse.filter((item) =>
      item.otherIdentifiers?.find((identifier) => identifier?.key === 'PART NUMBER')
    );
    return filteredResponse?.map((item) => {
      const otherIdentifier =
        item?.otherIdentifiers?.find((identifier) => identifier?.key === 'PART NUMBER') ?? undefined;
      return {
        ipn: otherIdentifier?.value ?? undefined,
        description: item.description ?? undefined,
        entityClasses: item.entityClasses ?? [],
        lifeCycleState: item.lifeCycleState ?? undefined,
        entityType: item.entityType ?? undefined,
      };
    });
  } else if (townsendRes.errors) {
    return rejectWithValue(townsendRes.errors);
  }
});

export const townsendValidateIpn = createAsyncThunk<
  { ipn?: string; description?: string } | undefined,
  string,
  {
    rejectValue: GraphQLError[] | string;
  }
>('townsend/townsendValidateIpn', async (ipn: string, { rejectWithValue }) => {
  const townsendRes = await searchTownsendByIpn(ipn);
  if (townsendRes.data && Object.keys(townsendRes).length > 0 && !townsendRes.errors) {
    const gqlResponse = townsendRes.data.searchPLM[0].items;
    const filteredResponse = gqlResponse.filter((item) =>
      item.otherIdentifiers?.find((identifier) => identifier?.key === 'PART NUMBER' && identifier?.value === ipn)
    );

    if (!filteredResponse.length) {
      return rejectWithValue('IPN not found');
    }

    const foundItem = filteredResponse[0];
    return {
      ipn,
      desccription: foundItem.description,
    };
  } else if (townsendRes.errors) {
    return rejectWithValue(townsendRes.errors);
  }
});

const { reducer, actions } = createSlice({
  name: 'townsend',
  initialState,
  reducers: {
    setIpns: (state, { payload }) => {
      state.ipnInfoList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Townsend Search - use `pending`, `loading`, `finished`, `error` for loading to match Polaris
      .addCase(townsendIpnSearch.pending, (state) => {
        state.ipnsLoading = 'loading';
        state.ipnInfoList = [];
      })
      .addCase(townsendIpnSearch.fulfilled, (state, { payload }) => {
        state.ipnsLoading = 'finished';
        state.ipnInfoList = payload;
      })
      .addCase(townsendIpnSearch.rejected, (state, { error }) => {
        state.ipnsError = miniSerializeError(error);
        state.ipnsLoading = 'error';
      })
      .addCase(townsendValidateIpn.pending, (state) => {
        state.ipnsValidateLoading = 'loading';
      })
      .addCase(townsendValidateIpn.fulfilled, (state, { payload }) => {
        state.ipnsValidateLoading = 'finished';
        state.ipnsValidatedValue = { ...payload, label: payload?.ipn };
      })
      .addCase(townsendValidateIpn.rejected, (state, { error }) => {
        state.ipnsValidateLoading = 'error';
        state.ipnsValidateError = error;
      });
  },
});
export const { setIpns } = actions;
export default reducer;
