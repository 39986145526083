import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClassificationAction,
  getUsEcnAction,
  searchRelatedClassificationsAction,
} from 'store/classification/classificationSlice';
import { Button, Container, Header, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import Error from 'components/Error';
import Loading from 'components/Loading';
import ClassificationDetailView from '../ClassificationDetailView';
import ClassificationsTable from 'components/ClassificationsTable';
import ClassificationEdit from 'components/ClassificationEdit';

const Classification = () => {
  const dispatch = useDispatch();
  const {
    classification,
    classificationLoading: classLoading,
    error,
    USClassification,
    relatedClassifications,
  } = useSelector((state) => state.classificationStore);
  const classificationLoading = classLoading === 'pending';
  const [classificationDetails, setClassificationDetails] = useState('view');
  const [classificationDetailsButton, setClassificationDetailsButton] = useState('Edit Details');
  const params = useParams();
  const { country, part: assetId } = params;

  useEffect(() => {
    dispatch(
      getClassificationAction({
        ic: country,
        assetId,
      })
    );
    dispatch(
      getUsEcnAction({
        ic: 'US',
        assetId,
      })
    );
  }, [country, dispatch, classificationDetails, assetId]);

  useEffect(() => {
    dispatch(
      searchRelatedClassificationsAction({
        searchTerm: assetId,
        ic: country,
        location: 'classification',
      })
    );
  }, [assetId, country, dispatch]);

  const toggleView = () => {
    if (classificationDetails === 'view') {
      setClassificationDetails('edit');
      setClassificationDetailsButton('Cancel Changes');
    } else {
      setClassificationDetails('view');
      setClassificationDetailsButton('Edit Details');
    }
  };

  const classificationDetailView =
    classificationDetails === 'edit' ? (
      <ClassificationEdit
        classification={classification}
        setClassificationDetails={setClassificationDetails}
        setClassificationDetailsButton={setClassificationDetailsButton}
      />
    ) : (
      <ClassificationDetailView classification={classification} USClassification={USClassification} />
    );

  if (classificationLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error errorMessage={error} />;
  }

  if (!classification) return null;

  return (
    <SpaceBetween size="m">
      <Header variant="h1">Classification</Header>
      <Container header={<Header variant="h2">Details</Header>}>
        {classificationDetailView}
        <br />
        <SpaceBetween direction="horizontal" size="xs">
          <Button data-test="edit-cancel-button" variant="primary" onClick={toggleView}>
            {classificationDetailsButton}
          </Button>
        </SpaceBetween>
      </Container>
      <ClassificationsTable itemType="Related Classifications" classifications={relatedClassifications} />
      <Link to="/classifications" data-test="create">
        <Button variant="primary">Back to Classifications</Button>
      </Link>
    </SpaceBetween>
  );
};

export default Classification;
