import React from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import dashboardImage from 'assets/images/DASHBOARD-GRAPHIC.svg';

const NotFound = (): React.ReactElement => {
  return (
    <>
      <div className="container main-header">
        <div className="row main-banner-header clear-fix">
          <div className="col-12">
            <div className="col-4 dashboard-image">
              <img src={dashboardImage} alt="dashboard_image" />
            </div>
            <div className="col-5">
              <Header>Hello!</Header>
              Looks like this page doesn&apos;t exist. That&apos;s ok, you can click on the left nav and find where you
              are going. Have a great day!
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
