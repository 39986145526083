import {
  ErrorType,
  ExtraneousMetadataError,
  IllegalCharacterErrorInfo,
  InvalidFieldValueError,
  LicenseLimitConstraintErrorInfo,
  LicenseManagerErrorInfo,
  LicenseManagerErrorReason,
  MissingOneOfFieldsError,
  MissingRequiredMetadataError,
  RequiredFieldError,
  UniqueConstraintErrorInfo,
  UsedLicenseUneditableFieldErrorInfo,
} from '@amzn/d2d-bff-schema';
import { D2DGraphQLError, D2DGraphQLResult, FlashbarItemsProps } from 'types';
import { createErrorItem, createUnknownErrorItem } from './formUtils';

// TODO: Once this field-to-label mapping is needed in more areas of code (search results), refactor to common util
// JS Object as Map. Key: field name from License object. Value: human readable label for that field.
const fieldToLabelMap: { [key: string]: string } = {
  unitLimit: 'Unit Limit',
  valueLimit: 'Value Limit',
};

// Common Error Message for all unknown errors from the server
export const unknownErrorMsg =
  'Oops! Something went wrong on our end! Please contact the GTPC tech team for further assistance';

const getMsgForLicenseLimitConstraint = (errorInfo: LicenseLimitConstraintErrorInfo): string => {
  // Default the fieldLabel to License so that message makes sense if we're unable to determine a better label.
  let fieldLabel = 'License';
  if (errorInfo.field) {
    const limitedField = fieldToLabelMap[errorInfo.field];
    if (limitedField) {
      fieldLabel = limitedField;
    } else {
      console.error(
        'Failed to find human readable label for license field while processing a LicenseLimitConstraintErrorInfo',
        errorInfo
      );
    }
  }
  return `${fieldLabel} is limited to ${errorInfo.limit}. Saving this usage would exceed that limit and set it to ${errorInfo.rejectedValue}`;
};

const getMsgForInvalidInputError = (errorInfo: LicenseManagerErrorInfo): string => {
  switch (errorInfo.reason) {
    case LicenseManagerErrorReason.UNIQUE_CONSTRAINT:
      return getMsgForUniqueConstraintError(errorInfo as UniqueConstraintErrorInfo);
    case LicenseManagerErrorReason.ILLEGAL_CHARACTER: {
      const illegalCharacterErrorInfo = errorInfo as IllegalCharacterErrorInfo;
      return `One of your fields contains an illegal character. The following characters are illegal: ${illegalCharacterErrorInfo.illegalCharacters}. You submitted: ${illegalCharacterErrorInfo.value}`;
    }
    // Check for Single Use License Constraints and Unit and Value limit constraints
    case LicenseManagerErrorReason.LICENSE_LIMIT_CONSTRAINT: {
      const errorMessage =
        errorInfo.field === 'singleUse'
          ? 'Commercial Invoice on this usage is different from commercial invoices in prior usages on a Single Use License. All usages on a Single Use License are expected to have the same Commercial Invoice number'
          : getMsgForLicenseLimitConstraint(errorInfo as LicenseLimitConstraintErrorInfo);
      return errorMessage;
    }
    case LicenseManagerErrorReason.USED_LICENSE_UNEDITABLE_FIELD_CONSTRAINT: {
      const uneditableFieldErrorInfo = errorInfo as UsedLicenseUneditableFieldErrorInfo;
      return `The field "${uneditableFieldErrorInfo.field}" cannot be updated once a usage is assigned to the license`;
    }
    case LicenseManagerErrorReason.USED_LICENSE_EXPIRATION_DATE_CONSTRAINT: {
      return `The expiration date cannot be set to an earlier date once a usage is assigned to the license`;
    }
    default:
      return `Invalid input provided`;
  }
};

const getMsgForUniqueConstraintError = (errorInfo: UniqueConstraintErrorInfo): string => {
  switch (errorInfo.field) {
    case 'LicenseNumber-Country-Authtype':
      return `A record already exists with the same combination of License Number, Issuing Country, and Import Authorization`;
    default:
      console.error('Failed to identify the type of unique constraint encountered.', errorInfo);
      return `A record appears to already exist with your data`;
  }
};

export const displayError = (error: unknown): string => {
  const err: Error & { response: { data: string } } = error as Error & { response: { data: string } };
  if (!!err.response && !!err.response.data) {
    return err.response.data;
  }
  return err.message;
};

/**
 * Typeguard to cast an error to a GraphQLResult
 * @param rejection some sort of error type
 * @returns error cast as a D2DGraphQLResult if it is a D2DGraphQLResult
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isGraphQLResult = <T,>(rejection: any): D2DGraphQLResult<T> | undefined => {
  const isGQLResult = rejection !== undefined && (rejection.data !== undefined || rejection.errors !== undefined);
  if (isGQLResult) {
    return rejection as D2DGraphQLResult<T>;
  }
};

export interface ErrorMap {
  [ErrorType.NOT_FOUND]?: string;
  [ErrorType.INVALID_AUTH]?: string;
  [ErrorType.INVALID_INPUT_ERROR]?: string;
  [ErrorType.UNKNOWN_ERROR]?: string;
  [ErrorType.UNIQUE_KEY_CONSTRAINT_ERROR]?: string;
  [ErrorType.ILLEGAL_CHARACTER_ERROR]?: string;
  [ErrorType.INVALID_FIELD_VALUE_ERROR]?: string;
  [ErrorType.MISSING_REQUIRED_FIELD_ERROR]?: string;
  [ErrorType.EXTRANEOUS_METADATA]?: string;
  [ErrorType.MISSING_REQUIRED_METADATA]?: string;
  [ErrorType.MISSING_ONE_OF_FIELDS]?: string;
}

const defaultErrorMap: ErrorMap = {
  [ErrorType.NOT_FOUND]: 'Record Not Found',
  [ErrorType.INVALID_AUTH]:
    'Either your authentication token has expired or you do not have access to the requested data. Please refresh the page and try again',
  [ErrorType.INVALID_INPUT_ERROR]: 'Invalid input provided',
  [ErrorType.UNKNOWN_ERROR]: unknownErrorMsg,
  [ErrorType.UNIQUE_KEY_CONSTRAINT_ERROR]: 'A record appears to already exist with your data',
  [ErrorType.ILLEGAL_CHARACTER_ERROR]: 'Your input contains an illegal character',
  [ErrorType.INVALID_FIELD_VALUE_ERROR]: 'Invalid field value',
  [ErrorType.MISSING_REQUIRED_FIELD_ERROR]: 'Required field not found',
  [ErrorType.EXTRANEOUS_METADATA]: 'Extra fields found in the input',
  [ErrorType.MISSING_REQUIRED_METADATA]: 'Input is missing required fields',
  [ErrorType.MISSING_ONE_OF_FIELDS]: 'One of the following fields is required in the input',
};

export const processGraphQLErrors = (errors: D2DGraphQLError[], errorMap: ErrorMap): FlashbarItemsProps[] => {
  console.error('Errors encountered while attempting GraphQL query.', errors);
  const returnItems = errors.map((err) => {
    if (ErrorType[err.errorType]) {
      const msg = errorMap[err.errorType] ?? defaultErrorMap[err.errorType];
      return createErrorItem(msg);
    }
    // Error is not a known error from ErrorType
    return createUnknownErrorItem();
  });
  return returnItems;
};

export const processLMGraphQLErrors = (errors: D2DGraphQLError[], appendedString?: string): FlashbarItemsProps[] => {
  const errorTypes = Object.values(ErrorType);
  const relevantErrors = errors.filter((error) => errorTypes.includes(error.errorType as ErrorType));
  let errs: FlashbarItemsProps[] = relevantErrors.map((err) => {
    let msg = '';
    const errorInfo = err.errorInfo as LicenseManagerErrorInfo;
    switch (err.errorType) {
      case ErrorType.INVALID_INPUT_ERROR:
        msg = getMsgForInvalidInputError(errorInfo);
        break;
      case ErrorType.NOT_FOUND:
        msg = `License not found`;
        break;
      case ErrorType.INVALID_AUTH:
        msg = `Either your authentication token has expired or you do not have access to the requested data. Please refresh the page and try again`;
        break;
      default:
        msg = unknownErrorMsg;
        break;
    }
    return createErrorItem(`${msg}${appendedString ? ` ${appendedString}.` : '.'}`);
  });
  if (!relevantErrors.length && errors.length > 0) {
    errs = [createErrorItem(`${unknownErrorMsg}.`)];
  }
  return errs;
};

export const processArtifactsGqlErrors = (errors: D2DGraphQLError[]): FlashbarItemsProps[] => {
  const errorTypes = Object.values(ErrorType);
  const relevantErrors = errors.filter((error) => errorTypes.includes(error.errorType as ErrorType));
  let errs: FlashbarItemsProps[] = relevantErrors.map((err) => {
    let msg = '';
    switch (err.errorType) {
      case ErrorType.MISSING_REQUIRED_METADATA: {
        const errorInfo = err.errorInfo as MissingRequiredMetadataError;
        msg = `Missing required metadata from the input: ${errorInfo.missingFields.join(', ')}`;
        break;
      }
      case ErrorType.INVALID_FIELD_VALUE_ERROR: {
        const errorInfo = err.errorInfo as InvalidFieldValueError;
        msg = `The field '${errorInfo.field}' has an invalid value '${errorInfo.value ?? ''}'`;
        break;
      }
      case ErrorType.EXTRANEOUS_METADATA: {
        const errorInfo = err.errorInfo as ExtraneousMetadataError;
        msg = `Extraneous fields found in the input: ${errorInfo.extraneousFields.join(
          ', '
        )}. Please remove these fields or update the schema for this artifact to allow them`;
        break;
      }
      case ErrorType.INVALID_INPUT_ERROR: {
        const errorInfo = err.errorInfo as InvalidFieldValueError;
        msg = `The field '${errorInfo.field}' has an invalid value '${errorInfo.value ?? ''}'`;
        break;
      }
      case ErrorType.MISSING_ONE_OF_FIELDS: {
        const errorInfo = err.errorInfo as MissingOneOfFieldsError;
        msg = `One of the following fields is required: ${errorInfo.fieldsList}`;
        break;
      }
      case ErrorType.MISSING_REQUIRED_FIELD_ERROR: {
        const errorInfo = err.errorInfo as RequiredFieldError;
        msg = `${errorInfo.field} is required`;
        break;
      }
      default:
        msg = defaultErrorMap[err.errorType] ?? unknownErrorMsg;
        break;
    }
    return createErrorItem(`${msg}`);
  });
  if (!relevantErrors.length && errors.length > 0) {
    errs = [createErrorItem(`${unknownErrorMsg}.`)];
  }
  return errs;
};
