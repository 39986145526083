import { Box, Link, TableProps } from '@amzn/awsui-components-react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React from 'react';
import { AppDispatch } from 'store/store';
import { EnumDisplayMapping, FlashbarItemsProps } from 'types';
import * as z from 'zod';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { CONTACT_US_HREF } from '../constants';

export const DEFAULT_REQUIRED = 'Required';

export const optionDefinitionSchema = z.object({
  value: z.string(),
  label: z.string().optional(),
  description: z.string().optional(),
});

export const yupOptionDefinitionSchema = yup.object().shape({
  value: yup.string().required(DEFAULT_REQUIRED),
  label: yup.string(),
  description: yup.string(),
});

// return type too complex to write out, intellisense infers
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const optionalOptionDefinitionSchema = (field: string) =>
  yupOptionDefinitionSchema.when(field, {
    is: true,
    then: (schema) => schema,
    otherwise: (schema) =>
      schema.shape({
        value: yup.string(),
        label: yup.string(),
        description: yup.string(),
      }),
  });

export const getOptionByValue = (
  value: string | undefined | null,
  options: EnumDisplayMapping[]
): EnumDisplayMapping | null => {
  const match = options.find((o) => o.value === value);
  return match ? match : null;
};

export const getOptionalLabel = (
  value: string | JSX.Element | undefined | null,
  customMessage?: string
): JSX.Element => {
  return (
    <>
      {value} <i>- {customMessage ? customMessage : 'optional'}</i>
    </>
  );
};

export const flashbarItemsCreator = (
  items: FlashbarItemsProps[],
  dispatch: AppDispatch,
  actionCreator: ActionCreatorWithPayload<FlashbarItemsProps[], string>
): FlashbarItemsProps[] => {
  return items
    ? items.map((fbItem) => ({
        ...fbItem,
        dismissible: fbItem.dismissible === false ? false : true,
        dismissLabel: 'Dismiss',
        onDismiss: () => {
          dispatch(actionCreator(items.filter((fb) => fb.dismissId !== fbItem.dismissId)));
        },
      }))
    : [];
};

export const createSuccessItem = (content: React.ReactNode): FlashbarItemsProps => {
  return {
    header: 'Success',
    type: 'success',
    content: content,
    dismissId: uuid(),
  };
};

export const createErrorItem = (content: React.ReactNode): FlashbarItemsProps => {
  return {
    header: 'Error',
    type: 'error',
    content: content,
    dismissId: uuid(),
  };
};

/**
 *
 * @returns FlashbarItemsProps to be used in case of general unexpected/unknown error.
 */
export const createUnknownErrorItem = (): FlashbarItemsProps => {
  return createErrorItem(
    <Box variant={'span'}>
      There was an error processing your request. You may reload the page or try again later.{' '}
      <Link href={CONTACT_US_HREF} color={'inverted'}>
        Contact us
      </Link>{' '}
      and share more details.
    </Box>
  );
};

/**
 * @param artifactsV2Available Whether or not the artifacts v2 UI has been made available to users.
 * @return The flashbar item props for a warning message that should be displayed to users regarding Artifacts.
 */
export const createArtifactsReadonlyWarningItem = (artifactsV2Available: boolean): FlashbarItemsProps => {
  // const linkMessage = (
  //   <>
  //     Please use{' '}
  //     <Link href="/artifacts/v2/create" data-test="create">
  //       Artifacts V2
  //     </Link>{' '}
  //     to enter these compliance artifacts. License Manager will still allow artifact creation for export authorizations.
  //   </>
  // );

  const notAvailableMessage =
    'Until Artifacts V2 is available, there is currently no way to upload those Artifacts to this system. Artifacts V2 is expected to be available on Sept 29, 2023.';

  // Remove this 'content' variable when showing the V2 link and uncomment the below line
  const content = (
    <Box variant={'span'}>
      Artifacts V1 is read only for Product Compliance and Trade Operations artifacts. {notAvailableMessage}
    </Box>
  );

  // Once we are ready to show the V2 link on the UI, remove the above code block and uncomment this one
  // const content = (
  //   <Box variant={'span'}>
  //     Artifacts V1 is read only for Product Compliance and Trade Operations artifacts.{' '}
  //     {artifactsV2Available && linkMessage}
  //     {!artifactsV2Available && notAvailableMessage}
  //   </Box>
  // );

  return {
    header: 'Temporary Access Limitation',
    type: 'warning',
    content,
    dismissible: false,
  };
};

export const DATE_PICKER_ARIA_LABELS = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next Month',
  previousMonthAriaLabel: 'Previous Month',
};

export const getTableSelectionLabels = <T extends object | null>(selectionName?: string): TableProps.AriaLabels<T> => ({
  itemSelectionLabel: () => `Select row`,
  allItemsSelectionLabel: () => 'Select all',
  selectionGroupLabel: `${selectionName ?? 'Table'} selection`,
});

export const nullishCheckToString = (arg: string | number | null | undefined): string => {
  if (arg !== null && arg !== undefined) {
    return arg?.toString();
  } else {
    return '';
  }
};
