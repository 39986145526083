const createMultiselectItems = (items = []) => {
  return items.map((item) => {
    return {
      label: item,
    };
  });
};

const createArrayFromMultiselect = (multiSelectItems) => {
  return multiSelectItems.map((item) => {
    return item.label;
  });
};

export const mapSelectOptions = (options) => {
  return options.map((o) => {
    return {
      value: o.value,
      label: o.label,
    };
  });
};

export { createMultiselectItems, createArrayFromMultiselect };
