import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, FormField, Header, SpaceBetween, TableProps } from '@amzn/awsui-components-react';
import { CollectionHooksTable } from '../Table';
import { v4 as uuidv4 } from 'uuid';
import { formatDateFromUnixYearToSecond } from '../../utils/dateFormat';
import './file-upload-table.css';
import {
  D2DFile,
  D2DFileViewModel,
  createFilesAction,
  getDownloadUrl,
  getFilesAction,
  setFilesToBeUploaded,
  setUploadInProgress,
} from 'store/files/filesSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getRumClient } from 'analytics/rum';

const FileUploadTable = ({
  serviceName,
  fileSetId,
  disabled,
}: {
  fileSetId: string | undefined | null;
  serviceName: string;
  disabled?: boolean;
}): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { files, getFilesLoading, filesToBeUploaded, uploadInProgress, urls } = useAppSelector(
    (state) => state.filesStore
  );

  const fileRef = useRef<HTMLInputElement>(null);
  const [displayedFiles, setDisplayedFiles] = useState<D2DFile[]>([]);
  const [downloadingId, setDownloadingId] = useState<string | null>(null);
  const [filesTableError, setFilesTableError] = useState<string>('');

  useEffect(() => {
    setFilesTableError('');
    if (downloadingId && downloadingId in urls && urls[downloadingId] && urls[downloadingId].loading === 'fulfilled') {
      const link = document.createElement('a');
      const downloadingUrl = urls[downloadingId]?.url;
      if (downloadingUrl) {
        link.href = downloadingUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setFilesTableError('Error: No URL found when attempting to download file');
        getRumClient()?.recordError(
          new Error(
            `FileUploadTable(${serviceName}): no URL found when attempting to download file from fileSetId ${fileSetId}`
          )
        );
      }
    }
  }, [downloadingId, fileSetId, serviceName, setDownloadingId, urls]);

  useEffect(() => {
    if (
      filesToBeUploaded.length > 0 &&
      filesToBeUploaded.filter((f) => f.progress === 100).length === filesToBeUploaded.length
    )
      setTimeout(() => {
        dispatch(setUploadInProgress(false));
      }, 2500);
    if (!uploadInProgress && filesToBeUploaded.length > 0) {
      const filesStillToBeUploaded = filesToBeUploaded && filesToBeUploaded.filter((f) => f.success !== true);
      setDisplayedFiles([...filesStillToBeUploaded, ...files]);
    } else {
      setDisplayedFiles(files);
    }
  }, [dispatch, files, filesToBeUploaded, uploadInProgress]);

  useEffect(() => {
    if (!uploadInProgress && serviceName && fileSetId) {
      dispatch(setFilesToBeUploaded([]));
      dispatch(getFilesAction({ serviceName, fileSetId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadInProgress]);

  const handleFiles: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault();
    const files = event.target.files;
    const numFiles = files?.length ?? 0;
    const newFiles = [];
    if (files && files.item) {
      for (let i = 0; i < numFiles; i++) {
        const file = files?.item(i);
        const fileId = uuidv4();
        newFiles.push({
          fileId,
          file,
          progress: 0,
          success: false,
          error: null,
          url: null,
        });
      }
    }
    dispatch(setFilesToBeUploaded([...filesToBeUploaded, ...newFiles]));
  };

  const removeFile = (key: string): void => {
    const newFiles = filesToBeUploaded.filter((f) => f.fileId !== key);
    dispatch(setFilesToBeUploaded(newFiles));
    if (fileRef.current) fileRef.current.value = '';
  };

  const onUpload = async (): Promise<void> => {
    if (fileSetId)
      dispatch(
        createFilesAction({
          serviceName,
          fileSetId,
        })
      ).then(() => {
        if (fileRef.current) fileRef.current.value = '';
      });
  };

  const downloadHandler = (serviceName: string, fileId: string): void => {
    dispatch(getDownloadUrl({ serviceName, fileId }));
    setDownloadingId(fileId);
  };

  const columnDefinitions: TableProps.ColumnDefinition<D2DFileViewModel>[] = [
    {
      id: 'fileName',
      header: 'File Name',
      cell: (e) => e.fileName || e.file?.name,
    },
    {
      id: 'uploadedBy',
      header: 'Uploaded By',
      cell: (e) => e.createdBy,
    },
    {
      id: 'uploadedAt',
      header: 'Uploaded At',
      cell: (e) => (e.fileStatus === 'active' ? formatDateFromUnixYearToSecond(e.createdAt) : 'Pending...'),
    },
    {
      id: 'download',
      header: 'Download',
      cell: (e) => {
        const downloadLoading = urls[e.fileId]?.loading === 'pending';
        return e.fileStatus === 'active' ? (
          <Button
            data-test="download"
            key={e.fileId}
            iconName={downloadLoading ? undefined : 'download'}
            onClick={() => downloadHandler(e.serviceName, e.fileId)}
            loading={downloadLoading ? true : false}
          />
        ) : null;
      },
    },
    {
      id: 'remove',
      header: '',
      cell: (e) => {
        return e.progress === 0 ? (
          <Button
            key={e.fileId}
            iconName={urls[e.fileId] && urls[e.fileId].loading === 'pending' ? undefined : 'close'}
            onClick={() => removeFile(e.fileId)}
            loading={uploadInProgress}
            disabled={uploadInProgress}
          />
        ) : null;
      },
    },
  ];

  const filesLoading = uploadInProgress || getFilesLoading === 'pending';

  const filesHeader = (
    <Header
      variant="h2"
      description={filesTableError ? <Box color="text-status-error">{filesTableError}</Box> : ''}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <FormField>
            <input
              data-test="file-input"
              className="file-input"
              type="file"
              ref={fileRef}
              onChange={handleFiles}
              multiple
            />
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-test="select-files"
                className="icon-btn-align"
                onClick={() => {
                  fileRef.current && fileRef.current.click();
                }}
                loading={uploadInProgress}
                disabled={getFilesLoading === 'pending' || disabled}
              >
                Select Files
              </Button>
              <Button
                data-test="upload-files"
                variant="primary"
                onClick={onUpload}
                loading={uploadInProgress}
                disabled={filesToBeUploaded.length === 0}
                iconName="upload"
                iconAlign="left"
              >
                {filesToBeUploaded.length > 0 ? `Upload ${filesToBeUploaded.length} file(s)` : 'Upload'}
              </Button>
            </SpaceBetween>
          </FormField>
        </SpaceBetween>
      }
    >
      Versions
    </Header>
  );

  const displayTable = (
    <CollectionHooksTable
      preferenceLocalStorageKey="files-table-preferences"
      header={filesHeader}
      filterOptions={{ type: 'text' }}
      disableHeader
      columnDefinitions={columnDefinitions}
      allItems={displayedFiles ?? []}
      loading={filesLoading}
      loadingText="Loading Files..."
      empty={
        <Box textAlign="center" color="inherit">
          <b>No Files</b>
        </Box>
      }
      resizeableColumns
    />
  );

  return displayTable;
};

export default FileUploadTable;
