import { SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/store';
import { ArtifactsV2FormInputs, artifactsV2FormSchemaToApiSchema } from '../ArtifactV2Common';
import ArtifactsV2Common from '../ArtifactV2Common/ArtifactV2Common';
import {
  getArtifactByIdAction,
  setArtifactV2DetailFlashbarItems,
  setArtifactV2EditFlashbarItems,
  setArtifactV2SearchFlashbarItems,
  updateArtifactV2Action,
} from '../../../store/artifactv2';
import { createErrorItem, createSuccessItem, createUnknownErrorItem } from '../../../utils/formUtils';
import { useNavigate } from 'react-router';
import { ArtifactWorkflowStatus, UpdateArtifactInput } from '@amzn/d2d-bff-schema';
import { ArtifactV2HistoryTable } from '../ArtifactV2HistoryTable/ArtifactV2HistoryTable';
import useClearFlashbarItems from '../../../hooks/useClearFlashbarItems';

interface ArtifactV2EditPageProps {
  artifactId: string;
}

const ArtifactV2Edit: React.FC<ArtifactV2EditPageProps> = ({ artifactId }) => {
  const { updateLoading, getArtifactByIdLoading, artifact, artifactIngressID } = useAppSelector(
    (state) => state.artifactV2Store
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [fileValidationStatus, setFileValidationStatus] = useState<ArtifactWorkflowStatus | null | 'PENDING'>(null);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { meta } = await dispatch(getArtifactByIdAction({ id: artifactId! }));
      if (meta.requestStatus === 'rejected') {
        navigate('/artifacts/v2/'); // navigate back to search
        dispatch(setArtifactV2SearchFlashbarItems([createUnknownErrorItem()]));
      }
    })();
  }, [dispatch, navigate, artifactId]);

  const onSubmit: SubmitHandler<ArtifactsV2FormInputs> = async (data) => {
    const artifactFormSchemaToApiSchema = artifactsV2FormSchemaToApiSchema.parse(data);
    if (
      fileValidationStatus !== null &&
      (fileValidationStatus !== ArtifactWorkflowStatus.FILE_VALIDATION_SUCCEEDED || !artifactIngressID)
    ) {
      dispatch(
        setArtifactV2EditFlashbarItems([
          createErrorItem('Error creating artifact: File missing or file validation was not successful'),
        ])
      );
      return;
    }
    const newArtifactVersion =
      fileValidationStatus === ArtifactWorkflowStatus.FILE_VALIDATION_SUCCEEDED
        ? { ingressArtifactID: artifactIngressID! } // eslint-disable-line @typescript-eslint/no-non-null-assertion
        : undefined;
    const updateArtifactInput: UpdateArtifactInput = {
      artifactId: artifact?.id ?? '',
      newArtifactVersion,
      ...artifactFormSchemaToApiSchema,
    };
    const { meta } = await dispatch(updateArtifactV2Action({ input: updateArtifactInput }));
    if (meta.requestStatus === 'fulfilled') {
      navigate(`/artifacts/v2/${artifact?.id}`); // Redirect to Details page
      dispatch(setArtifactV2DetailFlashbarItems([createSuccessItem(`Artifact edited successfully`)]));
    }
  };
  // clear edit page flashbar items on component unmount
  useClearFlashbarItems(setArtifactV2EditFlashbarItems);
  const artifactEdit =
    getArtifactByIdLoading === 'pending' ? (
      <Spinner size="large" />
    ) : (
      <>
        <SpaceBetween size={'l'}>
          <ArtifactsV2Common
            artifact={artifact}
            fileValidationStatus={fileValidationStatus}
            setFileValidationStatus={setFileValidationStatus}
            isLoading={updateLoading === 'pending'}
            onSubmit={onSubmit}
            submitText={fileValidationStatus !== 'PENDING' ? 'Save Changes' : 'File validating...'}
            isEdit={true}
            cancelRoute={`/artifacts/v2/${artifactId}`}
          />
          {<ArtifactV2HistoryTable artifact={artifact} collapsible={true} />}
        </SpaceBetween>
      </>
    );

  return <SpaceBetween size="l">{artifactEdit}</SpaceBetween>;
};

export default ArtifactV2Edit;
