// Redux
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
// RTK slices
import classificationReducer from './classification/classificationSlice';
import userReducer from './user/userSlice';
import filesReducer from './files/filesSlice';
import townsendReducer from './townsend/townsendSlice';
import configReducer from './config';
import licenseManagerReducer from './licenseManager';
import artifactsReducer from './artifact/artifactSlice';
import artifactsV2Reducers from './artifactv2/artifactV2Slice';
import featureFlagReducer from './featureFlag/featureFlagSlice';
import pcClassificationReducer from './pcClassification/pcClassificationSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const reducers = combineReducers({
  artifactStore: artifactsReducer,
  artifactV2Store: artifactsV2Reducers,
  classificationStore: classificationReducer,
  pcClassificationStore: pcClassificationReducer,
  featureFlagStore: featureFlagReducer,
  filesStore: filesReducer,
  townsendStore: townsendReducer,
  licenseManagerStore: licenseManagerReducer,
  userStore: userReducer,
  configStore: configReducer,
});

// call configure store here
const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // ignore checking for serializability in actions that use the File class
        ignoredActions: [
          'files/setFilesToBeUploaded',
          'files/getFilesAction',
          'files/createFilesAction',
          'files/setFileUploadProgress',
          'files/createFilesAction/pending',
          'files/createFilesAction/fulfilled',
          'files/createFilesAction/rejected',
          'files/setUploadInProgress',
        ],
        ignoredActionPaths: ['files'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
