import React from 'react';
import { CollectionHooksTable } from '../Table';
import {
  CLASS_FILTERING_PROPERTIES,
  COLUMN_DEFINITIONS,
  VISIBLE_CONTENT,
  VISIBLE_CONTENT_PREFERENCE,
} from './classificationsTableConfig';

export default function ClassificationsTable({ classifications = [], classificationsLoading, itemType }) {
  return (
    <CollectionHooksTable
      localStorageKey="old-classifications-table-preferences"
      headerTitle={itemType}
      allItems={classifications}
      loading={classificationsLoading}
      visibleContent={VISIBLE_CONTENT}
      columnDefinitions={COLUMN_DEFINITIONS}
      filterOptions={{
        type: 'property',
        filteringProperties: CLASS_FILTERING_PROPERTIES,
      }}
      visibleContentPreference={VISIBLE_CONTENT_PREFERENCE}
      empty="Matched Classifications will be displayed here"
    />
  );
}
