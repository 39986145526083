import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideNavigation } from '@amzn/awsui-components-react/polaris';
import { CONTACT_US_HREF, FeatureFlag } from '../../utils/constants';
import useFeatureFlag from '../../hooks/useFeatureFlag';

const SideNav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeHref, setActiveHref] = useState(pathname);
  const legacyArtifactsAvailable = useFeatureFlag(FeatureFlag.ARTIFACTS_UI, true).boolValue ?? true;
  // const artifactsV2Available = useFeatureFlag(FeatureFlag.ARTIFACTS_V2, false).boolValue ?? true;

  const setNavLocation = (e) => {
    if (!e.detail.external) {
      e.preventDefault();
      setActiveHref(e.detail.href);
      navigate(e.detail.href);
    }
  };

  const navItems = [
    legacyArtifactsAvailable ? { type: 'link', text: 'Artifacts', href: '/artifacts' } : [],
    // artifactsV2Available ? { type: 'link', text: 'Artifacts V2', href: '/artifacts/v2' } : [],
    { type: 'link', text: 'Classifications', href: '/classifications' },
    { type: 'divider' },
    {
      type: 'link',
      text: 'GTPC Partners Wiki',
      href: 'https://w.amazon.com/bin/view/AWS_Global_Trade_Product_Compliance_GTPC/GTPC_Tech/GTPCPartners',
      external: true,
    },
    {
      type: 'link',
      text: 'Access Request SIM',
      href: 'https://t.corp.amazon.com/create/templates/2d746882-e320-4cfe-bd4a-5ddbae700f65',
      external: true,
    },
    {
      type: 'link',
      text: 'Contact Us',
      href: CONTACT_US_HREF,
      external: true,
    },
  ];

  return (
    <>
      <SideNavigation
        items={navItems}
        header={{ href: '/', text: 'Design To Deploy' }}
        activeHref={activeHref}
        onFollow={(e) => setNavLocation(e)}
        data-test="sidenav-component"
      />
    </>
  );
};

export default SideNav;
