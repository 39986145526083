import * as XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export const exportAsCsv = (csvData) => {
  const csvBlob = XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(csvData));
  return new Blob([csvBlob], { type: fileType });
};

export const flattenObject = (obj) => {
  const flat = {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flat, flattenObject(obj[key]));
    } else {
      flat[key] = obj[key];
    }
  });
  return flat;
};

export const flattenObjectsInArray = (arr) => {
  return arr.map((result) => {
    return flattenObject(result);
  });
};
