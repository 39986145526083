import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { CREATE_LICENSE, CREATE_USAGE, UPDATE_LICENSE } from '../graphql/mutations';
import { IndexedLicense, License, LicenseInput, Usage, UsageInput } from '@amzn/d2d-bff-schema';
import { GET_LICENSE, SEARCH_LICENSES } from '../graphql/queries';

/**
 * GQL CREATE License
 * @param {LicenseInput} licenseInput input
 * @return {Promise<GraphQLResult<License>>} created license
 */
export async function makeCreateRequest(
  licenseInput: LicenseInput
): Promise<GraphQLResult<{ createLicense: License }>> {
  const mutation = CREATE_LICENSE;
  const variables = { input: licenseInput };
  return API.graphql(graphqlOperation(mutation, variables)) as Promise<GraphQLResult<{ createLicense: License }>>;
}

/**
 * GQL CREATE License Usage
 * @param {string} licenseId id
 * @param {UsageInput} usageInput input
 * @return {Promise<GraphQLResult<Usage>>} created license
 */
export async function makeUsageCreateRequest(
  licenseId: string,
  usageInput: UsageInput
): Promise<GraphQLResult<{ createUsage: Usage }>> {
  const mutation = CREATE_USAGE;
  const variables = { usage: usageInput, licenseId };
  return API.graphql(graphqlOperation(mutation, variables)) as Promise<GraphQLResult<{ createUsage: Usage }>>;
}

/**
 * GQL UPDATE License
 * @param {string} licenseId id
 * @param {LicenseInput} licenseInput input
 * @return {Promise<GraphQLResult<License>>} updated license
 */
export async function makeUpdateRequest(
  licenseId: string,
  licenseInput: LicenseInput
): Promise<GraphQLResult<{ updateLicense: License }>> {
  const mutation = UPDATE_LICENSE;
  const variables = { licenseId, input: licenseInput };
  return API.graphql(graphqlOperation(mutation, variables)) as Promise<GraphQLResult<{ updateLicense: License }>>;
}

/**
 * GQL GET License
 * @param {string} licenseId id
 * @return {Promise<GraphQLResult<License>>} License
 */
export async function makeGetRequest(licenseId: string): Promise<GraphQLResult<{ getLicense: License }>> {
  const query = GET_LICENSE;
  const variables = { licenseId };
  return API.graphql(graphqlOperation(query, variables)) as Promise<GraphQLResult<{ getLicense: License }>>;
}

/**
 * GQL search licenses
 * @param {string} searchTerm license search term
 * @return {Promise<GraphQLResult<License[]>>} list of matching licenses
 */
export async function makeSearchRequest(
  searchTerm: string
): Promise<GraphQLResult<{ searchLicenses: IndexedLicense[] }>> {
  const query = SEARCH_LICENSES;
  const variables = { searchTerm };
  return API.graphql(graphqlOperation(query, variables)) as Promise<
    GraphQLResult<{ searchLicenses: IndexedLicense[] }>
  >;
}
