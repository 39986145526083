export const APP_LAYOUT_LABELS = {
  notifications: 'Notifications',
  navigation: 'Navigation',
  navigationToggle: 'Toggle Side Navigation',
  navigationClose: 'Close Side Navigation',
  tools: 'Tools',
  toolsToggle: 'Toggle Tools',
  toolsClose: 'Close Tools',
};

export const HEADER_SELECTOR = '#app-header';
