import {
  Container,
  DatePicker,
  FormField,
  Grid,
  Header,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
  Textarea,
  Toggle,
} from '@amzn/awsui-components-react';
import React, { FormEvent, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LicenseFormInputs, lmApiSchemaToFormSchema, lmFormSchema } from '.';
import { License } from '@amzn/d2d-bff-schema';
import { COMMODITY, COUNTRIES, CURRENCY, LM_AUTHORIZATION_TYPE, SOURCE, TRANSACTION_DIRECTION } from 'utils/constants';
import { DATE_PICKER_ARIA_LABELS, getOptionalLabel, nullishCheckToString } from 'utils/formUtils';
import { useAppSelector } from 'store/store';
import TokenListInput from 'components/TokenListInput';
import { useDebouncedIPNSearch } from 'hooks/useDebounce/useDebounce';
import useValidationLicenseForm, { hasErrors } from 'hooks/useValidationLicenseForm/useValidationLicenseForm';
import FormWrapper from 'components/FormWrapper';
import { EnumDisplayMapping } from 'types';
import { currencyForCountry } from '../../utils/artifactHelpers';

interface LMCommonFormProps {
  onSubmit: SubmitHandler<LicenseFormInputs>;
  submitText: string;
  cancelRoute: string;
  formHeaderText?: string;
  isLoading?: boolean;
  isEdit?: boolean;
  license?: License;
  defaultValues?: Partial<LicenseFormInputs>;
  artifactType?: Partial<EnumDisplayMapping> | null;
  artifactSubType?: Partial<EnumDisplayMapping> | null;
}

// Common form shared between LM create and LM edit
const LicenseManagerCommonForm: React.FC<LMCommonFormProps> = ({
  onSubmit,
  submitText,
  cancelRoute,
  license,
  isLoading = false,
  defaultValues,
  artifactType,
  artifactSubType,
  isEdit = false,
}) => {
  const { resetField, control, handleSubmit, watch, formState, setValue } = useForm<LicenseFormInputs>({
    defaultValues: license ? lmApiSchemaToFormSchema.parse(license) : defaultValues,
    resolver: zodResolver(lmFormSchema),
    mode: 'onChange',
  });
  useEffect(() => {
    if (!license) {
      // Derive the transaction direction from ArtifactType Selection, should never return undefined except for coding errors in 'value'
      const calculateTransactionDirection = (): EnumDisplayMapping | undefined => {
        if (artifactType?.value === 'import_authorization') {
          return TRANSACTION_DIRECTION.find((td) => td.value === 'IMPORT');
        } else if (artifactType?.value === 'export_authorization') {
          return TRANSACTION_DIRECTION.find((td) => td.value === 'EXPORT');
        }
        // Add any future transaction direction here
      };
      const calculatedDirection = calculateTransactionDirection();
      calculatedDirection && setValue('transactionDirection', calculatedDirection);
      // Derive LM authorizationType from Artifact Sub Type.
      if (artifactSubType?.value) {
        const lmAuthType = LM_AUTHORIZATION_TYPE.find((td) => td.value === artifactSubType.value);
        setValue('authorizationType', lmAuthType);
      }
      resetField('mpn');
    }
  }, [artifactType?.value, artifactSubType?.value, setValue, resetField, license]);

  const { effectiveDate, expirationDate, transactionDirection, disabled, source } = watch();

  const { ipnInfoList, ipnsLoading } = useAppSelector((state) => state.townsendStore);
  const setTownsendSearchTerm = useDebouncedIPNSearch();
  const onTokenDismiss = (values: string[], itemIndex: number, onChangeFn: (...event: unknown[]) => void): void => {
    const tmpItems = [...values];
    tmpItems.splice(itemIndex, 1);
    onChangeFn(tmpItems);
  };

  const {
    allowedExportValidation,
    allowedImportValidation,
    partValidation,
    htsValidation,
    eccnValidation,
    effectiveDateValidation,
    otherSourceNameValidation,
  } = useValidationLicenseForm(watch(), formState.isSubmitted);

  const submit = async (submitEvent: FormEvent): Promise<void> => {
    submitEvent.preventDefault();

    handleSubmit((e) => {
      if (!hasErrors(watch())) {
        onSubmit(e);
      }
    })(submitEvent);
  };

  const licenseFormHeader = <Header>Authorization Information</Header>;
  const backendSourceValues = SOURCE.filter((sourceValue) => sourceValue.filteringTags?.includes('backend'));
  const frontendSourceValues = SOURCE.filter((sourceValue) => sourceValue.filteringTags?.includes('frontend'));
  const hasActiveUsages = Boolean(license?.usages?.some((usage) => !usage.canceled));
  const lmContent = (
    <Container header={licenseFormHeader}>
      <SpaceBetween size="l">
        <Controller
          name="licenseNumber"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField label="License Number" description="Enter a license number" errorText={error && error.message}>
              <Input disabled={hasActiveUsages} value={value} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
        <Controller
          name="applicationNumber"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField label={getOptionalLabel('Application Number')} errorText={error && error.message}>
              <Input value={value ?? ''} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
        <Controller
          name="disabled"
          control={control}
          render={({ field: { onChange, value = false }, fieldState: { error } }) => (
            <FormField
              label="Disabled"
              description="Is this license currently disabled?"
              errorText={error && error.message}
            >
              <Toggle checked={value} onChange={(e) => onChange(e.detail.checked)}>
                {value ? 'Yes' : 'No'}
              </Toggle>
            </FormField>
          )}
        />
        <Controller
          name="applicationDate"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={'Application Date'}
              description="Application date of the license"
              errorText={error && error.message}
            >
              <DatePicker
                value={value}
                onChange={(e) => onChange(e.detail.value)}
                placeholder="YYYY/MM/DD"
                {...DATE_PICKER_ARIA_LABELS}
              />
            </FormField>
          )}
        />
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
          <Controller
            name="effectiveDate"
            control={control}
            render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
              <FormField
                label={disabled?.valueOf() ? getOptionalLabel('Effective Date') : 'Effective Date'}
                description="Effective date of the license"
                errorText={
                  (error && error.message) || (effectiveDateValidation.hasError && effectiveDateValidation.message)
                }
              >
                <DatePicker
                  disabled={hasActiveUsages}
                  value={value}
                  isDateEnabled={(date: Date) => (expirationDate ? date < new Date(expirationDate) : true)}
                  onChange={(e) => onChange(e.detail.value)}
                  placeholder="YYYY/MM/DD"
                  {...DATE_PICKER_ARIA_LABELS}
                />
              </FormField>
            )}
          />
          <Controller
            name="expirationDate"
            control={control}
            render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
              <FormField
                label={getOptionalLabel('Expiration Date')}
                description="Expiration date of the license"
                errorText={error && error.message}
              >
                <DatePicker
                  value={value}
                  isDateEnabled={(date: Date) => {
                    return effectiveDate ? date > new Date(effectiveDate) : true;
                  }}
                  onChange={(e) => onChange(e.detail.value)}
                  placeholder="YYYY/MM/DD"
                  {...DATE_PICKER_ARIA_LABELS}
                />
              </FormField>
            )}
          />
        </Grid>
        <Controller
          name="singleUse"
          control={control}
          render={({ field: { onChange, value = false }, fieldState: { error } }) => (
            <FormField
              label="Single Use"
              description="Do you want this license to be single use?"
              errorText={error && error.message}
            >
              <Toggle disabled={hasActiveUsages} checked={value} onChange={(e) => onChange(e.detail.checked)}>
                {value ? 'Yes' : 'No'}
              </Toggle>
            </FormField>
          )}
        />
        <Controller
          name="issuingCountry"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label="Issuing Country"
              description="What country issued this license?"
              errorText={error && error.message}
            >
              <Select
                disabled={hasActiveUsages}
                filteringType="auto"
                selectedOption={value}
                options={COUNTRIES}
                onChange={(e) => {
                  e.detail.selectedOption.value &&
                    setValue('valueCurrency', currencyForCountry(e.detail.selectedOption.value), {
                      shouldValidate: formState.isSubmitted,
                    });
                  onChange(e.detail.selectedOption);
                  // TODO: Refactor this when implementing Import Authorization functionality
                }}
                placeholder="Choose a country"
              />
            </FormField>
          )}
        />
        <Controller
          name="allowedImportCountries"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={
                // TODO: Refactor this when implementing Import Authorization functionality
                transactionDirection?.value === 'IMPORT'
                  ? 'Export To Countries'
                  : getOptionalLabel('Export To Countries')
              }
              description={`If set, license is only valid to export to selected countries`}
              errorText={
                (error && error.message) || (allowedImportValidation.hasError && allowedImportValidation.message)
              }
            >
              <Multiselect
                disabled={hasActiveUsages}
                filteringType="auto"
                selectedOptions={value ?? []}
                options={COUNTRIES}
                onChange={(e) => onChange(e.detail.selectedOptions)}
                placeholder="Choose countries"
              />
            </FormField>
          )}
        />
        <Controller
          name="allowedExportCountries"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={
                // TODO: Refactor this when implementing Import Authorization functionality
                transactionDirection?.value === 'EXPORT'
                  ? 'Export From Countries'
                  : getOptionalLabel('Export From Countries')
              }
              description={`If set, license is only valid to export from selected countries`}
              errorText={
                (error && error.message) || (allowedExportValidation.hasError && allowedExportValidation.message)
              }
            >
              <Multiselect
                disabled={hasActiveUsages}
                filteringType="auto"
                selectedOptions={value ?? []}
                options={COUNTRIES}
                onChange={(e) => onChange(e.detail.selectedOptions)}
                placeholder="Choose countries"
              />
            </FormField>
          )}
        />
        {isEdit && (
          <Controller
            name="authorizationType"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormField
                label={getOptionalLabel('Authorization Type')}
                description="Ex: Bulk Permit, Deemed Export, etc."
                errorText={error && error.message}
              >
                <Select
                  selectedOption={value ?? null}
                  options={LM_AUTHORIZATION_TYPE}
                  onChange={(e) => onChange(e.detail.selectedOption)}
                  placeholder="Choose an authorization type"
                />
              </FormField>
            )}
          />
        )}
        <Controller
          name="commodity"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Commodity')}
              description="Hardware, Software, Source Code and Technology"
              errorText={error && error.message}
            >
              <Select
                selectedOption={value ?? null}
                options={COMMODITY}
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Choose a Commodity Type"
              />
            </FormField>
          )}
        />
        <Controller
          name="valueCurrency"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const option = value ? { value: value.toString() } : null;
            return (
              <FormField
                label="Value Currency"
                description="Currency of license (USD, CAD, etc.)"
                errorText={error && error.message}
              >
                <Select
                  filteringType="auto"
                  selectedOption={option}
                  options={CURRENCY}
                  onChange={(e) => onChange(e.detail.selectedOption.value)}
                  placeholder="Choose a currency"
                />
              </FormField>
            );
          }}
        />
        <Controller
          name="unitLimit"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Unit Limit')}
              description="Total number of units this license can be used with"
              errorText={error && error.message}
            >
              <Input
                disabled={hasActiveUsages}
                value={nullishCheckToString(value)}
                type="number"
                onChange={({ detail }) => {
                  const intRes = Number.parseInt(detail.value);
                  const res = isNaN(intRes) ? null : intRes;
                  onChange(res);
                }}
              />
            </FormField>
          )}
        />
        <Controller
          name="valueLimit"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Value Limit')}
              description="Total value of units this license can be used with"
              errorText={error && error.message}
            >
              <Input
                disabled={hasActiveUsages}
                value={nullishCheckToString(value)}
                type="number"
                onChange={({ detail }) => {
                  const floatRes = Number.parseFloat(detail.value);
                  const res = isNaN(floatRes) ? null : floatRes;
                  onChange(res);
                }}
              />
            </FormField>
          )}
        />
        <Controller
          name="ipn"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Agile IPN Search')}
              errorText={(error && error.message) || (partValidation.hasError && partValidation.message)}
            >
              <Multiselect
                disabled={hasActiveUsages}
                selectedOptions={value ?? []}
                filteringType="manual"
                onChange={(e) => onChange(e.detail.selectedOptions)}
                filteringPlaceholder="Search IPNs"
                placeholder="Choose IPNs"
                loadingText="Loading IPNs"
                errorText="Error fetching results."
                empty="No IPNs found"
                options={ipnInfoList?.map((part) => {
                  return { value: part.ipn, label: part.ipn };
                })}
                finishedText="End of results"
                recoveryText="Retry"
                onLoadItems={({ detail }) => {
                  setTownsendSearchTerm(detail.filteringText);
                }}
                statusType={ipnsLoading}
              />
            </FormField>
          )}
        />
        {transactionDirection?.value !== 'EXPORT' && (
          <Controller
            name="mpn"
            control={control}
            render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
              <FormField
                label={getOptionalLabel('MPN(s)')}
                errorText={(error && error.message) || (partValidation.hasError && partValidation.message)}
              >
                <TokenListInput
                  disabled={hasActiveUsages}
                  values={value}
                  onAdd={(tokenValues) => onChange(tokenValues)}
                  inputProps={{ placeholder: '100-012905' }}
                  tokenGroupProps={{
                    onDismiss: ({ detail: { itemIndex } }) => onTokenDismiss(value, itemIndex, onChange),
                  }}
                />
              </FormField>
            )}
          />
        )}
        <Controller
          name="eccn"
          control={control}
          render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
            <FormField
              label={transactionDirection?.value === 'EXPORT' ? 'ECCN(s)' : getOptionalLabel('ECCN(s)')}
              errorText={
                (error && error.message) ||
                (eccnValidation.hasError && eccnValidation.message) ||
                (partValidation.hasError && partValidation.message)
              }
            >
              <TokenListInput
                disabled={hasActiveUsages}
                values={value}
                onAdd={(tokenValues) => onChange(tokenValues)}
                inputProps={{ placeholder: '5A002' }}
                tokenGroupProps={{
                  onDismiss: ({ detail: { itemIndex } }) => onTokenDismiss(value, itemIndex, onChange),
                }}
              />
            </FormField>
          )}
        />
        <Controller
          name="hts"
          control={control}
          render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('HTS')}
              errorText={
                (error && error.message) ||
                (htsValidation.hasError && htsValidation.message) ||
                (partValidation.hasError && partValidation.message)
              }
            >
              <TokenListInput
                disabled={hasActiveUsages}
                values={value}
                onAdd={(tokenValues) => onChange(tokenValues)}
                inputProps={{ placeholder: '84715090' }}
                tokenGroupProps={{
                  onDismiss: ({ detail: { itemIndex } }) => onTokenDismiss(value, itemIndex, onChange),
                }}
              />
            </FormField>
          )}
        />
        <Controller
          name="nonPlmPartNames"
          control={control}
          render={({ field: { onChange, value = [] }, fieldState: { error } }) => {
            return (
              <FormField label={getOptionalLabel('Non-Agile Part Name(s)')} errorText={error && error.message}>
                <TokenListInput
                  values={value}
                  onAdd={(tokenValues) => onChange(tokenValues)}
                  inputProps={{ placeholder: 'AMA-ORG-001' }}
                  tokenGroupProps={{
                    onDismiss: ({ detail: { itemIndex } }) => onTokenDismiss(value, itemIndex, onChange),
                  }}
                />
              </FormField>
            );
          }}
        />
        <Controller
          name="source"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const isSourceSetOnBackend = backendSourceValues.some((source) => source.value === value?.value);
            return (
              <FormField
                label="Source"
                description="Where did this data come from? (Agile, Windchill, etc.)"
                errorText={error && error.message}
                constraintText={
                  isSourceSetOnBackend &&
                  `This value was set by your administrator, use 'Contact Us' link to cut a ticket if this field needs to be edited`
                }
              >
                <Select
                  selectedOption={value ?? null}
                  options={isSourceSetOnBackend ? backendSourceValues : frontendSourceValues}
                  onChange={(e) => {
                    if (e.detail.selectedOption.value !== 'OTHER') setValue('otherSourceName', undefined);
                    onChange(e.detail.selectedOption);
                  }}
                  disabled={isSourceSetOnBackend}
                  placeholder="Source of the license data"
                />
              </FormField>
            );
          }}
        />
        <Controller
          name="otherSourceName"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const isSourceOther = source?.value === 'OTHER';
            return isSourceOther ? (
              <FormField
                label="Other Source Name"
                errorText={
                  (error && error.message) || (otherSourceNameValidation.hasError && otherSourceNameValidation.message)
                }
                description="If you selected 'Other' as the Source, key in the Source here"
              >
                <Input
                  value={value ?? ''}
                  onChange={(e) => {
                    onChange(e.detail.value);
                  }}
                />
              </FormField>
            ) : (
              <></>
            );
          }}
        />
        <Controller
          name="exporter"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField label="Exporter" errorText={error && error.message}>
              <Input value={value ?? ''} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
        <Controller
          name="externalId"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField label={getOptionalLabel('External ID')} errorText={error && error.message}>
              <Input value={value ?? ''} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
        <Controller
          name="consignee"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField label={getOptionalLabel('Consignee')} errorText={error && error.message}>
              <Input disabled={hasActiveUsages} value={value ?? ''} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
        <Controller
          name="validEndUser"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Valid End User')}
              description="Business contact or address - If set, license is only valid to ship to this user/entity"
              errorText={error && error.message}
            >
              <Textarea disabled={hasActiveUsages} value={value ?? ''} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
        <Controller
          name="notes"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('License Notes')}
              description="Internal change notes for auditing and informational purposes"
              errorText={error && error.message}
            >
              <Textarea value={value ?? ''} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
      </SpaceBetween>
    </Container>
  );

  return (
    <form onSubmit={submit}>
      <FormWrapper
        isLoading={isLoading}
        submitText={submitText}
        cancelRoute={cancelRoute}
        isMainActionDisabled={isLoading}
      >
        {lmContent}
      </FormWrapper>
    </form>
  );
};

export default LicenseManagerCommonForm;
