import React, { useEffect } from 'react';
import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Flashbar,
  Header,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import ArtifactV2Details from './ArtifactV2Details';
import { Link, useParams } from 'react-router-dom';
import { createArtifactsReadonlyWarningItem, createUnknownErrorItem, flashbarItemsCreator } from 'utils/formUtils';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  getArtifactByIdAction,
  setArtifactV2DetailFlashbarItems,
  setArtifactV2SearchFlashbarItems,
} from 'store/artifactv2/artifactV2Slice';
import { useNavigate } from 'react-router';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import { FeatureFlag } from '../../../utils/constants';

const ArtifactV2DetailsLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { artifactId } = useParams<string>();
  const { artifactV2DetailFlashbarItems, getArtifactByIdLoading, artifact } = useAppSelector(
    (state) => state.artifactV2Store
  );
  const artifactV2Available = useFeatureFlag(FeatureFlag.ARTIFACTS_V2, true).boolValue ?? true;

  // clear detail page flashbar items on component unmount
  useEffect(() => {
    return () => {
      dispatch(setArtifactV2DetailFlashbarItems([]));
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { meta } = await dispatch(getArtifactByIdAction({ id: artifactId! }));
      if (meta.requestStatus === 'rejected') {
        navigate('/artifacts/v2/'); // navigate back to search
        dispatch(setArtifactV2SearchFlashbarItems([createUnknownErrorItem()]));
      }
    })();
  }, [dispatch, navigate, artifactId]);

  const flashBarItemsWithHardcodedMessage = [...artifactV2DetailFlashbarItems];
  const readonlyWarning = createArtifactsReadonlyWarningItem(artifactV2Available);
  if (!flashBarItemsWithHardcodedMessage.find((items) => items.header === readonlyWarning.header)) {
    flashBarItemsWithHardcodedMessage.push(readonlyWarning);
  }

  return (
    <AppLayout
      contentType="table"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={
        <Header
          variant="h1"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Link to={!artifact?.archived ? `/artifacts/v2/edit/${artifactId}` : '#'}>
                <Button disabled={artifact?.archived}>Edit</Button>
              </Link>
              <Link to={`/artifacts/v2`}>
                <Button>Back to Artifacts Search</Button>
              </Link>
            </SpaceBetween>
          }
        >
          {/*  TODO: Change to human-friendly Artifact ID */}
          {artifactId ? `Artifact #${artifactId}` : 'Artifact ID unknown'}
        </Header>
      }
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Artifacts', href: `/artifacts/v2` },
            { text: `${artifactId ?? 'Artifact'}`, href: '#' },
          ]}
        />
      }
      content={
        getArtifactByIdLoading !== 'fulfilled' && getArtifactByIdLoading !== 'rejected' ? (
          <Spinner size="large" />
        ) : (
          <ArtifactV2Details artifact={artifact} />
        )
      }
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar
          items={flashbarItemsCreator(flashBarItemsWithHardcodedMessage, dispatch, setArtifactV2DetailFlashbarItems)}
        />
      }
      stickyNotifications
      toolsHide
    />
  );
};

export default ArtifactV2DetailsLayout;
