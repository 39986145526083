import {
  Draft,
  PayloadAction,
  SerializedError,
  createAsyncThunk,
  createSlice,
  miniSerializeError,
} from '@reduxjs/toolkit';
import {
  D2DGraphQLError,
  EnumDisplayMapping,
  FlashbarItemsProps,
  LoadingState,
  PolarisLoadingStatus,
} from '../../types';
import { flattenObjectsInArray } from '../../utils/export';
import { getRumClient } from '../../analytics/rum';
import { isGraphQLResult, processGraphQLErrors } from '../../utils/errorHandler';
import { toRumSafeString } from '../../utils/rumUtils';
import { ErrorType, PcClassification, PccCode, PccPart, PccPartInput, PccPartSearchResult } from '@amzn/d2d-bff-schema';
import {
  makeCreateRequest,
  makeGetRequest,
  makeListPCCCodeRequest,
  makeSearchRequest,
  makeUpdateRequest,
} from '../../_services/api/pcClassification';
import { GraphQLResult } from '@aws-amplify/api-graphql';

export type PCClassificationState = {
  getLoading: LoadingState;
  createLoading: LoadingState;
  updateLoading: LoadingState;
  searchLoading: LoadingState;
  pccPart?: PccPart;
  updatedPCCPart?: PccPart;
  createdPCCPart?: PccPart;
  pcClassifications: PccPartSearchResult;
  pcClassificationErrors: SerializedError[];
  unknownPCClassificationError?: SerializedError;
  pcClassificationLandingFlashbarItems: FlashbarItemsProps[];
  pcClassificationCreateFlashbarItems: FlashbarItemsProps[];
  pcClassificationEditFlashbarItems: FlashbarItemsProps[];
  pcClassificationDetailFlashbarItems: FlashbarItemsProps[];
  pccCodes?: PccCode[];
  formattedPccCodes: EnumDisplayMapping[];
  pccCodesLoading: PolarisLoadingStatus;
  pccCodesError?: SerializedError;
};

export const initialPCClassificationState: PCClassificationState = {
  getLoading: 'idle',
  createLoading: 'idle',
  updateLoading: 'idle',
  searchLoading: 'idle',
  pcClassifications: { results: [], totalResults: 0 },
  pcClassificationErrors: [],
  pcClassificationCreateFlashbarItems: [],
  pcClassificationDetailFlashbarItems: [],
  pcClassificationEditFlashbarItems: [],
  pcClassificationLandingFlashbarItems: [],
  pccCodesLoading: 'pending',
  pccCodesError: undefined,
  formattedPccCodes: [],
};

type SearchFields = {
  searchTerm: string;
  sortOrder: 'asc' | 'desc';
  sortField: string;
  pageSize: number;
  from: number;
};

type ListPccCodesActionResults = {
  pccCodes: PccCode[];
  formattedPccCodes: { value: string; label?: string }[];
};

export const createPCCPartAction = createAsyncThunk<
  PccPart | undefined,
  PccPartInput,
  {
    rejectValue: D2DGraphQLError[];
  }
>('pcClassification/createPccPart', async (pccPartInput: PccPartInput, { rejectWithValue }) => {
  try {
    const res = await makeCreateRequest(pccPartInput);
    if (res.data?.createPccPart) {
      return res.data.createPccPart;
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<PccPart>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});
export const getPCCPartAction = createAsyncThunk<
  PccPart | undefined,
  {
    partSource: string;
    partIdentifierType: string;
    partIdentifierValue: string;
  },
  {
    rejectValue: D2DGraphQLError[];
  }
>(
  'pcClassification/getPccPart',
  async ({ partSource, partIdentifierType, partIdentifierValue }, { rejectWithValue }) => {
    try {
      const res = await makeGetRequest(partSource, partIdentifierType, partIdentifierValue);
      if (res.data?.getPccPart && !res.errors) {
        return res.data.getPccPart;
      }
    } catch (e) {
      const gqlResult = isGraphQLResult<PccPart>(e);
      if (gqlResult && gqlResult.errors) {
        return rejectWithValue(gqlResult.errors);
      } else {
        throw e;
      }
    }
  }
);

export const searchPCClassificationAction = createAsyncThunk<
  PccPartSearchResult | undefined,
  SearchFields,
  {
    rejectValue: D2DGraphQLError[];
  }
>(
  'pcClassifications/searchPCClassificationsAction',
  async (searchFields: SearchFields, { rejectWithValue, dispatch }) => {
    try {
      const pcResults = await makeSearchRequest(searchFields);
      if (!pcResults?.data?.searchPccPart) {
        throw new Error(
          `Invalid response from GQL query searcPccPart(). Cannot find searchPccPart in response. Response: ${JSON.stringify(
            pcResults
          )}`
        );
      }
      const pcClassifications = pcResults.data.searchPccPart;
      return pcClassifications;
    } catch (e) {
      const gqlResult = isGraphQLResult<PcClassification>(e);
      if (gqlResult && gqlResult.errors) {
        return rejectWithValue(gqlResult.errors);
      } else {
        throw e;
      }
    }
  }
);

export const updatePCCPartAction = createAsyncThunk<
  PccPart | undefined,
  { partId: string; partInput: PccPartInput },
  {
    rejectValue: D2DGraphQLError[];
  }
>('pcClassification/updatePccPart', async ({ partId, partInput }, { rejectWithValue }) => {
  try {
    const pccPartRes: GraphQLResult<{ updatePccPart: PccPart }> = await makeUpdateRequest(partId, partInput);
    if (pccPartRes.data?.updatePccPart && !pccPartRes.errors) {
      return pccPartRes.data.updatePccPart;
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<PccPart>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

export const listPCCCodesAction = createAsyncThunk<
  ListPccCodesActionResults,
  Record<string, never> | undefined,
  {
    rejectValue: D2DGraphQLError[];
  }
>('pcClassification/listPccCodes', async (record = {}, { rejectWithValue }) => {
  try {
    const pccCodesResult = await makeListPCCCodeRequest();
    if (!pccCodesResult?.data?.listPccCodes) {
      throw new Error(
        `Invalid response from GQL query listPccCodes(). Cannot find listPccCodes in response. Response: ${JSON.stringify(
          pccCodesResult
        )}`
      );
    }
    const pccCodes = pccCodesResult.data.listPccCodes;
    return {
      pccCodes: pccCodes,
      formattedPccCodes: pccCodes
        ?.map((i) => {
          return { value: i.category, label: i.label };
        })
        .sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          } else if (a.label < b.label) {
            return -1;
          } else {
            return 0;
          }
        }),
    };
  } catch (e) {
    const gqlResult = isGraphQLResult<PcClassification>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

const { reducer, actions } = createSlice({
  name: 'pcClassification',
  initialState: initialPCClassificationState,
  reducers: {
    setPCClassifications: (state, { payload }: PayloadAction<PccPartSearchResult>) => {
      state.pcClassifications = payload;
    },
    setPCClassificationCreateFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.pcClassificationCreateFlashbarItems = payload;
    },
    setPCClassificationDetailFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.pcClassificationDetailFlashbarItems = payload;
      state.createLoading = 'idle';
      state.updateLoading = 'idle';
    },
    setPCClassificationEditFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.pcClassificationEditFlashbarItems = payload;
    },
    setPCClassificationLandingFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.pcClassificationLandingFlashbarItems = payload;
      state.getLoading = 'idle';
      state.createLoading = 'idle';
      state.updateLoading = 'idle';
    },
    resetLoadingState: (state) => {
      state.createLoading = 'idle';
      state.updateLoading = 'idle';
      state.getLoading = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchPCClassificationAction.pending, (state) => {
        state.searchLoading = 'pending';
      })
      .addCase(searchPCClassificationAction.fulfilled, (state, { payload }) => {
        if (!payload) {
          throw new Error('Undefined payload after receiving fulflilled case for searchPCClassificationAction');
        }
        state.pcClassifications = payload;
        state.searchLoading = 'fulfilled';
      })
      .addCase(searchPCClassificationAction.rejected, (state, { payload, error }) => {
        handleRejectedAction(state, payload, error, 'searchLoading', 'pcClassificationLandingFlashbarItems');
      })
      .addCase(getPCCPartAction.pending, (state) => {
        state.pcClassificationErrors = [];
        state.getLoading = 'pending';
      })
      .addCase(getPCCPartAction.fulfilled, (state, { payload }) => {
        state.pccPart = payload;
        state.getLoading = 'fulfilled';
      })
      .addCase(getPCCPartAction.rejected, (state, { payload, error }) => {
        handleRejectedAction(state, payload, error, 'getLoading', 'pcClassificationLandingFlashbarItems');
      })
      .addCase(createPCCPartAction.pending, (state) => {
        state.createLoading = 'pending';
      })
      .addCase(createPCCPartAction.fulfilled, (state, { payload }) => {
        state.createdPCCPart = payload;
        state.createLoading = 'fulfilled';
      })
      .addCase(createPCCPartAction.rejected, (state, { payload, error }) => {
        handleRejectedAction(state, payload, error, 'createLoading', 'pcClassificationCreateFlashbarItems');
      })
      .addCase(updatePCCPartAction.pending, (state) => {
        state.updateLoading = 'pending';
      })
      .addCase(updatePCCPartAction.fulfilled, (state, { payload }) => {
        state.updatedPCCPart = payload;
        state.updateLoading = 'fulfilled';
      })
      .addCase(updatePCCPartAction.rejected, (state, { payload, error }) => {
        handleRejectedAction(state, payload, error, 'updateLoading', 'pcClassificationEditFlashbarItems');
      })
      .addCase(listPCCCodesAction.pending, (state) => {
        state.pccCodesLoading = 'loading';
      })
      .addCase(listPCCCodesAction.fulfilled, (state, { payload }) => {
        const { pccCodes, formattedPccCodes } = payload;
        state.pccCodes = pccCodes;
        state.formattedPccCodes = formattedPccCodes;
        state.pccCodesLoading = 'finished';
      })
      .addCase(listPCCCodesAction.rejected, (state, { payload, error }) => {
        state.pccCodesError = miniSerializeError(error);
        state.pccCodesLoading = 'error';
      });
  },
});

export const {
  setPCClassificationCreateFlashbarItems,
  setPCClassificationEditFlashbarItems,
  setPCClassificationLandingFlashbarItems,
  setPCClassificationDetailFlashbarItems,
  setPCClassifications,
} = actions;

export default reducer;

/* "Private" functions: */

const errorMap = {
  [ErrorType.NOT_FOUND]: 'Classification not found',
  [ErrorType.UNIQUE_KEY_CONSTRAINT_ERROR]: 'Classification already exists',
  [ErrorType.ILLEGAL_CHARACTER_ERROR]: 'Your input contains a forbidden character',
  [ErrorType.INVALID_FIELD_VALUE_ERROR]: 'Invalid field value. Please check field length and format',
};

/**
 * Handle all concerns related to any general rejected PCClassifications action, including setting up state and logging
 * the error to analytics.
 * @param state The Redux state, which may be mutated by this function.
 * @param payload The errors, if any, from a request to server.
 * @param error The error
 * @param stateFieldRejected The field within state which should be set to rejected
 * @param flashbarItemsField The field within state which should be set with flashbar items
 * @returns void
 */
const handleRejectedAction = (
  state: Draft<PCClassificationState>,
  payload: D2DGraphQLError[] | undefined,
  error: SerializedError,
  stateFieldRejected: 'getLoading' | 'createLoading' | 'updateLoading' | 'searchLoading',
  flashbarItemsField:
    | 'pcClassificationCreateFlashbarItems'
    | 'pcClassificationDetailFlashbarItems'
    | 'pcClassificationEditFlashbarItems'
    | 'pcClassificationLandingFlashbarItems'
): void => {
  // Log to analytics/RUM
  try {
    const rumSafeString = toRumSafeString(flattenObjectsInArray(payload));
    getRumClient()?.recordError(new Error(`Request to server was rejected. Payload: ${rumSafeString}`));
  } catch (e) {
    console.error('Failed to send to rum', e);
  }
  // Update State
  if (payload) {
    state[flashbarItemsField] = processGraphQLErrors(payload, errorMap);
    if (payload) state.pcClassificationErrors = payload;
  } else {
    state.unknownPCClassificationError = miniSerializeError(error);
  }
  state[stateFieldRejected] = 'rejected';
};
