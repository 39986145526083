import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { townsendIpnSearch } from 'store/townsend/townsendSlice';

/**
 * Delay state from upating if we need to wait for a user to finish typing before an expensive operation
 * @param {T} value value to debounce
 * @param {number} delay debounce delay in ms
 * @return {T} updated return value
 */
function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

/**
 * Debounced IPN townsend search
 * @param {number} delay debounce delay in ms
 * @return {React.Dispatch<React.SetStateAction<string>>} search term setter
 */
export function useDebouncedIPNSearch(delay?: number): React.Dispatch<React.SetStateAction<string>> {
  const dispatch = useDispatch();

  const [townsendSearchTerm, setTownsendSearchTerm] = useState('');

  // Debounce the townsend search
  const debouncedSearchTerm = useDebounce(townsendSearchTerm, delay ?? 300);

  useEffect(() => {
    // if we have value search townsend
    if (debouncedSearchTerm.length > 0) dispatch(townsendIpnSearch(debouncedSearchTerm));
    // run this effect only on updates to the debounced search term
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, dispatch]);

  return setTownsendSearchTerm;
}

export default useDebounce;
