import { Asset, AssetsSearchResponse, AwsPart, Country, Maybe, Scalars } from '@amzn/d2d-bff-schema';

export type ArtifactCard = {
  name?: string | null;
  issuingCountry?: string | null;
  issuingCountryFormat?: string | null;
  part?: string | null;
  ipn?: string | null;
  mpn?: string | null;
  requestId?: string | null;
};

export type AssetViewModelProps = {
  part?: string | null;
  mpn?: string | null;
  issuingCountryFormat?: string;
  authorizationTypeFormatted?: string;
  nonPlmPartName?: string | null;
  awsPart?: AwsPart | null;
  expirationDateFormatted?: string;
  startDateFormatted?: string;
  artifactTypeFormatted?: string;
  standardsFormatted?: Maybe<string>[];
  activeDisplay?: string;
  parts?: string[];
  countries?: string[];
  cards?: ArtifactCard[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expirationDate?: any;
};

export type AssetViewModel = Omit<Asset, '__typename' | 'createdAt' | 'modifiedAt' | 'expirationDate'> &
  AssetViewModelProps;
export type AssetSearchViewModel = Omit<
  AssetsSearchResponse,
  '__typename' | 'createdAt' | 'modifiedAt' | 'expirationDate'
> &
  AssetViewModelProps;

// Holds info needed for Assets and Licenses search results
export type ArtifactsSearchResult = {
  identifier: string;
  type?: string;
  country?: string;
  mpn?: string;
  ipn?: string;
  hts?: string;
  eccn?: string;
  status?: string;
  expiration?: string;
  superskuName?: string;
  awsProjectExternal?: string;
  getRoute?: string;
  searchFilter: SearchResultFilter;
};

export enum SearchResultFilter {
  asset = 'asset',
  license = 'license',
}

// type that holds the formatted values of Assets and License Manager
export type ArtifactSearchViewModel = {
  // COMMON
  mpn?: string | string[];
  ipn?: string | string[];
  issuingCountry?: string;
  expirationDate?: string;
  externalId?: string;
  score?: number;

  // ASSETS SEARCH
  requestId?: string;
  part?: string;
  artifactType?: string;
  nonPlmPartName?: string;
  standards?: string[];
  parts?: string[];
  countries?: string[];
  cards?: ArtifactCard[];
  startDate?: string;

  // LICENSE MANAGER SEARCH
  licenseNumber?: string;
  licenseId?: string;
  allowedExportCountries?: Array<Country>;
  allowedImportCountries?: Array<Country>;
  applicationNumber?: Scalars['String'];
  commodity?: string;
  consignee?: Scalars['String'];
  eccn?: Array<string>;
  effectiveDate?: Scalars['AWSDateTime'];
  hts?: Array<string>;
  manualReviewRequired?: Scalars['Boolean'];
  nonPlmPartNames?: Array<Scalars['String']>;
  notes?: Scalars['String'];
  singleUse?: Scalars['Boolean'];
  techControlPlanRequired?: Scalars['Boolean'];
  transactionDirection?: string;
  unitLimit?: Scalars['Int'];
  usageCount?: Scalars['Int'];
  usageValue?: Scalars['Float'];
  validEndUser?: Scalars['String'];
  valueCurrency?: string;
  valueLimit?: Scalars['Float'];
};
