import { GetFeatureFlagOutput } from '@amzn/gtpcfeature-flag-jsclient';
import { useEffect } from 'react';

import { useAppSelector } from '../../store/store';
import { getFeatureFlagAction } from '../../store/featureFlag';
import { useDispatch } from 'react-redux';

const cacheLengthMs = 5 * 60 * 1000; // 5 minutes
/**
 *
 * @param featureFlagName Name of feature flag to fetch
 * @param defaultValue The default value to use if fail to fetch value. TODO: Support additional data types beyond bool
 * @returns GetFeatureFlagOutput
 */
const useFeatureFlag = (featureFlagName: string, defaultValue?: boolean): GetFeatureFlagOutput => {
  const dispatch = useDispatch();
  const { featureFlags } = useAppSelector((state) => state.featureFlagStore);

  useEffect(() => {
    const getFF = async (): Promise<void> => {
      if (!(featureFlagName in featureFlags)) {
        await dispatch(getFeatureFlagAction(featureFlagName));
        return;
      }

      const featureFlag = featureFlags[featureFlagName];
      if (Date.now() > featureFlag.timestamp + cacheLengthMs) {
        await dispatch(getFeatureFlagAction(featureFlagName));
      }
    };
    getFF().catch((e) => {
      console.error(`Failed to fetch feature flag value for flag ${featureFlagName}`, e);
    });
  }, [featureFlagName, defaultValue, featureFlags, dispatch]);

  return featureFlags[featureFlagName]?.data ?? { boolValue: defaultValue, name: featureFlagName };
};

export default useFeatureFlag;
