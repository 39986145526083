import React, { useEffect } from 'react';
import { createSuccessItem } from '../../utils/formUtils';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/store';
import PcClassificationCommon from '../PcClassificationCommon/PcClassificationCommon';
import { SubmitHandler } from 'react-hook-form';
import {
  createPCCPartAction,
  setPCClassificationCreateFlashbarItems,
  setPCClassificationDetailFlashbarItems,
} from '../../store/pcClassification';
import { PCCPartFormInput, pccPartFormSchemaToApiSchema } from '../PcClassificationCommon';
import { useNavigate } from 'react-router';

const PcClassificationCreate: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createLoading: loading, pcClassificationErrors, createdPCCPart } = useAppSelector(
    (state) => state.pcClassificationStore
  );

  const onSubmit: SubmitHandler<PCCPartFormInput> = async (data: PCCPartFormInput) => {
    const pccFormSchemaToApiSchema = pccPartFormSchemaToApiSchema.parse(data);
    dispatch(createPCCPartAction(pccFormSchemaToApiSchema));
  };

  useEffect(() => {
    if (loading === 'fulfilled' && createdPCCPart?.partId) {
      const { partSource, partIdentifierType, partIdentifierValue } = createdPCCPart;
      dispatch(
        setPCClassificationDetailFlashbarItems([
          createSuccessItem(
            `Part with part source ${partSource}, part identifier type ${partIdentifierType}, and part identifier value ${partIdentifierValue} created successfully!`
          ),
        ])
      );
      navigate(`/classification/pc/${partSource}/${partIdentifierType}/${partIdentifierValue}`);
    }
  }, [dispatch, createdPCCPart, pcClassificationErrors, loading, navigate]);

  // clear create page flashbar items on component unmount
  useEffect(() => {
    return () => {
      dispatch(setPCClassificationCreateFlashbarItems([]));
    };
  }, [dispatch]);

  return (
    <PcClassificationCommon onSubmit={onSubmit} isLoading={false} submitText="Create" cancelRoute="/classifications" />
  );
};

export default PcClassificationCreate;
