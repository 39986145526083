/**
 * There is an unidentified bug in AWS RUM recordError: Errors sent will not be shown with any message or session ID
 * in the RUM dashboard. Cause appears related to presence of these illegalCharacters.
 * We filter out any illegal characters entries returned from the backend from the message to RUM.
 * @param recs The records to turn into a string safe for RUM
 * @returns a string safe for RUM
 */
export const toRumSafeString = (recs: Record<string, unknown>[]): string => {
  let s = '[';
  recs.forEach((rec) => {
    for (const [k, v] of Object.entries(rec)) {
      if (k !== 'illegalCharacters') s += `${k}:${v},`;
    }
  });
  return s + ']';
};

const ERROR_PATTERNS = [
  /chrome-extension/,
  /moz-extension/,
  // ResizeObserver loop errors are commonly ignored by other teams using RUM because they don't indicate a user impacting issue.
  /ResizeObserver loop/,
];

/**
 * Determine whether or not passed event is an ErrorEvent class
 * @param event - Error event to determine whether to ignore or not
 * @returns true if is ErrorEvent
 */
const isErrorEvent = (event: ErrorEvent | PromiseRejectionEvent): event is ErrorEvent => {
  return event instanceof ErrorEvent;
};

/**
 * Determine whether or not to ignore an error for RUM
 * Based off of https://code.amazon.com/packages/AWSSECSauronFrontend/blobs/5b27e6610f728550663e5df905027abf73ac8d4c/--/src/rum.ts#L91
 * @param errorEvent - ErrorEvent to handle
 * @returns true if the ErrorEvent should be ignored for RUM error telemetry
 */
export const errorEventHandler = (errorEvent: ErrorEvent | PromiseRejectionEvent): boolean => {
  let shouldIgnoreError = false;
  if (isErrorEvent(errorEvent)) {
    shouldIgnoreError = ERROR_PATTERNS.some(
      (errorPattern) =>
        errorPattern.test(errorEvent.filename) ||
        errorPattern.test(errorEvent.message) ||
        errorPattern.test(errorEvent.error?.name) ||
        errorPattern.test(errorEvent.error?.message) ||
        errorPattern.test(errorEvent.error?.stack)
    );
  }

  return shouldIgnoreError;
};
