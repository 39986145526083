import {
  Box,
  Button,
  Container,
  DatePicker,
  FormField,
  Grid,
  Header,
  Input,
  Multiselect,
  SegmentedControl,
  Select,
  SpaceBetween,
  Tabs,
  Textarea,
  TokenGroup,
} from '@amzn/awsui-components-react';
import InfoLink from 'components/InfoLink';
import React, { FormEvent, useEffect, useState } from 'react';
import { Controller, FieldError, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArtifactFormInputs, artifactsApiSchemaToFormSchema, artifactsFormSchema } from '.';
import useValidationArtifactForm, { hasErrors } from 'hooks/useValidationArtifactForm/useValidationArtifactForm';
import { Asset, AssetType, AwsPart } from '@amzn/d2d-bff-schema';
import {
  APPLICANT_TYPE_OPTIONS,
  ARTIFACT_SUBTYPE_MENU_OPTIONS,
  AWS_PROJECT_EXTERNAL,
  COUNTRIES,
  ISSUING_BODIES,
  SOURCE,
  STANDARDS,
  STATUS_OPTIONS,
  SUPERSKU_NAMES,
} from 'utils/constants';
import { DATE_PICKER_ARIA_LABELS, getOptionalLabel } from 'utils/formUtils';
import { useAppSelector } from 'store/store';
import { useDebouncedIPNSearch } from 'hooks/useDebounce/useDebounce';
import { EnumDisplayMapping } from 'types';
import FormWrapper from 'components/FormWrapper';
import { ClickDetail } from '@amzn/awsui-components-react/polaris/internal/events';
import { useDispatch } from 'react-redux';
import { setArtifactSideNavState } from 'store/artifact/artifactSlice';

interface ArtifactCommonFormProps {
  onSubmit: SubmitHandler<ArtifactFormInputs>;
  submitText: string;
  cancelRoute: string;
  artifactType?: Partial<EnumDisplayMapping> | null;
  isComplianceRecord?: boolean;
  formHeaderText?: string;
  isLoading?: boolean;
  isEdit?: boolean;
  asset?: Asset;
  defaultValues?: Partial<ArtifactFormInputs>;
}

type TabIds = 'awsPart' | 'nonAgileProduct';

// Common form shared between LM create and LM edit
const ArtifactCommonForm: React.FC<ArtifactCommonFormProps> = ({
  onSubmit,
  submitText,
  cancelRoute,
  asset,
  isLoading = false,
  isComplianceRecord = false,
  defaultValues,
  artifactType,
}) => {
  const dispatch = useDispatch();
  const [selectedAwsPart, setSelectedAwsPart] = useState<Partial<AwsPart> | null>(null);
  const [activeTabId, setActiveTabId] = useState<TabIds>('awsPart');
  const [selectedSegmentId, setSelectedSegmentId] = useState('awsPart');
  const [subAuthTypes, setSubAuthTypes] = useState<EnumDisplayMapping[]>([]);
  const { ipnInfoList, ipnsLoading } = useAppSelector((state) => state.townsendStore);
  // const artifactUiAvailable = useFeatureFlag(FeatureFlag.ARTIFACTS_UI, true).boolValue ?? true;

  useEffect(() => {
    setSubAuthTypes(ARTIFACT_SUBTYPE_MENU_OPTIONS.filter((sat) => sat.format === artifactType?.value));
  }, [artifactType?.value]);

  const { control, handleSubmit, watch, setError, setValue, clearErrors, getFieldState, trigger, formState } = useForm<
    ArtifactFormInputs
  >({
    defaultValues: asset ? artifactsApiSchemaToFormSchema(asset) : defaultValues,
    resolver: yupResolver(artifactsFormSchema),
    context: { partTabId: activeTabId, isComplianceRecord, subAuthTypes },
    mode: 'onChange',
  });
  const { errors } = formState;
  const { otherSourceNameValidation } = useValidationArtifactForm(watch(), formState.isSubmitted);
  const { fields: nonPlmPartFields, append, remove } = useFieldArray({
    control,
    name: 'nonPlmParts',
  });
  const { startDate, awsParts, nonPlmParts, currentNonPlmPart, source } = watch();

  const setTownsendSearchTerm = useDebouncedIPNSearch();
  const onTokenDismiss = (values: AwsPart[], itemIndex: number, onChangeFn: (...event: unknown[]) => void): void => {
    const tmpItems = [...values];
    tmpItems.splice(itemIndex, 1);
    onChangeFn(tmpItems);
  };

  const submit = async (submitEvent: FormEvent): Promise<void> => {
    submitEvent.preventDefault();
    handleSubmit((e) => {
      if (!hasErrors(watch())) {
        onSubmit(e);
      }
    })(submitEvent);
  };

  const onPartAddition = (
    event: CustomEvent<ClickDetail>,
    value: AwsPart[] | null,
    onChange: (...event: unknown[]) => void
  ): void => {
    event?.preventDefault();
    if (activeTabId === 'awsPart') {
      setValue('nonPlmParts', []);
      trigger('awsParts');
      setValue('assetType', AssetType.awspart);
      const hasIpnMatch =
        selectedAwsPart?.ipn &&
        !selectedAwsPart?.mpn &&
        awsParts &&
        awsParts?.findIndex((p) => p?.ipn === selectedAwsPart?.ipn) > -1;
      const hasIpnMpnMatch =
        selectedAwsPart?.mpn &&
        awsParts &&
        awsParts?.findIndex((p) => p?.ipn === selectedAwsPart?.ipn && p?.mpn === selectedAwsPart?.mpn) > -1;
      if (value && selectedAwsPart?.ipn && !hasIpnMatch && !hasIpnMpnMatch) {
        onChange([...value, selectedAwsPart]);
        setSelectedAwsPart(null);
      } else if (!selectedAwsPart?.ipn && selectedAwsPart?.mpn) {
        setError('awsParts', { message: 'IPN must be set in order to add an MPN' });
      }
    }
    if (activeTabId !== 'awsPart') {
      setValue('assetType', AssetType.nonplmpart);
      setValue('awsParts', []);
      if (
        currentNonPlmPart?.nonPlmPartName &&
        (nonPlmParts || [])?.findIndex((p) => p?.nonPlmPartName === currentNonPlmPart?.nonPlmPartName) === -1 &&
        currentNonPlmPart?.description
      ) {
        append(currentNonPlmPart);
        clearErrors('nonPlmParts');
        setValue('currentNonPlmPart', {
          nonPlmPartName: '',
          description: '',
          manufacturerName: undefined,
          manufacturerPartId: undefined,
        });
      }
      if (!currentNonPlmPart?.nonPlmPartName) {
        setError('currentNonPlmPart.nonPlmPartName', {
          message: 'Required',
        });
      }
      if (!currentNonPlmPart?.description) {
        setError('currentNonPlmPart.description', {
          message: 'Required',
        });
      }
    }
  };

  const artifactsFormHeader = <Header>Artifact Information</Header>;

  const nonPlmPart = (
    <>
      <Controller
        name="currentNonPlmPart.nonPlmPartName"
        control={control}
        render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
          <FormField data-test="3pName-input" label="Non-Agile Part Name" errorText={error && error.message}>
            <Input
              id="thirdPartyPartName"
              name="thirdPartyPartName"
              onChange={({ detail }) => onChange(detail.value)}
              value={value}
            />
          </FormField>
        )}
      />
      <Controller
        name="currentNonPlmPart.description"
        control={control}
        render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
          <FormField
            data-test="3pDescription-input"
            label="Non-Agile Part Description"
            errorText={error && error.message}
          >
            <Input
              id="thirdPartyDescription"
              name="thirdPartyDescription"
              onChange={({ detail }) => onChange(detail.value)}
              value={value}
            />
          </FormField>
        )}
      />
      <Controller
        name="currentNonPlmPart.manufacturerName"
        control={control}
        render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
          <FormField
            data-test="3p-manufacturer-name"
            label={getOptionalLabel('Non-Agile Manufacturer Name')}
            errorText={error && error.message}
          >
            <Input
              id="thirdPartyManufacturerName"
              name="thirdPartyManufacturerName"
              onChange={({ detail }) => onChange(detail.value)}
              value={value ?? ''}
            />
          </FormField>
        )}
      />
      <Controller
        name="currentNonPlmPart.manufacturerPartId"
        control={control}
        render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
          <FormField
            data-test="3pNumber-input"
            label={getOptionalLabel('Non-Agile Manufacturer ID')}
            errorText={error && error.message}
          >
            <Input
              id="manufacturerPartId"
              name="manufacturerPartId"
              onChange={({ detail }) => onChange(detail.value)}
              value={value ?? ''}
            />
          </FormField>
        )}
      />
    </>
  );

  const backendSourceValues = SOURCE.filter((sourceValue) => sourceValue.filteringTags?.includes('backend'));
  const frontendSourceValues = SOURCE.filter((sourceValue) => sourceValue.filteringTags?.includes('frontend'));

  const artifactsContent = (
    <SpaceBetween size="m">
      <Container header={artifactsFormHeader}>
        <SpaceBetween size="l">
          <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
            <Controller
              name="startDate"
              control={control}
              render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
                <FormField
                  label="Effective Date"
                  description="Effective date of the artifact"
                  errorText={error && error.message}
                >
                  <DatePicker
                    value={value}
                    onChange={(e) => onChange(e.detail.value)}
                    placeholder="YYYY/MM/DD"
                    {...DATE_PICKER_ARIA_LABELS}
                  />
                </FormField>
              )}
            />
            <Controller
              name="expirationDate"
              control={control}
              render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
                <FormField
                  label={getOptionalLabel('Expiration Date')}
                  description="Expiration date of the artifact"
                  errorText={error && error.message}
                >
                  <DatePicker
                    value={value}
                    isDateEnabled={(date: Date) => date > new Date(startDate)}
                    onChange={(e) => onChange(e.detail.value)}
                    placeholder="YYYY/MM/DD"
                    {...DATE_PICKER_ARIA_LABELS}
                  />
                </FormField>
              )}
            />
          </Grid>
          <Controller
            name="issuingCountries"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormField
                label="Issuing Countries"
                description="Add countries to this artifact"
                errorText={error && error.message}
              >
                <Multiselect
                  filteringType="auto"
                  selectedOptions={value ?? []}
                  options={COUNTRIES}
                  onChange={(e) => onChange(e.detail.selectedOptions)}
                  placeholder="Choose countries"
                />
              </FormField>
            )}
          />
          <Controller
            name="authorizationStatus"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <FormField
                  label="Status"
                  errorText={errors.authorizationStatus && errors.authorizationStatus?.value?.message}
                >
                  <Select
                    selectedOption={value ?? null}
                    options={STATUS_OPTIONS}
                    onChange={(e) => onChange(e.detail.selectedOption)}
                    placeholder="Choose a status"
                  />
                </FormField>
              );
            }}
          />
          <Controller
            name="awsProjectExternal"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <FormField
                  label={getOptionalLabel('AWS Project External')}
                  errorText={errors.awsProjectExternal && errors.awsProjectExternal?.value?.message}
                >
                  <Select
                    filteringType="auto"
                    selectedOption={value?.value ? value : null}
                    options={AWS_PROJECT_EXTERNAL}
                    onChange={(e) => onChange(e.detail.selectedOption)}
                    placeholder="Choose AWS Project External"
                    virtualScroll
                  />
                </FormField>
              );
            }}
          />

          <Controller
            name="source"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const isSourceSetOnBackend = backendSourceValues.some((source) => source.value === value?.value);

              return (
                <FormField
                  label="Source"
                  description="Where did this data come from? (Agile, Windchill, etc.)"
                  errorText={errors.source && errors.source?.value?.message}
                  constraintText={
                    isSourceSetOnBackend &&
                    `This value was set by your administrator, use 'Contact Us' link to cut a ticket if this field needs to be edited`
                  }
                >
                  <Select
                    selectedOption={value ?? null}
                    options={isSourceSetOnBackend ? backendSourceValues : frontendSourceValues}
                    onChange={(e) => {
                      if (e.detail.selectedOption.value !== 'OTHER') setValue('otherSourceName', undefined);

                      onChange(e.detail.selectedOption);
                    }}
                    disabled={isSourceSetOnBackend}
                    placeholder="Source of the artifact data"
                  />
                </FormField>
              );
            }}
          />

          <Controller
            name="otherSourceName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const isSourceOther = source?.value === 'OTHER';

              return isSourceOther ? (
                <FormField
                  label="Other Source Name"
                  errorText={
                    (error && error.message) ||
                    (otherSourceNameValidation.hasError && otherSourceNameValidation.message)
                  }
                  description="If you selected 'Other' as the Source, key in the Source here"
                >
                  <Input
                    value={value ?? ''}
                    onChange={(e) => {
                      onChange(e.detail.value);
                    }}
                  />
                </FormField>
              ) : (
                <></>
              );
            }}
          />
        </SpaceBetween>
      </Container>
      <Container
        header={
          <Header
            info={
              <InfoLink
                id="part-information-info-link"
                onFollow={() => {
                  dispatch(setArtifactSideNavState({ toolsContentId: 'part-information-info-link', toolsOpen: true }));
                }}
              />
            }
          >
            Part Information
          </Header>
        }
      >
        <SpaceBetween size="l">
          <Controller
            name="awsParts"
            control={control}
            render={({ field: { onChange, value = [] }, fieldState: { error } }) => {
              const ipnVal = selectedAwsPart?.ipn ? { value: selectedAwsPart?.ipn } : null;

              return (
                <>
                  <Tabs
                    activeTabId={activeTabId}
                    onChange={({ detail }) => {
                      setActiveTabId(detail.activeTabId as TabIds);
                      setSelectedAwsPart(null);
                      setValue('nonPlmParts', []);
                      setValue('awsParts', []);
                    }}
                    tabs={[
                      {
                        id: 'awsPart',
                        label: 'AWS Part',
                        content: (
                          <SpaceBetween size="l">
                            <Controller
                              name="superskuName"
                              control={control}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <FormField
                                    label={getOptionalLabel('SuperSKU Name')}
                                    errorText={errors.superskuName && errors.superskuName?.value?.message}
                                  >
                                    <Select
                                      selectedOption={value?.value ? value : null}
                                      options={SUPERSKU_NAMES}
                                      onChange={(e) => onChange(e.detail.selectedOption)}
                                      placeholder="Choose a SuperSKU"
                                    />
                                  </FormField>
                                );
                              }}
                            />
                            <SegmentedControl
                              selectedId={selectedSegmentId}
                              onChange={({ detail }) => setSelectedSegmentId(detail.selectedId)}
                              label="Agile Part Segmented Control"
                              options={[
                                { text: 'Agile AWS Part', id: 'awsPart' },
                                { text: 'AWS Part Not In Agile ', id: 'awsPartNotInAgile' },
                              ]}
                            />
                            {selectedSegmentId === 'awsPart' ? (
                              <FormField
                                label="Agile IPN Search"
                                description="After Agile IPN search, please choose an IPN"
                                errorText={error && error.message}
                              >
                                <Select
                                  selectedOption={ipnVal}
                                  filteringType="manual"
                                  onChange={(e) => setSelectedAwsPart({ ipn: e.detail.selectedOption.value ?? '' })}
                                  filteringPlaceholder="Search IPNs"
                                  placeholder="Choose an IPN"
                                  loadingText="Loading IPNs"
                                  errorText="Error fetching results."
                                  empty="No IPNs found"
                                  options={ipnInfoList?.map((part) => {
                                    return { value: part.ipn, label: part.ipn };
                                  })}
                                  finishedText="End of results"
                                  recoveryText="Retry"
                                  onLoadItems={({ detail }) => {
                                    setTownsendSearchTerm(detail.filteringText);
                                  }}
                                  statusType={ipnsLoading}
                                />
                              </FormField>
                            ) : (
                              <FormField
                                label="IPN"
                                description="Individual Part Number"
                                errorText={error && error.message}
                              >
                                <Input
                                  value={selectedAwsPart?.ipn ?? ''}
                                  placeholder="100-017414-001"
                                  onChange={({ detail }) => {
                                    setSelectedAwsPart((old) => ({ ...old, ipn: detail?.value }));
                                  }}
                                />
                              </FormField>
                            )}

                            <FormField
                              label={getOptionalLabel('MPN')}
                              description="Manufacturer Part Number - Leave blank for all associated MPNs"
                            >
                              <Input
                                value={selectedAwsPart?.mpn ?? ''}
                                placeholder="100-012905"
                                onChange={({ detail }) => {
                                  setSelectedAwsPart((old) => ({ ...old, mpn: detail?.value }));
                                }}
                              />
                            </FormField>
                          </SpaceBetween>
                        ),
                      },
                      {
                        id: 'nonAgileProduct',
                        label: 'Non-Agile Product',
                        content: (
                          <SpaceBetween size="m">
                            <Box color="text-body-secondary">
                              If you need to add a non-agile part, you can manually add the required non-agile part name
                              and description. Manufacturer name and model number can also be manually added, but are
                              not required.
                            </Box>
                            {nonPlmPart}
                            {(getFieldState('nonPlmParts')?.error as FieldError)?.message && (
                              <Box color="text-status-error" variant="p">
                                {(getFieldState('nonPlmParts')?.error as FieldError)?.message}
                              </Box>
                            )}
                          </SpaceBetween>
                        ),
                      },
                    ]}
                  />
                  <SpaceBetween size="m">
                    <Button
                      iconName="add-plus"
                      data-test="part-add-btn"
                      onClick={(e) => onPartAddition(e, value, onChange)}
                    >
                      Add
                    </Button>
                    {value && value?.length > 0 && (
                      <>
                        <Box color="text-body-secondary">AWS Parts</Box>
                        <TokenGroup
                          items={
                            value &&
                            value.map((awsPart) => ({
                              label: `${awsPart?.ipn}${awsPart?.mpn ? `:${awsPart?.mpn}` : ''}`,
                            }))
                          }
                          onDismiss={({ detail }) => onTokenDismiss(value, detail.itemIndex, onChange)}
                        />
                      </>
                    )}
                    {nonPlmPartFields?.length > 0 && (
                      <>
                        <Box color="text-body-secondary">Non-Agile Products</Box>
                        <TokenGroup
                          items={nonPlmPartFields.map((field) => ({
                            label: field.nonPlmPartName,
                            description: field?.description,
                          }))}
                          onDismiss={({ detail }) => remove(detail.itemIndex)}
                        />
                      </>
                    )}
                  </SpaceBetween>
                </>
              );
            }}
          />
        </SpaceBetween>
      </Container>
      <Container header={<Header>Additional Artifact Information</Header>}>
        <SpaceBetween size="l">
          {isComplianceRecord && (
            <>
              <Controller
                name="issuingBody"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormField label="Issuing Body" errorText={errors.issuingBody && errors.issuingBody?.value?.message}>
                    <Select
                      filteringType="auto"
                      selectedOption={value ?? null}
                      options={ISSUING_BODIES}
                      onChange={(e) => onChange(e.detail.selectedOption)}
                      placeholder="Choose an issuing body"
                    />
                  </FormField>
                )}
              />
              <Controller
                name="applicantType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormField
                    label="Applicant Type"
                    errorText={errors.applicantType && errors.applicantType?.value?.message}
                  >
                    <Select
                      selectedOption={value ?? null}
                      options={APPLICANT_TYPE_OPTIONS}
                      onChange={(e) => onChange(e.detail.selectedOption)}
                      placeholder="Choose an applicant type"
                    />
                  </FormField>
                )}
              />
              <Controller
                name="applicantName"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormField label={getOptionalLabel('Applicant Name')} errorText={error && error.message}>
                    <Input value={value ?? ''} onChange={(e) => onChange(e.detail.value)} />
                  </FormField>
                )}
              />
              <Controller
                name="standards"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormField label={getOptionalLabel('Standards')} errorText={error && error.message}>
                    <Multiselect
                      filteringType="auto"
                      selectedOptions={value ?? []}
                      options={STANDARDS}
                      onChange={(e) => onChange(e.detail.selectedOptions)}
                      placeholder="Choose standards"
                    />
                  </FormField>
                )}
              />
            </>
          )}
          <Controller
            name="governmentTrackingNumber"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormField label={getOptionalLabel('Government Tracking Number')} errorText={error && error.message}>
                <Input value={value ?? ''} onChange={(e) => onChange(e.detail.value)} />
              </FormField>
            )}
          />
          <Controller
            name="externalId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormField label={getOptionalLabel('External ID')} errorText={error && error.message}>
                <Input value={value ?? ''} onChange={(e) => onChange(e.detail.value)} />
              </FormField>
            )}
          />
          <Controller
            name="authorizationNotes"
            control={control}
            render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
              <FormField
                label={getOptionalLabel('Artifact Notes')}
                description="Notes about this artifact"
                errorText={error && error.message}
              >
                <Textarea value={value ?? ''} onChange={(e) => onChange(e.detail.value)} />
              </FormField>
            )}
          />
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );

  return (
    <form onSubmit={submit}>
      <FormWrapper
        isLoading={isLoading}
        submitText={submitText}
        cancelRoute={cancelRoute}
        // isMainActionDisabled={isLoading || !artifactType || !artifactUiAvailable}
        isMainActionDisabled={true}
      >
        {artifactType && artifactsContent}
      </FormWrapper>
    </form>
  );
};

export default ArtifactCommonForm;
