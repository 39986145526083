import { Box, HelpPanel } from '@amzn/awsui-components-react';
import { SideNavState } from 'types';

const ToolsContent = {
  'part-information-info-link': (
    <HelpPanel header={<Box variant="h2">Artifacts - Part Information</Box>}>
      <Box variant="p">
        Each part must be added individually using the &quot;Add&quot; button. An Artifact cannot currently be created
        for and AWS Part and Non-Agile Product at the same time.
      </Box>
      <Box variant="h2">AWS Part</Box>
      <Box variant="p">Navigate to this tab to add an Agile PLM part to your artifact entry.</Box>
      <ul>
        <li>
          <Box variant="p">
            <b>SuperSKU Name:</b> dropdown of available SuperSKUs to assign to your artifact entry. At this time, the
            selection of SuperSKU only tags the created record(s) with SuperSKU name. We cannot yet create Artifacts
            only by SuperSKU or for every IPN under a SuperSKU.
          </Box>
        </li>
        <li>
          <Box variant="p">
            <b>IPN (required):</b> use &quot;Agile AWS Part&quot; to add IPNs via search from Agile. Use &quot;AWS Part
            Not In Agile&quot; to key in an IPN that may not yet be in the PLM system.
          </Box>
        </li>
      </ul>
      <Box variant="h2">Non-Agile Product</Box>
      <Box variant="p">Navigate to this tab to add Non-Agile/Non-PLM product(s)/part(s) to your artifact entry.</Box>
      <ul>
        <li>
          <Box variant="strong">Non-Agile Part Name (required)</Box>
        </li>
        <li>
          <Box variant="strong">Non-Agile Part Description (required)</Box>
        </li>
        <li>
          <Box variant="strong">Non-Agile Manufacturer Name</Box>
        </li>
        <li>
          <Box variant="strong">Non-Agile Manufacturer ID</Box>
        </li>
      </ul>
    </HelpPanel>
  ),
};

export interface ArtifactsSideNavState extends SideNavState {
  toolsContentId: keyof typeof ToolsContent;
}

export default ToolsContent;
