import { API, graphqlOperation } from '@aws-amplify/api';
import axios from 'axios';
import { CREATE_FILE } from '../graphql/mutations';
import { GET_FILE, GET_URL } from '../graphql/queries';

// GQL CREATE FILE
export async function makeCreateRequest(data) {
  const query = CREATE_FILE;
  const variables = { input: data };
  return API.graphql(graphqlOperation(query, variables));
}

// GQL GET FILE
export async function makeGetRequest(serviceName, fileSetId) {
  const query = GET_FILE;
  const variables = { serviceName: serviceName, fileSetId: fileSetId };
  return API.graphql(graphqlOperation(query, variables));
}

// GQL GET FILE S3 URL
export async function makeGetUrlRequest(serviceName, fileId) {
  const query = GET_URL;
  const variables = { serviceName: serviceName, fileId: fileId };
  return API.graphql(graphqlOperation(query, variables));
}

/**
 * PUT the file to its presigned S3 URL
 * @param {string} url presigned url
 * @param {Object} data file
 * @param {AxiosRequestConfig} putConfig axios request config
 * @return {Object} file info
 */
export const putFile = async (url, data, putConfig) => {
  if (data.progress === 0) {
    const target = { ...data, url };
    try {
      await axios.put(url, data.file, {
        ...putConfig,
        headers: {
          'Content-Type': data.file.type,
          'Content-Disposition': 'attachment',
        },
      });
      target.success = true;
      target.error = null;
      target.progress = 100;
    } catch (error) {
      target.success = false;
      target.error = error;
      target.progress = 0;
    }
    return target;
  }
};
