import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Polaris Components
import {
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
  TextContent,
  Textarea,
} from '@amzn/awsui-components-react/polaris';
import Loading from 'components/Loading';
import './trade-classifications-create.css';
import ClassificationsTable from 'components/ClassificationsTable';
import {
  COUNTRIES,
  EX_REPORT_REQ_TYPE,
  IM_APP_REQ,
  IM_REQ_COMPLETED,
  JUSTIFICATION_OPTIONS,
  PC_STATUS,
  PENDING,
  PROGRAM_OPTIONS,
  TRUE_OR_FALSE,
} from '../../utils/constants';
import { mapSelectOptions } from 'utils/polarisHelpers';
import {
  clearRelatedClassifications,
  createClassificationAction,
  searchRelatedClassificationsAction,
  setClassificationCreateFlashbarItems,
} from 'store/classification/classificationSlice';
import { useDebouncedIPNSearch } from 'hooks/useDebounce/useDebounce';
import { townsendValidateIpn } from 'store/townsend/townsendSlice';
import { flashbarItemsCreator } from '../../utils/formUtils';

const TradeClassificationCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { classificationLoading, relatedClassifications, classificationCreateFlashbarItems } = useSelector(
    (state) => state.classificationStore
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const countryQueryParam = searchParams.get('country');
  let initialCountryState = '';
  if (countryQueryParam) {
    const foundCountryInList = COUNTRIES.find(({ value }) => value === countryQueryParam.toLocaleUpperCase());
    if (foundCountryInList) {
      initialCountryState = foundCountryInList;
    }
  }
  const ipnQueryParams = searchParams.get('ipn');

  const initialMpnState = searchParams.get('mpn');

  // multiselect values
  const [selectedCountry, setSelectedCountry] = useState(initialCountryState);
  const [programOptions, setProgramOptions] = useState([]);
  // single select values
  const [optionEAR, setOptionEAR] = useState('');
  const [optionERR, setOptionERR] = useState('');
  const [optionERRT, setOptionERRT] = useState('');
  const [optionIRC, setOptionIRC] = useState('');
  const [optionIAR, setOptionIAR] = useState('');
  const [optionPart, setOptionPart] = useState({
    label: 'AWS Part',
    value: 'awspart',
  });
  const [pcStatus, setPcStatus] = useState('');
  const [localLanguageDescription, setLocalLanguageDescription] = useState('');
  // part info
  const [ipn, setIpn] = useState('');
  const [mpn, setMpn] = useState(initialMpnState ?? '');
  const [thirdPName, setThirdPName] = useState('');
  const [thirdPDesc, setThirdPDesc] = useState('');
  const [thirdPManName, setThirdPManName] = useState('');
  const [thirdPManId, setThirdPManId] = useState('');
  const [ipnDescription, setIpnDescription] = useState('');
  // import details
  const [hts, setHts] = useState('');
  const [justification, setJustification] = useState('');
  const [ICDetails, setICDetails] = useState('');
  const [IIRNotes, setIIRNotes] = useState('');
  const [BIRNotes, setBIRNotes] = useState('');
  // export details
  const [ecn, setEcn] = useState('');
  const [ecDetails, setECDetails] = useState('');
  const [ERNotes, setERNotes] = useState('');

  // validation
  const [allValid, setAllValid] = useState(false);
  const [afterSubmit, setAfterSubmit] = useState(false);
  const [htsValid, setHtsValid] = useState(false);
  const [ecnValid, setEcnValid] = useState(false);
  const [tpnValid, setTPNValid] = useState(false);
  const [tpdValid, setTPDValid] = useState(false);
  const [ipnValid, setIpnValid] = useState(false);
  const [cValid, setCValid] = useState(false);
  const [hts6Valid, setHts6Valid] = useState(false);

  // townsend search
  const [filteringText, setFilteringText] = useState('');
  const [selectedIpn, setSelectedIpn] = useState('');
  const [notInAgile, setNotInAgile] = useState(false);
  const { ipnInfoList, ipnsLoading, ipnsValidateLoading, ipnsValidatedValue, ipnsValidateError } = useSelector(
    (state) => state.townsendStore
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateFields = () => {
    const areAllValid = cValid && (htsValid || (hts6Valid && ecnValid)) && ((tpnValid && tpdValid) || ipnValid);
    if ((afterSubmit && !hts) || (hts && hts.length < 6)) {
      setHtsValid(false);
    }
    if ((hts && hts.length >= 6) || !hts) {
      setHts6Valid(true);
    } else if (hts && hts.length < 6) {
      setHts6Valid(false);
    }
    if ((afterSubmit && !ecn) || ecn.length < 1) {
      setEcnValid(false);
    }
    // TODO: replace the initial ipn validation error with a banner notification, rather that field error
    // sim: https://sim.amazon.com/issues/GTPC-D2D-768
    if ((afterSubmit && !ipn) || (ipnsValidateError && !selectedIpn && !ipn)) {
      setIpnValid(false);
    }
    if (afterSubmit && !thirdPName) {
      setTPNValid(false);
    }
    if (afterSubmit && !thirdPDesc) {
      setTPDValid(false);
    }
    setAllValid(areAllValid);
  };

  useEffect(() => {
    // Scroll to the top of the screen when there is a new flashbar item to show
    window.scrollTo(0, 0);
  }, [classificationCreateFlashbarItems]);

  useEffect(() => {
    if (relatedClassifications && relatedClassifications.length) dispatch(clearRelatedClassifications());

    if (ipnQueryParams) {
      dispatch(townsendValidateIpn(ipnQueryParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validateFields();
  }, [validateFields, htsValid, hts6Valid, ecnValid, tpdValid, tpdValid, ipnValid, ipnValid, cValid]);

  const setTownsendSearchTerm = useDebouncedIPNSearch();

  // townsend search
  const handleLoadItems = ({ detail }) => {
    const { filteringText: searchTerm } = detail;
    setFilteringText(detail.filteringText);
    setTownsendSearchTerm(searchTerm);
  };

  const handleChange = (event) => {
    setIpnValid(true);
    setSelectedIpn(event.detail.selectedOption);
    setIpn(event.detail.selectedOption.label);
    setIpnDescription(ipnInfoList.find((item) => item.ipn === event.detail.selectedOption.label).description);
    searchParams.set('ipn', event.detail.selectedOption.label);
    setSearchParams(searchParams);

    const searchTerm = encodeURIComponent(event.detail.selectedOption.label);
    searchRelatedCassifications(searchTerm);
  };

  const searchRelatedCassifications = (searchTerm) => {
    dispatch(
      searchRelatedClassificationsAction({
        searchTerm: searchTerm,
        ic: selectedCountry.value,
        location: 'create',
      })
    );
  };

  const asterisk = <span className="asterisk">*</span>;

  const ASSET_TYPE = optionPart.value;
  const pcStatusSelect = (
    <FormField
      data-test="pc-stat"
      label={
        <span>
          PC Approval Status <i>- (&quot;Not Certified&quot; is default)</i>{' '}
        </span>
      }
    >
      <Select
        selectedOption={pcStatus}
        onChange={({ detail }) => {
          setPcStatus(detail.selectedOption);
        }}
        options={mapSelectOptions(PC_STATUS)}
        selectedAriaLabel="Selected"
        placeholder="Not Certified"
      />
    </FormField>
  );
  const partCategorySelect = (
    <FormField label="Part Category" data-test="asset-type">
      <Select
        selectedOption={optionPart}
        onChange={({ detail }) => {
          setOptionPart(detail.selectedOption);
        }}
        options={[
          { label: 'AWS Part', value: 'awspart' },
          { label: 'Non-Agile Part', value: 'nonplmpart' },
        ]}
        selectedAriaLabel="Selected"
        placeholder="Choose Part Type"
      />
    </FormField>
  );

  const townsendIPNSelect = (
    <SpaceBetween direction="vertical" size="l">
      <ColumnLayout borders="horizontal" columns={3} variant="text-grid">
        <FormField
          label={<>Agile IPN Search {asterisk}</>}
          data-test="ipn-townsend"
          errorText={
            (ipnsValidateError && !selectedIpn && 'Could not set IPN') || (!ipnValid && afterSubmit && 'Required Field')
          }
        >
          {ipnsValidateLoading === 'loading' ? (
            <span>Loading ipn...</span>
          ) : (
            <Select
              filteringType="manual"
              statusType={ipnsLoading}
              filteringPlaceholder="IPN"
              placeholder="Search to choose an IPN"
              loadingText="Type the IPN to start search"
              errorText="Error fetching results."
              recoveryText="Retry"
              finishedText={filteringText ? `End of "${filteringText}" results` : 'End of all results'}
              empty="No IPNs found"
              options={ipnInfoList.map((i) => {
                return { label: i.ipn };
              })}
              selectedOption={selectedIpn || ipnsValidatedValue}
              onChange={handleChange}
              onLoadItems={handleLoadItems}
            />
          )}
        </FormField>
        <FormField
          data-test="mpn-townsend"
          label={
            <span>
              MPN <i>- optional</i>{' '}
            </span>
          }
        >
          <Input
            id="mpn-townsend"
            name="mpn-townsend"
            onChange={({ detail }) => setMpn(detail.value)}
            placeholder="Leave blank for all associated MPNs"
            value={mpn}
          />
        </FormField>
        <div>
          <br />
          <Button
            onClick={() => {
              setNotInAgile(true);
            }}
          >
            My AWS part isn&apos;t currently in Agile
          </Button>
        </div>
        {ipnDescription ? (
          <FormField data-test="ipn-description" label="Agile IPN Description">
            <TextContent>
              <strong>{ipnDescription}</strong>
            </TextContent>
          </FormField>
        ) : (
          ''
        )}
      </ColumnLayout>
    </SpaceBetween>
  );
  const nonAgilePartInfo = (
    <Container data-test="3p-part">
      <SpaceBetween size="xxl">
        <h3>Part Info</h3>

        <ColumnLayout borders="horizontal" columns={3}>
          {partCategorySelect}
          {pcStatusSelect}
        </ColumnLayout>
        <ColumnLayout borders="horizontal" columns={4}>
          <FormField
            errorText={!tpnValid && afterSubmit && optionPart.value === 'nonplmpart' && 'Required Field'}
            data-test="3p-name"
          >
            Part Name {asterisk}
            <Input
              value={thirdPName}
              onChange={(event) => {
                setThirdPName(event.detail.value);
                setTPNValid(true);
                const searchTerm = encodeURIComponent(event.detail.value);
                searchRelatedCassifications(searchTerm);
              }}
              placeholder="Part Name"
            />
          </FormField>

          <FormField
            errorText={!tpdValid && afterSubmit && optionPart.value === 'nonplmpart' && 'Required Field'}
            data-test="3p-desc"
          >
            Part Description {asterisk}
            <Input
              value={thirdPDesc}
              onChange={(event) => {
                setThirdPDesc(event.detail.value);
                setTPDValid(true);
              }}
              placeholder="Part Description"
            />
          </FormField>
          <FormField label="Manufacturer Name" data-test="m-name">
            <Input
              value={thirdPManName}
              onChange={(event) => setThirdPManName(event.detail.value)}
              placeholder="Manufacturer Name"
            />
          </FormField>
          <FormField label="Manufacturer Part ID" data-test="mpid">
            <Input
              value={thirdPManId}
              onChange={(event) => setThirdPManId(event.detail.value)}
              placeholder="Manufacturer Part ID"
            />
          </FormField>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );
  const awsPartInfo = (
    <Container data-test="aws-part">
      <SpaceBetween size="xxl">
        <h3>Part Info</h3>
        <ColumnLayout borders="horizontal" columns={3}>
          {partCategorySelect}
          {pcStatusSelect}
        </ColumnLayout>
        {notInAgile ? (
          <ColumnLayout columns={3}>
            <FormField
              errorText={!ipnValid && afterSubmit && optionPart.value === 'awspart' && 'Required Field'}
              data-test="ipn"
            >
              IPN {asterisk}
              <Input
                value={ipn}
                onChange={(event) => {
                  setIpn(event.detail.value);
                  setIpnValid(true);
                  const searchTerm = encodeURIComponent(event.detail.value);
                  searchRelatedCassifications(searchTerm);
                }}
                placeholder="308-000056-001"
              />
            </FormField>
            <FormField
              label={
                <span>
                  MPN <i>- optional</i>{' '}
                </span>
              }
              data-test="mpn"
            >
              <Input
                value={mpn}
                onChange={(event) => {
                  setMpn(event.detail.value);
                }}
                placeholder="00-012905"
              />
            </FormField>
            <div>
              <br />
              <Button
                onClick={() => {
                  setNotInAgile(false);
                }}
              >
                My AWS part is in Agile
              </Button>
            </div>
          </ColumnLayout>
        ) : (
          townsendIPNSelect
        )}
      </SpaceBetween>
    </Container>
  );
  const relatedTable = (
    <ClassificationsTable
      itemType="Check if this classification is already related to this country below"
      classifications={relatedClassifications}
    />
  );
  if (classificationLoading === PENDING) {
    return <Loading />;
  }

  const handleCancel = (e) => {
    e.preventDefault();
    navigate('/classifications');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setAfterSubmit(true);

    if (allValid) {
      // grab all the inputs and set them as the api call
      // grab programs
      const PROGRAMS = programOptions.map((program) => {
        return program.value;
      });
      // api call
      const nonPlmClassification = {
        countryISO2: [selectedCountry.value],
        hts: hts,
        programs: PROGRAMS,
        pcStatus: pcStatus.value,
        importClassificationDetails: ICDetails,
        justification: justification.value,
        localLanguageDescription,
        ecn: ecn,
        exportClassificationDetails: ecDetails,
        importApprovalRequired: optionIAR.value,
        importRequirementsCompleted: optionIRC.value,
        brokerImportRequirementNotes: BIRNotes,
        internalImportRequirementNotes: IIRNotes,
        exportReportingRequirement: optionERR.value,
        exportReportingRequirementType: optionERRT.value,
        exportApprovalRequired: optionEAR.value,
        exportRequirementNotes: ERNotes,
        nonPlmParts: [
          {
            nonPlmPartName: thirdPName.trim(),
            description: thirdPDesc,
            manufacturerName: thirdPManName,
            manufacturerPartId: thirdPManId,
          },
        ],
        assetType: 'nonplmpart',
        active: true,
      };
      const awsClassification = {
        countryISO2: [selectedCountry.value],
        hts: hts,
        programs: PROGRAMS,
        pcStatus: pcStatus.value,
        importClassificationDetails: ICDetails,
        justification: justification.value,
        localLanguageDescription,
        ecn: ecn,
        exportClassificationDetails: ecDetails,
        importApprovalRequired: optionIAR.value,
        importRequirementsCompleted: optionIRC.value,
        brokerImportRequirementNotes: BIRNotes,
        internalImportRequirementNotes: IIRNotes,
        exportReportingRequirement: optionERR.value,
        exportReportingRequirementType: optionERRT.value,
        exportApprovalRequired: optionEAR.value,
        exportRequirementNotes: ERNotes,
        awsParts: [
          {
            ipn: ipn.trim(),
            mpn: mpn.trim(),
            ipnDescription: ipnDescription,
          },
        ],
        assetType: 'awspart',
        active: true,
      };
      let newClassification;
      if (ASSET_TYPE === 'awspart') {
        newClassification = awsClassification;
      } else {
        newClassification = nonPlmClassification;
      }
      try {
        await dispatch(createClassificationAction({ newClassification })).unwrap();
        if (ASSET_TYPE === 'awspart') {
          const encodedIPN = encodeURIComponent(ipn.trim());
          const encodedMPN = encodeURIComponent(mpn.trim());
          const delimiter = encodedMPN ? '::' : '';
          navigate('/classification/' + selectedCountry.value + '/' + encodedIPN + `${delimiter}${encodedMPN}`);
        } else if (ASSET_TYPE === 'nonplmpart') {
          const encodedTP = encodeURIComponent(thirdPName.trim());
          navigate('/classification/' + selectedCountry.value + '/' + encodedTP);
        }
      } catch (error) {
        console.log('');
      }
    }
  };

  return (
    <>
      <Flashbar
        items={flashbarItemsCreator(classificationCreateFlashbarItems, dispatch, setClassificationCreateFlashbarItems)}
      />
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              data-test="cancel"
              onClick={(e) => {
                handleCancel(e);
              }}
              variant="link"
            >
              Cancel
            </Button>
            <Button
              data-test="submit"
              onClick={(e) => {
                handleSubmit(e);
              }}
              variant="primary"
            >
              Submit
            </Button>
          </SpaceBetween>
        }
      >
        <Container
          data-test="part-info"
          header={
            <Header variant="h2" description="Add Classification details to create a new Classification.">
              Classification
            </Header>
          }
        >
          <ColumnLayout borders="horizontal" columns={2}>
            <FormField
              label={<>Country {asterisk}</>}
              errorText={!cValid && afterSubmit && 'Required Field'}
              data-test="country"
            >
              <Select
                onChange={({ detail }) => {
                  setSelectedCountry(detail.selectedOption);
                  setCValid(true);
                }}
                placeholder="Choose a country"
                selectedOption={selectedCountry}
                options={COUNTRIES}
                filteringType="auto"
                selectedAriaLabel="Selected"
                filteringPlaceholder="Search"
                noMatch="No matches found"
              />
            </FormField>

            <FormField label="Programs" data-test="programs">
              <Multiselect
                selectedOptions={programOptions}
                onChange={({ detail }) => setProgramOptions(detail.selectedOptions)}
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                options={mapSelectOptions(PROGRAM_OPTIONS)}
                placeholder="Choose program(s)"
                selectedAriaLabel="Selected"
              />
            </FormField>
            <FormField label="Local Language Description" data-test="lld">
              <Textarea
                onChange={({ detail }) => setLocalLanguageDescription(detail.value)}
                value={localLanguageDescription}
                placeholder="Local Language Description"
              />
            </FormField>
          </ColumnLayout>
        </Container>
        {ASSET_TYPE === 'nonplmpart' ? nonAgilePartInfo : awsPartInfo}
        {relatedTable}
        <Container data-test="import-info">
          <SpaceBetween size="xxl">
            <h3>Import Detail Info</h3>
            <ColumnLayout columns={3}>
              <FormField
                label={
                  <>
                    HTS Code {asterisk} <i>- (HTS or ECN is required)</i>
                  </>
                }
                errorText={
                  afterSubmit &&
                  ((hts && !htsValid) || (!ecnValid && !htsValid)) &&
                  'HTS must be at least 6 digits or ECN is required'
                }
                data-test="hts6"
              >
                <Input
                  value={hts}
                  onChange={(event) => {
                    setHts(event.detail.value);
                    setHtsValid(true);
                  }}
                  placeholder="HTS Code"
                />
              </FormField>
            </ColumnLayout>
            <ColumnLayout columns={3}>
              <FormField data-test="iar" label="Import Approval Required">
                <Select
                  selectedOption={optionIAR}
                  onChange={({ detail }) => setOptionIAR(detail.selectedOption)}
                  options={mapSelectOptions(IM_APP_REQ)}
                  selectedAriaLabel="Selected"
                  placeholder="Y / N / Transactional"
                />
              </FormField>
              <FormField label="Import Requirements Completed" data-test="irc">
                <Select
                  selectedOption={optionIRC}
                  onChange={({ detail }) => setOptionIRC(detail.selectedOption)}
                  options={mapSelectOptions(IM_REQ_COMPLETED)}
                  selectedAriaLabel="Selected"
                  placeholder="Y / N / Partial"
                  disabled={optionIAR.label === 'No' ? true : false}
                />
              </FormField>
              <FormField label="Justification" data-test="justification">
                <Select
                  selectedOption={justification}
                  onChange={({ detail }) => setJustification(detail.selectedOption)}
                  options={mapSelectOptions(JUSTIFICATION_OPTIONS)}
                  selectedAriaLabel="Selected"
                  placeholder="Choose justification"
                />
              </FormField>
              <FormField label="Import Classification Details">
                <Textarea
                  onChange={({ detail }) => setICDetails(detail.value)}
                  value={ICDetails}
                  placeholder="Details"
                />
              </FormField>
              <FormField label="Internal Import Requirements Notes" data-test="iirn">
                <Textarea onChange={({ detail }) => setIIRNotes(detail.value)} value={IIRNotes} placeholder="Notes" />
              </FormField>
              <FormField label="Broker Import Requirements Notes" data-test="birn">
                <Textarea onChange={({ detail }) => setBIRNotes(detail.value)} value={BIRNotes} placeholder="Notes" />
              </FormField>
            </ColumnLayout>
          </SpaceBetween>
        </Container>
        <Container data-test="export-info">
          <SpaceBetween size="xxl">
            <h3>Export Detail Info</h3>
            <ColumnLayout columns={3}>
              <FormField
                label={
                  <>
                    ECN {asterisk} <i>- (HTS or ECN is required)</i>
                  </>
                }
                errorText={!ecnValid && !htsValid && afterSubmit && 'ECN or HTS Required'}
                data-test="ecn"
              >
                <Input
                  value={ecn}
                  onChange={(event) => {
                    setEcn(event.detail.value);
                    setEcnValid(true);
                  }}
                  placeholder="ECN"
                />
              </FormField>
              <FormField label="Export Classification Details">
                <Textarea
                  onChange={({ detail }) => setECDetails(detail.value)}
                  value={ecDetails}
                  placeholder="Details"
                />
              </FormField>
              <FormField label="Export Requirements Notes" data-test="ern">
                <Textarea onChange={({ detail }) => setERNotes(detail.value)} value={ERNotes} placeholder="Notes" />
              </FormField>

              <FormField data-test="ear" label="Export Approval Required">
                <Select
                  selectedOption={optionEAR}
                  onChange={({ detail }) => setOptionEAR(detail.selectedOption)}
                  options={TRUE_OR_FALSE}
                  selectedAriaLabel="Selected"
                  placeholder="Y / N"
                />
              </FormField>

              <FormField data-test="err" label="Export Reporting Requirement">
                <Select
                  selectedOption={optionERR}
                  onChange={({ detail }) => setOptionERR(detail.selectedOption)}
                  options={TRUE_OR_FALSE}
                  selectedAriaLabel="Selected"
                  placeholder="Y / N"
                  disabled={!optionEAR.value}
                />
              </FormField>
              <FormField data-test="errt" label="Export Reporting Requirements Type">
                <Select
                  selectedOption={optionERRT}
                  onChange={({ detail }) => setOptionERRT(detail.selectedOption)}
                  options={mapSelectOptions(EX_REPORT_REQ_TYPE)}
                  selectedAriaLabel="Selected"
                  placeholder="Annual / Bi-Annual"
                  disabled={!optionEAR.value}
                />
              </FormField>
            </ColumnLayout>
          </SpaceBetween>
        </Container>
      </Form>
    </>
  );
};

export default TradeClassificationCreate;
