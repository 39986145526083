import { AppLayout, BreadcrumbGroup, Button, Flashbar, Header } from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { flashbarItemsCreator } from 'utils/formUtils';
import ClassificationsCombinedSearchContent from './ClassificationsCombined';
import { setPCClassificationLandingFlashbarItems } from '../../store/pcClassification';
import { useEffect } from 'react';

const ClassificationsSearchLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { pcClassificationLandingFlashbarItems } = useAppSelector((state) => state.pcClassificationStore);

  // clear flashbar items on component unmount
  useEffect(() => {
    return () => {
      dispatch(setPCClassificationLandingFlashbarItems([]));
    };
  }, [dispatch]);

  return (
    <AppLayout
      contentType="table"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={
        <Header
          variant="h1"
          actions={
            // TODO: Change the "Create Classification" form to create either a trade or a pc classification
            <Link to="/create/classification">
              <Button data-test="create" className="create" variant="normal">
                Create Classification
              </Button>
            </Link>
          }
        >
          Classifications
        </Header>
      }
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Classifications', href: `/classifications` },
          ]}
        />
      }
      content={<ClassificationsCombinedSearchContent />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar
          items={flashbarItemsCreator(
            pcClassificationLandingFlashbarItems,
            dispatch,
            setPCClassificationLandingFlashbarItems
          )}
        />
      }
      stickyNotifications
      toolsHide
    />
  );
};

export default ClassificationsSearchLayout;
