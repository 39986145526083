import { CollectionPreferencesProps, TableProps } from '@amzn/awsui-components-react';
import PolarisLink from 'components/PolarisLink';
import { FilteringProps } from 'hooks/useCollectionWithFilterType/useCollectionWithFilterType';
import { ArtifactsSearchResult } from 'types/artifacts';
import { STATUS_OPTIONS } from 'utils/constants';
import { getOptionByValue } from 'utils/formUtils';

export const COMBINED_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<ArtifactsSearchResult>[] = [
  {
    id: 'identifier',
    header: 'Identifier',
    cell: (item) => <PolarisLink to={`${item.getRoute}`}>{item.identifier}</PolarisLink>,
    sortingField: 'part',
  },
  {
    id: 'type',
    header: 'Type',
    cell: (e) => e.type,
    sortingField: 'type',
  },
  {
    id: 'mpn',
    header: 'MPN',
    cell: (e) => e.mpn,
    sortingField: 'mpn',
  },
  {
    id: 'ipn',
    header: 'IPN',
    cell: (e) => e.ipn,
    sortingField: 'ipn',
  },
  {
    id: 'hts',
    header: 'HTS',
    cell: (e) => e.hts,
    sortingField: 'hts',
  },
  {
    id: 'eccn',
    header: 'ECCN',
    cell: (e) => e.eccn,
    sortingField: 'eccn',
  },
  {
    id: 'country',
    header: 'Country',
    cell: (e) => e.country,
    sortingField: 'country',
  },
  {
    id: 'expiration',
    header: 'Expiration',
    cell: (e) => e.expiration,
    sortingField: 'expiration',
  },
  {
    id: 'status',
    header: 'Status',
    // TODO: Figure out License status
    cell: (e) => getOptionByValue(e.status?.toLowerCase(), STATUS_OPTIONS)?.label,
    sortingField: 'status',
  },
  {
    id: 'supersku_name',
    header: 'Supersku Name',
    cell: (e) => e.superskuName,
    sortingField: 'supersku_name',
  },
  {
    id: 'aws_project_external',
    header: 'AWS Project External',
    cell: (e) => e.awsProjectExternal,
    sortingField: 'aws_project_external',
  },
];

export const COMBINED_FILTERING_PROPERTIES: FilteringProps = [
  {
    key: 'identifier',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Identifier',
    groupValuesLabel: 'Identifier values',
  },
  {
    key: 'type',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Type',
    groupValuesLabel: 'Type values',
  },
  {
    key: 'country',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Country',
    groupValuesLabel: 'Country values',
  },
  {
    key: 'ipn',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'IPN',
    groupValuesLabel: 'IPN values',
  },
  {
    key: 'mpn',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'MPN',
    groupValuesLabel: 'MPN values',
  },
  {
    key: 'hts',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'HTS',
    groupValuesLabel: 'HTS values',
  },
  {
    key: 'eccn',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'ECCN',
    groupValuesLabel: 'ECCN values',
  },
  {
    key: 'expiration',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Expiration Date',
    groupValuesLabel: 'Expiration Date',
  },
  {
    key: 'status',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Status',
    groupValuesLabel: 'Status',
  },
  {
    key: 'superskuName',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'Supersku Name',
    groupValuesLabel: 'Supersku Name',
  },
  {
    key: 'awsProjectExternal',
    operators: ['=', '!=', ':', '!:'],
    propertyLabel: 'AWS Project External',
    groupValuesLabel: 'AWS Project External',
  },
];

export const COMBINED_VISIBLE_CONTENT: string[] = [
  'identifier',
  'type',
  'country',
  'mpn',
  'ipn',
  'hts',
  'eccn',
  'status',
  'expiration',
];

export const AUTH_CONTENT_PREFERENCE: CollectionPreferencesProps.VisibleContentPreference = {
  title: 'Select visible content',
  options: [
    {
      label: '',
      options: [
        { id: 'licenseNumber', label: 'License Number', editable: false },
        {
          id: 'issuingCountry',
          label: 'Issuing Country',
        },
        { id: 'ipn', label: 'IPN(s)' },
        { id: 'mpn', label: 'MPN(s)' },
        { id: 'hts', label: 'HTS(s)' },
        { id: 'eccn', label: 'ECCN(s)' },
      ],
    },
  ],
};

export const COMBINED_CONTENT_PREFERENCE: CollectionPreferencesProps.VisibleContentPreference = {
  title: 'Select visible content',
  options: [
    {
      label: 'Artifacts',
      options: [
        { id: 'identifier', label: 'Identifier', editable: false },
        { id: 'type', label: 'Type' },
        { id: 'country', label: 'Country' },
        { id: 'mpn', label: 'MPN' },
        { id: 'ipn', label: 'IPN' },
        { id: 'hts', label: 'HTS' },
        { id: 'eccn', label: 'ECCN' },
        { id: 'status', label: 'Status' },
        { id: 'expiration', label: 'Expiration' },
        { id: 'supersku_name', label: 'Supersku Name' },
        { id: 'aws_project_external', label: 'AWS Project External' },
      ],
    },
  ],
};
