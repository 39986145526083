import React, { useState } from 'react';
import { createErrorItem, createSuccessItem } from '../../../utils/formUtils';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { ArtifactsV2FormInput, artifactsV2FormSchemaToApiSchema } from '../ArtifactV2Common';
import {
  createArtifactV2Action,
  setArtifactV2CreateFlashbarItems,
  setArtifactV2DetailFlashbarItems,
} from '../../../store/artifactv2';
import ArtifactsV2Common from '../ArtifactV2Common/ArtifactV2Common';
import { SpaceBetween } from '@amzn/awsui-components-react';

import useClearFlashbarItems from '../../../hooks/useClearFlashbarItems';
import { ArtifactWorkflowStatus, CreateArtifactInput, CreateArtifactOutput } from '@amzn/d2d-bff-schema';

const ArtifactV2Create: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { artifactIngressID, createLoading } = useAppSelector((state) => state.artifactV2Store);

  const onSubmit: SubmitHandler<ArtifactsV2FormInput> = async (data: ArtifactsV2FormInput) => {
    if (fileValidationStatus !== ArtifactWorkflowStatus.FILE_VALIDATION_SUCCEEDED || !artifactIngressID) {
      dispatch(
        setArtifactV2CreateFlashbarItems([
          createErrorItem(`Error creating artifact: File missing or file validation was not successful`),
        ])
      );
      return;
    }
    await createArtifact(data);
  };

  const createArtifact = async (data: ArtifactsV2FormInput): Promise<void> => {
    const artifactFormSchemaToApiSchema = artifactsV2FormSchemaToApiSchema.parse(data);
    const artifactCreateInput: CreateArtifactInput = {
      ingressArtifactID: artifactIngressID ?? '',
      metadata: artifactFormSchemaToApiSchema.metadata.filter((field) => field.value.length),
      partFamilies: artifactFormSchemaToApiSchema.partFamilies,
    };
    const { meta, payload } = await dispatch(createArtifactV2Action({ input: artifactCreateInput }));
    if (meta.requestStatus === 'fulfilled') {
      navigate(`/artifacts/v2/${(payload as CreateArtifactOutput).artifact.id}`);
      dispatch(setArtifactV2DetailFlashbarItems([createSuccessItem(`Artifact created successfully!`)]));
    }
  };

  const [fileValidationStatus, setFileValidationStatus] = useState<ArtifactWorkflowStatus | null | 'PENDING'>(null);

  // clear create page flashbar items on component unmount
  useClearFlashbarItems(setArtifactV2CreateFlashbarItems);

  return (
    <>
      <SpaceBetween size={'l'}>
        <ArtifactsV2Common
          onSubmit={onSubmit}
          fileValidationStatus={fileValidationStatus}
          setFileValidationStatus={setFileValidationStatus}
          isLoading={createLoading === 'pending'}
          submitText="Create"
          cancelRoute="/artifacts/v2"
        />
      </SpaceBetween>
    </>
  );
};

export default ArtifactV2Create;
