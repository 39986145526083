import React, { useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AppLayout, Box, Flashbar, Header } from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import Artifacts from 'components/Artifacts';
import ArtifactDetails from 'components/ArtifactDetails';
import Classifications from 'components/Classifications';
import Classification from 'components/Classification';
import ClassificationCreate from 'components/ClassificationCreate';
import LicenseManagerCreate from 'components/LicenseManagerCreate';
import LicenseManagerDetails from 'components/LicenseManagerDetails';
import LicenseManagerEdit from 'components/LicenseManagerEdit';
import TopNavigation from 'components/TopNav';
import TradeClassificationCreate from '../components/TradeClassificationCreate';
import NotFound from 'components/NotFound';

// actions
import { getUser } from 'store/user/userSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { HEADER_SELECTOR } from 'config/navConfig';
import LicenseUsageCreate from 'components/LicenseUsageCreate';
import ArtifactCreate from 'components/ArtifactCreate';
import useFeatureFlag from '../hooks/useFeatureFlag';
import ClassificationsCombined from 'components/ClassificationsCombined';
import { FeatureFlag as FeatureFlagConstants } from '../utils/constants';
import PcClassificationDetails from '../components/PcClassificationDetails';
import PcClassificationEdit from '../components/PcClassificationEdit';
import FeatureFlag from '../components/FeatureFlag';
import ArtifactV2Details from '../components/ArtifactsV2/ArtifactV2Details';
import ArtifactV2Create from '../components/ArtifactsV2/ArtifactV2Create';
import ArtifactV2Edit from '../components/ArtifactsV2/ArtifactV2Edit';
import ArtifactV2Search from '../components/ArtifactsV2/ArtifactV2Search';
import { createArtifactsReadonlyWarningItem } from '../utils/formUtils';

/**
 * everything not written in TS with the Polaris AppLayout pattern needs wrapped in the old global AppLayout
 * @param {ReactNode} content app content
 * @param {string} name name to put in header
 * @param includeFlashBar If true, notifications will be setup with a flashbar, currently with hardcoded message.
 * @param artifactsV2Available Whether artifacts V2 UI has been made available to user.
 * @return {ReactNode} wrapped app content
 */
export const makeAppContent = (content, name, includeFlashBar = false, artifactsV2Available = false) => (
  <AppLayout
    breadcrumbs={<></>}
    headerSelector={HEADER_SELECTOR}
    toolsHide
    navigation={<SideNav />}
    contentHeader={name && <Header variant="h1">{name}</Header>}
    content={content}
    notifications={includeFlashBar && <Flashbar items={[createArtifactsReadonlyWarningItem(artifactsV2Available)]} />}
  />
);

const App = () => {
  const dispatch = useAppDispatch();

  const { user, userLoading } = useAppSelector((state) => state.userStore);
  const { getLoading: featureFlagsLoading } = useAppSelector((state) => state.featureFlagStore);

  const spreadProps = { user, userLoading, dispatch };

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const pccFeatureFlag = useFeatureFlag(FeatureFlagConstants.PC_CLASSIFICATIONS).boolValue;
  const artifactsV2FeatureFlag = useFeatureFlag(FeatureFlagConstants.ARTIFACTS_V2).boolValue;
  const header = <TopNavigation />;

  const appContent = (
    <Routes>
      <Route path="/artifacts" element={<Artifacts />} />
      <Route
        path="/artifact/:requestId/:country/:product"
        element={makeAppContent(<ArtifactDetails {...spreadProps} />, 'Artifact Details', true, artifactsV2FeatureFlag)}
      />
      <Route path="/create/artifact" element={<ArtifactCreate />} />
      <Route path="/classification/:country/:part" element={makeAppContent(<Classification {...spreadProps} />)} />

      <Route
        path="/classification/pc/:partSource/:partIdentifierType/:partIdentifierValue"
        element={
          <FeatureFlag
            trueComponent={<PcClassificationDetails />}
            falseComponent={makeAppContent(<NotFound />)}
            flag={pccFeatureFlag}
            flagState={featureFlagsLoading}
          />
        }
      />
      <Route
        path="/edit/classification/pc/:partSource/:partIdentifierType/:partIdentifierValue"
        element={
          <FeatureFlag
            trueComponent={<PcClassificationEdit />}
            falseComponent={makeAppContent(<NotFound />)}
            flag={pccFeatureFlag}
            flagState={featureFlagsLoading}
          />
        }
      />
      <Route
        path="/classifications"
        element={
          <FeatureFlag
            trueComponent={<ClassificationsCombined />}
            falseComponent={makeAppContent(<Classifications {...spreadProps} />)}
            flag={pccFeatureFlag}
            flagState={featureFlagsLoading}
          />
        }
      />
      <Route
        path="/create/classification"
        element={
          <FeatureFlag
            trueComponent={<ClassificationCreate {...spreadProps} />}
            falseComponent={makeAppContent(<TradeClassificationCreate {...spreadProps} />)}
            flag={pccFeatureFlag}
            flagState={featureFlagsLoading}
          />
        }
      />
      <Route
        path="/artifacts/v2/:artifactId"
        element={
          <FeatureFlag
            trueComponent={<ArtifactV2Details />}
            falseComponent={makeAppContent(<NotFound />)}
            flag={artifactsV2FeatureFlag}
            flagState={featureFlagsLoading}
          />
        }
      />

      <Route
        path="/artifacts/v2/create"
        element={
          <FeatureFlag
            trueComponent={<ArtifactV2Create />}
            falseComponent={makeAppContent(<NotFound />)}
            flag={artifactsV2FeatureFlag}
            flagState={featureFlagsLoading}
          />
        }
      />

      <Route
        path="/artifacts/v2/edit/:artifactId"
        element={
          <FeatureFlag
            trueComponent={<ArtifactV2Edit />}
            falseComponent={makeAppContent(<NotFound />)}
            flag={artifactsV2FeatureFlag}
            flagState={featureFlagsLoading}
          />
        }
      />

      <Route
        path="/artifacts/v2"
        element={
          <FeatureFlag
            trueComponent={<ArtifactV2Search />}
            falseComponent={makeAppContent(<NotFound />)}
            flag={artifactsV2FeatureFlag}
            flagState={featureFlagsLoading}
          />
        }
      />

      <Route path="/license/:licenseId" element={<LicenseManagerDetails />} />
      <Route path="/create/license" element={<LicenseManagerCreate />} />
      <Route path="/license/:licenseId/create/usage" element={<LicenseUsageCreate />} />
      <Route path="/edit/license/:licenseId" element={<LicenseManagerEdit />} />
      <Route path="*" element={<Navigate to="/artifacts" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );

  return userLoading !== 'pending' ? (
    <Router>
      <Box>
        {header}
        {appContent}
      </Box>
    </Router>
  ) : null;
};

export default App;
