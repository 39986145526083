import { LinkProps, Link as PolarisLink } from '@amzn/awsui-components-react';
import { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';

const InfoLink: React.FC<{ id: string; onFollow: CancelableEventHandler<LinkProps.FollowDetail> }> = ({
  id,
  onFollow,
}) => (
  <PolarisLink variant="info" id={id} onFollow={onFollow}>
    Info
  </PolarisLink>
);

export default InfoLink;
