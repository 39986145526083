import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api';

import { CREATE_PCC_PART, UPDATE_PCC_PART } from '../graphql/mutations';
import { PccCode, PccPart, PccPartInput, PccPartSearchResult } from '@amzn/d2d-bff-schema';
import { GET_PCC_PART, LIST_PCC_CODES, SEARCH_PCC_PARTS } from '../graphql/queries';

/**
 * GQL CREATE PC Classification
 * @param {PccPartInput} pccPartInput input
 * @return {Promise<GraphQLResult<PccPart>>} created PCCPart
 */
export async function makeCreateRequest(
  pccPartInput: PccPartInput
): Promise<GraphQLResult<{ createPccPart: PccPart }>> {
  const mutation = CREATE_PCC_PART;
  const variables = { partInput: pccPartInput };
  return API.graphql(graphqlOperation(mutation, variables)) as Promise<GraphQLResult<{ createPccPart: PccPart }>>;
}

/**
 * GQL GET PC Classification
 * @return {Promise<GraphQLResult<PccPart>>} Pcc Part
 * @param partSource part source.
 * @param partIdentifierType part identifier type.
 * @param partIdentifierValue part identifier value.
 */
export async function makeGetRequest(
  partSource: string,
  partIdentifierType: string,
  partIdentifierValue: string
): Promise<GraphQLResult<{ getPccPart: PccPart }>> {
  const query = GET_PCC_PART;
  const variables = { partSource, partIdentifierType, partIdentifierValue };
  return API.graphql(graphqlOperation(query, variables)) as Promise<GraphQLResult<{ getPccPart: PccPart }>>;
}

/**
 * GQL UPDATE PCC Part
 * @param {string} partId id
 * @param {PccPartInput} partInput input
 * @return {Promise<GraphQLResult<PccPart>>} updated part
 */
export async function makeUpdateRequest(
  partId: string,
  partInput: PccPartInput
): Promise<GraphQLResult<{ updatePccPart: PccPart }>> {
  const mutation = UPDATE_PCC_PART;
  const variables = { partId, partInput };
  return API.graphql(graphqlOperation(mutation, variables)) as Promise<GraphQLResult<{ updatePccPart: PccPart }>>;
}

/**
 *
 * @param searchFields pc classification search fields
 * @returns list of matching pc classifications
 */
export async function makeSearchRequest(searchFields: {
  searchTerm: string;
  sortField: string;
  sortOrder: string;
  from: number;
  pageSize: number;
}): Promise<GraphQLResult<{ searchPccPart: PccPartSearchResult }>> {
  const { searchTerm, sortOrder, from, pageSize, sortField } = searchFields;
  const mutation = SEARCH_PCC_PARTS;
  const variables = { searchTerm, from, pageSize, sortField, sortOrder };
  return API.graphql(graphqlOperation(mutation, variables)) as Promise<
    GraphQLResult<{ searchPccPart: PccPartSearchResult }>
  >;
}

/**
 * @returns List of all pc classifications codes
 */
export async function makeListPCCCodeRequest(): Promise<GraphQLResult<{ listPccCodes: PccCode[] }>> {
  const mutation = LIST_PCC_CODES;
  return API.graphql(graphqlOperation(mutation)) as Promise<GraphQLResult<{ listPccCodes: PccCode[] }>>;
}
