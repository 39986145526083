import { SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { PCCPartFormInput, pccPartFormSchemaToApiSchema } from 'components/PcClassificationCommon';
import PcClassificationCommon from 'components/PcClassificationCommon/PcClassificationCommon';
import React, { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router';
import {
  setPCClassificationDetailFlashbarItems,
  setPCClassificationEditFlashbarItems,
  updatePCCPartAction,
} from 'store/pcClassification';
import { useAppDispatch, useAppSelector } from 'store/store';
import { createErrorItem, createSuccessItem } from 'utils/formUtils';
import useValidatePccPartParams from '../../hooks/useValidatePccPartParams';

interface PCCEditProps {
  partSource?: string;
  partIdentifierType?: string;
  partIdentifierValue?: string;
}

const PcClassificationEdit: React.FC<PCCEditProps> = ({ partSource, partIdentifierType, partIdentifierValue }) => {
  const navigate = useNavigate();
  const { updateLoading } = useAppSelector((state) => state.pcClassificationStore);
  const dispatch = useAppDispatch();

  const { isLoading: isCheckLoading, pccPart } = useValidatePccPartParams(
    partSource,
    partIdentifierType,
    partIdentifierValue
  );
  const partId = pccPart?.partId;

  const onSubmit: SubmitHandler<PCCPartFormInput> = async (data) => {
    /** parse if needed */
    const apiSchemaPart = pccPartFormSchemaToApiSchema.parse(data);

    /** call edit part, if no part ID useValidatePccPartParams will redirect user to search page */
    const { meta, payload: updatedPCCPart } = await dispatch(
      updatePCCPartAction({ partId: partId ?? '', partInput: apiSchemaPart })
    );
    if (meta.requestStatus === 'fulfilled' && updatedPCCPart && 'partId' in updatedPCCPart) {
      dispatch(
        setPCClassificationDetailFlashbarItems([
          createSuccessItem(
            `Part with part source ${partSource}, part identifier type ${partIdentifierType}, and part identifier value ${partIdentifierValue} updated successfully!`
          ),
        ])
      );
      navigate(
        `/classification/pc/${updatedPCCPart?.partSource}/${updatedPCCPart?.partIdentifierType}/${updatedPCCPart?.partIdentifierValue}`
      );
    } else {
      dispatch(setPCClassificationEditFlashbarItems([createErrorItem(`Failed to update part with ID ${partId}`)]));
    }
  };

  // clear edit page flashbar items on component unmount
  useEffect(() => {
    return () => {
      dispatch(setPCClassificationEditFlashbarItems([]));
    };
  }, [dispatch]);

  const pccEdit =
    isCheckLoading && updateLoading !== 'pending' ? (
      <Spinner size="large" />
    ) : (
      <PcClassificationCommon
        pccPart={pccPart}
        isLoading={updateLoading === 'pending'}
        onSubmit={onSubmit}
        submitText="Edit"
        isEdit={true}
        cancelRoute={`/classification/pc/${partSource}/${partIdentifierType}/${partIdentifierValue}`}
      />
    );

  return <SpaceBetween size="l">{pccEdit}</SpaceBetween>;
};

export default PcClassificationEdit;
