import { API, graphqlOperation } from '@aws-amplify/api';
import { CREATE_CLASSIFICATION, UPDATE_CLASSIFICATION } from '../graphql/mutations';
import { GET_CLASSIFICATION, OPENSEARCH_CLASSIFICATION } from '../graphql/queries';

// GQL CREATE
export async function makeCreateRequest(classification) {
  const query = CREATE_CLASSIFICATION;
  const variables = { input: classification };
  return API.graphql(graphqlOperation(query, variables));
}

// GQL GET
export async function makeGetRequest(ic, decodeAssetId) {
  const query = GET_CLASSIFICATION;
  const variables = { assetId: decodeAssetId, countryISO2: ic };
  return API.graphql(graphqlOperation(query, variables));
}

// GQL Update
export async function makeUpdateRequest(updatedClassification) {
  const query = UPDATE_CLASSIFICATION;
  const variables = { input: updatedClassification };
  return API.graphql(graphqlOperation(query, variables));
}

// GQL OpenSearch
export async function makeSearchRequest(searchTerm) {
  const query = OPENSEARCH_CLASSIFICATION;
  const variables = { searchTerm: searchTerm };
  return API.graphql(graphqlOperation(query, variables));
}
