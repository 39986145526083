import { AppLayout, BreadcrumbGroup, Button, Flashbar, Header } from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setLMLandingFlashbarItems } from 'store/licenseManager';
import { useAppSelector } from 'store/store';
import { createArtifactsReadonlyWarningItem, flashbarItemsCreator } from 'utils/formUtils';
import ArtifactSearchContent from './Artifacts';
import { FeatureFlag } from '../../utils/constants';
import useFeatureFlag from '../../hooks/useFeatureFlag';

const ArtifactSearchLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { lmLandingFlashbarItems } = useAppSelector((state) => state.licenseManagerStore);
  const artifactUiAvailable = useFeatureFlag(FeatureFlag.ARTIFACTS_UI, true).boolValue ?? true;
  const artifactV2Available = useFeatureFlag(FeatureFlag.ARTIFACTS_V2, true).boolValue ?? true;

  let createButton = (
    <Button data-test="create" className="create" variant="normal" disabled={!artifactUiAvailable}>
      Create Artifact
    </Button>
  );
  // If the UI isn't available, don't wrap the button in a Link.
  if (artifactUiAvailable) {
    createButton = <Link to="/create/artifact">{createButton}</Link>;
  }

  const flashBarItemsWithHardcodedMessage = [...lmLandingFlashbarItems];
  const readonlyWarning = createArtifactsReadonlyWarningItem(artifactV2Available);
  if (!flashBarItemsWithHardcodedMessage.find((items) => items.header === readonlyWarning.header)) {
    flashBarItemsWithHardcodedMessage.push(readonlyWarning);
  }
  return (
    <AppLayout
      contentType="table"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={
        <Header variant="h1" actions={createButton}>
          Artifacts
        </Header>
      }
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Artifacts', href: `/artifacts` },
          ]}
        />
      }
      content={<ArtifactSearchContent artifactUiAvailable={artifactUiAvailable} />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar
          items={flashbarItemsCreator(flashBarItemsWithHardcodedMessage, dispatch, setLMLandingFlashbarItems)}
        />
      }
      stickyNotifications
      toolsHide
    />
  );
};

export default ArtifactSearchLayout;
