import { useState } from 'react';
import ArtifactsTable from 'components/ArtifactsTable';
import { Button, FormField, Input, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { searchArtifactsAction } from 'store/artifact/artifactSlice';
import './artifacts-page.css';
import useOnEnter from 'hooks/useOnEnter/useOnEnter';
import { useAppDispatch, useAppSelector } from 'store/store';
import useOnLoad from '../../hooks/useOnLoad';
import { SEARCH_QUERY_PARAMETER } from '../../utils/constants';
import useHashParam from 'use-hash-param';
import { createUnknownErrorItem } from '../../utils/formUtils';
import { setLMLandingFlashbarItems } from '../../store/licenseManager';

interface ArtifactsPageProps {
  artifactUiAvailable: boolean;
}

const Artifacts: React.FC<ArtifactsPageProps> = ({ artifactUiAvailable }) => {
  const dispatch = useAppDispatch();

  // Optional initial search value passed in a hash parameter in the url
  const [searchParam, setSearchParam] = useHashParam(SEARCH_QUERY_PARAMETER, '');

  const [searchTerm, setSearchTerm] = useState(searchParam ?? '');

  const { artifacts, combinedArtifactSearchResults, artifactsLoading: artifactsLoadingState } = useAppSelector(
    (state) => state.artifactStore
  );

  const { licenses, getLoading: licensesLoadingState } = useAppSelector((state) => state.licenseManagerStore);
  const artifactsLoading = artifactsLoadingState === 'pending';
  const licensesLoading = licensesLoadingState === 'pending';

  const handleSearch = (): void => {
    if (searchTerm && !artifactsLoading && !licensesLoading) {
      // Whenever a user enters a new search term, update the URL hash parameter
      // This allows the user to copy the URL for any search they have just performed
      // It also allows the user to use the "back" browser button after viewing a search result to return to the same search
      setSearchParam(searchTerm);

      (async () => {
        const { meta } = await dispatch(searchArtifactsAction(searchTerm));
        if (meta.requestStatus === 'rejected') {
          dispatch(setLMLandingFlashbarItems([createUnknownErrorItem()]));
        }
      })();
    }
  };

  useOnEnter(handleSearch, ['searchTerm']);
  // If there is a hash parameter, search on the parameter when the page initially loads
  useOnLoad(handleSearch);

  return (
    <SpaceBetween size="m">
      <div data-test="input">
        <FormField
          description="Search through all artifact fields. For example, IPN, MPN, Non-Agile Part Name, or Status."
          label="Artifact Search"
        >
          <Input
            id="searchTerm"
            name="searchTerm"
            type="search"
            placeholder="Search"
            onChange={({ detail }) => setSearchTerm(detail.value)}
            value={searchTerm}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        </FormField>
      </div>
      <Button
        variant="primary"
        disabled={artifactsLoading || !artifactUiAvailable}
        loading={artifactsLoading}
        onClick={() => handleSearch()}
      >
        Search Artifacts
      </Button>

      <ArtifactsTable
        combinedArtifactSearchResults={combinedArtifactSearchResults}
        artifacts={artifacts}
        licenses={licenses}
        artifactsLoading={artifactsLoading}
      />
    </SpaceBetween>
  );
};

export default Artifacts;
