import React from 'react';
import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import LMEditContent from './LicenseManagerEdit';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { flashbarItemsCreator } from 'utils/formUtils';
import { setLMEditFlashbarItems } from 'store/licenseManager';

const LicenseManagerEditLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { lmEditFlashbarItems } = useAppSelector((state) => state.licenseManagerStore);

  /** get licenseId from path params */
  const { licenseId } = useParams<string>();

  return (
    <AppLayout
      contentType="form"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={<Header variant="h1">Edit License</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Artifacts', href: `/artifacts` },
            { text: 'Edit Authorization', href: '#' },
            { text: licenseId ?? '', href: '#' },
          ]}
        />
      }
      content={<LMEditContent licenseId={licenseId} />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={<Flashbar items={flashbarItemsCreator(lmEditFlashbarItems, dispatch, setLMEditFlashbarItems)} />}
      stickyNotifications
      toolsHide
    />
  );
};

export default LicenseManagerEditLayout;
