import { SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import LicenseManagerCommonForm, { LicenseFormInputs, lmFormSchemaToApiSchema } from 'components/LicenseManagerCommon';
import useValidateLicenseParams from 'hooks/useValidateLicenseParams';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { setLMDetailFlashbarItems, updateLicenseAction } from 'store/licenseManager';
import { useAppDispatch, useAppSelector } from 'store/store';
import { createSuccessItem } from 'utils/formUtils';

interface LMEditProps {
  licenseId?: string;
}

const LicenseManagerEdit: React.FC<LMEditProps> = ({ licenseId }) => {
  const navigate = useNavigate();
  const { updateLoading } = useAppSelector((state) => state.licenseManagerStore);
  const dispatch = useAppDispatch();

  const { isLoading: isCheckLoading, license } = useValidateLicenseParams(licenseId);

  const onSubmit: SubmitHandler<LicenseFormInputs> = async (data) => {
    /** parse if needed */
    const apiSchemaLicense = lmFormSchemaToApiSchema.parse(data);

    /** call edit license, if no license ID useValidateLicenseParams will redirect user to search page */
    const { meta, payload: updatedLicense } = await dispatch(
      updateLicenseAction({ licenseId: licenseId ?? '', licenseInput: apiSchemaLicense })
    );
    if (meta.requestStatus === 'fulfilled' && updatedLicense && 'licenseId' in updatedLicense) {
      dispatch(
        setLMDetailFlashbarItems([
          createSuccessItem(`License with ID ${updatedLicense?.licenseId} updated successfully!`),
        ])
      );
      navigate(`/license/${updatedLicense?.licenseId}`);
    }
  };

  const licenseEdit =
    isCheckLoading && updateLoading !== 'pending' ? (
      <Spinner size="large" />
    ) : (
      <LicenseManagerCommonForm
        license={license}
        isLoading={updateLoading === 'pending'}
        onSubmit={onSubmit}
        submitText="Edit"
        isEdit
        cancelRoute={`/license/${licenseId}`}
      />
    );

  return <SpaceBetween size="l">{licenseEdit}</SpaceBetween>;
};

export default LicenseManagerEdit;
