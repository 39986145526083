import { SpaceBetween } from '@amzn/awsui-components-react';
import LicenseManagerCommon, { LicenseFormInputs, lmFormSchemaToApiSchema } from 'components/LicenseManagerCommon';
import React, { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { createLicenseAction, setLMDetailFlashbarItems } from 'store/licenseManager';
import { useAppSelector } from 'store/store';
import { createSuccessItem } from 'utils/formUtils';

const LicenseManagerCreate: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createLoading: loading, licenseErrors, createdLicense } = useAppSelector(
    (state) => state.licenseManagerStore
  );

  const onSubmit: SubmitHandler<LicenseFormInputs> = async (data: LicenseFormInputs) => {
    /** parse if needed */
    const apiSchemaLicense = lmFormSchemaToApiSchema.parse(data);
    /** call create license */
    dispatch(createLicenseAction(apiSchemaLicense));
  };

  useEffect(() => {
    if (loading === 'fulfilled' && createdLicense?.licenseId) {
      dispatch(
        setLMDetailFlashbarItems([
          createSuccessItem(`License with ID ${createdLicense?.licenseId} created successfully!`),
        ])
      );
      navigate(`/license/${createdLicense?.licenseId}`);
    }
  }, [dispatch, createdLicense?.licenseId, licenseErrors, loading, navigate]);

  const licenseCreate = (
    <LicenseManagerCommon
      isLoading={loading === 'pending'}
      onSubmit={onSubmit}
      submitText="Create"
      cancelRoute="/license-manager"
      artifactType={{ value: 'export_authorization' }}
      artifactSubType={{ value: 'general_export_license_eu' }}
    />
  );

  return <SpaceBetween size="l">{licenseCreate}</SpaceBetween>;
};

export default LicenseManagerCreate;
