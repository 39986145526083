/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DEFAULT_REQUIRED, optionalOptionDefinitionSchema, yupOptionDefinitionSchema } from 'utils/formUtils';
import { Asset, AssetType, Country, Source, SuperskuName } from '@amzn/d2d-bff-schema';
import * as yup from 'yup';
import {
  APPLICANT_TYPES,
  ARTIFACT_SUBTYPE_MENU_OPTIONS,
  AWS_PROJECT_EXTERNAL,
  ISSUING_BODIES,
  SOURCE,
  STANDARDS,
  STATUS_OPTIONS,
  SUPERSKU_NAMES,
} from 'utils/constants';
import { POLARIS_DATE_PATTERN, formatFromEpoch } from 'utils/dateFormat';
import { getUnixTime, parse } from 'date-fns';

const awsPartSchema = yup.object({
  ipn: yup.string().required(DEFAULT_REQUIRED),
  mpn: yup.string().nullable(),
  townsendPartNumber: yup.string().nullable(),
});

const nonPlmPartSchema = yup.object({
  // yup.when is not able to make child fields optional after being marked required. Do the requirement of these fields in the form instead.
  nonPlmPartName: yup.string(),
  description: yup.string(),
  manufacturerName: yup.string().nullable(),
  manufacturerPartId: yup.string().nullable(),
});

export const artifactsFormSchema = yup.object().shape({
  recordType: yup.string(),
  assetType: yup.string(),
  authorizationType: yup.string(),
  active: yup.boolean().default(false),
  subAuthorizationType: yup.string().optional().nullable(),
  authorizationStatus: yupOptionDefinitionSchema.required(DEFAULT_REQUIRED),
  issuingCountries: yup.array(yupOptionDefinitionSchema).required(DEFAULT_REQUIRED).min(1),
  startDate: yup.string().required(DEFAULT_REQUIRED),
  expirationDate: yup.string(),
  nonPlmParts: yup
    .array(nonPlmPartSchema)
    .when('$partTabId', ([partTabId], schema) =>
      partTabId !== 'awsPart'
        ? schema
            .required(DEFAULT_REQUIRED)
            .min(1, 'At least one Non-Agile product must be chosen when the current tab is selected.')
        : schema.optional()
    ),
  awsParts: yup
    .array(awsPartSchema)
    .when('$partTabId', ([partTabId], schema) =>
      partTabId === 'awsPart'
        ? schema.required(DEFAULT_REQUIRED).min(1, 'At least one part must be chosen when the current tab is selected.')
        : schema.optional()
    ),
  currentNonPlmPart: nonPlmPartSchema.when('$partTabId', ([partTabId], schema) => {
    if (partTabId === 'awsPart') return schema;
    else {
      return schema;
    }
  }),
  superskuName: yupOptionDefinitionSchema.partial(),
  awsProjectExternal: yupOptionDefinitionSchema.partial(),
  authorizationNotes: yup.string().nullable(),
  governmentTrackingNumber: yup.string().nullable(),
  externalId: yup.string().nullable(),
  source: yupOptionDefinitionSchema.required(DEFAULT_REQUIRED),
  otherSourceName: yup.string().optional().nullable(),
  // compliance only
  applicantType: optionalOptionDefinitionSchema('$isComplianceRecord'),
  applicantName: yup.string().nullable(),
  standards: yup.array(yupOptionDefinitionSchema).nullable(),
  issuingBody: optionalOptionDefinitionSchema('$isComplianceRecord'),
});

export type ArtifactFormInputs = yup.InferType<typeof artifactsFormSchema>;

export const artifactsApiSchema = yup.object({
  recordType: yup.string().required(),
  assetType: yup.string<AssetType>().oneOf(Object.values(AssetType)),
  authorizationType: yup.string().required(),
  active: yup.boolean().required(),
  subAuthorizationType: yup.string().optional().nullable(),
  authorizationStatus: yup.string().required(),
  issuingCountries: yup.array(yup.string()).required(),
  startDate: yup.number().required(),
  expirationDate: yup.number().optional().nullable(),
  nonPlmParts: yup.array(nonPlmPartSchema).nullable().optional(),
  awsParts: yup.array(awsPartSchema.partial()).nullable().optional(),
  superskuName: yup.string<SuperskuName>().oneOf(Object.values(SuperskuName)),
  awsProjectExternal: yup.string().optional().nullable(),
  authorizationNotes: yup.string().optional().nullable(),
  governmentTrackingNumber: yup.string().optional().nullable(),
  externalId: yup.string().optional().nullable(),
  team: yup.string().required(),
  source: yup.string<Source>().oneOf(Object.values(Source)).optional(),
  otherSourceName: yup.string().optional().nullable(),
  // compliance only
  applicantType: yup.string().nullable(),
  applicantName: yup.string().optional().nullable(),
  standards: yup.array(yup.string().required()).optional().nullable(),
  issuingBody: yup.string().nullable(),
});

export type ArtifactAPISchema = yup.InferType<typeof artifactsApiSchema>;

export const artifactsApiSchemaToFormSchema = (asset: Asset): ArtifactFormInputs => {
  const emptyStringValue = { value: '' };
  const status = STATUS_OPTIONS.find((status) => status.value === asset.authorizationStatus)!;
  const subAuth = ARTIFACT_SUBTYPE_MENU_OPTIONS.find((sub) => sub.value === asset.subAuthorizationType)?.value;
  const applicantType = APPLICANT_TYPES.find((at) => at.value === asset.applicantType) ?? emptyStringValue;
  const standards = asset.standards ? STANDARDS.filter((st) => asset?.standards?.includes(st.value ?? null)) : [];
  const issuingBody = ISSUING_BODIES.find((ib) => ib.value === asset.issuingBody) ?? emptyStringValue;
  const superskuName = SUPERSKU_NAMES.find((ss) => ss.value === asset.superskuName) ?? emptyStringValue;
  const source = SOURCE.find((source) => source.value === asset.source) ?? emptyStringValue;
  const awsProjectExternal =
    AWS_PROJECT_EXTERNAL.find((ape) => ape.value === asset.awsProjectExternal) ?? emptyStringValue;
  return {
    ...asset,
    recordType: asset.recordType ?? undefined,
    assetType: asset.assetType as AssetType,
    authorizationType: asset.authorizationType ?? undefined,
    subAuthorizationType: subAuth,
    startDate: formatFromEpoch(asset.startDate, POLARIS_DATE_PATTERN),
    expirationDate: asset.expirationDate ? formatFromEpoch(asset.expirationDate, POLARIS_DATE_PATTERN) : undefined,
    authorizationStatus: status,
    applicantType,
    issuingBody,
    standards,
    active: asset.active ? true : false,
    superskuName,
    awsProjectExternal,
    source,
    otherSourceName: asset.otherSourceName ?? undefined,
    // these are non-editable fields
    awsParts: asset.awsPart ? [asset.awsPart] : [],
    nonPlmParts: asset.nonPlmPart ? [asset.nonPlmPart] : [],
    issuingCountries: [],
    currentNonPlmPart: {},
  };
};

export const artifactsFormSchemaToApiSchema = (asset: ArtifactFormInputs): ArtifactAPISchema => {
  const assetCopy: Partial<ArtifactFormInputs> = { ...asset };
  // this field needs removed as it isn't actually part of the API schema
  delete assetCopy.currentNonPlmPart;
  return {
    ...assetCopy,
    authorizationType: asset.authorizationType ?? '',
    recordType: asset.recordType ?? '',
    assetType: asset.assetType as AssetType,
    subAuthorizationType: asset?.subAuthorizationType,
    startDate: getUnixTime(parse(asset.startDate, POLARIS_DATE_PATTERN, new Date())),
    expirationDate: asset.expirationDate
      ? getUnixTime(parse(asset.expirationDate, POLARIS_DATE_PATTERN, new Date()))
      : undefined,
    issuingCountries: asset.issuingCountries.map((c) => c.value as Country),
    authorizationNotes: asset?.authorizationNotes ?? undefined,
    governmentTrackingNumber: asset?.governmentTrackingNumber ?? undefined,
    externalId: asset?.externalId ?? undefined,
    applicantType: asset.applicantType?.value ?? undefined,
    authorizationStatus: asset?.authorizationStatus?.value ?? '',
    issuingBody: asset?.issuingBody?.value ?? undefined,
    source: asset.source?.value as Source,
    otherSourceName: asset?.otherSourceName ?? undefined,
    standards: asset?.standards?.map((s) => s.value),
    superskuName: asset?.superskuName?.value as SuperskuName,
    awsProjectExternal: asset?.awsProjectExternal?.value,
    // hardcoded values to send to assets every time
    team: 'awsgtpc',
    active: true,
  };
};

export { default } from './ArtifactCommon';
