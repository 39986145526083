import moment from 'moment';
import { format, fromUnixTime } from 'date-fns';

/*
 * Set of function to standardize the user's experience with formatted Date strings throughout the website.
 */

export const DATE_PATTERN = 'LLL dd, yyyy';
export const DATE_TIME_PATTERN = "LLLL dd, yyyy HH:mm ('UTC'X)";

export const POLARIS_DATE_PATTERN = 'yyyy-MM-dd';
export const POLARIS_DATE_PATTERN_MOMENT = 'YYYY-MM-DD'; // in moment formatting syntax
export const formatDateFromUnix = (dateInUnix) => {
  return dateInUnix ? moment.unix(dateInUnix).format('DD MMM YYYY') : '';
};

export const formatDateFromUnixYearToSecond = (dateInUnix) => {
  return dateInUnix ? moment.unix(dateInUnix).format('DD MMM YYYY, hh:mm:ss') : '';
};

export const getCurrentTimeEpochSecond = () => {
  return Math.floor(Date.now() / 1000);
};

// convert epoch date to readable format without converting to local timezone
export const convertEpoch = (date) => {
  if (date !== 0) {
    const date1000 = date * 1000;
    const newDate = new Date(date1000);
    const newUTCDate = newDate.toUTCString();
    const utcDateSplit = newUTCDate.split(' ');
    const month = utcDateSplit[2];
    const day = utcDateSplit[1];
    const year = utcDateSplit[3];
    const finalDate = `${month} ${day}, ${year}`;
    return finalDate;
  } else return;
};

export const formatFromEpoch = (epochSeconds, pattern) => {
  return format(fromUnixTime(epochSeconds), pattern ?? DATE_TIME_PATTERN);
};

/**
 *
 * @param dateString A string representing the date, which can be parsed by the JavaScript Date constructor:
 *                  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
 * @param pattern (Optional)
 * @return {string} A human readable string representing the date, but not time
 */
export const formatDateFromString = (dateString, pattern = DATE_PATTERN) => {
  return format(new Date(dateString), pattern);
};

/**
 *
 * @param dateString A string representing the date, which can be parsed by the JavaScript Date constructor:
 *                  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
 * @param pattern (Optional)
 * @return {string} A human readable string representing the date and time
 */
export const formatDateTimeFromString = (dateString, pattern = DATE_TIME_PATTERN) => {
  return format(new Date(dateString), pattern);
};

// convert epoch date to a date format that works with Polaris Date Picker
export const calendarConvert = (date) => {
  const date1000 = date * 1000;
  const newDate = new Date(date1000);
  const month = newDate.getUTCMonth();
  const year = newDate.getUTCFullYear();
  const utcDate = newDate.toUTCString();
  const utcSplit = utcDate.split(' ');
  const day = utcSplit[1];
  let newMonth;
  if (month === 0) {
    newMonth = '01';
  } else if (month === 1) {
    newMonth = '02';
  } else if (month === 2) {
    newMonth = '03';
  } else if (month === 3) {
    newMonth = '04';
  } else if (month === 4) {
    newMonth = '05';
  } else if (month === 5) {
    newMonth = '06';
  } else if (month === 6) {
    newMonth = '07';
  } else if (month === 7) {
    newMonth = '08';
  } else if (month === 8) {
    newMonth = '09';
  } else if (month === 9) {
    newMonth = 10;
  } else if (month === 10) {
    newMonth = 11;
  } else if (month === 11) {
    newMonth = 12;
  }
  const finalDate = `${year}-${newMonth}-${day}`;
  return finalDate;
};
// convert Date from Date Picker format to 12:00 UTC then epoch before sending
export const convertDate = (date) => {
  const splitDate = date.split('-');
  const year = parseInt(splitDate[0]);
  const month = parseInt(splitDate[1]);
  const day = parseInt(splitDate[2]);
  let newMonth;
  // Date.UTC needs integer between 0 (January) and 11 (December)
  if (month === 1) {
    newMonth = 0;
  } else if (month === 2) {
    newMonth = 1;
  } else if (month === 3) {
    newMonth = 2;
  } else if (month === 4) {
    newMonth = 3;
  } else if (month === 5) {
    newMonth = 4;
  } else if (month === 6) {
    newMonth = 5;
  } else if (month === 7) {
    newMonth = 6;
  } else if (month === 8) {
    newMonth = 7;
  } else if (month === 9) {
    newMonth = 8;
  } else if (month === 10) {
    newMonth = 9;
  } else if (month === 11) {
    newMonth = 10;
  } else if (month === 12) {
    newMonth = 11;
  }
  const utcDate = new Date(Date.UTC(year, newMonth, day, 0, 0, 0));
  const dateInUnix = moment(utcDate.toUTCString()).unix();
  return dateInUnix;
};
