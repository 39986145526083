/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ArtifactDetailsView from 'components/ArtifactDetailsView';
import ArtifactEdit from 'components/ArtifactEdit';
import ArtifactConnectedCards from 'components/ArtifactConnectedCards';
import Error from 'components/Error';
import Loading from 'components/Loading';
import { Button, ColumnLayout, Container, Grid, Header, SpaceBetween } from '@amzn/awsui-components-react/polaris';

import './artifact-page.css';
import { getAWSPartFromProduct, getNonPlmFromProduct } from 'utils/artifactHelpers';
import { getArtifactRequestAction } from 'store/artifact/artifactSlice';
import FileUploadTable from 'components/FileUploadTable';
import { ARTIFACT_SUBTYPE_MENU_OPTIONS, FeatureFlag } from '../../utils/constants';
import { useAppSelector } from 'store/store';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import { AwsPart } from '@amzn/d2d-bff-schema';

const ArtifactDetails = (): React.ReactElement | null => {
  const dispatch = useDispatch();
  const { artifact, error, getArtifactLoading: artLoading } = useAppSelector((state) => state.artifactStore);
  const artifactLoading = artLoading === 'pending';
  const params = useParams();
  const { requestId, country, product } = params;
  const decodedProduct = decodeURIComponent(product ?? '');
  let awsPart: AwsPart | undefined;
  let nonPlm: { nonPlmPartName: string } | undefined;
  if (decodedProduct?.includes(':')) {
    awsPart = getAWSPartFromProduct(decodedProduct);
  } else {
    nonPlm = getNonPlmFromProduct(decodedProduct);
  }

  const artifactUiAvailable = useFeatureFlag(FeatureFlag.ARTIFACTS_UI, true).boolValue ?? true;
  const [artifactDetailsMode, setArtifactDetailsMode] = useState('view');
  const [artifactDetailsButton, setArtifactDetailsButton] = useState('Edit Details');
  const SERVICE_NAME = 'd2dauthorization';

  useEffect(() => {
    if (artifactDetailsMode === 'view' && requestId)
      dispatch(
        getArtifactRequestAction({
          requestId,
          ic: country,
          nonPlmPartName: nonPlm?.nonPlmPartName,
          ipn: awsPart?.ipn,
          mpn: awsPart?.mpn ?? undefined,
        })
      );
  }, [artifactDetailsMode, nonPlm?.nonPlmPartName, awsPart?.ipn, awsPart?.mpn, country, dispatch, requestId]);

  const toggleAuthDetails = (): void => {
    if (artifactDetailsMode === 'view') {
      setArtifactDetailsMode('edit');
      setArtifactDetailsButton('Cancel Changes');
    } else {
      setArtifactDetailsMode('view');
      setArtifactDetailsButton('Edit Details');
    }
  };
  const getSubAuth = ARTIFACT_SUBTYPE_MENU_OPTIONS.find((a) => a.value === artifact?.subAuthorizationType);
  const subAuth = getSubAuth?.label;

  if (artifactLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error errorMessage={error?.message} />;
  }

  if (!artifact) return null;

  const artifactDetailsComponent =
    artifactDetailsMode === 'edit' ? (
      <ArtifactEdit
        artifact={artifact}
        setArtifactDetails={setArtifactDetailsMode}
        setArtifactDetailsButton={setArtifactDetailsButton}
        artifactUiAvailable={artifactUiAvailable}
      />
    ) : (
      <ArtifactDetailsView artifact={artifact} />
    );

  const fileUploadTable = (
    <FileUploadTable serviceName={SERVICE_NAME} fileSetId={artifact?.requestId} disabled={true} />
  );

  return (
    <>
      <Grid gridDefinition={[{ colspan: 11 }, { colspan: 11 }, { colspan: 11 }]}>
        <div>
          <Container header={<Header variant="h2">Details</Header>}>
            <ColumnLayout borders="horizontal" columns={4} variant="text-grid">
              <div>
                <label>Part:</label>
                <p className="authorization-details-cell">
                  {artifact.awsPart ? artifact.part : artifact.nonPlmPart?.nonPlmPartName}
                </p>
              </div>
              <div>
                <label>Type:</label>
                <p className="authorization-details-cell">{artifact.artifactTypeFormatted}</p>
              </div>
              <div>
                <label>Sub-Type:</label>
                <p className="authorization-details-cell">{subAuth}</p>
              </div>

              <div>
                <label>Issuing Country:</label>
                <p className="authorization-details-cell">{artifact.issuingCountryFormat}</p>
              </div>
            </ColumnLayout>
            {artifactDetailsComponent}
            <SpaceBetween direction="horizontal" size="xs">
              <Button data-test="edit-cancel-button" variant="primary" onClick={toggleAuthDetails} disabled={true}>
                {artifactDetailsButton}
              </Button>
            </SpaceBetween>
          </Container>
          <br />
          {fileUploadTable}
        </div>
        <div>
          <Container header={<Header variant="h2">Connected Artifacts</Header>}>
            <ArtifactConnectedCards cards={artifact?.cards ?? []} />
          </Container>
        </div>
        <div className="authorization-page-bottom-nav">
          <Link to="/artifacts">
            <Button variant="primary">Back to Artifact Search</Button>
          </Link>
        </div>
      </Grid>
    </>
  );
};

export default ArtifactDetails;
