import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ClassificationsTable from 'components/ClassificationsTable';
import { useDispatch, useSelector } from 'react-redux';
import { searchClassificationsAction } from 'store/classification/classificationSlice';
import './classifications.css';
import { Button, ColumnLayout, FormField, Header, Input, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import useOnEnter from 'hooks/useOnEnter/useOnEnter';
const Classifications = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const { classifications, classificationsError, classificationsLoading: classLoading } = useSelector(
    (state) => state.classificationStore
  );
  const classificationsLoading = classLoading === 'pending';

  // ES Search
  const handleSearch = () => {
    if (searchTerm && !classificationsLoading) dispatch(searchClassificationsAction(searchTerm));
  };

  useOnEnter(handleSearch, ['searchTerm']);
  return (
    <ColumnLayout columns={1}>
      <SpaceBetween size="m">
        <Header
          actions={
            <Link to="/create/classification" data-test="create">
              <Button className="create" variant="normal">
                Create Classification
              </Button>
            </Link>
          }
          variant="h1"
        >
          Classifications
        </Header>

        <div data-test="search-input">
          <FormField description="Search classifications by part" label="Search">
            <Input
              id="searchTerm"
              name="searchTerm"
              type="search"
              placeholder="Search"
              onChange={({ detail }) => setSearchTerm(detail.value)}
              value={searchTerm}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </FormField>
        </div>

        <Button variant="primary" onClick={() => handleSearch()}>
          Search Classifications
        </Button>

        <ClassificationsTable
          classifications={classifications}
          classificationsError={classificationsError}
          classificationsLoading={classificationsLoading}
          itemType="Search Results"
        />
      </SpaceBetween>
    </ColumnLayout>
  );
};

export default Classifications;
