import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../types';
import { getFeatureFlag } from '../../utils/featureFlagUtils/featureFlagUtils';
import { GetFeatureFlagOutput } from '@amzn/gtpcfeature-flag-jsclient';

export type FeatureFlagState = {
  getLoading: LoadingState;
  featureFlags: Record<string, FeatureFlagElement>;
};

export const initialState: FeatureFlagState = {
  getLoading: 'idle',
  featureFlags: {},
};

export type FeatureFlagElement = {
  name: string;
  timestamp: number;
  data: GetFeatureFlagOutput;
};

export const getFeatureFlagAction = createAsyncThunk<FeatureFlagElement | undefined, string>(
  'featureFlags/getFeatureFlag',
  async (featureFlagName: string) => {
    try {
      const featureFlag = await getFeatureFlag(featureFlagName);
      const el: FeatureFlagElement = {
        name: featureFlagName,
        timestamp: Date.now(),
        data: featureFlag,
      };
      return el;
    } catch (e) {
      // TODO: Custom metric to record feature flag failure? Maybe RUM event?
      console.error(`Failed to fetch feature flag value for flag ${featureFlagName}`, e);
    }
  }
);

const { reducer } = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    resetLoadingState: (state) => {
      state.getLoading = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeatureFlagAction.pending, (state) => {
        state.getLoading = 'pending';
      })
      .addCase(getFeatureFlagAction.fulfilled, (state, { payload }) => {
        if (payload?.name) {
          state.featureFlags[payload?.name] = payload;
        }
        state.getLoading = 'fulfilled';
      })
      .addCase(getFeatureFlagAction.rejected, (state, { payload, error }) => {
        console.error(error);
      });
  },
});

export default reducer;
