import axios from 'axios';

export type ApplicationSettings = {
  stage: 'beta' | 'gamma' | 'prod';
  region: string;
  appUrl: string;
  rumAppMonitorId?: string;
  rumIdentityPoolId?: string;
  rumGuestRoleArn?: string;
  evidentlyIdentityPoolId?: string;
  relationshipServiceEndpoint?: string;
  evidentlyGuestRoleArn?: string;
  cognito: {
    userPoolId: string;
    userPoolWebClientId: string;
  };
};

/**
 * Retrieves the Application Settings.
 * Note: ApplicationSettings should be stored as "{url}/settings.json" within the website S3 bucket.
 * @return {Promise<ApplicationSettings>} app settings
 */
export const getApplicationSettings = async (): Promise<ApplicationSettings> => {
  try {
    const { data } = await axios.get('/settings.json');
    return data;
  } catch (e) {
    console.error('No settings found in /public/settings');
    return {} as ApplicationSettings;
  }
};
