import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { createArtifactsReadonlyWarningItem, flashbarItemsCreator } from 'utils/formUtils';
import { FeatureFlag } from '../../../utils/constants';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import { setArtifactV2SearchFlashbarItems } from '../../../store/artifactv2';
import ArtifactV2Search from './ArtifactV2Search';

const ArtifactSearchLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { artifactV2SearchFlashbarItems } = useAppSelector((state) => state.artifactV2Store);
  const artifactUiAvailable = useFeatureFlag(FeatureFlag.ARTIFACTS_UI, true).boolValue ?? true;
  const artifactV2Available = useFeatureFlag(FeatureFlag.ARTIFACTS_V2, true).boolValue ?? true;

  const flashBarItemsWithHardcodedMessage = [...artifactV2SearchFlashbarItems];
  const readonlyWarning = createArtifactsReadonlyWarningItem(artifactV2Available);
  if (!flashBarItemsWithHardcodedMessage.find((items) => items.header === readonlyWarning.header)) {
    flashBarItemsWithHardcodedMessage.push(readonlyWarning);
  }

  return (
    <AppLayout
      contentType="table"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={<Header variant="h1">Artifacts</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Artifacts', href: `/artifacts` },
          ]}
        />
      }
      content={<ArtifactV2Search artifactUiAvailable={artifactUiAvailable} />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar
          items={flashbarItemsCreator(flashBarItemsWithHardcodedMessage, dispatch, setArtifactV2SearchFlashbarItems)}
        />
      }
      stickyNotifications
      toolsHide
    />
  );
};

export default ArtifactSearchLayout;
