import { UseCollectionOptions, UseCollectionResult, useCollection } from '@amzn/awsui-collection-hooks';
import './styles.css';
import {
  Button,
  Checkbox,
  CollectionPreferencesProps,
  Grid,
  PropertyFilter,
  PropertyFilterProps,
  Select,
  TextFilter,
} from '@amzn/awsui-components-react/polaris';
import { EmptyState } from 'components/Table';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from 'components/Table/tableConfig';
import { SetStateAction, useState } from 'react';
import { EnumDisplayMapping } from 'types';

const NoMatch = ({ onFilterClear }: { onFilterClear: () => void }): JSX.Element => {
  return (
    <EmptyState
      title="No matches"
      subtitle="We can’t find a match."
      action={
        <Button data-test="empty-state" onClick={onFilterClear}>
          Clear filter
        </Button>
      }
    />
  );
};

type UseStateResult = [
  Partial<EnumDisplayMapping> | null,
  React.Dispatch<SetStateAction<Partial<EnumDisplayMapping> | null>>
];
export type UseLocalStorageResult<T> = [T, (value: T | ((val: T) => T)) => void];

export type SelectFilter = {
  options: EnumDisplayMapping[];
  useStateRes: UseStateResult | UseLocalStorageResult<Partial<EnumDisplayMapping>>;
};

export type FilteringProps = PropertyFilterProps['filteringProperties'];

export type FilterType = {
  type: 'text' | 'property' | 'none';
  filteringProperties?: FilteringProps;
  customGroupFilters?: {
    key: string;
    values: EnumDisplayMapping[];
    display: string;
    byValue?: boolean;
  }[];
  selectFilters?: SelectFilter[];
};

/**
 *
 * @param {T[]} items - table items to filter
 * @param {Object} filterType - configuration object for the filter type used
 * @param {Object} preferences - page size and other preferences
 * @param {Object} selectFilters - configuration object for the filter type used
 * @return {[JSX.Element, UseCollectionResult<any>]} - useCollection result augmented with specified filter element
 */
const useCollectionWithFilterType = <T extends object>(
  items: T[],
  filterType: FilterType,
  preferences: CollectionPreferencesProps.Preferences<T>
): [JSX.Element | null | undefined, UseCollectionResult<T>] => {
  const emptyState = <EmptyState title="No results" subtitle="Please search again." action={null} />;

  const { type, filteringProperties, customGroupFilters, selectFilters } = filterType;

  const initialGroupCheckboxState =
    customGroupFilters &&
    customGroupFilters.map(() => {
      return false;
    });
  const [customGroupCheckboxState, setCustomGroupCheckboxState] = useState<boolean[]>(initialGroupCheckboxState ?? []);

  const noMatchImpl = (
    <NoMatch
      onFilterClear={() => {
        collectionItems.actions.setFiltering('');
        collectionItems.actions.setPropertyFiltering({ tokens: [], operation: 'and' });
        setCustomGroupCheckboxState(initialGroupCheckboxState ?? []);
      }}
    />
  );

  const collectionOptions: UseCollectionOptions<T> = {
    propertyFiltering:
      type === 'property' && filteringProperties
        ? {
            filteringProperties: filteringProperties,
            empty: emptyState,
            noMatch: noMatchImpl,
          }
        : undefined,
    filtering:
      type === 'text'
        ? {
            empty: emptyState,
            noMatch: noMatchImpl,
          }
        : undefined,
    pagination: { pageSize: preferences?.pageSize },
    sorting: {},
    selection: {},
  };

  const collectionItems = useCollection(items, collectionOptions);

  const groupCheckboxes =
    customGroupFilters &&
    customGroupFilters.map(({ key, display }, i) => {
      return (
        <Checkbox
          key={`${key}-${i}`}
          checked={customGroupCheckboxState[i] || false}
          onChange={({ detail }) => {
            setCustomGroupCheckboxState((old) => {
              const temp = [...old];
              temp[i] = detail.checked;
              return temp;
            });
            if (detail.checked)
              collectionItems.actions.setPropertyFiltering({
                tokens: customGroupFilters[i].values.map((mapping) => ({
                  value: customGroupFilters[i]?.byValue ? mapping.value : mapping.label ?? '',
                  propertyKey: customGroupFilters[i].key,
                  operator: '=',
                })),
                operation: 'or',
              });
            else collectionItems.actions.setPropertyFiltering({ tokens: [], operation: 'and' });
          }}
        >
          {display}
        </Checkbox>
      );
    });

  let filter = null;
  switch (type) {
    case 'text':
      filter = (
        <TextFilter
          {...collectionItems?.filterProps}
          filteringPlaceholder="Filter by text"
          filteringAriaLabel="Filter"
        />
      );
      break;
    case 'property':
      filter = (
        <PropertyFilter
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...collectionItems?.propertyFilterProps}
          onChange={(e) => {
            collectionItems.actions.setPropertyFiltering(e.detail);
            setCustomGroupCheckboxState(initialGroupCheckboxState ?? []);
          }}
          tokenLimit={8}
        />
      );
      break;
    default:
      break;
  }

  filter =
    filter || groupCheckboxes ? (
      <Grid gridDefinition={[{ colspan: 6 }, { colspan: 3 }, { colspan: 3 }]}>
        <div>{filter}</div>
        <div>{groupCheckboxes ?? null}</div>
        {selectFilters &&
          selectFilters.map((item, i) => {
            const [state, setState] = item.useStateRes;
            return (
              <Select
                key={`filter-select-${i}`}
                data-test="select-filter"
                className="select-filter"
                options={item.options}
                selectedAriaLabel="Selected"
                selectedOption={state}
                onChange={(event) => {
                  setState(event.detail.selectedOption);
                }}
                expandToViewport={true}
              />
            );
          })}
      </Grid>
    ) : null;

  return [filter, collectionItems];
};

export default useCollectionWithFilterType;
