import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, FormField, Input, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import useOnEnter from 'hooks/useOnEnter/useOnEnter';
import { useAppSelector } from '../../store/store';
import TradeClassificationsTable from '../TradeClassificationsTable/TradeClassificationsTable';
import PcClassificationsTable from '../PcClassificationsTable/PcClassificationsTable';
import { searchClassificationsAction } from '../../store/classification/classificationSlice';
import useLocalStorage from '../../hooks/useLocalStorage';
import { EnumDisplayMapping } from '../../types';
import {
  CLASSIFICATIONS_SEARCH_MODES_LABELS,
  ClassificationsSearchModes,
} from '../TradeClassificationsTable/TradeClassificationsTableConfig';
import { setPCClassificationLandingFlashbarItems } from '../../store/pcClassification';
import { CONTACT_US_HREF, SEARCH_QUERY_PARAMETER } from '../../utils/constants';
import useOnLoad from '../../hooks/useOnLoad';
import useHashParam from 'use-hash-param';

const CLASSIFICATIONS_SEARCH_MODE_KEY = 'classifications-search-mode';

const ClassificationsCombined: React.FC = () => {
  const dispatch = useDispatch();

  // Optional initial search value passed in a hash parameter in the url
  const [searchParam, setSearchParam] = useHashParam(SEARCH_QUERY_PARAMETER, '');

  /*
   We use one state variable to track the value in the search input element, and a separate to track the finished
   searchTerm which the user has requested a search for. This allows us to keep the latest string typed by the user
   in state, but not send a request to server until the user has finished typing their string.
   */
  // The value of the form field
  const [searchTermFieldValue, setSearchTermFieldValue] = useState(searchParam ?? '');
  // The term the user has request to search, after pressing enter or clicking a submit button
  const [searchTerm, setSearchTerm] = useState(searchParam ?? '');

  const { pcClassifications, searchLoading } = useAppSelector((state) => state.pcClassificationStore);
  const pcClassificationsLoading = searchLoading === 'pending';

  const { classifications, classificationsLoading: classLoading } = useAppSelector(
    (state) => state.classificationStore
  );
  const tradeClassificationsLoading = classLoading === 'pending';

  // Set initial search mode
  const classificationsSearchModeLocalStorage: [
    Partial<EnumDisplayMapping>,
    (value: Partial<EnumDisplayMapping> | ((val: Partial<EnumDisplayMapping>) => Partial<EnumDisplayMapping>)) => void
  ] = useLocalStorage<Partial<EnumDisplayMapping>>(
    CLASSIFICATIONS_SEARCH_MODE_KEY,
    CLASSIFICATIONS_SEARCH_MODES_LABELS[0]
  );

  const [searchMode] = classificationsSearchModeLocalStorage;

  const handleSearch = (): void => {
    if (searchTermFieldValue && !pcClassificationsLoading && !tradeClassificationsLoading) {
      // Whenever a user enters a new search term, update the URL hash parameter
      // This allows the user to copy the URL for any search they have just performed
      // It also allows the user to use the "back" browser button after viewing a search result to return to the same search
      setSearchParam(searchTermFieldValue);

      switch (searchMode.value) {
        case ClassificationsSearchModes.TRADE_CLASSIFICATIONS:
          // TS error comes from calling a JS function. Ignoring for now. TODO: refactor JS classifications code into TS
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(searchClassificationsAction(searchTermFieldValue));
          break;
        case ClassificationsSearchModes.PC_CLASSIFICATIONS:
          setSearchTerm(searchTermFieldValue);
          break;
        default:
          console.error(`Unrecognized searchMode ${searchMode.value}, cannot dispatch search!`);
          dispatch(
            setPCClassificationLandingFlashbarItems([
              {
                type: 'error',
                content: `An internal error was encountered while attempting to perform search. Contact us using the following SIM Template: ${CONTACT_US_HREF}`,
              },
            ])
          );
      }
    }
  };

  useOnEnter(handleSearch, ['searchTerm']);
  // If there is a hash search parameter, search on the hash parameter when the page initially loads
  useOnLoad(handleSearch);

  return (
    <SpaceBetween size="m">
      <div data-test="input">
        <FormField>
          <Input
            name="searchTerm"
            type="search"
            placeholder="Search"
            onChange={({ detail }) => setSearchTermFieldValue(detail.value)}
            value={searchTermFieldValue}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        </FormField>
      </div>
      <Button
        variant="primary"
        disabled={pcClassificationsLoading || tradeClassificationsLoading}
        loading={pcClassificationsLoading || tradeClassificationsLoading}
        onClick={() => handleSearch()}
      >
        Search Classifications
      </Button>

      {searchMode.value === ClassificationsSearchModes.TRADE_CLASSIFICATIONS && (
        <TradeClassificationsTable
          classificationsLoading={tradeClassificationsLoading}
          tradeClassifications={classifications}
          localStorage={classificationsSearchModeLocalStorage}
        />
      )}
      {searchMode.value === ClassificationsSearchModes.PC_CLASSIFICATIONS && (
        <PcClassificationsTable
          searchTerm={searchTerm}
          pcClassificationsResponse={pcClassifications}
          pcClassificationsLoading={pcClassificationsLoading}
          localStorage={classificationsSearchModeLocalStorage}
        />
      )}
    </SpaceBetween>
  );
};

export default ClassificationsCombined;
