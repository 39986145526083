import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import {
  Artifact,
  CreateArtifactOutput,
  CreateIngressArtifactInput,
  CreateIngressArtifactOutput,
  DownloadArtifactVersionOutput,
  GetArtifactByIdInput,
  IdentifierPage,
  MutationCreateArtifactArgs,
  MutationDownloadArtifactVersionArgs,
  MutationUpdateArtifactArgs,
  QueryListIdentifiersByPrefixArgs,
  QuerySearchArtifactsArgs,
  UpdateArtifactOutput,
} from '@amzn/d2d-bff-schema';
import {
  CREATE_ARTIFACT,
  CREATE_INGRESS_ARTIFACT,
  DOWNLOAD_ARTIFACT_VERSION,
  GET_ARTIFACT_BY_ID,
  LIST_IDENTIFIERS_BY_PREFIX,
  SEARCH_ARTIFACTS,
  UPDATE_ARTIFACT,
} from '../graphql/queries';
import axios, { AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';
import { getApplicationSettings } from '../../../config/settingsConfig';

/**
 * call the Relationship Service to get all identifers that start with the give prefix of the identifier type provided
 * @param prefix the prefix of the part identifiers to search for, the identifier type, the pagination token, and the result size
 * @return GraphQLResult the GraphQL result of the API call
 */
export async function makeListIdentifiersByPrefixRequest(
  prefix: QueryListIdentifiersByPrefixArgs
): Promise<GraphQLResult<{ listIdentifiersByPrefix: IdentifierPage }>> {
  const { input, limit, nextToken } = prefix;
  const query = LIST_IDENTIFIERS_BY_PREFIX;
  const variables = { criteria: input, limit, nextToken };

  const { relationshipServiceEndpoint } = await getApplicationSettings();

  if (!relationshipServiceEndpoint) {
    console.error(`No endpoint is configured for the Relationship Service`);
    return {}; // Return an empty object so the general error message is displayed
  }

  const currentSession = await Auth.currentSession();
  const token = currentSession.getAccessToken().getJwtToken();
  const result: AxiosResponse<GraphQLResult<{ listIdentifiersByPrefix: IdentifierPage }>, unknown> = await axios.post(
    relationshipServiceEndpoint,
    {
      query,
      variables,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  if (result.data.errors?.length) {
    throw result.data;
  }
  return result.data;
}

/**
 * GQL GET Artifact
 * @return Artifact
 * @param artifactId the id of the artifact to search for
 */
export async function makeGetArtifactByIdRequest(
  artifactId: GetArtifactByIdInput
): Promise<GraphQLResult<{ getArtifactById: Artifact }>> {
  const query = GET_ARTIFACT_BY_ID;
  const variables = { getArtifactByIdInput: artifactId };
  return API.graphql(graphqlOperation(query, variables)) as Promise<GraphQLResult<{ getArtifactById: Artifact }>>;
}

/**
 * GQL Search Artifacts
 * @return Artifact[] the list of artifact search results
 * @param searchArgs search arguments to use for the search
 */
export async function makeSearchArtifactRequest(
  searchArgs: QuerySearchArtifactsArgs
): Promise<GraphQLResult<{ searchArtifacts: Artifact[] }>> {
  const { andCriteria, includeHorizontallyRelatedMatches, includeExactMatches, pageSize, pageNum } = searchArgs;
  const query = SEARCH_ARTIFACTS;
  const variables = {
    andCriteria,
    includeHorizontallyRelatedMatches,
    includeExactMatches,
    pageSize,
    pageNum,
  };
  return API.graphql(graphqlOperation(query, variables)) as Promise<GraphQLResult<{ searchArtifacts: Artifact[] }>>;
}

/**
 * GQL Upload File: Create the ingress artifact object and get back a pre-signed URL which can be used to upload the file
 * @param file the filename to associate with the ingress artifact object
 * @return UploadFileOutput the result of the upload
 */
export async function makeCreateIngressArtifactRequest(
  file: CreateIngressArtifactInput
): Promise<GraphQLResult<{ createIngressArtifact: CreateIngressArtifactOutput }>> {
  const mutation = CREATE_INGRESS_ARTIFACT;
  const variables = { file };
  return API.graphql(graphqlOperation(mutation, variables)) as Promise<
    GraphQLResult<{ createIngressArtifact: CreateIngressArtifactOutput }>
  >;
}

/**
 * GQL Download Artifact: returns a link to download the file
 * @param input contains the artifactId and the artifact version id to download
 * @returns presignedUrl link to download requested file
 */
export async function makeDownloadArtifactVersionRequest(
  input: MutationDownloadArtifactVersionArgs
): Promise<GraphQLResult<{ downloadArtifactVersion: DownloadArtifactVersionOutput }>> {
  const mutation = DOWNLOAD_ARTIFACT_VERSION;
  const variables = { input: input.input };
  return API.graphql(graphqlOperation(mutation, variables)) as Promise<
    GraphQLResult<{ downloadArtifactVersion: DownloadArtifactVersionOutput }>
  >;
}

/**
 * GQL Update Artifact
 * @param input contains the artifactId and update fields on the artifact
 * @returns updated artifact and array of errors
 */
export async function makeUpdateArtifactRequest(
  input: MutationUpdateArtifactArgs
): Promise<GraphQLResult<{ updateArtifact: UpdateArtifactOutput }>> {
  const mutation = UPDATE_ARTIFACT;
  const variables = { input: input.input };
  return API.graphql(graphqlOperation(mutation, variables)) as Promise<
    GraphQLResult<{ updateArtifact: UpdateArtifactOutput }>
  >;
}

/**
 * GQL Create Artifact
 * @param input contains the artifactId and fields to create on the artifact
 * @returns created artifact ID and array of errors
 */
export async function makeCreateArtifactRequest(
  input: MutationCreateArtifactArgs
): Promise<GraphQLResult<{ createArtifact: CreateArtifactOutput }>> {
  const mutation = CREATE_ARTIFACT;
  const variables = { input: input.input };
  return API.graphql(graphqlOperation(mutation, variables)) as Promise<
    GraphQLResult<{ createArtifact: CreateArtifactOutput }>
  >;
}
