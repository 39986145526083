import { SerializedError, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { D2DGraphQLError, LoadingState } from 'types';
import { makeCreateRequest, makeGetRequest, makeGetUrlRequest, putFile } from '../../_services/api/files';
import { RootState } from 'store/store';
import { isGraphQLResult } from 'utils/errorHandler';

export type D2DFile = {
  fileStatus: string;
  createdBy: string;
  createdAt: string;
  fileName: string;
  fileId: string;
  serviceName: string;
};

export type D2DFileViewModel = D2DFile & { file: globalThis.File; progress?: number; success?: boolean };

type GetUrlResponse = {
  serviceName: string;
  fileId: string;
  fileSetId: string;
  fileStatus: string;
  fileName: string;
  createdBy: string;
  createdAt: string;
  url: string;
};

export type FilesState = {
  getFilesLoading: LoadingState;
  getFilesError: SerializedError | null;
  filesToBeUploaded: D2DFileViewModel[];
  files: D2DFile[];
  urls: Record<string, Partial<GetUrlResponse & { loading?: LoadingState }>>;
  createFilesLoading: LoadingState;
  uploadInProgress: boolean;
  createFilesError: SerializedError | null;
  getUrlError: SerializedError | null;
};

const initialState: FilesState = {
  getFilesLoading: 'pending',
  getFilesError: null,
  filesToBeUploaded: [],
  files: [],
  urls: {},
  createFilesLoading: 'idle',
  uploadInProgress: false,
  createFilesError: null,
  getUrlError: null,
};

/**
 * Get files for the fileSetId
 * @param {string} serviceName
 * @param {string} fileSetId
 * @returns {Object[]}
 */

export const getFilesAction = createAsyncThunk<
  D2DFile[],
  { serviceName: string; fileSetId: string },
  {
    rejectValue: D2DGraphQLError[];
  }
>('files/getFilesAction', async ({ serviceName, fileSetId }) => {
  const files = await makeGetRequest(serviceName, fileSetId);
  const formatGQL = files.data.getFile;
  return formatGQL;
});

export const getDownloadUrl = createAsyncThunk<
  { url: GetUrlResponse; fileId: string },
  { serviceName: string; fileId: string },
  {
    rejectValue: D2DGraphQLError[];
  }
>('files/getDownloadUrl', async ({ serviceName, fileId }) => {
  const url = await makeGetUrlRequest(serviceName, fileId);
  const formatGQL = url.data.getUrl;
  return { url: formatGQL[0], fileId };
});

/**
 * Create files based on what is present in filesToBeUploaded
 * @param {string} serviceName
 * @param {string} fileSetId
 */
export const createFilesAction = createAsyncThunk<
  void,
  { serviceName: string; fileSetId: string },
  {
    rejectValue: D2DGraphQLError[];
    state: RootState;
  }
>('files/createFilesAction', async ({ serviceName, fileSetId }, { getState, dispatch, rejectWithValue }) => {
  try {
    const { filesToBeUploaded } = getState().filesStore;
    const fileNames = filesToBeUploaded.map((f) => f.file.name);
    const fileURLs = await makeCreateRequest({ serviceName, fileSetId, fileNames });
    const formatGQL = fileURLs.data.createFile;
    filesToBeUploaded &&
      filesToBeUploaded.forEach(async (v, index) => {
        const match = formatGQL.find((x: { fileName: string; url: string }) => x.fileName === v.file.name);
        if (match) {
          const putConfig = {
            onUploadProgress: (progressEvent: ProgressEvent) => {
              const progress = (progressEvent.loaded / progressEvent.total) * 100;
              dispatch(setFileUploadProgress({ progress, index }));
            },
          };
          const target = await putFile(match.url, v, putConfig);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (v.file.size === 0) dispatch(setFileUploadProgress({ progress: target.progress, index }));
        } else console.error('No file/url match found');
      });
  } catch (e) {
    const gqlResult = isGraphQLResult(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

/** File Slice */
const { reducer, actions } = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFileUploadProgress: (state, { payload }) => {
      const { progress, index } = payload;
      state.filesToBeUploaded[index].progress = progress;
    },
    setFilesToBeUploaded: (state, { payload }) => {
      state.filesToBeUploaded = payload;
    },
    setUploadInProgress: (state, { payload }) => {
      state.uploadInProgress = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFilesAction.pending, (state) => {
      state.files = [];
      state.filesToBeUploaded = [];
      state.getFilesLoading = 'pending';
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getFilesAction.fulfilled, (state, { payload }) => {
      state.files = payload;
      state.getFilesLoading = 'fulfilled';
    });
    builder.addCase(getFilesAction.rejected, (state, { error }) => {
      state.files = [];
      state.getFilesError = error;
      state.getFilesLoading = 'rejected';
    });
    builder.addCase(getDownloadUrl.pending, (state, { meta }) => {
      const fileId = meta?.arg?.fileId;
      state.urls[fileId] = {};
      state.urls[fileId].loading = 'pending';
    });
    builder.addCase(getDownloadUrl.fulfilled, (state, { payload }) => {
      const { url, fileId } = payload;
      state.urls[fileId] = { ...url, ...state.urls[fileId] };
      state.urls[fileId].loading = 'fulfilled';
    });
    builder.addCase(getDownloadUrl.rejected, (state, { error, meta }) => {
      state.getUrlError = error;
      const fileId = meta?.arg?.fileId;
      state.urls[fileId].loading = 'rejected';
    });
    builder.addCase(createFilesAction.pending, (state) => {
      state.uploadInProgress = true;
      state.createFilesLoading = 'pending';
    });
    builder.addCase(createFilesAction.fulfilled, (state) => {
      state.createFilesLoading = 'fulfilled';
    });
    builder.addCase(createFilesAction.rejected, (state, { error }) => {
      console.error(error);
      state.createFilesError = error;
      state.createFilesLoading = 'rejected';
    });
  },
});

export const { setFilesToBeUploaded, setFileUploadProgress, setUploadInProgress } = actions;

export default reducer;
