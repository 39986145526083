import { Query } from '@amzn/d2d-bff-schema';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { gql } from 'graphql-request';

// Note: Any variables used inside an operation must be declared as part of the operation definition

export type QueryResult = GraphQLResult<Query>;

/** Classifications */
export const GET_CLASSIFICATION = gql`
  query getClassification($assetId: String!, $countryISO2: String!) {
    getClassification(assetId: $assetId, countryISO2: $countryISO2) {
      createdAt
      createdBy
      modifiedAt
      modifiedBy
      changeNotes
      assetId
      countryISO2
      hts
      programs
      pcStatus
      importClassificationDetails
      justification
      ecn
      exportClassificationDetails
      importApprovalRequired
      importRequirementsCompleted
      brokerImportRequirementNotes
      localLanguageDescription
      internalImportRequirementNotes
      exportReportingRequirement
      exportReportingRequirementType
      exportApprovalRequired
      exportRequirementNotes
      exportLicenseOrLicenseException
      assetType
      active
      awsPart {
        ipn
        mpn
        townsendPartNumber
        ipnDescription
      }
      nonPlmPart {
        nonPlmPartName
        description
        manufacturerName
        manufacturerPartId
      }
    }
  }
`;

export const OPENSEARCH_CLASSIFICATION = gql`
  query searchClassifications($searchTerm: String!) {
    searchClassifications(searchTerm: $searchTerm) {
      assetId
      countryISO2
      hts
      programs
      pcStatus
      importClassificationDetails
      justification
      ecn
      exportClassificationDetails
      importApprovalRequired
      importRequirementsCompleted
      brokerImportRequirementNotes
      localLanguageDescription
      internalImportRequirementNotes
      exportReportingRequirement
      exportReportingRequirementType
      exportApprovalRequired
      exportRequirementNotes
      exportLicenseOrLicenseException
      assetType
      active
      ipn
      mpn
      ipnDescription
      nonPlmPartName
      description
      manufacturerName
      manufacturerPartId
      modifiedAt
      modifiedBy
      createdAt
      createdBy
      changeNotes
    }
  }
`;

/** D2D Files */
export const GET_FILE = gql`
  query getFile($serviceName: String!, $fileSetId: String!) {
    getFile(serviceName: $serviceName, fileSetId: $fileSetId) {
      serviceName
      fileId
      fileSetId
      fileStatus
      fileName
      createdBy
      createdAt
    }
  }
`;

export const GET_URL = gql`
  query getUrl($serviceName: String!, $fileId: String!) {
    getUrl(serviceName: $serviceName, fileId: $fileId) {
      serviceName
      fileId
      fileSetId
      fileStatus
      fileName
      createdBy
      createdAt
      testData
      url
    }
  }
`;

/** D2D Assets/Artifacts */
export const OPENSEARCH_ASSETS = gql`
  query searchAssets($searchTerm: String!) {
    searchAssets(searchTerm: $searchTerm) {
      active
      applicantName
      applicantType
      assetAndRequestId
      assetId
      assetType
      authorizationNotes
      authorizationStatus
      authorizationType
      ipn
      mpn
      townsendPartNumber
      createdAt
      createdBy
      expirationDate
      externalId
      governmentTrackingNumber
      issuingBody
      issuingCountry
      nonPlmPartName
      description
      manufacturerName
      manufacturerPartId
      recordType
      requestId
      standards
      startDate
      subAuthorizationType
      team
      testData
      typeAndCountry
      modifiedAt
      modifiedBy
      changeNotes
      superskuName
      awsProjectExternal
      score
      source
      otherSourceName
    }
  }
`;

export const LIST_AUTHORIZATIONS_AWSPART = gql`
  query listAuthorizations($issuingCountry: String, $ipn: String, $mpn: String, $requestId: String) {
    listAuthorizations(issuingCountry: $issuingCountry, ipn: $ipn, mpn: $mpn, requestId: $requestId) {
      active
      applicantName
      applicantType
      assetAndRequestId
      assetId
      assetType
      authorizationNotes
      authorizationStatus
      authorizationType
      awsPart {
        ipn
        mpn
      }
      nonPlmPart {
        nonPlmPartName
        description
        manufacturerName
        manufacturerPartId
      }
      createdBy
      expirationDate
      externalId
      governmentTrackingNumber
      issuingBody
      issuingCountry
      recordType
      requestId
      standards
      startDate
      subAuthorizationType
      team
      typeAndCountry
      modifiedBy
      changeNotes
      superskuName
      awsProjectExternal
      source
      otherSourceName
    }
  }
`;

export const LIST_AUTHORIZATIONS_NONPLM = gql`
  query listAuthorizations($issuingCountry: String, $nonPlmPartName: String, $requestId: String) {
    listAuthorizations(issuingCountry: $issuingCountry, nonPlmPartName: $nonPlmPartName, requestId: $requestId) {
      active
      applicantName
      applicantType
      assetAndRequestId
      assetId
      assetType
      authorizationNotes
      authorizationStatus
      authorizationType
      createdBy
      expirationDate
      externalId
      governmentTrackingNumber
      issuingBody
      issuingCountry
      nonPlmPart {
        nonPlmPartName
        description
        manufacturerName
        manufacturerPartId
      }
      recordType
      requestId
      standards
      startDate
      subAuthorizationType
      team
      typeAndCountry
      modifiedBy
      changeNotes
      source
      otherSourceName
    }
  }
`;

/** License Manager */
export const GET_LICENSE = gql`
  query GetLicense($licenseId: String!) {
    getLicense(licenseIdRequest: { licenseId: $licenseId }) {
      licenseNumber
      licenseId
      transactionDirection
      issuingCountry
      allowedImportCountries
      allowedExportCountries
      effectiveDate
      expirationDate
      hts
      eccn
      ipn
      mpn
      shipByDate
      unitLimit
      usageCount
      valueLimit
      valueCurrency
      usageValue
      validEndUser
      singleUse
      consignee
      notes
      manualReviewRequired
      files
      associatedLicenses
      authorizationType
      techControlPlanRequired
      applicationNumber
      externalId
      nonPlmPartNames
      disabled
      commodity
      applicationDate
      exporter
      source
      otherSourceName
      usages {
        clientId
        transactionId
        partId
        quantity
        value
        valueCurrency
        hts
        eccn
        ipn
        mpn
        exportCountry
        importCountry
        canceled
      }
    }
  }
`;

export const SEARCH_LICENSES = gql`
  query SearchLicenses($searchTerm: String!) {
    searchLicenses(searchTerm: $searchTerm) {
      licenseId
      licenseNumber
      issuingCountry
      transactionDirection
      valueCurrency
      manualReviewRequired
      consignee
      validEndUser
      usageValue
      authorizationType
      associatedLicenses
      singleUse
      allowedImportCountries
      allowedExportCountries
      usageCount
      notes
      effectiveDate
      expirationDate
      createdAt
      createdBy
      hts
      eccn
      ipn
      mpn
      unitLimit
      valueLimit
      techControlPlanRequired
      applicationNumber
      externalId
      nonPlmPartNames
      disabled
      score
      commodity
      applicationDate
      exporter
      source
      otherSourceName
    }
  }
`;

/** Townsend Search */
export const TOWNSEND_PLM = gql`
  query searchPLM($searchTerm: String!) {
    searchPLM(searchTerm: $searchTerm) {
      items {
        description
        otherIdentifiers {
          key
          value
        }
        entityClasses
        entityId
        entityType
        lifeCycleState
      }
      nextToken
      searchType
      totalCount
      itemCount
    }
  }
`;

export const GET_PCC_PART = gql`
  query getPccPart(
    $partSource: PCCPartSource!
    $partIdentifierType: PCCPartIdentifierType!
    $partIdentifierValue: String!
  ) {
    getPccPart(
      partSource: $partSource
      partIdentifierType: $partIdentifierType
      partIdentifierValue: $partIdentifierValue
    ) {
      partId
      partSource
      partIdentifierType
      partIdentifierValue
      partDescription
      createdAt
      createdBy
      classificationStatus
      lastClassifiedBy
      lastClassifiedAt
      pcClassifications {
        pcCategory
        createdAt
        createdBy
      }
    }
  }
`;

export const SEARCH_PCC_PARTS = gql`
  query searchPccPart($from: Int!, $pageSize: Int!, $searchTerm: String!, $sortField: String!, $sortOrder: String!) {
    searchPccPart(
      from: $from
      pageSize: $pageSize
      searchTerm: $searchTerm
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      results {
        classificationCodes
        classificationStatus
        createdAt
        createdBy
        lastClassifiedAt
        lastClassifiedBy
        modifiedAt
        modifiedBy
        partDescription
        partId
        partIdentifierValue
        partSource
        partIdentifierType
      }
      totalResults
    }
  }
`;

export const LIST_PCC_CODES = gql`
  query ListPccCodes {
    listPccCodes {
      category
      label
      description
    }
  }
`;

export const GET_ARTIFACT_BY_ID = gql`
  query GetArtifactById($getArtifactByIdInput: GetArtifactByIdInput!) {
    getArtifactById(getArtifactByIdInput: $getArtifactByIdInput) {
      id
      archived
      latestFile {
        name
        uploadDate
        cvAttachmentId
        checksum
      }
      files {
        name
        uploadDate
        cvAttachmentId
        checksum
      }
      changelog {
        modifiedAt
        modifiedBy
        metadata {
          before {
            key
            value
          }
          after {
            key
            value
          }
        }
        partFamilies {
          before {
            userPattern
            replacementCharacters {
              character
              characterClasses
            }
          }
          after {
            userPattern
            replacementCharacters {
              character
              characterClasses
            }
          }
        }
        archived {
          before
          after
        }
        files {
          before {
            cvAttachmentId
            checksum
            uploadDate
            name
          }
          after {
            cvAttachmentId
            checksum
            uploadDate
            name
          }
        }
      }
      metadata {
        data {
          key
          value
        }
        schema {
          key
          required
          type
          validation
        }
      }
      partFamilies {
        userPattern
        replacementCharacters {
          character
          characterClasses
        }
      }
      createdBy
      createdAt
      lastModifiedBy
      lastModifiedAt
    }
  }
`;

export const SEARCH_ARTIFACTS = gql`
  query SearchArtifacts(
    $andCriteria: [ArtifactSearchCriteria!]!
    $includeExactMatches: Boolean!
    $includeHorizontallyRelatedMatches: Boolean!
    $pageNum: Int!
    $pageSize: Int!
  ) {
    searchArtifacts(
      andCriteria: $andCriteria
      includeExactMatches: $includeExactMatches
      includeHorizontallyRelatedMatches: $includeHorizontallyRelatedMatches
      pageNum: $pageNum
      pageSize: $pageSize
    ) {
      id
      archived
      files {
        name
        uploadDate
        cvAttachmentId
        checksum
      }
      changelog {
        modifiedAt
        modifiedBy
        metadata {
          before {
            key
            value
          }
          after {
            key
            value
          }
        }
        partFamilies {
          before {
            userPattern
            replacementCharacters {
              character
              characterClasses
            }
          }
          after {
            userPattern
            replacementCharacters {
              character
              characterClasses
            }
          }
        }
        archived {
          before
          after
        }
        files {
          before {
            cvAttachmentId
            checksum
            uploadDate
            name
          }
          after {
            cvAttachmentId
            checksum
            uploadDate
            name
          }
        }
      }
      metadata {
        data {
          key
          value
        }
        schema {
          key
          required
          type
          validation
        }
      }
      createdBy
      createdAt
      lastModifiedBy
      lastModifiedAt
      partFamilies {
        userPattern
        replacementCharacters {
          character
          characterClasses
        }
      }
    }
  }
`;

export const CREATE_INGRESS_ARTIFACT = gql`
  mutation CreateIngressArtifact($file: CreateIngressArtifactInput!) {
    createIngressArtifact(file: $file) {
      uploadUrl
      ingressArtifact {
        ingressArtifactID
      }
    }
  }
`;

export const ON_UPDATE_FILE_VALIDATION_STATUS = gql`
  subscription OnUpdateFileValidationStatus($ingressArtifactID: String!) {
    onUpdateFileValidationStatus(ingressArtifactID: $ingressArtifactID) {
      ingressArtifactID
      existingArtifactID
      artifactWorkflowStatus
    }
  }
`;

export const LIST_IDENTIFIERS_BY_PREFIX = gql`
  query MyQuery($criteria: IdentifierPrefixInput!, $nextToken: String, $limit: Int) {
    listIdentifiersByPrefix(input: $criteria, nextToken: $nextToken, limit: $limit) {
      nextToken
      identifiers {
        id
        idType
      }
    }
  }
`;

export const DOWNLOAD_ARTIFACT_VERSION = gql`
  mutation DownloadArtifactVersion($input: DownloadArtifactVersionInput!) {
    downloadArtifactVersion(input: $input) {
      presignedUrl
    }
  }
`;

export const UPDATE_ARTIFACT = gql`
  mutation UpdateArtifact($input: UpdateArtifactInput!) {
    updateArtifact(input: $input) {
      artifact {
        id
        archived
        latestFile {
          name
          uploadDate
          cvAttachmentId
          checksum
        }
        files {
          name
          uploadDate
          cvAttachmentId
          checksum
        }
        changelog {
          modifiedAt
          modifiedBy
          metadata {
            before {
              key
              value
            }
            after {
              key
              value
            }
          }
          partFamilies {
            before {
              userPattern
              replacementCharacters {
                character
                characterClasses
              }
            }
            after {
              userPattern
              replacementCharacters {
                character
                characterClasses
              }
            }
          }
          archived {
            before
            after
          }
          files {
            before {
              cvAttachmentId
              checksum
              uploadDate
              name
            }
            after {
              cvAttachmentId
              checksum
              uploadDate
              name
            }
          }
        }
        metadata {
          data {
            key
            value
          }
          schema {
            key
            required
            type
            validation
          }
        }
        partFamilies {
          userPattern
          replacementCharacters {
            character
            characterClasses
          }
        }
        createdBy
        createdAt
        lastModifiedBy
        lastModifiedAt
      }
    }
  }
`;

export const CREATE_ARTIFACT = gql`
  mutation CreateArtifact($input: CreateArtifactInput!) {
    createArtifact(input: $input) {
      artifact {
        id
        archived
        latestFile {
          name
          uploadDate
          cvAttachmentId
          checksum
        }
        files {
          name
          uploadDate
          cvAttachmentId
          checksum
        }
        changelog {
          modifiedAt
          modifiedBy
          metadata {
            before {
              key
              value
            }
            after {
              key
              value
            }
          }
          partFamilies {
            before {
              userPattern
              replacementCharacters {
                character
                characterClasses
              }
            }
            after {
              userPattern
              replacementCharacters {
                character
                characterClasses
              }
            }
          }
          archived {
            before
            after
          }
          files {
            before {
              cvAttachmentId
              checksum
              uploadDate
              name
            }
            after {
              cvAttachmentId
              checksum
              uploadDate
              name
            }
          }
        }
        metadata {
          data {
            key
            value
          }
          schema {
            key
            required
            type
            validation
          }
        }
        partFamilies {
          userPattern
          replacementCharacters {
            character
            characterClasses
          }
        }
        createdBy
        createdAt
        lastModifiedBy
        lastModifiedAt
      }
    }
  }
`;
