import { useEffect, useState } from 'react';
import { searchPCClassificationAction } from '../../store/pcClassification';
import { useDispatch } from 'react-redux';

export type UsePcClassificationsParams = {
  searchTerm: string;
  pageSize: number;
  pageIndex: number;
  sortColumn: string;
  isSortDesc: boolean;
};

/**
 * React hook responsible for listening to all state related to PC C12n search results and dispatching a request for
 * fresh data.
 *
 * @param params The params which this function requires to monitor for sending requests for new data
 * @returns nothing
 */
export function usePcClassifications(params: UsePcClassificationsParams): void {
  const { pageSize, pageIndex } = params;
  const { isSortDesc, sortColumn } = params;
  const { searchTerm } = params;
  const [currentPageIndex, setCurrentPageIndex] = useState(pageIndex);

  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentPageIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    if (searchTerm) {
      dispatch(
        searchPCClassificationAction({
          searchTerm,
          sortField: sortColumn,
          sortOrder: isSortDesc ? 'desc' : 'asc',
          pageSize,
          from: pageSize * (pageIndex - 1),
        })
      );
    }
  }, [pageSize, isSortDesc, sortColumn, currentPageIndex, searchTerm, dispatch, pageIndex]);
}
