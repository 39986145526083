import { useEffect } from 'react';

/**
 * Run a function once when a component first loads
 * @param onLoadFunction the function to run once
 * @returns void
 */
const useOnLoad = (onLoadFunction: () => void): void => {
  useEffect(() => {
    onLoadFunction();
    // Having an empty dependency array ensures that the hook only runs once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOnLoad;
