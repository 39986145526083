import React, { FormEvent, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PCCPartFormInput, pccPartApiSchemaToFormSchema, pccPartFormSchema } from './index';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { PccPart, PccPartIdentifierType, PccPartSource } from '@amzn/d2d-bff-schema';
import ValueWithLabel from '../ValueWithLabel';
import FormWrapper from 'components/FormWrapper';
import { PCC_PART_IDENTIFIER_TYPES, PCC_PART_SOURCES } from '../../utils/constants';
import { useDebouncedIPNSearch } from '../../hooks/useDebounce/useDebounce';
import { useAppSelector } from '../../store/store';
import { getOptionalLabel } from '../../utils/formUtils';
import usePCCCodesAPI from '../../hooks/useApiDataFetch';

interface PcClassificationCommonFormProps {
  defaultValues?: Partial<PCCPartFormInput>;
  onSubmit: SubmitHandler<PCCPartFormInput>;
  submitText: string;
  cancelRoute: string;
  formHeaderText?: string;
  isLoading?: boolean;
  pccPart?: PccPart;
  isEdit?: boolean;
}

const PcClassificationCommon: React.FC<PcClassificationCommonFormProps> = ({
  defaultValues,
  onSubmit,
  submitText,
  cancelRoute,
  pccPart,
  isLoading = false,
  isEdit = false,
}) => {
  const { pccCodesLoading, formattedPccCodes } = useAppSelector((state) => state.pcClassificationStore);

  const { control, handleSubmit, watch, setValue, resetField, reset } = useForm<PCCPartFormInput>({
    defaultValues: pccPart ? pccPartApiSchemaToFormSchema(formattedPccCodes).parse(pccPart) : defaultValues,
    resolver: zodResolver(pccPartFormSchema),
    mode: 'onChange',
  });

  const setTownsendSearchTerm = useDebouncedIPNSearch();
  const stringArray: string[] = [];
  const [ipnCategories, setIpnCategories] = useState(stringArray);
  const [ipnValid, setIpnValid] = useState(false);
  const [selectedIpn, setSelectedIpn] = useState('');
  const [ipnDescription, setIpnDescription] = useState('');
  const [ipnState, setIpnState] = useState('');
  const [ipnType, setIpnType] = useState('');
  const { ipnInfoList, ipnsLoading } = useAppSelector((state) => state.townsendStore);
  const { partSource, partIdentifierType, partIdentifierValue } = watch();

  usePCCCodesAPI(
    isEdit
      ? {
          pccPart: pccPart,
          resetFunction: reset,
        }
      : undefined
  );

  useEffect(() => {
    if (selectedIpn && ipnInfoList && ipnInfoList?.length > 0) {
      const ipnItem = ipnInfoList?.find((item) => item.ipn === selectedIpn ?? '');
      setIpnValid(true);
      setIpnDescription(ipnItem?.description ?? '');
      setIpnState(ipnItem?.lifeCycleState ?? '');
      setIpnType(ipnItem?.entityType ?? '');
      setIpnCategories(ipnItem?.entityClasses ?? []);
    }
  }, [ipnInfoList, ipnValid, selectedIpn]);

  useEffect(() => {
    if (isEdit) {
      if (partSource?.value === PccPartSource.AGILE_PLM && partIdentifierValue) {
        setTownsendSearchTerm(partIdentifierValue);
        setSelectedIpn(partIdentifierValue);
      }
    }
  }, [isEdit, partIdentifierValue, partSource, setTownsendSearchTerm]);

  const submit = async (submitEvent: FormEvent): Promise<void> => {
    submitEvent.preventDefault();
    await handleSubmit((e) => {
      onSubmit(e);
    })(submitEvent);
  };

  const plmInfo = (
    <>
      {ipnValid && (
        <>
          {ipnsLoading === 'pending' ? (
            <Spinner size="large" />
          ) : (
            <Container header={<Header variant="h2">Agile Information</Header>}>
              <ColumnLayout columns={2} variant="text-grid">
                <SpaceBetween size="l">
                  <ValueWithLabel label="Agile IPN Description">{ipnDescription}</ValueWithLabel>
                  <ValueWithLabel label="Agile IPN Type">{ipnType}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                  <ValueWithLabel label="Agile IPN State">{ipnState}</ValueWithLabel>
                  <ValueWithLabel label="Agile IPN Categories">
                    {' '}
                    <ul>
                      {ipnCategories.map((c) => (
                        <li key={c}>{c}</li>
                      ))}
                    </ul>
                  </ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
            </Container>
          )}
        </>
      )}
    </>
  );
  const pccContent = (
    <>
      <Container header={<Header>Part Type</Header>}>
        <SpaceBetween size="xl">
          <Controller
            name="partSource"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormField label="Part Source" description="Choose the part source" errorText={error && error.message}>
                <Select
                  selectedOption={value ?? null}
                  onChange={(e) => {
                    onChange(e.detail.selectedOption);
                    if (e.detail.selectedOption.value === PccPartSource.AGILE_PLM) {
                      setValue('partIdentifierType', { value: PccPartIdentifierType.IPN });
                    } else {
                      resetField('partIdentifierType');
                    }
                  }}
                  filteringType="auto"
                  options={PCC_PART_SOURCES}
                  placeholder="Choose a part source"
                  disabled={isEdit}
                />
              </FormField>
            )}
          />

          {partSource?.value !== PccPartSource.AGILE_PLM && (
            <Controller
              name="partIdentifierType"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label="Part Identifier Type"
                  description="Choose the part identifier type"
                  errorText={error && error.message}
                >
                  <Select
                    selectedOption={value ?? null}
                    onChange={(e) => onChange(e.detail.selectedOption)}
                    filteringType="auto"
                    options={
                      partSource?.value === PccPartSource.AGILE_PLM
                        ? PCC_PART_IDENTIFIER_TYPES
                        : PCC_PART_IDENTIFIER_TYPES.filter((s) => {
                            return s.value !== PccPartIdentifierType.IPN;
                          })
                    }
                    placeholder="Choose a part identifier type"
                    disabled={isEdit}
                  />
                </FormField>
              )}
            />
          )}
        </SpaceBetween>
      </Container>
      <Container header={<Header>Details</Header>}>
        <SpaceBetween size={'xl'}>
          {partSource?.value === PccPartSource.AGILE_PLM && partIdentifierType?.value === PccPartIdentifierType.IPN && (
            <>
              <Controller
                name="partIdentifierValue"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  const ipnVal = partIdentifierValue ? { value: partIdentifierValue } : null;
                  return (
                    <FormField
                      label="Agile IPN Search"
                      description="After Agile IPN search, please choose an IPN"
                      errorText={error && error.message}
                    >
                      <SpaceBetween size={'l'}>
                        <Select
                          selectedOption={ipnVal}
                          filteringType="manual"
                          onChange={(e) => {
                            setSelectedIpn(e.detail.selectedOption.label ?? '');

                            onChange(e.detail.selectedOption.value);
                          }}
                          filteringPlaceholder="Search IPNs"
                          placeholder="Choose an IPN"
                          loadingText="Loading IPNs"
                          errorText="Error fetching results."
                          empty="No IPNs found"
                          options={ipnInfoList?.map((part) => {
                            return { value: part.ipn, label: part.ipn };
                          })}
                          finishedText="End of results"
                          recoveryText="Retry"
                          onLoadItems={({ detail }) => {
                            setTownsendSearchTerm(detail.filteringText);
                          }}
                          statusType={ipnsLoading}
                          disabled={isEdit}
                        />
                        {plmInfo}
                      </SpaceBetween>
                    </FormField>
                  );
                }}
              />
            </>
          )}

          {!(
            partSource?.value === PccPartSource.AGILE_PLM && partIdentifierType?.value === PccPartIdentifierType.IPN
          ) && (
            <Controller
              name="partIdentifierValue"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField label="Part Number" description="Enter part number" errorText={error && error.message}>
                  <Input
                    placeholder="Enter part number"
                    value={value ?? ''}
                    onChange={(e) => {
                      onChange(e.detail.value);
                      setIpnValid(false);
                    }}
                    disabled={isEdit}
                  />
                </FormField>
              )}
            />
          )}
          {!(partSource?.value === PccPartSource.AGILE_PLM) && (
            <Controller
              name="partDescription"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label={getOptionalLabel('Part Description')}
                  description="Enter part description"
                  errorText={error && error.message}
                >
                  <Input
                    placeholder="Enter part description"
                    value={value ?? ''}
                    onChange={(e) => onChange(e.detail.value)}
                  />
                </FormField>
              )}
            />
          )}
          <Controller
            name="pcClassifications"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormField
                label="Part Classification"
                description="Choose the part classification"
                errorText={error && error.message}
              >
                <Multiselect
                  selectedOptions={value ?? []}
                  loadingText="Loading codes..."
                  statusType={pccCodesLoading}
                  errorText="Error fetching results."
                  onChange={(e) => onChange(e.detail.selectedOptions)}
                  filteringType="auto"
                  empty="No classification codes found"
                  options={formattedPccCodes}
                  placeholder="Choose a part classification"
                />
              </FormField>
            )}
          />
        </SpaceBetween>
      </Container>
    </>
  );

  return (
    <form onSubmit={submit}>
      <FormWrapper
        isLoading={isLoading}
        submitText={submitText}
        cancelRoute={cancelRoute}
        isMainActionDisabled={isLoading}
      >
        {pccContent}
      </FormWrapper>
    </form>
  );
};

export default PcClassificationCommon;
