import { convertEpoch, formatDateFromString, formatFromEpoch } from './dateFormat';
import { ARTIFACT_MENU_OPTIONS, COUNTRIES, COUNTRY_CURRENCY_MAPPING } from './constants';
import {
  ArtifactCard,
  ArtifactsSearchResult,
  AssetSearchViewModel,
  AssetViewModel,
  SearchResultFilter,
} from 'types/artifacts';
import {
  Asset,
  AssetsSearchResponse,
  AwsPart,
  Currency,
  IndexedLicense,
  TransactionDirection,
} from '@amzn/d2d-bff-schema';
import { getOptionByValue } from './formUtils';

export const formatResults = (artifacts: AssetsSearchResponse[]): AssetSearchViewModel[] => {
  return artifacts.map((artifact) =>
    formatArtifact({
      ...artifact,
      awsPart: { ipn: artifact?.ipn ?? '', mpn: artifact?.mpn ?? '' },
    })
  );
};

export const formatArtifact = (
  artifact: AssetViewModel | AssetSearchViewModel
): AssetViewModel | AssetSearchViewModel => {
  const result: AssetViewModel | AssetSearchViewModel = { ...artifact };
  result.startDateFormatted = convertEpoch(artifact.startDate);
  result.expirationDateFormatted = convertEpoch(artifact.expirationDate);
  result.artifactTypeFormatted = formatType(artifact.authorizationType);
  // BFF2 returns the null which causes .map issues - this will .map off of an empty
  // array if the values are null
  result.standardsFormatted = artifact.standards === null ? [] : artifact.standards;
  result.activeDisplay = artifact.active === true ? 'True' : 'False';
  if (artifact.awsPart) {
    result.part = artifact.awsPart.mpn ? artifact.awsPart.ipn + ':' + artifact.awsPart.mpn : artifact.awsPart.ipn;
  } else if ('nonPlmPart' in artifact && artifact.nonPlmPart) {
    result.part = artifact.nonPlmPart?.nonPlmPartName;
  }
  result.issuingCountryFormat = getCountryFormat(artifact.issuingCountry);
  return result;
};

/**
 * @param type The id for an Artifact Type (Known in some legacy systems as AuthorizationType).
 * @returns Human readable label for the given type, if one exists. Undefined if the type given doesn't exist or has no label.
 */
export const formatType = (type?: string | null): string | undefined => {
  if (type) {
    const lowercase = type.toLowerCase();
    const auth = ARTIFACT_MENU_OPTIONS.find((s) => s.value === lowercase);
    return auth?.label;
  }
};

export const getAuthIcPart = (
  artifacts?: Asset[],
  ic?: string,
  ipn?: string,
  mpn?: string,
  nonPlmPartName?: string
): Asset | undefined => {
  return artifacts?.find((art) => {
    if (mpn && art.issuingCountry === ic && art.awsPart?.ipn === ipn && art.awsPart?.mpn === mpn) {
      return true;
    }
    if (!mpn && ipn && art.issuingCountry === ic && art.awsPart?.ipn === ipn && !art.awsPart?.mpn) {
      return true;
    }
    if (nonPlmPartName && art.issuingCountry === ic && art.nonPlmPart?.nonPlmPartName === nonPlmPartName) {
      return true;
    }
    return false;
  });
};

export const getRestOfArtifacts = (
  artifacts?: Asset[],
  ic?: string,
  ipn?: string,
  mpn?: string,
  nonPlmPartName?: string
): Asset[] => {
  if (artifacts) {
    if (ipn) {
      return artifacts?.filter((auth) => {
        return (
          auth.issuingCountry !== ic ||
          auth.awsPart?.ipn !== ipn ||
          // prevent creating a circular dependency by checking if we have an mpn first
          (mpn && auth.awsPart?.mpn && auth.awsPart?.mpn !== mpn)
        );
      });
    } else if (nonPlmPartName) {
      return artifacts?.filter((auth) => {
        return auth.issuingCountry !== ic || auth.nonPlmPart?.nonPlmPartName !== nonPlmPartName;
      });
    }
  }
  return [];
};

export const buildCards = (artifacts: Asset[]): ArtifactCard[] => {
  if (artifacts.length > 0 && artifacts[0].awsPart) {
    const result = artifacts.map((auth) => {
      return {
        name: getCountryFormat(auth.issuingCountry),
        issuingCountry: auth.issuingCountry,
        issuingCountryFormat: getCountryFormat(auth.issuingCountry),
        part: auth?.awsPart?.mpn ? auth.awsPart.ipn + ':' + auth.awsPart.mpn : auth.awsPart?.ipn + ':',
        ipn: auth?.awsPart?.ipn,
        mpn: auth?.awsPart?.mpn ? auth.awsPart.mpn : '',
        requestId: auth.requestId,
      };
    });
    return result.sort((a, b) => (a.name && b.name && a.name < b?.name ? 1 : -1));
  } else if (artifacts.length > 0 && artifacts[0].nonPlmPart) {
    const result = artifacts.map((auth) => {
      return {
        name: getCountryFormat(auth.issuingCountry),
        issuingCountry: auth.issuingCountry,
        issuingCountryFormat: getCountryFormat(auth.issuingCountry),
        part: auth.nonPlmPart?.nonPlmPartName,
        requestId: auth.requestId,
      };
    });

    return result.sort((a, b) => (a.name && b.name && a.name < b.name ? 1 : -1));
  } else {
    return [];
  }
};

export const getParts = (artifacts: AssetViewModel[]): string[] => {
  if (artifacts[0].awsPart) {
    const set = new Set<string>();
    artifacts.forEach((auth) => {
      if (auth.awsPart?.ipn) set.add(auth.awsPart.mpn ? auth.awsPart.ipn + ':' + auth.awsPart.mpn : auth.awsPart.ipn);
    });
    return Array.from(set.values());
  } else {
    const set = new Set<string>();
    artifacts.forEach((auth) => {
      if (auth.nonPlmPart?.nonPlmPartName) set.add(auth.nonPlmPart?.nonPlmPartName);
    });
    return Array.from(set.values());
  }
};

export const getAWSPartFromProduct = (product: string): AwsPart | undefined => {
  if (product) {
    const awsPart = product.split(':');
    if (awsPart.length === 1) {
      return {
        ipn: awsPart[0],
      };
    } else {
      return {
        ipn: awsPart[0],
        mpn: awsPart[1],
      };
    }
  }
};

export const getNonPlmFromProduct = (product?: string): { nonPlmPartName: string } | undefined => {
  if (product) {
    return { nonPlmPartName: product };
  }
};

export const getCountryFormat = (code?: string | null): string | undefined => {
  const findc = COUNTRIES.find((c) => c.value === code);
  return findc?.label;
};

export const getCountries = (artifacts: AssetViewModel[]): string[] => {
  const set = new Set<string>();

  artifacts.forEach((art) => {
    if (art?.issuingCountry) {
      const countryLabel = getCountryFormat(art?.issuingCountry);
      countryLabel && set.add(countryLabel);
    }
  });
  return Array.from(set.values());
};

/**
 *
 * @param country An alpha-2 iso code for a country
 * @return The Currency for the given country.
 */
export const currencyForCountry = (country: string): Currency => {
  return COUNTRY_CURRENCY_MAPPING[country] as Currency;
};

export const authPath = (category: string): string | undefined => {
  switch (category) {
    case 'compliance':
      return 'CreateCompliance/';
    case 'ielicense':
      return 'CreateIELicense/';
    default:
      break;
  }
};

export const authUpdatePath = (category: string): string | undefined => {
  switch (category) {
    case 'compliance':
      return 'UpdateCompliance/';
    case 'ielicense':
      return 'UpdateIELicense/';
    default:
      break;
  }
};

export const getCombinedSearchResults = (
  licenses: IndexedLicense[],
  assets: AssetsSearchResponse[]
): ArtifactsSearchResult[] => {
  let licensePointer = 0;
  let assetPointer = 0;

  const combinedResults: ArtifactsSearchResult[] = [];
  while (licensePointer < licenses.length && assetPointer < assets.length) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (licenses[licensePointer].score! > assets[assetPointer].score!) {
      combinedResults.push(transformLicenseToCombined(licenses[licensePointer]));
      licensePointer += 1;
    } else {
      combinedResults.push(transformAssetToCombined(assets[assetPointer]));
      assetPointer += 1;
    }
  }

  while (licensePointer < licenses.length) {
    combinedResults.push(transformLicenseToCombined(licenses[licensePointer]));
    licensePointer += 1;
  }

  while (assetPointer < assets.length) {
    combinedResults.push(transformAssetToCombined(assets[assetPointer]));
    assetPointer += 1;
  }

  return combinedResults;
};

export const transformLicenseToCombined = (license: IndexedLicense): ArtifactsSearchResult => {
  return {
    country: getOptionByValue(license?.issuingCountry, COUNTRIES)?.label,
    eccn: license.eccn ? license.eccn.join(',') : '',
    expiration: license.expirationDate ? formatDateFromString(license.expirationDate) : '',
    hts: license.hts ? license.hts.join(', ') : '',
    identifier: license.licenseNumber ?? '',
    ipn: license.ipn ? license.ipn.join(', ') : '',
    mpn: license.mpn ? license.mpn.join(', ') : '',
    type:
      license.transactionDirection === TransactionDirection.EXPORT ? 'Export Authorization' : 'Import Authorization',
    searchFilter: SearchResultFilter.license,
    getRoute: `/license/${license?.licenseId}`,
  };
};

export const transformAssetToCombined = (asset: AssetsSearchResponse): ArtifactsSearchResult => {
  const mpn = asset.mpn ? encodeURIComponent(asset.mpn) : '';
  const nonPlmPartName = asset.nonPlmPartName ? encodeURIComponent(asset.nonPlmPartName) : '';
  const ipn = asset.ipn ? encodeURIComponent(asset.ipn) : '';
  const getRoute = asset.ipn
    ? `/artifact/${asset.requestId}/${asset.issuingCountry}/${ipn}:${mpn}`
    : `/artifact/${asset.requestId}/${asset.issuingCountry}/${nonPlmPartName}`;
  return {
    awsProjectExternal: asset.awsProjectExternal ?? '',
    country: getOptionByValue(asset?.issuingCountry, COUNTRIES)?.label,
    expiration: asset.expirationDate ? formatFromEpoch(asset.expirationDate) : '',
    hts: '',
    identifier: asset.ipn ?? asset.assetId ?? '',
    ipn: asset.ipn ?? '',
    mpn: asset.mpn ?? '',
    status: asset.authorizationStatus ?? '',
    superskuName: asset.superskuName ?? '',
    type: asset.authorizationType ? formatType(asset.authorizationType) : '',
    searchFilter: SearchResultFilter.asset,
    getRoute,
  };
};
