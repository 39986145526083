import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, SpaceBetween } from '@amzn/awsui-components-react';

type FormWrapperProps = {
  isLoading: boolean;
  isMainActionDisabled: boolean;
  submitText: string;
  cancelRoute: string;
};

const FormWrapper: React.FC<FormWrapperProps> = ({
  isLoading,
  cancelRoute,
  submitText,
  isMainActionDisabled,
  children,
}) => {
  return (
    <Form
      actions={
        <SpaceBetween size="xs" direction="horizontal">
          <Link to={cancelRoute}>
            <Button disabled={isLoading} formAction="none">
              Cancel
            </Button>
          </Link>
          <Button
            disabled={isLoading || isMainActionDisabled}
            loading={isLoading}
            formAction="submit"
            variant="primary"
            data-test="submit-form-btn"
          >
            {submitText}
          </Button>
        </SpaceBetween>
      }
    >
      {children}
    </Form>
  );
};

export default FormWrapper;
