import { AppLayout, BreadcrumbGroup, Header } from '@amzn/awsui-components-react';
import { Flashbar } from '@amzn/awsui-components-react/polaris';
import React from 'react';
import { useDispatch } from 'react-redux';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from '../../config/navConfig';
import { setPCClassificationCreateFlashbarItems } from '../../store/pcClassification';
import { useAppSelector } from '../../store/store';
import { flashbarItemsCreator } from '../../utils/formUtils';
import SideNav from '../SideNav';
import ClassificationCreate from './ClassificationCreate';

const ClassificationCreateLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { pcClassificationCreateFlashbarItems } = useAppSelector((state) => state.pcClassificationStore);

  return (
    <AppLayout
      contentType="form"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={<Header variant="h1">Create Classification</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Classifications', href: `/classifications` },
            { text: 'Create', href: '#' },
          ]}
        />
      }
      content={<ClassificationCreate />}
      toolsHide
      notifications={
        <Flashbar
          items={flashbarItemsCreator(
            pcClassificationCreateFlashbarItems,
            dispatch,
            setPCClassificationCreateFlashbarItems
          )}
        />
      }
      stickyNotifications
      ariaLabels={APP_LAYOUT_LABELS}
    />
  );
};

export default ClassificationCreateLayout;
