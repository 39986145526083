import React, { useState } from 'react';
import {
  APPLICANT_TYPES,
  AWS_PROJECT_EXTERNAL,
  ISSUING_BODIES,
  SOURCE,
  STATUS_OPTIONS,
  SUPERSKU_NAMES,
  STANDARDS as standardOptions,
} from '../../utils/constants';
import { authUpdatePath } from '../../utils/artifactHelpers';
import { calendarConvert, convertDate } from '../../utils/dateFormat';
import Button from '@amzn/awsui-components-react/polaris/button';
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import DatePicker from '@amzn/awsui-components-react/polaris/date-picker';
import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import Header from '@amzn/awsui-components-react/polaris/header';
import Select from '@amzn/awsui-components-react/polaris/select';
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import Toggle from '@amzn/awsui-components-react/polaris/toggle';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import './artifact-details-edit.css';
import { createArrayFromMultiselect, createMultiselectItems } from 'utils/polarisHelpers';
import { useDispatch } from 'react-redux';
import { updateArtifactAction } from 'store/artifact/artifactSlice';

const ArtifactEdit = ({ artifact, setArtifactDetails, setArtifactDetailsButton, artifactUiAvailable }) => {
  const dispatch = useDispatch();
  const statusOption = STATUS_OPTIONS.find((s) => s.value === artifact.authorizationStatus);
  const superskuOption = SUPERSKU_NAMES.find((s) => s.value === artifact.superskuName);
  const awsProjectExternalOption = AWS_PROJECT_EXTERNAL.find((s) => s.value === artifact.awsProjectExternal);

  let calendarFormattedExpirationDate;
  if (artifact.expirationDate === 0) {
    calendarFormattedExpirationDate = '';
  } else {
    calendarFormattedExpirationDate = calendarConvert(artifact.expirationDate);
  }
  const { issuingBody: issuingBodyOption, standardsFormatted } = artifact;
  const [authorizationStatus, setAuthorizationStatus] = useState(statusOption);
  const [governmentTrackingNumber, setGovernmentTrackingNumber] = useState(artifact.governmentTrackingNumber);
  const [externalId, setExternalId] = useState(artifact.externalId);
  const [authorizationNotes, setAuthorizationNotes] = useState(artifact.authorizationNotes);
  const [expirationDate, setExpirationDate] = useState(calendarFormattedExpirationDate);
  const [startDate, setStartDate] = useState(calendarConvert(artifact.startDate));
  const [authActive, setAuthActive] = useState(artifact.active);

  const [applicantType, setApplicantType] = useState(APPLICANT_TYPES.find((at) => at.value === artifact.applicantType));
  const [applicantName, setApplicantName] = useState(artifact.applicantName || '');

  const [selStandards, setSelStandards] = useState(createMultiselectItems(standardsFormatted));
  const [issuingBody, setIssuingBody] = useState({ value: issuingBodyOption });
  const [connectAuthSave, setConnectAuthSave] = useState(artifact.cards);
  const [superskuName, setSuperskuName] = useState(superskuOption);
  const [awsProjectExternal, setAwsProjectExternal] = useState(awsProjectExternalOption);
  const [source, setSource] = useState(SOURCE.find((at) => at.value === artifact.source));
  const [otherSourceName, setOtherSourceName] = useState(artifact.otherSourceName || '');

  // 3p
  const [manufacturerName] = useState(artifact.nonPlmPart?.manufacturerName);
  const [manufacturerPartId] = useState(artifact.nonPlmPart?.manufacturerPartId);
  const [description] = useState(artifact.nonPlmPart?.description);
  const [saveLoading, setSaveLoading] = useState(false);
  const [flashMessages, setFlashMessages] = useState([]);
  const [statusError, setStatusError] = useState(false);
  // auth status constants
  const SUBMITTED_STATUS = 'Submitted';
  const APPROVED_STATUS = 'Approved';
  const DENIED_STATUS = 'Denied';
  const APPROVED_W_CONDITIONS_STATUS = 'Approved w/ Conditions';

  const checkAuthStatus = () => {
    if (
      authorizationStatus.label === SUBMITTED_STATUS &&
      (statusOption.label === DENIED_STATUS ||
        statusOption.label === APPROVED_STATUS ||
        statusOption.label === APPROVED_W_CONDITIONS_STATUS)
    ) {
      setStatusError(true);
      return true;
    } else {
      setStatusError(false);
      return false;
    }
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    if (checkAuthStatus()) {
      setSaveLoading(false);
      addFlashMessage(
        'Can not update artifact Status to Submitted from Approved, Approved w/ Conditions, or Denied',
        'error'
      );
      return;
    }
    const startDateUnix = convertDate(startDate);
    let expirationDateUnix;
    if (expirationDate) {
      expirationDateUnix = convertDate(expirationDate);
    }
    // Appsync BFF2 does not like null, if the user doesn't update the value
    // set the value to undefined
    const updateAwsAuth = {
      assetType: artifact.assetType,
      issuingCountry: artifact.issuingCountry,
      authorizationType: artifact.authorizationType,
      subAuthorizationType: artifact.subAuthorizationType ?? undefined,
      awsParts: [
        {
          ipn: artifact.awsPart?.ipn,
          mpn: artifact.awsPart?.mpn ?? undefined,
        },
      ],
      requestId: artifact.requestId,
      active: authActive ? true : false,
      authorizationNotes: authorizationNotes ?? undefined,
      authorizationStatus: authorizationStatus.value,
      startDate: startDateUnix,
      expirationDate: expirationDateUnix,
      externalId: externalId ?? undefined,
      governmentTrackingNumber: governmentTrackingNumber ?? undefined,
      awsProjectExternal: awsProjectExternal?.value,
      superskuName: superskuName?.value,
      source: source?.value,
      otherSourceName: otherSourceName ?? undefined,
    };

    if (artifact.recordType === 'compliance') {
      updateAwsAuth.applicantType = applicantType?.value;
      updateAwsAuth.applicantName = applicantName;
      updateAwsAuth.issuingBody = issuingBody?.value ?? undefined;
      updateAwsAuth.standards = createArrayFromMultiselect(selStandards);
    }

    const update3pAuth = {
      assetType: artifact.assetType,
      issuingCountry: artifact.issuingCountry,
      authorizationType: artifact.authorizationType,
      subAuthorizationType: artifact.subAuthorizationType ?? undefined,
      nonPlmParts: [
        {
          nonPlmPartName: artifact.nonPlmPart?.nonPlmPartName,
          description: artifact.nonPlmPart?.description,
          manufacturerName: artifact.nonPlmPart?.manufacturerName ?? undefined,
          manufacturerPartId: artifact.nonPlmPart?.manufacturerPartId ?? undefined,
        },
      ],
      requestId: artifact.requestId,
      active: authActive ? true : false,
      authorizationNotes: authorizationNotes ?? undefined,
      authorizationStatus: authorizationStatus.value,
      startDate: startDateUnix,
      expirationDate: expirationDateUnix,
      externalId: externalId ?? undefined,
      governmentTrackingNumber: governmentTrackingNumber ?? undefined,
      source: source?.value,
      otherSourceName: otherSourceName ?? undefined,
    };

    if (artifact.recordType === 'compliance') {
      update3pAuth.applicantType = applicantType?.value;
      update3pAuth.applicantName = applicantName;
      update3pAuth.issuingBody = issuingBody?.value ?? undefined;
      update3pAuth.standards = createArrayFromMultiselect(selStandards);
    }

    const path = authUpdatePath(`${artifact.recordType}`);
    let part;

    if (artifact.awsPart) {
      part = artifact.awsPart.mpn ? artifact.awsPart.ipn + ':' + artifact.awsPart.mpn : artifact.awsPart.ipn;
    } else {
      part = {
        nonPlmPartName: artifact.nonPlmPart.nonPlmPartName,
      };
    }

    let updateAuth;
    if (artifact.awsPart) {
      updateAuth = updateAwsAuth;
    } else {
      updateAuth = update3pAuth;
    }

    try {
      const result = await dispatch(updateArtifactAction({ path, updateArtifact: updateAuth })).unwrap();
      if (typeof result === 'object') {
        setArtifactDetails('view');
        setArtifactDetailsButton('Edit Details');
      } else {
        addFlashMessage('Did not update: ' + artifact.issuingCountryFormat + ' for ' + part + ':' + result, 'error');
      }
      // @TODO:  MOVE this to BFF, in api
      // for now, will handle the multiple calls
      connectAuthSave.forEach((auth) => {
        updateConnectedAuth(path, updateAuth, auth);
      });

      setSaveLoading(false);
    } catch (error) {
      addFlashMessage('Did not update: ' + artifact.issuingCountryFormat + ' for ' + part + ' : ' + error, 'error');
      return false;
    }
  };

  const updateConnectedAuth = async (path, updateAuth, auth) => {
    const connectedAuth = { ...updateAuth };
    connectedAuth.issuingCountry = auth.issuingCountry;

    // check for mpn - if we don't have one we don't send it
    if (connectedAuth.awsParts) {
      connectedAuth.awsParts = [
        {
          ipn: auth.ipn,
          ...(auth.mpn && { mpn: auth.mpn }),
        },
      ];
    } else {
      connectedAuth.nonPlmParts = [
        {
          nonPlmPartName: auth.part,
          description: description,
          manufacturerName: manufacturerName ?? undefined,
          manufacturerPartId: manufacturerPartId ?? undefined,
        },
      ];
    }

    try {
      // Async call to promise return as the first record was successful
      // assuming all will be
      await dispatch(updateArtifactAction({ path, updateArtifact: connectedAuth })).unwrap();
    } catch (error) {
      return false;
    }
  };

  const setConnectAuthToggle = (e, auth) => {
    e.preventDefault();

    // Is in array?
    const isFound = connectAuthSave.find((a) => a.issuingCountry === auth.issuingCountry && a.part === auth.part);

    // Add to array
    if (e.detail.checked && !isFound) {
      setConnectAuthSave((old) => [...old, auth]);
    }

    // Remove from array
    if (!e.detail.checked && isFound) {
      let newConnectAuth = connectAuthSave;
      newConnectAuth = newConnectAuth.filter((a) => a !== auth);
      setConnectAuthSave(newConnectAuth);
    }
  };

  const connectedAuthorizations = artifact.cards.map((auth) => {
    const key = auth.issuingCountry + ':' + auth.part;

    let checkedAuth = false;
    if (connectAuthSave.find((a) => a.issuingCountry === auth.issuingCountry && a.part === auth.part)) {
      checkedAuth = true;
    }

    return (
      <div key={key}>
        <Checkbox
          onChange={(e) => setConnectAuthToggle(e, auth)}
          checked={checkedAuth}
          data-test="connected-auth-checkbox"
        >
          {auth.issuingCountryFormat} ({auth.part})
        </Checkbox>
      </div>
    );
  });

  const addFlashMessage = (message, type) => {
    setFlashMessages((old) => [
      ...old,
      {
        type: type,
        content: <div>{message}</div>,
      },
    ]);
  };

  const otherSourceNameField =
    source?.value === 'OTHER' ? (
      <>
        <br />
        <FormField label="Other source name">
          <Input
            value={otherSourceName}
            ariaLabel="Other source name"
            onChange={({ detail }) => setOtherSourceName(detail.value)}
          />
        </FormField>
      </>
    ) : null;

  const addNonPlmFields = artifact.nonPlmPart ? (
    <>
      <div>
        <div>Manufacturer Name</div>
        <p className="authorization-details-cell">{artifact.nonPlmPart.manufacturerName}</p>
      </div>

      <div>
        <div>Model Number</div>
        <p className="authorization-details-cell">{artifact.nonPlmPart.manufacturerPartId}</p>
      </div>
      <div>
        <div>Non-Agile Part Description</div>
        <p className="authorization-details-cell">{artifact.nonPlmPart.description}</p>
      </div>
    </>
  ) : null;

  const addFields =
    artifact.recordType === 'compliance' ? (
      <>
        <div>
          <FormField label={<span>Applicant Type</span>}>
            <Select
              placeholder="Choose an applicant type"
              ariaLabel="Choose an applicant type"
              selectedAriaLabel="Selected"
              selectedOption={applicantType}
              options={APPLICANT_TYPES}
              onChange={({ detail }) => setApplicantType(detail.selectedOption)}
            />
          </FormField>
          <br />
          <FormField
            label={
              <span>
                Applicant Name <i>- optional</i>{' '}
              </span>
            }
          >
            <Input
              value={applicantName}
              ariaLabel="Applicant name"
              onChange={({ detail }) => setApplicantName(detail.value)}
            />
          </FormField>
        </div>
        <div data-test="issuingBody-field">
          <FormField label="Issuing Body">
            <Select
              filteringType="auto"
              placeholder="Choose an issuing body"
              selectedOption={issuingBody?.value ? issuingBody : undefined}
              onChange={({ detail }) => {
                setIssuingBody(detail.selectedOption);
              }}
              options={ISSUING_BODIES}
              selectedAriaLabel="Selected"
            />
          </FormField>
        </div>
        <div>
          <FormField
            label={
              <span>
                Standards <i>- optional</i>{' '}
              </span>
            }
            data-test="standards-field"
          >
            <Multiselect
              onChange={({ detail }) => {
                setSelStandards(detail.selectedOptions);
              }}
              selectedOptions={selStandards}
              options={standardOptions}
              filteringType="auto"
              ariaLabel="Choose standards"
              filteringPlaceholder="Search"
              placeholder="Choose standards"
              noMatch="No matches found"
              selectedAriaLabel="Selected"
            />
          </FormField>
        </div>
      </>
    ) : null;

  return (
    <>
      <ColumnLayout borders="horizontal" columns={4} variant="text-grid">
        <div data-test="supersku-edit">
          <FormField label={<span>Supersku Name</span>}>
            <Select
              filteringType="auto"
              placeholder="Select a Supersku"
              options={SUPERSKU_NAMES}
              selectedOption={superskuName}
              selectedAriaLabel="Selected"
              onChange={({ detail }) => setSuperskuName(detail.selectedOption)}
            />
          </FormField>
        </div>
        <div data-test="aws-project-external-edit">
          <FormField label={<span>AWS Project External</span>}>
            <Select
              filteringType="auto"
              placeholder="Select AWS Project External"
              options={AWS_PROJECT_EXTERNAL}
              selectedOption={awsProjectExternal}
              selectedAriaLabel="Selected"
              onChange={({ detail }) => setAwsProjectExternal(detail.selectedOption)}
              virtualScroll
            />
          </FormField>
        </div>
        <div>
          <FormField
            label={
              <span>
                Government Tracking <i>- optional</i>{' '}
              </span>
            }
            data-test="gov-track"
          >
            <Input
              name="governmentTrackingNumber"
              value={governmentTrackingNumber}
              onChange={({ detail }) => setGovernmentTrackingNumber(detail.value)}
            />
          </FormField>
        </div>
        <div>
          <FormField
            label={
              <span>
                External Id <i>- optional</i>{' '}
              </span>
            }
            data-test="external-id"
          >
            <Input name="externalId" value={externalId} onChange={({ detail }) => setExternalId(detail.value)} />
          </FormField>
        </div>
        <div>
          <div data-test="status-field">
            <FormField label="Status">
              <Select
                selectedOption={authorizationStatus}
                onChange={({ detail }) => {
                  setAuthorizationStatus(detail.selectedOption);
                  setStatusError(false);
                }}
                options={STATUS_OPTIONS}
                selectedAriaLabel="Selected"
                invalid={statusError}
                placeholder="Choose a status"
              />
            </FormField>
          </div>
        </div>
        <div>
          <FormField label="Effective Date" data-test="effective-date-picker">
            <DatePicker
              onChange={({ detail }) => setStartDate(detail.value)}
              value={startDate}
              openCalendarAriaLabel={(selectedDate) =>
                'Choose Date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
              }
              nextMonthAriaLabel="Next month"
              previousMonthAriaLabel="Previous month"
              todayAriaLabel="Today"
              placeholder="YYYY/MM/DD"
            />
          </FormField>
        </div>
        <div>
          <FormField
            label={
              <span>
                Expiration Date <i>- optional</i>{' '}
              </span>
            }
            data-test="expiration-date-picker"
          >
            <DatePicker
              onChange={({ detail }) => setExpirationDate(detail.value)}
              value={expirationDate}
              openCalendarAriaLabel={(selectedDate) =>
                'Choose Date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
              }
              nextMonthAriaLabel="Next month"
              previousMonthAriaLabel="Previous month"
              todayAriaLabel="Today"
              placeholder="YYYY/MM/DD"
            />
          </FormField>
        </div>
        <div>
          <FormField
            label={
              <span>
                Notes <i>- optional</i>{' '}
              </span>
            }
            data-test="notes"
          >
            <Textarea
              name="authorizationNotes"
              value={authorizationNotes}
              onChange={({ detail }) => setAuthorizationNotes(detail.value)}
            />
          </FormField>
        </div>
        <div>
          <FormField label="Active" data-test="toggle">
            <Toggle onChange={({ detail }) => setAuthActive(detail.checked)} checked={authActive} />
          </FormField>
        </div>
        <div>
          <FormField label="Source">
            <Select
              placeholder="Choose artifact source"
              ariaLabel="Choose an artifact source"
              selectedAriaLabel="Selected"
              selectedOption={source}
              options={SOURCE}
              onChange={({ detail }) => setSource(detail.selectedOption)}
            />
          </FormField>
          {otherSourceNameField}
        </div>
        {addFields}
        {addNonPlmFields}
      </ColumnLayout>
      <br />
      <Header variant="h2">Update connected artifacts</Header>
      {connectedAuthorizations}
      <div className="auth-details-edit-save">
        <Button
          data-test="save-button"
          ariaLabel="Save Changes"
          id="saveChanges"
          variant="primary"
          onClick={(e) => {
            saveChanges(e);
          }}
          loading={saveLoading}
          // disabled={!artifactUiAvailable}
          disabled={true || !artifactUiAvailable}
        >
          Save Changes
        </Button>
      </div>
      <br />
      {/* TODO: Flash messages are no longer seen because the user moves directly back to view -- there is currently no feedback for updating auths with these hidden */}
      <Flashbar items={flashMessages} />
    </>
  );
};

export default ArtifactEdit;
