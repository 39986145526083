import { getCountryFormat } from './artifactHelpers';
import { PROGRAM_OPTIONS } from './constants';

export const formatClassification = (classification) => {
  classification.countryISO2Format = getCountryFormat(classification.countryISO2);
  classification.programsFormatted = getPrograms(classification.programs);
  return classification;
};
export const getPrograms = (e) => {
  const programs = e?.map((program) => {
    const getLabel = PROGRAM_OPTIONS.filter((at) => at.value === program);
    return getLabel.map((programLabel) => {
      return programLabel.label;
    });
  });
  const programList = programs?.flat();
  if (programList) {
    return programList;
  } else {
    return;
  }
};

export const formatESResults = (classifications) => {
  return classifications.map((classification) => {
    return formatClassification(classification);
  });
};
