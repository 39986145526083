import * as z from 'zod';
import { getOptionByValue, optionDefinitionSchema } from '../../utils/formUtils';
import { PcClassificationInput, PccPartIdentifierType, PccPartSource } from '@amzn/d2d-bff-schema';
import { PCC_PART_IDENTIFIER_TYPES, PCC_PART_SOURCES } from '../../utils/constants';
import { ZodType } from 'zod';
import { EnumDisplayMapping } from '../../types';

export type PCCPartFormInput = z.infer<typeof pccPartFormSchema>;

const ILLEGAL_CHARACTERS = ['`', ';', '"', '^', "'"].join('');
const VALID_FREE_TEXT_FORMAT = new RegExp(`^[^${ILLEGAL_CHARACTERS}]+$`);

export const pccPartFormSchema = z.object({
  partDescription: z
    .string()
    .regex(VALID_FREE_TEXT_FORMAT, `Cannot contain the following characters: ${ILLEGAL_CHARACTERS}`)
    .max(1000)
    .or(z.literal('').nullable())
    .optional(),
  partIdentifierType: optionDefinitionSchema,
  partIdentifierValue: z
    .string()
    .min(1, 'Required')
    .max(1000)
    .regex(VALID_FREE_TEXT_FORMAT, `Cannot contain the following characters: ${ILLEGAL_CHARACTERS}`),
  partSource: optionDefinitionSchema,
  pcClassifications: z.array(optionDefinitionSchema).nonempty('At least one classification must be chosen.'),
});

/** Input to PC Classify a part */
export const pccPartFormSchemaToApiSchema = pccPartFormSchema.transform((pccPart) => {
  return {
    ...pccPart,
    pcClassifications: pccPart.pcClassifications.map((pcc) => {
      return { pcCategory: pcc.value } as PcClassificationInput;
    }),
    partDescription: pccPart.partDescription ?? undefined,
    partIdentifierType: pccPart.partIdentifierType?.value as PccPartIdentifierType,
    partSource: pccPart.partSource.value as PccPartSource,
  };
});

const classificationSchema = z.object({
  pcCategory: z.string(),
  createdAt: z.number(),
  createdBy: z.string(),
});

export const pccPartApiSchema = pccPartFormSchema.extend({
  partId: z.string(),
  partSource: z.string(),
  partIdentifierType: z.string(),
  partIdentifierValue: z.string(),
  partDescription: z.string().optional().nullable(),
  createdAt: z.number(),
  createdBy: z.string(),
  classificationStatus: z.string(),
  lastClassifiedBy: z.string(),
  lastClassifiedAt: z.number(),
  pcClassifications: z.array(classificationSchema),
});

/**
 *
 * @param formattedPccCodes list of PccCodes to use to assign labels to pc classification values
 * @returns ZodType of the form schema
 */
export const pccPartApiSchemaToFormSchema = (formattedPccCodes: EnumDisplayMapping[]): ZodType => {
  return pccPartApiSchema.transform((pccPart) => {
    return {
      ...pccPart,
      partIdentifierType: PCC_PART_IDENTIFIER_TYPES.find((p) => p.value === pccPart.partIdentifierType),
      partSource: PCC_PART_SOURCES.find((p) => p.value === pccPart.partSource),
      pcClassifications: pccPart.pcClassifications?.map((p) => {
        return {
          value: p.pcCategory,
          label: getOptionByValue(p.pcCategory, formattedPccCodes)?.label ?? p.pcCategory,
        };
      }),
    };
  });
};
