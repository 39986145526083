import React from 'react';
import { AppLayout, BreadcrumbGroup, Flashbar, Header, Spinner } from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import LMUsageCreateContent from './LicenseUsageCreate';
import { flashbarItemsCreator } from 'utils/formUtils';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { setLMCreateFlashbarItems } from 'store/licenseManager';
import { useParams } from 'react-router-dom';
import useValidateLicenseParams from '../../hooks/useValidateLicenseParams';

const LicenseUsageCreateLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { licenseId } = useParams();
  const { isLoading, license } = useValidateLicenseParams(licenseId);
  const { lmCreateFlashbarItems } = useAppSelector((state) => state.licenseManagerStore);

  return (
    <AppLayout
      contentType="form"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={<Header variant="h1">Create Authorization Usage</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Artifact Authorizations', href: `/artifacts` },
            { text: licenseId ?? '', href: `/license/${licenseId}` },
            { text: 'Create Usage', href: '#' },
          ]}
        />
      }
      content={isLoading ? <Spinner size="large" /> : <LMUsageCreateContent license={license} />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar items={flashbarItemsCreator(lmCreateFlashbarItems, dispatch, setLMCreateFlashbarItems)} />
      }
      stickyNotifications
      toolsHide
    />
  );
};

export default LicenseUsageCreateLayout;
