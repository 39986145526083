import { getPartFamilyLabel } from './artifactsV2Utils';
import { CharacterClass } from '@amzn/d2d-bff-schema';
import { EnumDisplayMapping } from '../../types';

export const DEFAULT_NON_ERROR_OBJECT = { message: '', hasError: false };

export const PART_FAMILY_CHARACTER_CLASSES: EnumDisplayMapping[] = [
  CharacterClass.ALPHA,
  CharacterClass.NUMERIC,
  CharacterClass.SPACE,
  CharacterClass.BLANK,
  CharacterClass.NUMBER_SIGN,
  CharacterClass.HYPHEN,
  CharacterClass.A,
  CharacterClass.B,
  CharacterClass.C,
  CharacterClass.D,
  CharacterClass.E,
  CharacterClass.F,
  CharacterClass.G,
  CharacterClass.H,
  CharacterClass.I,
  CharacterClass.J,
  CharacterClass.K,
  CharacterClass.L,
  CharacterClass.M,
  CharacterClass.N,
  CharacterClass.O,
  CharacterClass.P,
  CharacterClass.Q,
  CharacterClass.R,
  CharacterClass.S,
  CharacterClass.T,
  CharacterClass.U,
  CharacterClass.V,
  CharacterClass.W,
  CharacterClass.X,
  CharacterClass.Y,
  CharacterClass.Z,
  CharacterClass.ZERO,
  CharacterClass.ONE,
  CharacterClass.TWO,
  CharacterClass.THREE,
  CharacterClass.FOUR,
  CharacterClass.FIVE,
  CharacterClass.SIX,
  CharacterClass.SEVEN,
  CharacterClass.EIGHT,
  CharacterClass.NINE,
].map((characterClass) => ({
  value: characterClass,
  label: getPartFamilyLabel(characterClass as CharacterClass),
}));

export const artifactsV2KeyToLabel: Map<string, string> = new Map<string, string>([
  ['ipn', 'IPNs'],
  ['mpn', 'MPNs'],
  ['expiration', 'Expiration Date'],
  ['projectExternal', 'AWS Project Externals'],
  ['superSku', 'SuperSKUs'],
  ['partFamilies', 'Part Families'],
  ['type', 'Type'],
  ['subtype', 'Subtype'],
  ['applicableCountries', 'Applicable Countries'],
  ['standards', 'Standards'],
  ['lastModifiedAt', 'Last Modified'],
]);
