import React, { useEffect } from 'react';
import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Flashbar,
  Header,
  PropertyFilterProps,
  SpaceBetween,
  Spinner,
  TableProps,
} from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import LMDetailContent from './LicenseManagerDetails';
import { Link, useParams } from 'react-router-dom';
import useValidateLicenseParams from 'hooks/useValidateLicenseParams';
import { COUNTRIES, FILTER_OPERATORS } from 'utils/constants';
import { Maybe, Usage } from '@amzn/d2d-bff-schema';
import { flashbarItemsCreator, getOptionByValue } from 'utils/formUtils';
import { useAppSelector } from 'store/store';
import { useDispatch } from 'react-redux';
import { setLMDetailFlashbarItems } from 'store/licenseManager';
import getSymbolFromCurrency from 'currency-symbol-map';

export const USAGE_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Maybe<Usage>>[] = [
  {
    id: 'importCountry',
    header: 'Import Country',
    cell: (item) => getOptionByValue(item?.importCountry, COUNTRIES)?.label,
    sortingField: 'importCountry',
  },
  {
    id: 'exportCountry',
    header: 'Export Country',
    cell: (item) => getOptionByValue(item?.exportCountry, COUNTRIES)?.label,
    sortingField: 'exportCountry',
  },
  {
    id: 'ipn',
    header: 'IPN',
    cell: (item) => item?.ipn,
    sortingField: 'ipn',
  },
  {
    id: 'mpn',
    header: 'MPN',
    cell: (item) => item?.mpn,
    sortingField: 'mpn',
  },
  {
    id: 'hts',
    header: 'HTS',
    cell: (item) => item?.hts,
    sortingField: 'hts',
  },
  {
    id: 'eccn',
    header: 'ECCN',
    cell: (item) => item?.eccn,
    sortingField: 'eccn',
  },
  {
    id: 'value',
    header: 'Value',
    cell: (item) => {
      return `${getSymbolFromCurrency(item?.valueCurrency ?? '') ?? ''}${item?.value} ${item?.valueCurrency}`;
    },
    sortingField: 'value',
  },
  {
    id: 'quantity',
    header: 'Quantity',
    cell: (item) => item?.quantity,
    sortingField: 'quantity',
  },
];

export const LICENSE_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: 'importCountry',
    operators: FILTER_OPERATORS,
    propertyLabel: 'Import Country',
    groupValuesLabel: 'Import Country values',
  },
  {
    key: 'exportCountry',
    operators: FILTER_OPERATORS,
    propertyLabel: 'Export Country',
    groupValuesLabel: 'Export Country values',
  },
  {
    key: 'ipn',
    operators: FILTER_OPERATORS,
    propertyLabel: 'IPN',
    groupValuesLabel: 'IPN values',
  },
  {
    key: 'mpn',
    operators: FILTER_OPERATORS,
    propertyLabel: 'MPN',
    groupValuesLabel: 'MPN values',
  },
  {
    key: 'hts',
    operators: FILTER_OPERATORS,
    propertyLabel: 'HTS',
    groupValuesLabel: 'HTS values',
  },
  {
    key: 'eccn',
    operators: FILTER_OPERATORS,
    propertyLabel: 'ECCN',
    groupValuesLabel: 'ECCN values',
  },
  {
    key: 'value',
    operators: FILTER_OPERATORS,
    propertyLabel: 'Value',
    groupValuesLabel: 'Usage Value values',
  },
  {
    key: 'quantity',
    operators: [':', '!:'],
    propertyLabel: 'Quantity',
    groupValuesLabel: 'Quantity values',
  },
];

const LicenseManagerDetailLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { licenseId } = useParams<string>();
  const { isLoading, license } = useValidateLicenseParams(licenseId);
  const { lmDetailFlashbarItems } = useAppSelector((state) => state.licenseManagerStore);

  // clear detail page flashbar items on component unmount
  useEffect(() => {
    return () => {
      dispatch(setLMDetailFlashbarItems([]));
    };
  }, [dispatch]);

  return (
    <AppLayout
      contentType="table"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={
        <Header
          variant="h1"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Link to={`/artifacts`}>
                <Button>Search</Button>
              </Link>
              <Link to={`/edit/license/${licenseId}`}>
                <Button>Edit</Button>
              </Link>
            </SpaceBetween>
          }
        >
          License Details
        </Header>
      }
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Artifacts', href: `/artifacts` },
            { text: 'Authorization Details', href: '#' },
            { text: licenseId || '', href: '#' },
          ]}
        />
      }
      content={isLoading ? <Spinner size="large" /> : <LMDetailContent license={license} />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar items={flashbarItemsCreator(lmDetailFlashbarItems, dispatch, setLMDetailFlashbarItems)} />
      }
      stickyNotifications
      toolsHide
    />
  );
};

export default LicenseManagerDetailLayout;
