import * as z from 'zod';
import { ZodType } from 'zod';
import { optionDefinitionSchema } from '../../../utils/formUtils';
import { Artifact, CharacterClass, FieldType, MetadataFieldAndLabel, MetadataFieldSchema } from '@amzn/d2d-bff-schema';
import { formatArtifactsV2StringDate, getLabel, parseMetadataValue } from '../artifactsV2Utils';
import { POLARIS_DATE_PATTERN_MOMENT } from '../../../utils/dateFormat';
import { ARTIFACT_MENU_OPTIONS, ARTIFACT_SUBTYPE_MENU_OPTIONS, COUNTRIES } from '../../../utils/constants';

export type ArtifactsV2FormInput = z.infer<typeof artifactsV2FormSchema>;

export const artifactsV2FormSchema = z.object({
  fileUpload: z.any().optional(),
  type: optionDefinitionSchema,
  subtype: optionDefinitionSchema,
  applicableCountries: z.array(optionDefinitionSchema).min(1, 'Required'),
  standards: z.array(optionDefinitionSchema).optional(),
  expiration: z.string().optional(),
  ipns: z.array(optionDefinitionSchema).optional(),
  projectExternals: z.array(optionDefinitionSchema).optional(),
  superSkus: z.array(optionDefinitionSchema).optional(),
  mpns: z.array(z.string()).optional(),
  partFamilies: z
    .array(
      z.object({
        userPattern: z.string(),
        replacementCharacters: z.array(
          z.object({
            character: z.string(),
            characterClasses: z.array(z.nativeEnum(CharacterClass)),
          })
        ),
      })
    )
    .optional(),
});

export const artifactsV2FormSchemaToApiSchema = artifactsV2FormSchema.transform((formData) => {
  return {
    metadata: [
      {
        key: 'type',
        value: [formData.type.value],
      },
      {
        key: 'subtype',
        value: [formData.subtype.value],
      },
      {
        key: 'applicableCountries',
        value: formData.applicableCountries?.map((country) => country.value) ?? [],
      },
      {
        key: 'standards',
        value: formData.standards?.map((standard) => standard.value) ?? [],
      },
      {
        key: 'expiration',
        value: formData.expiration ? [new Date(formData.expiration).toISOString()] : [],
      },
      {
        key: 'ipn',
        value: formData.ipns?.map((ipn) => ipn.value) ?? [],
      },
      {
        key: 'projectExternal',
        value: formData.projectExternals?.map((projectExternal) => projectExternal.value) ?? [],
      },
      {
        key: 'superSku',
        value: formData.superSkus?.map((superSku) => superSku.value) ?? [],
      },
      {
        key: 'mpn',
        value: formData.mpns ?? [],
      },
    ].filter((metadata) => metadata.value.length),
    partFamilies: formData.partFamilies ?? [],
  };
});

export type ArtifactsV2FormInputs = z.infer<typeof artifactsV2FormSchema>;

const metadataField = z.object({
  key: z.string(),
  value: z.array(z.string()),
  label: z.string().optional(),
});

const metadataFieldSchema = z.object({
  key: z.string(),
  required: z.boolean(),
  type: z.nativeEnum(FieldType),
  validation: z.string().optional().nullable(),
});

export const artifactsV2ApiSchema = z.object({
  metadata: z.object({
    data: z.array(metadataField),
    schema: z.array(metadataFieldSchema),
  }),
  changelog: z.array(
    z.object({
      modifiedAt: z.string(),
      modifiedBy: z.string(),
      metadata: z
        .object({
          after: z.array(metadataField),
          before: z.array(metadataField),
        })
        .optional()
        .nullable(),
      partFamilies: z
        .object({
          after: z.array(
            z.object({
              userPattern: z.string(),
              replacementCharacters: z.array(
                z.object({
                  character: z.string(),
                  characterClasses: z.array(z.nativeEnum(CharacterClass)),
                })
              ),
            })
          ),
          before: z.array(
            z.object({
              userPattern: z.string(),
              replacementCharacters: z.array(
                z.object({
                  character: z.string(),
                  characterClasses: z.array(z.nativeEnum(CharacterClass)),
                })
              ),
            })
          ),
        })
        .optional()
        .nullable(),
      files: z
        .object({
          after: z.array(
            z.object({ checksum: z.string(), cvAttachmentId: z.string(), name: z.string(), uploadDate: z.string() })
          ),
          before: z.array(
            z.object({ checksum: z.string(), cvAttachmentId: z.string(), name: z.string(), uploadDate: z.string() })
          ),
        })
        .optional()
        .nullable(),
      archived: z
        .object({
          after: z.boolean(),
          before: z.boolean(),
        })
        .optional()
        .nullable(),
    })
  ),
  files: z.array(
    z.object({ checksum: z.string(), cvAttachmentId: z.string(), name: z.string(), uploadDate: z.string() })
  ),
  latestFile: z.object({ checksum: z.string(), cvAttachmentId: z.string(), name: z.string(), uploadDate: z.string() }),
  archived: z.boolean(),
  id: z.string(),
  partFamilies: z.array(
    z.object({
      userPattern: z.string(),
      replacementCharacters: z.array(
        z.object({
          character: z.string(),
          characterClasses: z.array(z.nativeEnum(CharacterClass)),
        })
      ),
    })
  ),
  createdAt: z.string(),
  createdBy: z.string(),
  lastModifiedAt: z.string(),
  lastModifiedBy: z.string(),
});

export const artifactsV2ApiSchemaToFormSchema = (): ZodType => {
  return artifactsV2ApiSchema.transform((artifact: Artifact) => {
    // We need to convert the undetermined metadata fields from the back-end into form fields
    const metadataLookup: Map<string, MetadataFieldAndLabel> = new Map<string, MetadataFieldAndLabel>();
    artifact.metadata.data.forEach((field) => {
      metadataLookup.set(field.key, {
        key: field.key,
        value: field.value,
        label: field.label,
      });
    });

    const schemaLookup: Map<string, MetadataFieldSchema> = new Map<string, MetadataFieldSchema>();
    artifact?.metadata.schema?.forEach((metadataFieldSchema) => {
      schemaLookup.set(metadataFieldSchema.key, metadataFieldSchema as MetadataFieldSchema);
    });

    const applicableCountries = parseMetadataValue('applicableCountries', artifact);
    const standards = parseMetadataValue('standards', artifact);
    const ipns = parseMetadataValue('ipn', artifact);
    const projectExternals = parseMetadataValue('projectExternal', artifact);
    const superSkus = parseMetadataValue('superSku', artifact);
    const mpns = parseMetadataValue('mpn', artifact);
    const type = metadataLookup.get('type')?.value;
    const subtype = metadataLookup.get('subtype')?.value;
    const expiration = metadataLookup.get('expiration')?.value;

    return {
      partFamilies: artifact.partFamilies,
      fileUpload: [],
      type: {
        value: type?.length ? type[0] : undefined,
        label: getLabel(type?.length ? type[0] : '', ARTIFACT_MENU_OPTIONS),
      },
      subtype: {
        value: subtype?.length ? subtype[0] : undefined,
        label: getLabel(subtype?.length ? subtype[0] : '', ARTIFACT_SUBTYPE_MENU_OPTIONS),
      },
      applicableCountries: applicableCountries
        ? applicableCountries.map((item) => ({
            value: item,
            label: getLabel(item as string, COUNTRIES),
          }))
        : undefined,
      standards: standards
        ? standards.map((item) => ({
            value: item,
            label: item,
          }))
        : undefined,
      ipns: ipns
        ? ipns.map((item) => ({
            value: item,
            label: item,
          }))
        : undefined,
      projectExternals: projectExternals
        ? projectExternals.map((item) => ({
            value: item,
            label: item,
          }))
        : undefined,
      superSkus: superSkus
        ? superSkus.map((item) => ({
            value: item,
            label: item,
          }))
        : undefined,
      mpns: mpns,

      expiration: expiration?.length
        ? formatArtifactsV2StringDate(expiration[0], POLARIS_DATE_PATTERN_MOMENT)
        : undefined,
    };
  });
};
