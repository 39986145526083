import React from 'react';
import { AppLayout, Box, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import ArtifactsV2Edit from './ArtifactV2Edit';
import { useAppSelector } from 'store/store';
import { createArtifactsReadonlyWarningItem, flashbarItemsCreator } from 'utils/formUtils';
import { setArtifactV2EditFlashbarItems } from '../../../store/artifactv2';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import { FeatureFlag } from '../../../utils/constants';

const ArtifactV2EditLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { artifactV2EditFlashbarItems } = useAppSelector((state) => state.artifactV2Store);
  const artifactV2Available = useFeatureFlag(FeatureFlag.ARTIFACTS_V2, true).boolValue ?? true;

  /** get part source, part identifier type and part identifier value from path params */
  const { artifactId } = useParams<string>();

  const flashBarItemsWithHardcodedMessage = [...artifactV2EditFlashbarItems];
  const readonlyWarning = createArtifactsReadonlyWarningItem(artifactV2Available);
  if (!flashBarItemsWithHardcodedMessage.find((items) => items.header === readonlyWarning.header)) {
    flashBarItemsWithHardcodedMessage.push(readonlyWarning);
  }

  return (
    <AppLayout
      contentType="form"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={<Header variant="h1">Edit Artifact</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Artifacts', href: '/artifacts/v2' },
            {
              text: `${artifactId}`,
              href: `/artifacts/v2/${artifactId}`,
            },
            { text: 'Edit Artifact', href: '#' },
          ]}
        />
      }
      content={
        artifactId ? <ArtifactsV2Edit artifactId={artifactId} /> : <Box variant={'h1'}>No artifact ID provided</Box>
      }
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar
          items={flashbarItemsCreator(flashBarItemsWithHardcodedMessage, dispatch, setArtifactV2EditFlashbarItems)}
        />
      }
      stickyNotifications
      toolsHide
    />
  );
};

export default ArtifactV2EditLayout;
