import { PropertyFilterProps } from '@amzn/awsui-components-react';
import { Currency, PccPartIdentifierType, PccPartSource } from '@amzn/d2d-bff-schema';
import {
  BooleanEnumDisplayMapping,
  CountryEnumDisplayMapping,
  EnumDisplayMapping,
  HideableEnumDisplayMapping,
} from 'types';

export const INIT = 'INIT';
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';

// Loading states
export const PENDING = 'pending';
export const REJECTED = 'rejected';
export const FULFILLED = 'fulfilled';

export const FILTER_OPERATORS: PropertyFilterProps.ComparisonOperator[] = ['=', '!=', ':', '!:'];

export const SEARCH_QUERY_PARAMETER = 'q';

// Classification Program Options
export const PROGRAM_OPTIONS: EnumDisplayMapping[] = [
  { label: 'Data Center: Media Custody', value: 'dc_media_custody' },
  { label: 'Data Center: Media Destruction', value: 'dc_media_destruction' },
  { label: 'Data Center: Operations', value: 'dc_operations' },
  { label: 'Data Center: Winston Wolfe', value: 'dc_winston_wolfe' },
  { label: 'Infrastructure: Edge', value: 'infrastructure_edge' },
  { label: 'Infrastructure: Outpost', value: 'infrastructure_outpost' },
  { label: 'Infrastructure: PoP', value: 'infrastructure_pop' },
  { label: 'Infrastructure: Region', value: 'infrastructure_region' },
  { label: 'Infrastructure: Sapphire', value: 'infrastructure_sapphire' },
  { label: 'Infrastructure: Sparetacus', value: 'infrastructure_sparetacus' },
  { label: 'Infrastructure: TOA', value: 'infrastructure_toa' },
  { label: 'Machine Learning: DeepX', value: 'ml_deepx' },
  { label: 'Machine Monitoring: Castle', value: 'mm_castle' },
  { label: 'Machine Monitoring: Thor', value: 'mm_thor' },
  { label: 'Mailroom: Business Use', value: 'mailroom_bus_use' },
  { label: 'Satellite: Ground Station', value: 'sat_ground_station' },
  { label: 'Storage: Snowx', value: 'storage_snowx' },
  { label: 'Subsidiary: Annapurna', value: 'subsid_annapurna' },
  { label: 'Subsidiary: Elemental', value: 'subsid_elemental' },
  { label: 'Subsidiary: Twitch', value: 'subsid_twitch' },
];

export const JUSTIFICATION_OPTIONS: EnumDisplayMapping[] = [
  { label: 'AMCOP', value: 'amcop' },
  { label: 'Fiber Optic Cables', value: 'fiber_optic_cables' },
  { label: 'HDD', value: 'hdd' },
  { label: 'K2 Family', value: 'k2_family' },
  { label: 'Monitron', value: 'monitron' },
  { label: 'Motherboard', value: 'motherboard' },
  { label: 'Optical Module', value: 'optical_module' },
  { label: 'Patch Panel', value: 'patch_panel' },
  { label: 'Racks', value: 'racks' },
  { label: 'Raspberry Pi 3 Kit', value: 'raspberry_pi_3_kit' },
  { label: 'Router', value: 'router' },
  { label: 'SSD', value: 'ssd' },
  { label: 'Snow Devices', value: 'snow_devices' },
  { label: 'Switches', value: 'switches' },
  { label: 'Whips', value: 'whips' },
  { label: 'No Applicable Policy', value: 'no_applicable_policy' },
];

export const EX_REPORT_REQ_TYPE: EnumDisplayMapping[] = [
  { label: 'Annual', value: 'annual' },
  { label: 'Bi-Annual', value: 'biannual' },
];

export const TRUE_OR_FALSE: BooleanEnumDisplayMapping[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const IM_REQ_COMPLETED: EnumDisplayMapping[] = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Partial', value: 'partial' },
];

export const IM_APP_REQ: EnumDisplayMapping[] = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Transactional', value: 'transactional' },
];

export const PC_STATUS: EnumDisplayMapping[] = [
  { label: 'Not Certified', value: 'not_certified' },
  { label: 'Certified', value: 'certified' },
  { label: 'Prototype', value: 'prototype' },
];

export const getProgressTypeClass = (progressType: string): string | undefined => {
  switch (progressType) {
    case 'InProgress':
      return 'progress-in-progress';
    case 'Pending':
      return 'progress-pending';
    case 'Review':
      return 'progress-review';
    case 'Completed':
      return 'progress-completed';
    case 'Ready':
      return 'progress-ready';
    default:
  }
};
// Product Compliance Markets
// look to remove
export const PRODUCT_COMPLIANCE_MARKETS = [
  'Africa',
  'Africa.Kenya',
  'Africa.Egypt',
  'Africa.Ghana',
  'Africa.Morocco',
  'Africa.Nigeria',
  'Africa.SoAfrica',
  'APAC.Australia',
  'APAC.China',
  'APAC.HongKong',
  'APAC.Indonesia',
  'APAC.India',
  'APAC.Japan',
  'APAC.Macau',
  'APAC.Malaysia',
  'APAC.NewZealand',
  'APAC.Philippines',
  'APAC.Singapore',
  'APAC.SoKorea',
  'APAC.Taiwan',
  'APAC.Thailand',
  'APAC.Vietnam',
  'Asia.Bangledash',
  'Asia.Cambodia',
  'Asia.India',
  'Asia.Jordan',
  'Asia.Laos',
  'Asia.Mongolia',
  'Asia.Myanmar',
  'Asia.Nepal',
  'Asia.Pakistan',
  'Asia.SriLanka',
  'EEU.Armenia',
  'EEU.Belarus',
  'EEU.Kazakstan',
  'EEU.Kyrgystan',
  'EU-EEA-CH',
  'EU-EEA-CH.Andora',
  'EU-Non-EEA',
  'Foundational',
  'ME.Bahrain',
  'ME.Israel',
  'ME.Kuwait',
  'ME.Oman',
  'ME.Qatar',
  'ME.SaudiArabia',
  'ME.Turkey',
  'ME.UAE',
  'Middle East',
  'NA.Bermuda',
  'NA.CAN',
  'NA.CaymanIslands',
  'NA.Cuba',
  'NA.DominicanRepublic',
  'NA.Jamaica',
  'NA.Mexico',
  'NA.Panama',
  'NA.Puerto Rico',
  'NA.USA',
  'Non-EU-EEA-CH.Albania',
  'Non-EU-EEA-CH.Gibraltar',
  'Non-EU-EEA-CH.Monaco',
  'Non-EU-EEA-CH.Russia',
  'Non-EU-EEA-CH.UK',
  'Non-EU-EEA-CH.Ukraine',
  'Non-EU-EEA.Serbia',
  'SA.Argentina',
  'SA.Bolivia',
  'SA.Brazil',
  'SA.Chile',
  'SA.Colombia',
  'SA.Costa Rich',
  'SA.Ecuador',
  'SA.Guatemala',
  'SA.Hondorus',
  'SA.Paraguay',
  'SA.Peru',
  'SA.Uruguay',
  'SA.Venezuela',
  'TCA',
  'USA_CAN',
];

// Product Compliance Categories
export const PRODUCT_COMPLIANCE_CATEGORIES = ['EMC', 'Safety', 'RF (intentional radiators)', 'Dangerous Goods'];

// Product Compliance Product Levels
export const PRODUCT_COMPLIANCE_PRODUCT_LEVELS = [
  'Rack',
  'Rack - Integrated Storage Server',
  'Rack - Networking',
  'Server (Generic)',
  'Server (Network)',
  'Server (Storage)',
  'Server (Collective +Wireless)',
  'Power Shelf (Generic)',
  'Power Module (Power Shelf)',
  'Power Supply (Generic)',
  'Power Supply (Built-in)',
  'Power Supply (Stand-alone)',
  'Switch (Generic)',
  'Cables  (Rack) (Generic)',
  'NFC (Host +Module)',
  'NFC (Modular)',
  'Cable (MAINS Whip)',
  'Battery (Generic - Li-Ion button cell)',
  'Battery  Back UP (Generic - Li-Ion Bank)',
  'UPS',
  'rPDU',
];

export const PC_COMPLIANCE_REQUIREMENT_FIELDS = {
  Market: 'market',
  Categories: 'categories',
  'Product Level': 'productLevel',
  'Needed? (Y/N)': 'needed',
  Rationale: 'rationaleReNeededFlag',
  'Third Party Agent': 'thirdPartyAgentValueAdd',
  Authority: 'authorityAhj',
  'Conformity Scheme': 'conformityScheme',
  'Acceptable Labs': 'acceptableLabs',
  'Known Acceptable Labs': 'knownAcceptableLabs',
  'On-Device Marking': 'onDeviceMarking',
  Deliverables: 'deliverables',
  'Needed to Start (R2G checklist)': 'neededToStartR2gChecklist',
  'Sample for Submission? (Y/N/NA/C)': 'sampleForSubmission',
  'Comments Re Sample Submission': 'commentsReSampleSubmission',
  'In-country Testing': 'inCountryTesting',
  'Sample Specification': 'sampleSpecification',
  'Sample EUT (Proxy)': 'sampleEutProxy',
  'Sample Quantity': 'sampleQuantity',
  'Turn-around time (working days)': 'turnAroundTimeWorkingDays',
  'Brief Summary': 'briefSummary',
  'Source Reference Summary': 'sourceReferenceSummary',
  URL: 'url',
  'Comments (General)': 'commentsGeneral',
  'HTS Code 1': 'htsCode1',
  'HTS Code 2': 'htsCode2',
  'AWS Preferred Applicant': 'awsPreferredApplicant',
  'Local Entity Required to Complete Activity?': 'localEntityRequiredToCompleteActivity',
  'Localization Requirements': 'localizationRequirements',
  'Validity Period (in months)': 'validityPeriod',
  'Record keeping Requirements': 'recordKeepingRequirements',
  'Reporting Requirements': 'reportingRequirements',
  'Regulatory Reference': 'regulatoryReference',
  Exceptions: 'exceptions',
  'UN Dangerous Goods Number': 'unDangerousGoodsNumber',
  'Last Updated': 'lastUpdated',
  'Created By': 'createdBy',
  'Created At': 'createdAt',
  'Modified By': 'modifiedBy',
  'Modified At': 'modifiedAt',
  'Change Notes': 'changeNotes',
};

export const ARTIFACT_SEARCH_MODES: EnumDisplayMapping[] = [
  { value: 'artifacts_authorizations', label: 'Artifacts and Authorizations' },
  { value: 'artifacts', label: 'Artifacts Only' },
  { value: 'authorizations', label: 'Authorizations Only' },
];

const FILTER_TAG_COMPLIANCE = 'compliance';
const FILTER_TAG_TRADE = 'trade';
const FILTER_TAG_LM = 'licenseManager';

// all compliance auth types and then all trade (ielicense) auth types
// alphabetical order in dropdowns
export const ARTIFACT_MENU_OPTIONS: HideableEnumDisplayMapping[] = [
  {
    value: 'identifier_declaration',
    label: 'Identifier Declaration',
    filteringTags: [FILTER_TAG_COMPLIANCE],
  },
  {
    value: 'pc_certificate',
    label: 'PC Certificate',
    filteringTags: [FILTER_TAG_COMPLIANCE],
  },
  {
    value: 'pc_certificate_test_report_combo',
    label: 'PC Certificate & Test Report Combo',
    filteringTags: [FILTER_TAG_COMPLIANCE],
  },
  {
    value: 'pc_declaration',
    label: 'PC Declaration',
    filteringTags: [FILTER_TAG_COMPLIANCE],
  },
  {
    value: 'pc_documentation',
    label: 'PC Documentation',
    filteringTags: [FILTER_TAG_COMPLIANCE],
  },
  {
    value: 'pc_exemption_letter',
    label: 'PC Exemption Letter',
    filteringTags: [FILTER_TAG_COMPLIANCE],
  },
  { value: 'pc_letter', label: 'PC Letter', filteringTags: [FILTER_TAG_COMPLIANCE] },
  {
    value: 'pc_registration',
    label: 'PC Registration',
    filteringTags: [FILTER_TAG_COMPLIANCE],
  },
  { value: 'pc_report', label: 'PC Report', filteringTags: [FILTER_TAG_COMPLIANCE] },
  {
    value: 'product_brochure',
    label: 'Product Brochure',
    filteringTags: [FILTER_TAG_COMPLIANCE],
  },
  { value: 'product_label', label: 'Product Label', filteringTags: [FILTER_TAG_COMPLIANCE] },
  { value: 'product_photo', label: 'Product Photo', filteringTags: [FILTER_TAG_COMPLIANCE] },
  {
    value: 'export_authorization',
    label: 'Export Authorization',
    filteringTags: ['authorization', FILTER_TAG_TRADE, FILTER_TAG_LM],
  },
  {
    value: 'encryption_authorization',
    label: 'Encryption Authorization',
    filteringTags: [FILTER_TAG_TRADE],
  },
  {
    value: 'import_authorization',
    label: 'Import Authorization',
    filteringTags: ['authorization', FILTER_TAG_TRADE],
  },
  {
    value: 'trade_authorizations',
    label: 'Trade Authorizations',
    filteringTags: [FILTER_TAG_TRADE],
  },
  { value: 'trade_document', label: 'Trade Document', filteringTags: [FILTER_TAG_TRADE] },
];

export const ARTIFACT_V2_MENU_OPTIONS_FOR_CREATE = [
  {
    label: 'Compliance',
    options: ARTIFACT_MENU_OPTIONS.filter(
      (type) => type.filteringTags?.includes(FILTER_TAG_COMPLIANCE) && !type.hideInCreateMenu
    ),
  },
  {
    label: 'Trade',
    options: ARTIFACT_MENU_OPTIONS.filter(
      (type) => type.filteringTags?.includes(FILTER_TAG_TRADE) && !type.hideInCreateMenu
    ),
  },
];

export const ARTIFACT_V1_MENU_OPTIONS_FOR_CREATE = [
  {
    label: 'Trade',
    options: ARTIFACT_MENU_OPTIONS.filter(
      (type) => type.filteringTags?.includes(FILTER_TAG_LM) && !type.hideInCreateMenu
    ),
  },
];

// all compliance sub auth types and then all trade (ielicense) sub auth type
// alphabetical order in dropdowns
export const ARTIFACT_SUBTYPE_MENU_OPTIONS: HideableEnumDisplayMapping[] = [
  {
    value: 'acma_sdoc_supplier_declaration_of_conformity',
    label: 'ACMA SDoC (Supplier Declaration of Conformity)',
    format: 'pc_declaration',
    category: 'compliance',
  },
  { value: 'anatel_certificate', label: 'Anatel Certificate', format: 'pc_certificate', category: 'compliance' },
  { value: 'aws_business_license', label: 'AWS Business License', format: 'pc_documentation', category: 'compliance' },
  { value: 'bis_certificate_in', label: 'BIS Certificate – IN', format: 'pc_certificate', category: 'compliance' },
  {
    value: 'bis_registration_scheme',
    label: 'BIS Registration Scheme',
    format: 'pc_registration',
    category: 'compliance',
  },
  { value: 'bis_test_report', label: 'BIS Test Report', format: 'pc_report', category: 'compliance' },
  { value: 'block_diagram', label: 'Block Diagram', format: 'pc_documentation', category: 'compliance' },
  { value: 'brand_label', label: 'Brand Label', format: 'pc_documentation', category: 'compliance' },
  { value: 'bsmi_certificate', label: 'BSMI Certificate', format: 'pc_certificate', category: 'compliance' },
  {
    value: 'bsmi_doc_declaration_of_conformity',
    label: 'BSMI DoC (Declaration of Conformity)',
    format: 'pc_declaration',
    category: 'compliance',
    hideInCreateMenu: true,
  },
  { value: 'bsmi_test_report', label: 'BSMI Test Report', format: 'pc_report', category: 'compliance' },
  {
    value: 'bulk_permit',
    label: 'Bulk Permit',
    format: 'export_authorization',
    category: 'ielicense',
    filteringTags: ['export_authorization'],
  },
  { value: 'ca_nrcan_grant', label: 'CA NRCAN Grant', format: 'pc_certificate', category: 'compliance' },
  { value: 'cb_certificate', label: 'CB Certificate', format: 'pc_certificate', category: 'compliance' },
  {
    value: 'cb_certificate_and_cb_test_report',
    label: 'CB Certificate and CB Test Report',
    format: 'pc_certificate_test_report_combo',
    category: 'compliance',
  },
  { value: 'cb_test_report', label: 'CB Test Report', format: 'pc_report', category: 'compliance' },
  { value: 'ccc_certificate', label: 'CCC Certificate', format: 'pc_certificate', category: 'compliance' },
  { value: 'ccc_report', label: 'CCC Report', format: 'pc_report', category: 'compliance' },
  {
    value: 'ccc_type_approval_certificate',
    label: 'CCC Type Approval Certificate',
    format: 'pc_certificate',
    category: 'compliance',
  },
  { value: 'ce_doc_arabic', label: 'CE DoC (Arabic)', format: 'pc_declaration', category: 'compliance' },
  { value: 'ce_doc_english', label: 'CE DoC (English)', format: 'pc_declaration', category: 'compliance' },
  { value: 'ce_doc_french', label: 'CE DoC (French)', format: 'pc_declaration', category: 'compliance' },
  { value: 'certificate_misc', label: 'Certificate MISC', format: 'pc_certificate', category: 'compliance' },
  {
    value: 'mii_network_access_license',
    label: 'MII Network Access License',
    format: 'pc_certificate',
    category: 'compliance',
  },
  {
    value: 'type_examination_certificate',
    label: 'Type Examination Certificate',
    format: 'pc_certificate',
    category: 'compliance',
  },
  {
    value: 'certificate_of_compliance',
    label: 'Certificate of Compliance',
    format: 'pc_certificate',
    category: 'compliance',
  },
  {
    value: 'certification_of_conformity',
    label: 'Certification of Conformity',
    format: 'pc_certificate',
    category: 'compliance',
  },
  {
    value: 'children_product_certificate',
    label: 'Children Product Certificate',
    format: 'pc_certificate',
    category: 'compliance',
  },
  {
    value: 'classification_document',
    label: 'Classification Document',
    format: 'trade_document',
    category: 'ielicense',
  },
  {
    value: 'country_registration',
    label: 'Country Registration',
    format: 'encryption_authorization',
    category: 'ielicense',
  },
  { value: 'dcac_permit', label: 'DCAC Permit', format: 'import_authorization', category: 'ielicense' },
  {
    value: 'declaration_misc',
    label: 'Declaration MISC',
    format: 'pc_declaration',
    category: 'compliance',
  },
  {
    value: 'deemed_export_license',
    label: 'Deemed Export License',
    format: 'export_authorization',
    category: 'ielicense',
  },
  {
    value: 'doc_declaration_of_conformity',
    label: 'DoC (Declaration of Conformity)',
    format: 'pc_declaration',
    category: 'compliance',
  },
  { value: 'documentation_misc', label: 'Documentation MISC', format: 'pc_documentation', category: 'compliance' },
  { value: 'eac_certificate', label: 'EAC Certificate', format: 'pc_certificate', category: 'compliance' },
  { value: 'emc_certificate', label: 'EMC Certificate', format: 'pc_certificate', category: 'compliance' },
  {
    value: 'emc_certificate_and_emc_test_report',
    label: 'EMC Certificate and EMC Test Report',
    format: 'pc_certificate_test_report_combo',
    category: 'compliance',
  },
  { value: 'emc_test_report', label: 'EMC Test Report', format: 'pc_report', category: 'compliance' },
  {
    value: 'encryption_license',
    label: 'Encryption License',
    format: 'encryption_authorization',
    category: 'ielicense',
  },
  {
    value: 'encryption_notification_pl_poland',
    label: 'Encryption Notification PL (Poland)',
    format: 'import_authorization',
    category: 'ielicense',
  },
  { value: 'compliance_documents', label: 'Compliance Documents', format: 'trade_document', category: 'ielicense' },
  {
    value: 'energy_efficiency_certificate',
    label: 'Energy Efficiency Certificate',
    format: 'pc_certificate',
    category: 'compliance',
  },
  {
    value: 'energy_efficiency_test_report',
    label: 'Energy Efficiency Test Report',
    format: 'pc_report',
    category: 'compliance',
  },
  {
    value: 'equipment_approval_certificate',
    label: 'Equipment Approval Certificate',
    format: 'pc_certificate',
    category: 'compliance',
  },
  {
    value: 'exemption_letter_misc',
    label: 'Exemption Letter MISC',
    format: 'pc_exemption_letter',
    category: 'compliance',
  },
  { value: 'factory_certificate', label: 'Factory Certificate', format: 'pc_documentation', category: 'compliance' },
  { value: 'fcc_grant', label: 'FCC Grant', format: 'pc_certificate', category: 'compliance' },
  { value: 'fcc_certificate', label: 'FCC Certificate', format: 'pc_certificate', category: 'compliance' },
  { value: 'fcc_report', label: 'FCC Report', format: 'pc_report', category: 'compliance' },
  {
    value: 'hazardous_substance_test_report',
    label: 'Hazardous Substance Test Report',
    format: 'pc_report',
    category: 'compliance',
  },
  {
    value: 'icasa_type_approval_certificate_sa',
    label: 'ICASA Type Approval Certificate – SA',
    format: 'pc_certificate',
    category: 'compliance',
  },
  { value: 'ices_emc_test_report', label: 'ICES EMC Test Report', format: 'pc_report', category: 'compliance' },
  {
    value: 'identifier_declaration',
    label: 'Identifier Declaration',
    format: 'identifier_declaration',
    category: 'compliance',
  },
  { value: 'imda_registration', label: 'IMDA Registration', format: 'pc_registration', category: 'compliance' },
  { value: 'import_anrt', label: 'Import ANRT', format: 'import_authorization', category: 'ielicense' },
  { value: 'import_bmda', label: 'Import BMDA', format: 'import_authorization', category: 'ielicense' },
  { value: 'import_btmd', label: 'Import BTMD', format: 'import_authorization', category: 'ielicense' },
  { value: 'import_btrc_noc', label: 'Import BTRC NOC', format: 'import_authorization', category: 'ielicense' },
  { value: 'import_cak_approval', label: 'Import CAK Approval', format: 'import_authorization', category: 'ielicense' },
  {
    value: 'import_certification_of_conformity',
    label: 'Import Certification of Conformity',
    format: 'import_authorization',
    category: 'ielicense',
  },
  { value: 'import_chims', label: 'import CHIMS', format: 'import_authorization', category: 'ielicense' },
  { value: 'import_citc', label: 'Import CITC', format: 'import_authorization', category: 'ielicense' },
  { value: 'import_coc', label: 'Import COC', format: 'import_authorization', category: 'ielicense' },
  { value: 'import_cra_approval', label: 'Import CRA approval', format: 'import_authorization', category: 'ielicense' },
  { value: 'import_diw', label: 'Import DIW', format: 'import_authorization', category: 'ielicense' },
  {
    value: 'import_electrical_safety_security_sworn_declaration',
    label: 'Import Electrical Safety Security Sworn declaration',
    format: 'import_authorization',
    category: 'ielicense',
  },
  {
    value: 'import_foreign_trade_control',
    label: 'Import Foreign Trade Control',
    format: 'import_authorization',
    category: 'ielicense',
  },
  { value: 'import_idf', label: 'Import IDF', format: 'import_authorization', category: 'ielicense' },
  { value: 'import_imod_license', label: 'Import IMOD License', format: 'import_authorization', category: 'ielicense' },
  { value: 'import_license_misc', label: 'import License MISC', format: 'import_authorization', category: 'ielicense' },
  {
    value: 'import_ministry_of_communications',
    label: 'Import Ministry of Communications',
    format: 'import_authorization',
    category: 'ielicense',
  },
  {
    value: 'import_moc_permit_exemption',
    label: 'Import MOC Permit Exemption',
    format: 'import_authorization',
    category: 'ielicense',
  },
  {
    value: 'import_nbtc_exemption_letter',
    label: 'Import NBTC Exemption Letter',
    format: 'import_authorization',
    category: 'ielicense',
  },
  { value: 'import_nbtc_sdoc', label: 'import NBTC SDOC', format: 'import_authorization', category: 'ielicense' },
  {
    value: 'import_nbtc_sdoc_out_of_scope',
    label: 'Import NBTC SDoC Out of Scope',
    format: 'import_authorization',
    category: 'ielicense',
  },
  {
    value: 'import_ntra_approval',
    label: 'Import NTRA approval',
    format: 'import_authorization',
    category: 'ielicense',
  },
  { value: 'import_pvoc', label: 'Import PVOC', format: 'import_authorization', category: 'ielicense' },
  {
    value: 'import_registration_with_gocie',
    label: 'Import Registration with GOCIE',
    format: 'import_authorization',
    category: 'ielicense',
  },
  { value: 'import_sdppi', label: 'import SDPPI', format: 'import_authorization', category: 'ielicense' },
  { value: 'import_sirim', label: 'import SIRIM', format: 'import_authorization', category: 'ielicense' },
  {
    value: 'import_sni_certificate',
    label: 'Import SNI Certificate',
    format: 'import_authorization',
    category: 'ielicense',
  },
  {
    value: 'import_stamped_approval_from_gocie',
    label: 'Import Stamped Approval from GOCIE',
    format: 'import_authorization',
    category: 'ielicense',
  },
  {
    value: 'import_supreme_council_for_environment',
    label: 'Import Supreme Council For Environment',
    format: 'import_authorization',
    category: 'ielicense',
  },
  {
    value: 'import_telecommunications_license',
    label: 'Import Telecommunications License',
    format: 'import_authorization',
    category: 'ielicense',
  },
  {
    value: 'import_the_automatic_and_non_automatic_license',
    label: 'Import The Automatic and Non-Automatic License',
    format: 'import_authorization',
    category: 'ielicense',
  },
  { value: 'import_tisi', label: 'Import TISI', format: 'import_authorization', category: 'ielicense' },
  {
    value: 'import_tisi_self_use_exemption',
    label: 'Import TISI Self Use Exemption',
    format: 'import_authorization',
    category: 'ielicense',
  },
  { value: 'import_tra', label: 'import TRA', format: 'import_authorization', category: 'ielicense' },
  {
    value: 'import_transactional_permit',
    label: 'Import Transactional Permit',
    format: 'import_authorization',
    category: 'ielicense',
  },
  {
    value: 'import_type_approval_tra',
    label: 'Import Type Approval TRA',
    format: 'import_authorization',
    category: 'ielicense',
  },
  { value: 'importer_agreement', label: 'Importer Agreement', format: 'pc_documentation', category: 'compliance' },
  { value: 'iram_certificate_ar', label: 'IRAM Certificate - AR', format: 'pc_certificate', category: 'compliance' },
  { value: 'ised_certificate', label: 'ISED Certificate', format: 'pc_certificate', category: 'compliance' },
  {
    value: 'israel_ministry_of_economy_and_trade_license',
    label: 'Israel Ministry of Economy and Trade License',
    format: 'import_authorization',
    category: 'ielicense',
  },
  {
    value: 'itar_agreement_intangible',
    label: 'ITAR Agreement (Intangible)',
    format: 'export_authorization',
    category: 'ielicense',
  },
  {
    value: 'itar_license_tangibles__intangibles',
    label: 'ITAR License (Tangibles & Intangibles)',
    format: 'export_authorization',
    category: 'ielicense',
  },
  { value: 'kc_test_report', label: 'KC Test Report', format: 'pc_report', category: 'compliance' },
  { value: 'kcc_certificate', label: 'KCC Certificate', format: 'pc_certificate', category: 'compliance' },
  { value: 'layout', label: 'Layout', format: 'pc_documentation', category: 'compliance' },
  { value: 'letter_of_attestation', label: 'Letter of Attestation', format: 'pc_letter', category: 'compliance' },
  {
    value: 'mic_type_approval_certificate_jp',
    label: 'MIC Type Approval Certificate - JP',
    format: 'pc_certificate',
    category: 'compliance',
  },
  {
    value: 'mic_type_approval_certificate_vn',
    label: 'MIC Type Approval Certificate - VN',
    format: 'pc_declaration',
    category: 'compliance',
  },
  {
    value: 'moe_aws_specific_ruling',
    label: 'MOE AWS Specific Ruling',
    format: 'pc_exemption_letter',
    category: 'compliance',
  },
  { value: 'moe_existing_ruling', label: 'MOE Existing Ruling', format: 'pc_exemption_letter', category: 'compliance' },
  { value: 'moe_hse_exemption', label: 'MOE HSE Exemption', format: 'pc_exemption_letter', category: 'compliance' },
  {
    value: 'mou_memorandum_of_understanding',
    label: 'MOU (Memorandum of Understanding)',
    format: 'trade_document',
    category: 'compliance',
  },
  {
    value: 'nbtc_sdoc_supplier_declaration_of_confirmity',
    label: 'NBTC SDoC (Supplier Declaration of Confirmity)',
    format: 'import_authorization',
    category: 'ielicense',
  },
  {
    value: 'ncc_type_approval_certificate',
    label: 'NCC Type Approval Certificate',
    format: 'pc_certificate',
    category: 'compliance',
  },
  { value: 'nebs_report', label: 'NEBS Report', format: 'pc_report', category: 'compliance' },
  { value: 'nom_certificate', label: 'NOM Certificate', format: 'pc_certificate', category: 'compliance' },
  { value: 'non-cb_certificate', label: 'non-CB Certificate', format: 'pc_certificate', category: 'compliance' },
  { value: 'non_cb_test_report', label: 'Non-CB Test Report', format: 'pc_report', category: 'compliance' },
  {
    value: 'nrcs_loa_letter_of_authority',
    label: 'NRCS LoA (Letter of Authority)',
    format: 'import_authorization',
    category: 'ielicense',
  },
  { value: 'nrra_registration', label: 'NRRA Registration', format: 'pc_registration', category: 'compliance' },
  { value: 'nrtl_certificate', label: 'NRTL Certificate', format: 'pc_certificate', category: 'compliance' },
  {
    value: 'nrtl_certificate_and_nrtl_test_report',
    label: 'NRTL Certificate and NRTL Test Report',
    format: 'pc_certificate_test_report_combo',
    category: 'compliance',
  },
  { value: 'nrtl_test_report', label: 'NRTL Test Report', format: 'pc_report', category: 'compliance' },
  { value: 'other_product_labels', label: 'Other Product Labels', format: 'product_label', category: 'compliance' },
  { value: 'packaging_label', label: 'Packaging Label', format: 'product_label', category: 'compliance' },
  { value: 'photo_back_rear', label: 'Photo Back/Rear', format: 'product_photo', category: 'compliance' },
  { value: 'photo_external', label: 'Photo (External)', format: 'product_photo', category: 'compliance' },
  {
    value: 'photo_internal__subassembly',
    label: 'Photo (Internal) & Subassembly',
    format: 'product_photo',
    category: 'compliance',
  },
  { value: 'photo_front', label: 'Photo Front', format: 'product_photo', category: 'compliance' },
  { value: 'photo_side', label: 'Photo Side', format: 'product_photo', category: 'compliance' },
  { value: 'photos_all', label: 'Photos All', format: 'product_photo', category: 'compliance' },
  { value: 'product_brochure', label: 'Product Brochure', format: 'product_brochure', category: 'compliance' },
  { value: 'product_design', label: 'Product Design', format: 'pc_documentation', category: 'compliance' },
  { value: 'product_label', label: 'Product Label', format: 'product_label', category: 'compliance' },
  { value: 'proforma_invoice', label: 'Proforma Invoice', format: 'trade_document', category: 'ielicense' },
  { value: 'rcm_test_report', label: 'RCM Test Report', format: 'pc_report', category: 'compliance' },
  { value: 'reach_declaration', label: 'REACH Declaration', format: 'pc_declaration', category: 'compliance' },
  { value: 'registration_misc', label: 'Registration MISC', format: 'pc_registration', category: 'compliance' },
  { value: 'rf_fcc_report', label: 'RF FCC Report', format: 'pc_report', category: 'compliance' },
  { value: 'rf_ised_report', label: 'RF ISED Report', format: 'pc_report', category: 'compliance' },
  { value: 'rf_report', label: 'RF Report', format: 'pc_report', category: 'compliance' },
  { value: 'rf_exposure_report', label: 'RF Exposure Report', format: 'pc_report', category: 'compliance' },
  { value: 'rohs_declaration', label: 'RoHS Declaration', format: 'pc_declaration', category: 'compliance' },
  { value: 'rsm_sdoc', label: 'RSM SDOC', format: 'pc_declaration', category: 'compliance' },
  { value: 'sabs_certificate', label: 'SABs Certificate', format: 'pc_certificate', category: 'compliance' },
  { value: 'schematics', label: 'Schematics', format: 'pc_documentation', category: 'compliance' },
  { value: 'scip_report_number', label: 'SCIP Report Number', format: 'pc_registration', category: 'compliance' },
  {
    value: 'sdoc_supplier_declaration_of_conformity',
    label: 'SDoC (Supplier Declaration of Conformity)',
    format: 'pc_declaration',
    category: 'compliance',
  },
  {
    value: 'sdoc_against_aws_material_restricted_substance_specification',
    label: 'SDoC against AWS Material Restricted Substance Specification',
    format: 'pc_declaration',
    category: 'compliance',
  },
  {
    value: 'sdppi_declaration_of_conformity',
    label: 'SDPPI Declaration of Conformity',
    format: 'pc_declaration',
    category: 'ielicense',
  },
  {
    value: 'sdppi_functional_test_report',
    label: 'SDPPI Functional Test Report',
    format: 'pc_report',
    category: 'compliance',
  },
  { value: 'sert_2_report', label: 'SERT 2 report', format: 'pc_report', category: 'compliance' },
  { value: 'signatory_document_', label: 'Signatory Document ', format: 'pc_documentation', category: 'compliance' },
  { value: 'sii_certificate', label: 'SII Certificate', format: 'pc_certificate', category: 'compliance' },
  {
    value: 'single_export_license_tangibles__intangibles',
    label: 'Single Export License (Tangibles & Intangibles)',
    format: 'export_authorization',
    category: 'ielicense',
  },
  { value: 'sirim_certificate', label: 'SIRIM Certificate', format: 'pc_certificate', category: 'compliance' },
  {
    value: 'sirim_type_approval_certificate',
    label: 'SIRIM Type Approval Certificate',
    format: 'pc_certificate',
    category: 'compliance',
  },
  {
    value: 'son_product_certificate_ng',
    label: 'SON Product Certificate -NG',
    format: 'pc_certificate',
    category: 'compliance',
  },
  {
    value: 'technical_assessment_report',
    label: 'Technical Assessment Report',
    format: 'pc_report',
    category: 'compliance',
  },
  { value: 'tec_certificate', label: 'TEC Certificate', format: 'pc_certificate', category: 'compliance' },
  { value: 'test_report_misc', label: 'Test Report MISC', format: 'pc_report', category: 'compliance' },
  { value: 'toy_cp_test_report', label: 'Toy/CP Test Report', format: 'pc_report', category: 'compliance' },
  {
    value: 'type_approval_certificate_misc',
    label: 'Type Approval Certificate MISC',
    format: 'pc_certificate',
    category: 'compliance',
  },
  { value: 'ukca_doc', label: 'UKCA DoC', format: 'pc_declaration', category: 'compliance' },
  { value: 'us_doe_grant', label: 'US DOE Grant', format: 'pc_certificate', category: 'compliance' },
  { value: 'user_manual', label: 'User Manual', format: 'pc_documentation', category: 'compliance' },
  {
    value: 'vcci_acceptance_of_report_of_compliance',
    label: 'VCCI Acceptance of Report of Compliance',
    format: 'pc_registration',
    category: 'compliance',
  },
  { value: 'vcci_certificate', label: 'VCCI Certificate', format: 'pc_certificate', category: 'compliance' },
  { value: 'vcci_test_report', label: 'VCCI Test Report', format: 'pc_report', category: 'compliance' },
  {
    value: 'wpc_type_approval_certificate_in',
    label: 'WPC Type Approval Certificate – IN',
    format: 'pc_certificate',
    category: 'compliance',
  },
];

export const ARTIFACT_V2_SUBTYPE_MENU_OPTIONS_FOR_CREATE = ARTIFACT_SUBTYPE_MENU_OPTIONS.filter(
  (subType) => !subType.hideInCreateMenu
);

const artifactsV1TypeValues = ARTIFACT_V1_MENU_OPTIONS_FOR_CREATE[0].options.map((typeOption) => typeOption.value);
export const ARTIFACT_V1_SUBTYPE_MENU_OPTIONS_FOR_CREATE = ARTIFACT_SUBTYPE_MENU_OPTIONS.filter(
  (subType) => (!subType.format || artifactsV1TypeValues.includes(subType.format)) && !subType.hideInCreateMenu
);

export const CATEGORY_OPTIONS: EnumDisplayMapping[] = [
  { label: 'Compliance', value: 'compliance' },
  { label: 'Trade', value: 'ielicense' },
];

export const STATUS_OPTIONS: EnumDisplayMapping[] = [
  { label: 'Approved', value: 'approved' },
  { label: 'Approved w/ Conditions', value: 'approvedwithconditions' },
  { label: 'Submitted', value: 'submitted' },
  { label: 'Pending', value: 'pending' },
  { label: 'Denied', value: 'denied' },
  { label: 'Returned w/o Action', value: 'returnedwithoutaction' },
];

export const APPLICANT_TYPE_OPTIONS: EnumDisplayMapping[] = [
  { label: 'AWS', value: 'aws' },
  { label: 'Manufacturer', value: 'manufacturer' },
  { label: 'Third Party', value: 'third_party' },
];

const FILTER_TAG_EU = 'EU';

export const COUNTRIES: CountryEnumDisplayMapping[] = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Åland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL', legacyCode: 'Non-EU-EEA-CH.Albania' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD', legacyCode: 'EU-EEA-CH.Andora' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  {
    label: 'Argentina',
    value: 'AR',
    legacyCode: 'SA.Argentina',
  },
  { label: 'Armenia', value: 'AM', legacyCode: 'EEU.Armenia' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU', legacyCode: 'APAC.Australia' },
  { label: 'Austria', value: 'AT', filteringTags: [FILTER_TAG_EU] },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas (the)', value: 'BS' },
  { label: 'Bahrain', value: 'BH', legacyCode: 'ME.Bahrain' },
  { label: 'Bangladesh', value: 'BD', legacyCode: 'Asia.Bangledash' },
  { label: 'Barbados', value: 'BB' },
  {
    label: 'Belarus',
    value: 'BY',
    legacyCode: 'EEU.Belarus',
  },
  { label: 'Belgium', value: 'BE', filteringTags: [FILTER_TAG_EU] },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  {
    label: 'Bermuda',
    value: 'BM',
    legacyCode: 'NA.Bermuda',
  },
  { label: 'Bhutan', value: 'BT' },
  {
    label: 'Bolivia (Plurinational State of)',
    value: 'BO',
    legacyCode: 'SA.Bolivia',
  },
  { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR', legacyCode: 'SA.Brazil' },
  { label: 'British Indian Ocean Territory (the)', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG', filteringTags: [FILTER_TAG_EU] },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cabo Verde', value: 'CV' },
  {
    label: 'Cambodia',
    value: 'KH',
    legacyCode: 'Asia.Cambodia',
  },
  { label: 'Cameroon', value: 'CM' },
  {
    label: 'Canada',
    value: 'CA',
    legacyCode: 'NA.CAN',
  },
  { label: 'Cayman Islands (the)', value: 'KY', legacyCode: 'NA.CaymanIslands' },
  { label: 'Central African Republic (the)', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL', legacyCode: 'SA.Chile' },
  { label: 'China', value: 'CN', legacyCode: 'APAC.China' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands (the)', value: 'CC' },
  { label: 'Colombia', value: 'CO', legacyCode: 'SA.Colombia' },
  { label: 'Comoros (the)', value: 'KM' },
  { label: 'Congo (the Democratic Republic of the)', value: 'CD' },
  { label: 'Congo (the)', value: 'CG' },
  { label: 'Cook Islands (the)', value: 'CK' },
  { label: 'Costa Rica', value: 'CR', legacyCode: 'SA.Costa Rich' },
  { label: 'Croatia', value: 'HR', filteringTags: [FILTER_TAG_EU] },
  { label: 'Cuba', value: 'CU', legacyCode: 'NA.Cuba' },
  { label: 'Curaçao', value: 'CW' },
  { label: 'Cyprus', value: 'CY', filteringTags: [FILTER_TAG_EU] },
  { label: 'Czechia', value: 'CZ', filteringTags: [FILTER_TAG_EU] },
  { label: "Côte d'Ivoire", value: 'CI' },
  { label: 'Denmark', value: 'DK', filteringTags: [FILTER_TAG_EU] },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  {
    label: 'Dominican Republic (the)',
    value: 'DO',
    legacyCode: 'NA.DominicanRepublic',
  },
  {
    label: 'Ecuador',
    value: 'EC',
    legacyCode: 'SA.Ecuador',
  },
  { label: 'Egypt', value: 'EG', legacyCode: 'Africa.Egypt' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE', filteringTags: [FILTER_TAG_EU] },
  { label: 'Eswatini', value: 'SZ' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'European Union', value: 'EU' },
  { label: 'Falkland Islands (the) [Malvinas]', value: 'FK' },
  { label: 'Faroe Islands (the)', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI', filteringTags: [FILTER_TAG_EU] },
  { label: 'France', value: 'FR', filteringTags: [FILTER_TAG_EU] },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories (the)', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia (the)', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE', filteringTags: [FILTER_TAG_EU] },
  { label: 'Ghana', value: 'GH', legacyCode: 'Africa.Ghana' },
  { label: 'Gibraltar', value: 'GI', legacyCode: 'Non-EU-EEA-CH.Gibraltar' },
  { label: 'Greece', value: 'GR', filteringTags: [FILTER_TAG_EU] },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT', legacyCode: 'SA.Guatemala' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and McDonald Islands', value: 'HM' },
  { label: 'Holy See (the)', value: 'VA' },
  { label: 'Honduras', value: 'HN', legacyCode: 'SA.Hondorus' },
  { label: 'Hong Kong', value: 'HK', legacyCode: 'APAC.HongKong' },
  { label: 'Hungary', value: 'HU', filteringTags: [FILTER_TAG_EU] },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN', legacyCode: 'APAC.India' },
  { label: 'Indonesia', value: 'ID', legacyCode: 'APAC.Indonesia' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE', filteringTags: [FILTER_TAG_EU] },
  { label: 'Isle of Man', value: 'IM' },
  {
    label: 'Israel',
    value: 'IL',
    legacyCode: 'ME.Israel',
  },
  { label: 'Italy', value: 'IT', filteringTags: [FILTER_TAG_EU] },
  { label: 'Jamaica', value: 'JM', legacyCode: 'NA.Jamaica' },
  { label: 'Japan', value: 'JP', legacyCode: 'APAC.Japan' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO', legacyCode: 'Asia.Jordan' },
  {
    label: 'Kazakhstan',
    value: 'KZ',
    legacyCode: 'EEU.Kazakstan',
  },
  { label: 'Kenya', value: 'KE', legacyCode: 'Africa.Kenya' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Korea (the Republic of)', value: 'KR', legacyCode: 'APAC.SoKorea' },
  { label: 'Kuwait', value: 'KW', legacyCode: 'ME.Kuwait' },
  { label: 'Kyrgyzstan', value: 'KG', legacyCode: 'EEU.Kyrgystan' },
  {
    label: "Lao People's Democratic Republic (the)",
    value: 'LA',
    legacyCode: 'Asia.Laos',
  },
  { label: 'Latvia', value: 'LV', filteringTags: [FILTER_TAG_EU] },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT', filteringTags: [FILTER_TAG_EU] },
  { label: 'Luxembourg', value: 'LU', filteringTags: [FILTER_TAG_EU] },
  { label: 'Macao', value: 'MO', legacyCode: 'APAC.Macau' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY', legacyCode: 'APAC.Malaysia' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT', filteringTags: [FILTER_TAG_EU] },
  { label: 'Marshall Islands (the)', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX', legacyCode: 'NA.Mexico' },
  { label: 'Micronesia (Federated States of)', value: 'FM' },
  { label: 'Moldova (the Republic of)', value: 'MD' },
  { label: 'Monaco', value: 'MC', legacyCode: 'Non-EU-EEA-CH.Monaco' },
  {
    label: 'Mongolia',
    value: 'MN',
    legacyCode: 'Asia.Mongolia',
  },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA', legacyCode: 'Africa.Morocco' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM', legacyCode: 'Asia.Myanmar' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP', legacyCode: 'Asia.Nepal' },
  { label: 'Netherlands (the)', value: 'NL', filteringTags: [FILTER_TAG_EU] },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ', legacyCode: 'APAC.NewZealand' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger (the)', value: 'NE' },
  { label: 'Nigeria', value: 'NG', legacyCode: 'Africa.Nigeria' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'North Macedonia', value: 'MK' },
  { label: 'Northern Mariana Islands (the)', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM', legacyCode: 'ME.Oman' },
  { label: 'Pakistan', value: 'PK', legacyCode: 'Asia.Pakistan' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestine, State of', value: 'PS' },
  { label: 'Panama', value: 'PA', legacyCode: 'NA.Panama' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY', legacyCode: 'SA.Paraguay' },
  { label: 'Peru', value: 'PE', legacyCode: 'SA.Peru' },
  { label: 'Philippines (the)', value: 'PH', legacyCode: 'APAC.Philippines' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL', filteringTags: [FILTER_TAG_EU] },
  { label: 'Portugal', value: 'PT', filteringTags: [FILTER_TAG_EU] },
  {
    label: 'Puerto Rico',
    value: 'PR',
    legacyCode: 'NA.Puerto Rico',
  },
  { label: 'Qatar', value: 'QA', legacyCode: 'ME.Qatar' },
  { label: 'Romania', value: 'RO', filteringTags: [FILTER_TAG_EU] },
  {
    label: 'Russian Federation (the)',
    value: 'RU',
    legacyCode: 'Non-EU-EEA-CH.Russia',
  },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Réunion', value: 'RE' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Martin (French part)', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA', legacyCode: 'ME.SaudiArabia' },
  { label: 'Senegal', value: 'SN' },
  {
    label: 'Serbia',
    value: 'RS',
    legacyCode: 'Non-EU-EEA.Serbia',
  },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG', legacyCode: 'APAC.Singapore' },
  { label: 'Sint Maarten (Dutch part)', value: 'SX' },
  { label: 'Slovakia', value: 'SK', filteringTags: [FILTER_TAG_EU] },
  { label: 'Slovenia', value: 'SI', filteringTags: [FILTER_TAG_EU] },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  {
    label: 'South Africa',
    value: 'ZA',
    legacyCode: 'Africa.SoAfrica',
  },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Spain', value: 'ES', filteringTags: [FILTER_TAG_EU] },
  { label: 'Sri Lanka', value: 'LK', legacyCode: 'Asia.SriLanka' },
  { label: 'Sudan (the)', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Sweden', value: 'SE', filteringTags: [FILTER_TAG_EU] },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  {
    label: 'Taiwan (Province of China)',
    value: 'TW',
    legacyCode: 'APAC.Taiwan',
  },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH', legacyCode: 'APAC.Thailand' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR', legacyCode: 'ME.Turkey' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands (the)', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA', legacyCode: 'Non-EU-EEA-CH.Ukraine' },
  {
    label: 'United Arab Emirates (the)',
    value: 'AE',
    legacyCode: 'ME.UAE',
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland (the)',
    value: 'GB',
    legacyCode: 'Non-EU-EEA-CH.UK',
  },
  { label: 'United States Minor Outlying Islands (the)', value: 'UM' },
  { label: 'United States of America (the)', value: 'US', legacyCode: 'NA.USA' },
  { label: 'Uruguay', value: 'UY', legacyCode: 'SA.Uruguay' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    value: 'VE',
    legacyCode: 'SA.Venezuela',
  },
  { label: 'Vietnam', value: 'VN', legacyCode: 'APAC.Vietnam' },
  { label: 'Virgin Islands (British)', value: 'VG' },
  { label: 'Virgin Islands (U.S.)', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Worldwide', value: 'WW', legacyCode: 'Foundational' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];

export const ARTIFACTSV2_COUNTRIES: CountryEnumDisplayMapping[] = [
  // Make 'Worldwide' appear first in dropdowns
  { label: 'Worldwide', value: 'WW', legacyCode: 'Foundational' },
  ...COUNTRIES.filter((country) => country.value !== 'EU' && country.value !== 'WW'),
];

export const ARTIFACTSV2_COUNTRIES_FOR_CREATE = [
  ...ARTIFACTSV2_COUNTRIES.filter(
    (country) => !country.filteringTags?.includes(FILTER_TAG_EU) && country.value !== 'WW'
  ),
  {
    label: 'European Union',
    options: COUNTRIES.filter((country) => country.filteringTags?.includes(FILTER_TAG_EU)),
  },
].sort((a, b) => (a.label && b.label && a.label > b.label ? 1 : -1));

// Add 'Worldwide' to beginning of country list
ARTIFACTSV2_COUNTRIES_FOR_CREATE.unshift({ label: 'Worldwide', value: 'WW', legacyCode: 'Foundational' });

/** countries where AWS has a DC */
export const MARKET_ACCESS_COUNTRIES = [
  'AE',
  'AU',
  'BH',
  'BR',
  'CA',
  'CN',
  'DE',
  'ES',
  'UK',
  'FR',
  'ID',
  'IN',
  'IT',
  'IL',
  'JP',
  'KR',
  'MY',
  'NL',
  'SG',
  'SE',
  'US',
  'ZA',
];

/**
 * These Standards are sorted in alphabetical order. When adding a new standard, maintain alphabetical order
 */
export const STANDARDS: EnumDisplayMapping[] = [
  {
    label: 'ANSI 62368-1-2019',
    value: 'ANSI 62368-1-2019',
  },
  {
    label: 'ANSI C63.4-2014 AMD ANSI C64.4a-2017',
    value: 'ANSI C63.4-2014 AMD ANSI C64.4a-2017',
  },
  {
    label: 'ANSI C63.4:2014',
    value: 'ANSI C63.4:2014',
  },
  {
    label: 'ANSI C63.4:2014/ANSI C63.4a-2017',
    value: 'ANSI C63.4:2014/ANSI C63.4a-2017',
  },
  {
    label: 'ANSI/CAN/UL 9540A:2019',
    value: 'ANSI/CAN/UL 9540A:2019',
  },
  {
    label: 'ANSI/CAN/UL 9540A:2019 4th Ed',
    value: 'ANSI/CAN/UL 9540A:2019 4th Ed',
  },
  {
    label: 'ANSI/UL 62368-1, 3rd Ed',
    value: 'ANSI/UL 62368-1, 3rd Ed',
  },
  {
    label: 'AS/NZS 60950.1:2015',
    value: 'AS/NZS 60950.1:2015',
  },
  {
    label: 'AS/NZS 62368.1:2018',
    value: 'AS/NZS 62368.1:2018',
  },
  {
    label: 'AS/NZS 62368.1:2022',
    value: 'AS/NZS 62368.1:2022',
  },
  {
    label: 'AS/NZS CISPR 32:2015 + A1:2020',
    value: 'AS/NZS CISPR 32:2015 + A1:2020',
  },
  {
    label: 'BS EN 55032:2015',
    value: 'BS EN 55032:2015',
  },
  {
    label: 'BS EN 55032:2015 + A11:2020',
    value: 'BS EN 55032:2015 + A11:2020',
  },
  {
    label: 'BS EN 55032:2015 + AC:2020',
    value: 'BS EN 55032:2015 + AC:2020',
  },
  {
    label: 'BS EN 55035:2017 + A11:2020',
    value: 'BS EN 55035:2017 + A11:2020',
  },
  {
    label: 'BS EN 60950-1:2006 +A1:2010 +A2:2013',
    value: 'BS EN 60950-1:2006 +A1:2010 +A2:2013',
  },
  {
    label: 'BS EN 61000-3-11:2000',
    value: 'BS EN 61000-3-11:2000',
  },
  {
    label: 'BS EN 61000-3-12:2011',
    value: 'BS EN 61000-3-12:2011',
  },
  {
    label: 'BS EN 61000-4-2:2009',
    value: 'BS EN 61000-4-2:2009',
  },
  {
    label: 'BS EN 61000-4-4:2012',
    value: 'BS EN 61000-4-4:2012',
  },
  {
    label: 'BS EN 61000-4-5:2014 +A1:2017',
    value: 'BS EN 61000-4-5:2014 +A1:2017',
  },
  {
    label: 'BS EN 61000-4-6:2014',
    value: 'BS EN 61000-4-6:2014',
  },
  {
    label: 'BS EN 61000-4-8:2010',
    value: 'BS EN 61000-4-8:2010',
  },
  {
    label: 'BS EN 61000-6-2:2005',
    value: 'BS EN 61000-6-2:2005',
  },
  {
    label: 'BS EN 61000-6-4:2007 +A1:2011',
    value: 'BS EN 61000-6-4:2007 +A1:2011',
  },
  {
    label: 'BS EN 62368-1:2014 +A11:2017',
    value: 'BS EN 62368-1:2014 +A11:2017',
  },
  {
    label: 'BS EN 62368-1:2014 +AC:2015',
    value: 'BS EN 62368-1:2014 +AC:2015',
  },
  {
    label: 'BS EN IEC 61000-3-11:2019',
    value: 'BS EN IEC 61000-3-11:2019',
  },
  {
    label: 'BS EN IEC 61000-4-11:2020',
    value: 'BS EN IEC 61000-4-11:2020',
  },
  {
    label: 'BS EN IEC 61000-4-3:2020',
    value: 'BS EN IEC 61000-4-3:2020',
  },
  {
    label: 'BS EN IEC 62368-1:2020 +A11:2021',
    value: 'BS EN IEC 62368-1:2020 +A11:2021',
  },
  {
    label: 'BS EN IEC 62368-1:2020+A11:2020 3rd Ed.',
    value: 'BS EN IEC 62368-1:2020+A11:2020 3rd Ed.',
  },
  {
    label: 'BS EN IEC 63000:2018',
    value: 'BS EN IEC 63000:2018',
  },
  {
    label: 'BS ΕN 61000-4-3:2006 +A1:2008 +A2:2010',
    value: 'BS ΕN 61000-4-3:2006 +A1:2008 +A2:2010',
  },
  {
    label: 'BS ΕN 61000-4-6:2014 +AC:2015',
    value: 'BS ΕN 61000-4-6:2014 +AC:2015',
  },
  {
    label: 'CAN/CSA C22.2 No. 107.1-16 (R2021)',
    value: 'CAN/CSA C22.2 No. 107.1-16 (R2021)',
  },
  {
    label: 'CAN/CSA C22.2 No. 60950-1-07',
    value: 'CAN/CSA C22.2 No. 60950-1-07',
  },
  {
    label: 'CAN/CSA C22.2 No. 60950-1-07 + A1:2011 + A2:2014',
    value: 'CAN/CSA C22.2 No. 60950-1-07 + A1:2011 + A2:2014',
  },
  {
    label: 'CAN/CSA C22.2 No. 62368-1:2014 2nd Ed',
    value: 'CAN/CSA C22.2 No. 62368-1:2014 2nd Ed',
  },
  {
    label: 'CAN/CSA C22.2 No. 62368-1:2019 3rd Ed',
    value: 'CAN/CSA C22.2 No. 62368-1:2019 3rd Ed',
  },
  {
    label: 'CISPR 32:2015',
    value: 'CISPR 32:2015',
  },
  {
    label: 'CISPR 32:2015 + A1:2019 2nd Ed',
    value: 'CISPR 32:2015 + A1:2019 2nd Ed',
  },
  {
    label: 'CISPR 32:2015 + Cor1:2016',
    value: 'CISPR 32:2015 + Cor1:2016',
  },
  {
    label: 'CISPR 35:2016',
    value: 'CISPR 35:2016',
  },
  {
    label: 'CNS 13438',
    value: 'CNS 13438',
  },
  {
    label: 'CNS 15663',
    value: 'CNS 15663',
  },
  {
    label: 'CNS 15936',
    value: 'CNS 15936',
  },
  {
    label: 'CSA C22.2 No. 62133-1:20',
    value: 'CSA C22.2 No. 62133-1:20',
  },
  {
    label: 'CSA C22.2 No. 62133-2:20',
    value: 'CSA C22.2 No. 62133-2:20',
  },
  {
    label: 'EN 300 328 V2.1.1',
    value: 'EN 300 328 V2.1.1',
  },
  {
    label: 'EN 300 328 V2.2.2',
    value: 'EN 300 328 V2.2.2',
  },
  {
    label: 'EN 300 330 V2.1.1',
    value: 'EN 300 330 V2.1.1',
  },
  {
    label: 'EN 300 386 V1.6.1',
    value: 'EN 300 386 V1.6.1',
  },
  {
    label: 'EN 300 440 V.2.1.1',
    value: 'EN 300 440 V.2.1.1',
  },
  {
    label: 'EN 300 440-2 V1.4.1',
    value: 'EN 300 440-2 V1.4.1',
  },
  {
    label: 'EN 301 489-1 v1.9.2',
    value: 'EN 301 489-1 v1.9.2',
  },
  {
    label: 'EN 301 489-17',
    value: 'EN 301 489-17',
  },
  {
    label: 'En 301-489-1/V2:2019',
    value: 'En 301-489-1/V2:2019',
  },
  {
    label: 'EN 5022:2010',
    value: 'EN 5022:2010',
  },
  {
    label: 'EN 50310:2016 + A1:2020',
    value: 'EN 50310:2016 + A1:2020',
  },
  {
    label: 'EN 55022:2010 + AC:2011',
    value: 'EN 55022:2010 + AC:2011',
  },
  {
    label: 'EN 55024:2010',
    value: 'EN 55024:2010',
  },
  {
    label: 'EN 55032:2012',
    value: 'EN 55032:2012',
  },
  {
    label: 'EN 55032:2012 + AC:2013',
    value: 'EN 55032:2012 + AC:2013',
  },
  {
    label: 'EN 55032:2015',
    value: 'EN 55032:2015',
  },
  {
    label: 'EN 55032:2015 + A1:2020',
    value: 'EN 55032:2015 + A1:2020',
  },
  {
    label: 'EN 55032:2015 + A11:2020 + A1:2020',
    value: 'EN 55032:2015 + A11:2020 + A1:2020',
  },
  {
    label: 'EN 55032:2015 + AC:2020',
    value: 'EN 55032:2015 + AC:2020',
  },
  {
    label: 'EN 55032:2015/A11:2020',
    value: 'EN 55032:2015/A11:2020',
  },
  {
    label: 'EN 55035',
    value: 'EN 55035',
  },
  {
    label: 'EN 55035:2017',
    value: 'EN 55035:2017',
  },
  {
    label: 'EN 55035:2017+A11:2020',
    value: 'EN 55035:2017+A11:2020',
  },
  {
    label: 'EN 60445: 2017',
    value: 'EN 60445: 2017',
  },
  {
    label: 'EN 60825-1:2014',
    value: 'EN 60825-1:2014',
  },
  {
    label: 'EN 60825-2:2004 +A1:2008 +A2:2010',
    value: 'EN 60825-2:2004 +A1:2008 +A2:2010',
  },
  {
    label: 'EN 60950-1:2006 2nd Ed + A11:2009 + A1:2010 + A12:2011 + A2:2013',
    value: 'EN 60950-1:2006 2nd Ed + A11:2009 + A1:2010 + A12:2011 + A2:2013',
  },
  {
    label: 'EN 60950-1:2006 2nd Ed +A11:2009 + A1:2010, +A12:2011 +A2:2013',
    value: 'EN 60950-1:2006 2nd Ed +A11:2009 + A1:2010, +A12:2011 +A2:2013',
  },
  {
    label: 'EN 61000-3-11:2000',
    value: 'EN 61000-3-11:2000',
  },
  {
    label: 'EN 61000-3-12:2011',
    value: 'EN 61000-3-12:2011',
  },
  {
    label: 'EN 61000-3-2:2014',
    value: 'EN 61000-3-2:2014',
  },
  {
    label: 'EN 61000-3-3:2013',
    value: 'EN 61000-3-3:2013',
  },
  {
    label: 'EN 61000-3-3:2013 +A1:2019 +A2:2021',
    value: 'EN 61000-3-3:2013 +A1:2019 +A2:2021',
  },
  {
    label: 'EN 61000-3-3:2013 +A2:2021',
    value: 'EN 61000-3-3:2013 +A2:2021',
  },
  {
    label: 'EN 61000-3-3:2013 +A2:2021 +AC:2022-01',
    value: 'EN 61000-3-3:2013 +A2:2021 +AC:2022-01',
  },
  {
    label: 'EN 61000-4-11:2004 +A1:2017',
    value: 'EN 61000-4-11:2004 +A1:2017',
  },
  {
    label: 'EN 61000-4-2:2009',
    value: 'EN 61000-4-2:2009',
  },
  {
    label: 'EN 61000-4-3:2006 +A1:2008 +A2:2010',
    value: 'EN 61000-4-3:2006 +A1:2008 +A2:2010',
  },
  {
    label: 'EN 61000-4-34:2007 +A1:2009',
    value: 'EN 61000-4-34:2007 +A1:2009',
  },
  {
    label: 'EN 61000-4-4:2012',
    value: 'EN 61000-4-4:2012',
  },
  {
    label: 'EN 61000-4-5:2014 +A1:2017',
    value: 'EN 61000-4-5:2014 +A1:2017',
  },
  {
    label: 'EN 61000-4-6:2014',
    value: 'EN 61000-4-6:2014',
  },
  {
    label: 'EN 61000-4-6:2014 +AC:2015',
    value: 'EN 61000-4-6:2014 +AC:2015',
  },
  {
    label: 'EN 61000-4-8: 2010',
    value: 'EN 61000-4-8: 2010',
  },
  {
    label: 'EN 61000-4-8:2010',
    value: 'EN 61000-4-8:2010',
  },
  {
    label: 'EN 61000-6-4:2007 + A1:2011',
    value: 'EN 61000-6-4:2007 + A1:2011',
  },
  {
    label: 'EN 61984:2009',
    value: 'EN 61984:2009',
  },
  {
    label: 'EN 62133-1:2017',
    value: 'EN 62133-1:2017',
  },
  {
    label: 'EN 62133-2:2017',
    value: 'EN 62133-2:2017',
  },
  {
    label: 'EN 62368-1:2014',
    value: 'EN 62368-1:2014',
  },
  {
    label: 'EN 62368-1:2014 2nd Ed + A11:2017',
    value: 'EN 62368-1:2014 2nd Ed + A11:2017',
  },
  {
    label: 'EN 62368-1:2014/AC:2015',
    value: 'EN 62368-1:2014/AC:2015',
  },
  {
    label: 'EN 62619:2017',
    value: 'EN 62619:2017',
  },
  {
    label: 'EN IEC 61000-3-11:2019',
    value: 'EN IEC 61000-3-11:2019',
  },
  {
    label: 'EN IEC 61000-3-2:2019+A1:2021',
    value: 'EN IEC 61000-3-2:2019+A1:2021',
  },
  {
    label: 'EN IEC 61000-3-3:2021',
    value: 'EN IEC 61000-3-3:2021',
  },
  {
    label: 'EN IEC 61000-4-11:2020',
    value: 'EN IEC 61000-4-11:2020',
  },
  {
    label: 'EN IEC 61000-4-11:2020 +AC:2020',
    value: 'EN IEC 61000-4-11:2020 +AC:2020',
  },
  {
    label: 'EN IEC 61000-4-3:2020',
    value: 'EN IEC 61000-4-3:2020',
  },
  {
    label: 'EN IEC 62368-1:2020',
    value: 'EN IEC 62368-1:2020',
  },
  {
    label: 'EN IEC 62368-1:2020+A11',
    value: 'EN IEC 62368-1:2020+A11',
  },
  {
    label: 'EN IEC 63000:2018',
    value: 'EN IEC 63000:2018',
  },
  {
    label: 'FCC Part 15 Subpart B',
    value: 'FCC Part 15 Subpart B',
  },
  {
    label: 'FCC Part 15 Subpart C',
    value: 'FCC Part 15 Subpart C',
  },
  {
    label: 'GB 17625.1-2012',
    value: 'GB 17625.1-2012',
  },
  {
    label: 'GB 4943.1-2011',
    value: 'GB 4943.1-2011',
  },
  {
    label: 'GB 4943.1-2022',
    value: 'GB 4943.1-2022',
  },
  {
    label: 'GB 9254-2008',
    value: 'GB 9254-2008',
  },
  {
    label: 'GB/T 9254-2008',
    value: 'GB/T 9254-2008',
  },
  {
    label: 'GB/T 9254.1-2021',
    value: 'GB/T 9254.1-2021',
  },
  {
    label: 'ICES-003 Issue 6',
    value: 'ICES-003 Issue 6',
  },
  {
    label: 'ICES-003 issue 7',
    value: 'ICES-003 issue 7',
  },
  {
    label: 'ICES-Gen:2018 Issue 1 +A1:2021',
    value: 'ICES-Gen:2018 Issue 1 +A1:2021',
  },
  {
    label: 'IEC 60309-1:1999+A1:2007+A2:2012',
    value: 'IEC 60309-1:1999+A1:2007+A2:2012',
  },
  {
    label: 'IEC 60445: b2017 Ed. 6',
    value: 'IEC 60445: b2017 Ed. 6',
  },
  {
    label: 'IEC 60825-1:2014',
    value: 'IEC 60825-1:2014',
  },
  {
    label: 'IEC 60950-1:2005',
    value: 'IEC 60950-1:2005',
  },
  {
    label: 'IEC 60950-1:2005 + A1:2009',
    value: 'IEC 60950-1:2005 + A1:2009',
  },
  {
    label: 'IEC 60950-1:2005 + A1:2009 + A2:2013 2nd Ed',
    value: 'IEC 60950-1:2005 + A1:2009 + A2:2013 2nd Ed',
  },
  {
    label: 'IEC 61000-3-11:2019',
    value: 'IEC 61000-3-11:2019',
  },
  {
    label: 'IEC 61000-3-12:2011',
    value: 'IEC 61000-3-12:2011',
  },
  {
    label: 'IEC 61000-3-2:2014 4th Ed',
    value: 'IEC 61000-3-2:2014 4th Ed',
  },
  {
    label: 'IEC 61000-4-11:2017 ED. 2.1',
    value: 'IEC 61000-4-11:2017 ED. 2.1',
  },
  {
    label: 'IEC 61000-4-11:2020 3rd Ed',
    value: 'IEC 61000-4-11:2020 3rd Ed',
  },
  {
    label: 'IEC 61000-4-11:b2020',
    value: 'IEC 61000-4-11:b2020',
  },
  {
    label: 'IEC 61000-4-2:2008 2nd Ed',
    value: 'IEC 61000-4-2:2008 2nd Ed',
  },
  {
    label: 'IEC 61000-4-3:2006+A1:2007',
    value: 'IEC 61000-4-3:2006+A1:2007',
  },
  {
    label: 'IEC 61000-4-3:2010 ED. 3.2',
    value: 'IEC 61000-4-3:2010 ED. 3.2',
  },
  {
    label: 'IEC 61000-4-3:2020 ED. 4.0',
    value: 'IEC 61000-4-3:2020 ED. 4.0',
  },
  {
    label: 'IEC 61000-4-34:2005 +A1:2009 ED. 1.1',
    value: 'IEC 61000-4-34:2005 +A1:2009 ED. 1.1',
  },
  {
    label: 'IEC 61000-4-4:2012',
    value: 'IEC 61000-4-4:2012',
  },
  {
    label: 'IEC 61000-4-5:2014 ED. 3.0',
    value: 'IEC 61000-4-5:2014 ED. 3.0',
  },
  {
    label: 'IEC 61000-4-5:2014+A1:2017',
    value: 'IEC 61000-4-5:2014+A1:2017',
  },
  {
    label: 'IEC 61000-4-6:2013',
    value: 'IEC 61000-4-6:2013',
  },
  {
    label: 'IEC 61000-4-6:2013 + AC:2015',
    value: 'IEC 61000-4-6:2013 + AC:2015',
  },
  {
    label: 'IEC 61000-4-8:2009',
    value: 'IEC 61000-4-8:2009',
  },
  {
    label: 'IEC 61010-1:2010',
    value: 'IEC 61010-1:2010',
  },
  {
    label: 'IEC 61010-1:2010 + A1:2016',
    value: 'IEC 61010-1:2010 + A1:2016',
  },
  {
    label: 'IEC 61010-1:2010 + A1:2016 + COR1:2019',
    value: 'IEC 61010-1:2010 + A1:2016 + COR1:2019',
  },
  {
    label: 'IEC 61010-2-201:2017',
    value: 'IEC 61010-2-201:2017',
  },
  {
    label: 'IEC 61984:2008',
    value: 'IEC 61984:2008',
  },
  {
    label: 'IEC 61984:2008 2nd Ed',
    value: 'IEC 61984:2008 2nd Ed',
  },
  {
    label: 'IEC 62133-1:b2017 1st Ed',
    value: 'IEC 62133-1:b2017 1st Ed',
  },
  {
    label: 'IEC 62133-2:b2017 1st Ed',
    value: 'IEC 62133-2:b2017 1st Ed',
  },
  {
    label: 'IEC 62368-1: 2014 2nd Ed.',
    value: 'IEC 62368-1: 2014 2nd Ed.',
  },
  {
    label: 'IEC 62368-1: b2018 3rd Ed.',
    value: 'IEC 62368-1: b2018 3rd Ed.',
  },
  {
    label: 'IEC 62368-1:2018 3rd Ed',
    value: 'IEC 62368-1:2018 3rd Ed',
  },
  {
    label: 'IEC 62619: 2017 Ed. 1',
    value: 'IEC 62619: 2017 Ed. 1',
  },
  {
    label: 'IS 13252(Part 1):2010',
    value: 'IS 13252(Part 1):2010',
  },
  {
    label: 'IS 13252(Part 1):2010/ IEC 60950-1:2005',
    value: 'IS 13252(Part 1):2010/ IEC 60950-1:2005',
  },
  {
    label: 'J60950-1 (H29)',
    value: 'J60950-1 (H29)',
  },
  {
    label: 'J62368-1 (2020)',
    value: 'J62368-1 (2020)',
  },
  {
    label: 'J62368-1 (2023)',
    value: 'J62368-1 (2023)',
  },
  {
    label: 'K 60950-1',
    value: 'K 60950-1',
  },
  {
    label: 'KN 301 489-1',
    value: 'KN 301 489-1',
  },
  {
    label: 'KN 301 489-17',
    value: 'KN 301 489-17',
  },
  {
    label: 'KN 32',
    value: 'KN 32',
  },
  {
    label: 'KN 35',
    value: 'KN 35',
  },
  {
    label: 'NEBS GR-1089-CORE: 2017 Issue 7',
    value: 'NEBS GR-1089-CORE: 2017 Issue 7',
  },
  {
    label: 'NEBS GR-63-CORE: 2017 Issue 5',
    value: 'NEBS GR-63-CORE: 2017 Issue 5',
  },
  {
    label: 'NOM-001-SCFI',
    value: 'NOM-001-SCFI',
  },
  {
    label: 'NOM-003-SCFI',
    value: 'NOM-003-SCFI',
  },
  {
    label: 'NOM-016-SCFI',
    value: 'NOM-016-SCFI',
  },
  {
    label: 'NOM-019-SCFI',
    value: 'NOM-019-SCFI',
  },
  {
    label: 'NOM-0208-SCFI',
    value: 'NOM-0208-SCFI',
  },
  {
    label: 'NOM-024-SCFI',
    value: 'NOM-024-SCFI',
  },
  {
    label: 'NOM-029-ENER',
    value: 'NOM-029-ENER',
  },
  {
    label: 'NOM-032-ENER',
    value: 'NOM-032-ENER',
  },
  {
    label: 'NOM-050-SCFI',
    value: 'NOM-050-SCFI',
  },
  {
    label: 'NOM-196-SCFI',
    value: 'NOM-196-SCFI',
  },
  {
    label: 'NOM-218-SCFI',
    value: 'NOM-218-SCFI',
  },
  {
    label: 'NOM-221-SCFI',
    value: 'NOM-221-SCFI',
  },
  {
    label: 'RSS-102:2015 Issue 5 +A1:2021',
    value: 'RSS-102:2015 Issue 5 +A1:2021',
  },
  {
    label: 'RSS-210: 2019 Issue 10 +A1:2020',
    value: 'RSS-210: 2019 Issue 10 +A1:2020',
  },
  {
    label: 'RSS-GEN: 2018 Issue 5 +A1:2019 +A2:2021',
    value: 'RSS-GEN: 2018 Issue 5 +A1:2019 +A2:2021',
  },
  {
    label: 'SANS 2332:2017',
    value: 'SANS 2332:2017',
  },
  {
    label: 'SANS 60950-1:2014. Edition 2.2',
    value: 'SANS 60950-1:2014. Edition 2.2',
  },
  {
    label: 'SASO-IEC 62368-1:2020',
    value: 'SASO-IEC 62368-1:2020',
  },
  {
    label: 'SI 62368',
    value: 'SI 62368',
  },
  {
    label: 'ST/SG/AC.10/11/Rev.7',
    value: 'ST/SG/AC.10/11/Rev.7',
  },
  {
    label: 'UL 1012, 8th, (2021-03-30)',
    value: 'UL 1012, 8th, (2021-03-30)',
  },
  {
    label: 'UL 1642',
    value: 'UL 1642',
  },
  {
    label: 'UL 1863',
    value: 'UL 1863',
  },
  {
    label: 'UL 1973',
    value: 'UL 1973',
  },
  {
    label: 'UL 294',
    value: 'UL 294',
  },
  {
    label: 'UL 60950-1',
    value: 'UL 60950-1',
  },
  {
    label: 'UL 60950-1:2007',
    value: 'UL 60950-1:2007',
  },
  {
    label: 'UL 60950-1:2007 2nd Ed +A1:2011 +A2:2014',
    value: 'UL 60950-1:2007 2nd Ed +A1:2011 +A2:2014',
  },
  {
    label: 'UL 60950-1:2nd Ed.',
    value: 'UL 60950-1:2nd Ed.',
  },
  {
    label: 'UL 62368-1: 2014 2nd Ed',
    value: 'UL 62368-1: 2014 2nd Ed',
  },
  {
    label: 'UL 62368-1: 2019, 3rd Ed',
    value: 'UL 62368-1: 2019, 3rd Ed',
  },
  {
    label: 'UL 796',
    value: 'UL 796',
  },
  {
    label: 'UL 817:2021',
    value: 'UL 817:2021',
  },
  {
    label: 'UL 9540A',
    value: 'UL 9540A',
  },
  {
    label: 'VCCI-CISPR 32',
    value: 'VCCI-CISPR 32',
  },
];

export const ISSUING_BODIES: EnumDisplayMapping[] = [
  { value: 'A+' },
  { value: 'ACMA' },
  { value: 'Anatel' },
  { value: 'ANCE' },
  { value: 'ANRT' },
  { value: 'ARCOTEL' },
  { value: 'ASEP' },
  { value: 'ATL' },
  { value: 'AUDIX' },
  { value: 'BIS' },
  { value: 'BSMI' },
  { value: 'BTL' },
  { value: 'BTRC' },
  { value: 'BV' },
  { value: 'CAK' },
  { value: 'CCC' },
  { value: 'CE ' },
  { value: 'CEB' },
  { value: 'CITC' },
  { value: 'CONATEL' },
  { value: 'CRA' },
  { value: 'CRAN' },
  { value: 'CSA' },
  { value: 'CTTL' },
  { value: 'DEKRA' },
  { value: 'DSPR' },
  { value: 'DST' },
  { value: 'DTH' },
  { value: 'EAC' },
  { value: 'EAFSM' },
  { value: 'ENACOM' },
  { value: 'ESMA' },
  { value: 'ETL Intertek' },
  { value: 'Eurofins' },
  { value: 'FCC' },
  { value: 'FSP' },
  { value: 'GITEKI' },
  { value: 'IBEC' },
  { value: 'ICASA' },
  { value: 'ICTA' },
  { value: 'IFITEL' },
  { value: 'IFT' },
  { value: 'IMDA' },
  { value: 'Industry Canada' },
  { value: 'IRAM' },
  { value: 'IST' },
  { value: 'KC' },
  { value: 'KCC' },
  { value: 'KEBS' },
  { value: 'KOMINOFO' },
  { value: 'KOSTEC' },
  { value: 'KTC' },
  { value: 'KTR' },
  { value: 'LCIE BV' },
  { value: 'Manufacturer' },
  { value: 'MCMC' },
  { value: 'MET' },
  { value: 'MIC' },
  { value: 'MII' },
  { value: 'MinTIC' },
  { value: 'MOC' },
  { value: 'MPT' },
  { value: 'N/A' },
  { value: 'NBTC' },
  { value: 'NCA' },
  { value: 'NCC Nigeria' },
  { value: 'NCC Taiwan' },
  { value: 'NCCIR' },
  { value: 'NOM' },
  { value: 'NRCan' },
  { value: 'NRCS' },
  { value: 'NTC' },
  { value: 'NTS' },
  { value: 'NTRA' },
  { value: 'NYCE' },
  { value: 'OFCA' },
  { value: 'PTA' },
  { value: 'QTK' },
  { value: 'RRA' },
  { value: 'SABS' },
  { value: 'SDPPI' },
  { value: 'SII' },
  { value: 'SII' },
  { value: 'SIRIM' },
  { value: 'SITTEL' },
  { value: 'SON' },
  { value: 'SONCAP' },
  { value: 'SRRC' },
  { value: 'STB' },
  { value: 'SUBTEL' },
  { value: 'Supplier' },
  { value: 'SUTEL' },
  { value: 'TEC' },
  { value: 'TERTEC' },
  { value: 'TRA' },
  { value: 'TSII' },
  { value: 'TUV Rheinland' },
  { value: 'TUV SUD' },
  { value: 'UL' },
  { value: 'URSEC' },
  { value: 'VCCI' },
  { value: 'VNTA' },
  { value: 'ZICTA' },
];

export const APPLICANT_TYPES: EnumDisplayMapping[] = [
  { label: 'AWS', value: 'aws' },
  { label: 'Manufacturer', value: 'manufacturer' },
  { label: 'Third Party', value: 'third_party' },
];

/** License Manager Constants */

export const TRANSACTION_DIRECTION: EnumDisplayMapping[] = [
  { label: 'Import', value: 'IMPORT' },
  { label: 'Export', value: 'EXPORT' },
];

export const LM_AUTHORIZATION_TYPE: EnumDisplayMapping[] = ARTIFACT_SUBTYPE_MENU_OPTIONS.filter((subType) => {
  return subType.filteringTags?.includes('export_authorization');
});

export const CURRENCY: EnumDisplayMapping[] = Object.values(Currency).map((currency) => ({ value: currency }));

export const COUNTRY_CURRENCY_MAPPING: Record<string, string> = {
  AD: 'EUR',
  AE: 'AED',
  AF: 'AFN',
  AG: 'XCD',
  AI: 'XCD',
  AL: 'ALL',
  AM: 'AMD',
  AO: 'AOA',
  AR: 'ARS',
  AS: 'USD',
  AT: 'EUR',
  AU: 'AUD',
  AW: 'AWG',
  AX: 'EUR',
  AZ: 'AZN',
  BA: 'BAM',
  BB: 'BBD',
  BD: 'BDT',
  BE: 'EUR',
  BF: 'XOF',
  BG: 'BGN',
  BH: 'BHD',
  BI: 'BIF',
  BJ: 'XOF',
  BL: 'EUR',
  BM: 'BMD',
  BN: 'BND',
  BO: 'BOB',
  BQ: 'USD',
  BR: 'BRL',
  BS: 'BSD',
  BT: 'BTN',
  BV: 'NOK',
  BW: 'BWP',
  BY: 'BYN',
  BZ: 'BZD',
  CA: 'CAD',
  CC: 'AUD',
  CD: 'CDF',
  CF: 'XAF',
  CG: 'CDF',
  CH: 'CHF',
  CI: 'XOF',
  CK: 'NZD',
  CL: 'CLP',
  CM: 'XAF',
  CN: 'CNY',
  CO: 'COP',
  CR: 'CRC',
  CU: 'CUC',
  CV: 'CVE',
  CW: 'ANG',
  CX: 'AUD',
  CY: 'EUR',
  CZ: 'CZK',
  DE: 'EUR',
  DJ: 'DJF',
  DK: 'DKK',
  DM: 'DOP',
  DO: 'DOP',
  DZ: 'DZD',
  EC: 'USD',
  EE: 'EUR',
  EG: 'EGP',
  EH: 'MAD',
  ER: 'ERN',
  ES: 'EUR',
  EU: 'EUR',
  ET: 'ETB',
  FI: 'EUR',
  FJ: 'FJD',
  FM: 'USD',
  FO: 'DKK',
  FR: 'EUR',
  GA: 'XAF',
  GB: 'GBP',
  GD: 'XCD',
  GE: 'GEL',
  GF: 'EUR',
  GG: 'GBP',
  GH: 'GHS',
  GI: 'GIP',
  GL: 'DKK',
  GM: 'GMD',
  GN: 'GNF',
  GP: 'EUR',
  GQ: 'XAF',
  GR: 'EUR',
  GT: 'GTQ',
  GU: 'USD',
  GW: 'XOF',
  GY: 'GYD',
  HK: 'HKD',
  HN: 'HNL',
  HR: 'HRK',
  HT: 'HTG',
  HU: 'HUF',
  ID: 'IDR',
  IE: 'EUR',
  IL: 'ILS',
  IM: 'GBP',
  IN: 'INR',
  IO: 'USD',
  IQ: 'IQD',
  IR: 'IRR',
  IS: 'ISK',
  IT: 'EUR',
  JE: 'GBP',
  JM: 'JMD',
  JO: 'JOD',
  JP: 'JPY',
  KE: 'KES',
  KG: 'KGS',
  KH: 'KHR',
  KI: 'AUD',
  KM: 'KMF',
  KN: 'XCD',
  KP: 'KPW',
  KR: 'KRW',
  KW: 'KWD',
  KY: 'KYD',
  KZ: 'KZT',
  LB: 'LBP',
  LC: 'XCD',
  LI: 'CHF',
  LK: 'LKR',
  LR: 'LRD',
  LS: 'LSL',
  LT: 'EUR',
  LU: 'EUR',
  LV: 'EUR',
  LY: 'LYD',
  MA: 'MAD',
  MC: 'EUR',
  MD: 'MDL',
  ME: 'EUR',
  MF: 'EUR',
  MG: 'MGA',
  MH: 'USD',
  ML: 'XOF',
  MM: 'MMK',
  MN: 'MNT',
  MO: 'MOP',
  MP: 'USD',
  MQ: 'EUR',
  MR: 'MRU',
  MS: 'XCD',
  MT: 'EUR',
  MU: 'MUR',
  MV: 'MVR',
  MW: 'MWK',
  MX: 'MXN',
  MY: 'MYR',
  MZ: 'MZN',
  NA: 'NAD',
  NC: 'XPF',
  NE: 'NGN',
  NF: 'AUD',
  NG: 'NGN',
  NI: 'NIO',
  NL: 'EUR',
  NO: 'NOK',
  NP: 'NPR',
  NR: 'AUD',
  NU: 'NZD',
  NZ: 'NZD',
  OM: 'OMR',
  PA: 'PAB',
  PE: 'PEN',
  PF: 'XPF',
  PG: 'PGK',
  PH: 'PHP',
  PK: 'PKR',
  PL: 'PLN',
  PM: 'EUR',
  PN: 'NZD',
  PR: 'USD',
  PT: 'EUR',
  PW: 'USD',
  PY: 'PYG',
  QA: 'QAR',
  RE: 'EUR',
  RO: 'RON',
  RS: 'RSD',
  RU: 'RUB',
  RW: 'RWF',
  SA: 'SAR',
  SB: 'SBD',
  SC: 'SCR',
  SD: 'SDG',
  SE: 'SEK',
  SG: 'SGD',
  SH: 'SHP',
  SI: 'EUR',
  SJ: 'NOK',
  SK: 'EUR',
  SL: 'SLL',
  SM: 'EUR',
  SN: 'XOF',
  SO: 'SOS',
  SR: 'SRD',
  SS: 'SSP',
  ST: 'STN',
  SV: 'SVC',
  SX: 'ANG',
  SY: 'SYP',
  SZ: 'SZL',
  TC: 'USD',
  TD: 'XAF',
  TF: 'EUR',
  TG: 'XOF',
  TH: 'THB',
  TJ: 'TJS',
  TK: 'NZD',
  TL: 'USD',
  TM: 'TMT',
  TN: 'TND',
  TO: 'TOP',
  TR: 'TRY',
  TT: 'TTD',
  TV: 'AUD',
  TW: 'TWD',
  TZ: 'TZS',
  UA: 'UAH',
  UG: 'UGX',
  UM: 'USD',
  US: 'USD',
  UY: 'UYI',
  UZ: 'UZS',
  VA: 'EUR',
  VC: 'XCD',
  VE: 'VES',
  VG: 'USD',
  VI: 'USD',
  VN: 'VND',
  VU: 'VUV',
  WF: 'XPF',
  WS: 'USD',
  YE: 'YER',
  YT: 'EUR',
  ZA: 'ZAR',
  ZM: 'ZMW',
  ZW: 'ZWL',
  HM: 'AUD',
  FK: 'FKP',
  GS: 'GEL',
  LA: 'LAK',
  MK: 'MKD',
  AQ: 'USD',
  PS: 'ILS',
};

export const COMMODITY: EnumDisplayMapping[] = [
  { label: 'Hardware', value: 'HARDWARE' },
  { label: 'Service', value: 'SERVICE' },
  { label: 'Software', value: 'SOFTWARE' },
  { label: 'Source Code', value: 'SOURCE_CODE' },
  { label: 'Technology', value: 'TECHNOLOGY' },
];

/** Supersku and AWS Project External Names for Artifacts - Potential Throwaway */
export const SUPERSKU_NAMES: EnumDisplayMapping[] = [
  { value: 'Adam_Scott' },
  { value: 'Albert_Einstein' },
  { value: 'Alfred_Hermann_Fried' },
  { value: 'Alfred_Werner' },
  { value: 'Alice_Munro' },
  { value: 'Angel_Cabrera' },
  { value: 'Arnold_Palmer' },
  { value: 'Aung_Kyi' },
  { value: 'Bertha_von_Suttner' },
  { value: 'Billy_Casper' },
  { value: 'Bjornstjerne_Bjornson' },
  { value: 'Bob_Dylan' },
  { value: 'Brian_Schmidt' },
  { value: 'Camillo_Golgi' },
  { value: 'Carl_Bosch' },
  { value: 'Carlos_Lamas' },
  { value: 'Charles_Albert_Gobat' },
  { value: 'Charles_Richet' },
  { value: 'Donna_Strickland' },
  { value: 'Drawbridge_Main' },
  { value: 'EOL_No_Demand' },
  { value: 'Eduard_Buchner' },
  { value: 'Elie_Ducommun' },
  { value: 'Elihu_Root' },
  { value: 'Ernest_Rutherford' },
  { value: 'Ernesto_Teodoro_Moneta' },
  { value: 'Francis_Crick' },
  { value: 'Frederic_Mistral' },
  { value: 'Frederic_Passy' },
  { value: 'Frederick_Sanger' },
  { value: 'Fredrik_Bajer' },
  { value: 'Gerhart_Hauptmann' },
  { value: 'Gertrude_Elion' },
  { value: 'Giosue_Carducci' },
  { value: 'Gustaf_Dalen' },
  { value: 'Henri_Moissan' },
  { value: 'Henry_Dunant' },
  { value: 'Henryk_Sienkiewicz' },
  { value: 'Hermann_Emil_Fischer' },
  { value: 'Hermann_Muller' },
  { value: 'Jimmy_Carter' },
  { value: 'John_Bardeen' },
  { value: 'Jose_Echegaray' },
  { value: 'Klas_Pontus_Arnoldson' },
  { value: 'Kofi_Annan' },
  { value: 'Leon_Lederman' },
  { value: 'Leonid_Hurwicz' },
  { value: 'Linus_Pauling' },
  { value: 'Lord_Rayleigh' },
  { value: 'Louis_Renault' },
  { value: 'Maurice_Maeterlinck' },
  { value: 'May_Moser' },
  { value: 'Niels_Bohr' },
  { value: 'No_Production_Plan' },
  { value: 'Otto_Wallach' },
  { value: 'Paul_Ehrlich' },
  { value: 'Peter_Higgs' },
  { value: 'Pierre_Curie' },
  { value: 'Rabindranath_Tagore' },
  { value: 'Ralph_Steinman' },
  { value: 'Richard_Willstatter' },
  { value: 'Rigoberta_Tum' },
  { value: 'Robert_Barany' },
  { value: 'Rudolf_Christoph_Eucken' },
  { value: 'Rudyard_Kipling' },
  { value: 'Santiago_Ramon_y_Cajal' },
  { value: 'Selma_Lagerlof' },
  { value: 'Shirin_Ebadi' },
  { value: 'Sully_Prudhomme' },
  { value: 'Svante_Arrhenius' },
  { value: 'Tenzin_Gyatso' },
  { value: 'Theodore_Roosevelt' },
  { value: 'Theodore_William_Richards' },
  { value: 'Tobias_Asser' },
  { value: 'Victor_Grignard' },
  { value: 'Wangari_Maathai' },
  { value: 'Wilhelm_Ostwald' },
  { value: 'Wilhelm_Rontgen' },
  { value: 'Wilhelm_Wien' },
  { value: 'William_Faulkner' },
  { value: 'William_Henry_Bragg' },
  { value: 'William_Lawrence_Bragg' },
  { value: 'William_Nordhaus' },
  { value: 'William_Ramsay' },
  { value: 'Woodrow_Wilson' },
];

export const AWS_PROJECT_EXTERNAL: EnumDisplayMapping[] = [
  { value: '12.8T-BR' },
  { value: '12.8T-BR-8X8' },
  { value: '12.8T-Briggs-FinalCat-Split-Brick-Odd-Rack' },
  { value: '12.8T-ENCRYPTION-BFC-8W-BR-WITH-OPTICAL-LINE-ZR-MUX-25.6T' },
  { value: '12.8T-ENCRYPTION-BFC-8W-BR-WITH-OPTICAL-LINE-ZR-MUX-INTRA-AZ' },
  { value: '12.8T-Encryption-BFC-8W-BR-Base' },
  { value: '12.8T-Encryption-BFC-8W-BR-No-Optic' },
  { value: '12.8T-Encryption-BFC-8W-BR-With-Optical-Line-ZR-MUX-No-Optic' },
  { value: '12.8T-SP' },
  { value: '12.8T-SP-8X8' },
  { value: '12.8T_ENCRYPTION_BFC_8W_BR_WITH_2_OPTICAL_MUX' },
  { value: '16S_NVR' },
  { value: '4S_NVR' },
  { value: '7018-1B12-1B16' },
  { value: '7018-1B16' },
  { value: '7018-1M108-1B16' },
  { value: 'ACADIA12' },
  { value: 'ACHERON11' },
  { value: 'ACHERON11.5' },
  { value: 'ACHERON11.5;BONSAI11.5;MIXED RACK' },
  { value: 'ACHERON11.6' },
  { value: 'ACHERON12' },
  { value: 'ACS_Controls' },
  { value: 'ADVA-100G-SHELF' },
  { value: 'ADVA-100G-SHELF-100G-Client' },
  { value: 'ADVA-100G-SHELF-TCE' },
  { value: 'ADVA-200G-SHELF' },
  { value: 'ADVA-200G-SHELF-TCE' },
  { value: 'ADVA-300G-SHELF' },
  { value: 'ADVA-300G-SHELF-100G-Client' },
  { value: 'ADVA-300G-SHELF-TCE' },
  { value: 'ADVA-400G-SHELF' },
  { value: 'ADVA-400G-SHELF-100G-Client' },
  { value: 'ADVA-400G-SHELF-TCE' },
  { value: 'ADVA-500G-SHELF-TCE' },
  { value: 'ADVA-600G-SHELF-TCE' },
  { value: 'ADVA-700G-SHELF-TCE' },
  { value: 'ALTAIR16' },
  { value: 'AMBROSE01' },
  { value: 'AMBROSE01B' },
  { value: 'AMBROSE01F' },
  { value: 'AMBROSE02' },
  { value: 'AMBROSE02F' },
  { value: 'AMBROSE03' },
  { value: 'AMBROSE03B' },
  { value: 'AMBROSE03F' },
  { value: 'AMBROSE04' },
  { value: 'AMBROSE04F' },
  { value: 'AMBROSE05' },
  { value: 'AMBROSE05F' },
  { value: 'AMBROSE06' },
  { value: 'AMBROSE06F' },
  { value: 'AMBROSE63' },
  { value: 'AMBROSE63F' },
  { value: 'AMBROSE66' },
  { value: 'AMBROSE68' },
  { value: 'AMBROSE76' },
  { value: 'AMBROSE86' },
  { value: 'AMBROSE87' },
  { value: 'ANTILA16' },
  { value: 'APOLLOF' },
  { value: 'AQUILA16' },
  { value: 'AQUILAD16' },
  { value: 'ARCHON14' },
  { value: 'ARCTIC16' },
  { value: 'ARCTICC16' },
  { value: 'ARCTICS16' },
  { value: 'ARTEMIS' },
  { value: 'ASH11' },
  { value: 'ASH11.4' },
  { value: 'ASH11.8' },
  { value: 'ASH12' },
  { value: 'ASH12.5' },
  { value: 'ASH12.6' },
  { value: 'ASH12.7' },
  { value: 'ASH13' },
  { value: 'ASH13;MIXED RACK;SKAGIT13' },
  { value: 'ASH14' },
  { value: 'ASH15' },
  { value: 'ASH16' },
  { value: 'ASH19' },
  { value: 'ASH816' },
  { value: 'ASHW16' },
  { value: 'ASHW816' },
  { value: 'ASIA_AND_BRAZIL' },
  { value: 'ATLANTA10' },
  { value: 'ATLANTA13' },
  { value: 'ATLANTA14' },
  { value: 'ATLANTA15' },
  { value: 'ATLANTA16' },
  { value: 'ATLANTA17' },
  { value: 'ATLANTA17C' },
  { value: 'ATLANTA18' },
  { value: 'ATLANTA19' },
  { value: 'ATLANTA19;ATLANTA23SF' },
  { value: 'ATLANTA19;HOPPER26SF' },
  { value: 'ATLANTA23SF' },
  { value: 'ATLANTA23SFX' },
  { value: 'ATLANTAB1' },
  { value: 'ATLANTAB2' },
  { value: 'ATLANTAR2P' },
  { value: 'AVALON12' },
  { value: 'AWS Panorama' },
  { value: 'AWS.NW.VEGETRON' },
  { value: 'AWSTEST' },
  { value: 'AZ-3500' },
  { value: 'AZ-NP-COR-R' },
  { value: 'AZ-NP-COR-V' },
  { value: 'AZ-NP-EDG-FW' },
  { value: 'AZ-NP-EDG_TRA-R_' },
  { value: 'AZ-WDM-CORP-CON-ACC' },
  { value: 'AZ-WS-EDG-FW' },
  { value: 'AZ-WS-ESC-FW' },
  { value: 'AZ-XX-EDG-FW' },
  { value: 'BAHUBALI01' },
  { value: 'BAHUBALI02' },
  { value: 'BAHUBALI03' },
  { value: 'BAHUBALI04' },
  { value: 'BAHUBALI05' },
  { value: 'BAHUBALI06' },
  { value: 'BAHUBALI07' },
  { value: 'BAHUBALI08' },
  { value: 'BAHUBALI09' },
  { value: 'BAHUBALI41' },
  { value: 'BAHUBALI49' },
  { value: 'BAHUBALI49F' },
  { value: 'BAHUBALI56' },
  { value: 'BAHUBALI59' },
  { value: 'BAHUBALI97' },
  { value: 'BAHUBALI972' },
  { value: 'BAHUBALI98' },
  { value: 'BAHUBALI99' },
  { value: 'BALDR15' },
  { value: 'BALDR15C' },
  { value: 'BALDR19' },
  { value: 'BALDR19L' },
  { value: 'BALDR19M' },
  { value: 'BANFF12' },
  { value: 'BANFF12.3' },
  { value: 'BANFF12.3;HAPUNA12;MIXED RACK' },
  { value: 'BANFF12;HAPUNA12;MIXED RACK' },
  { value: 'BANFF13' },
  { value: 'BARGE11' },
  { value: 'BARGE11.2' },
  { value: 'BARGE13' },
  { value: 'BARGE13.2' },
  { value: 'BARKLEY14' },
  { value: 'BARKLEY14D' },
  { value: 'BARKLEY15' },
  { value: 'BARKLEY16' },
  { value: 'BARKLEYB14' },
  { value: 'BARKLEYD15' },
  { value: 'BARKLEYK15' },
  { value: 'BARRAMUNDI15' },
  { value: 'BFC-40-BR' },
  { value: 'BFC-40-BR-LR4-12' },
  { value: 'BFC-40-BR-LR4-16' },
  { value: 'BFC-40-BR-LR4-8' },
  { value: 'BFC-40-BR-PSM4-12' },
  { value: 'BFC-40-BR-PSM4-16' },
  { value: 'BFC-40-BR-PSM4-8' },
  { value: 'BFC-40-MGMT' },
  { value: 'BFC-40-PANELS' },
  { value: 'BFC-40-SP' },
  { value: 'BFC-40-SP-12' },
  { value: 'BFC-40-SP-8' },
  { value: 'BFC-40-SP;BFC-40-SP-16' },
  { value: 'BFC-40-SPARES' },
  { value: 'BFC.IRIS-PATCH-LEFT' },
  { value: 'BFC.MICRO' },
  { value: 'BFC.MICRO-SPARES' },
  { value: 'BFC12.8T-BR-400G-DR4' },
  { value: 'BFC12.8T-BR-8W-400G-DR4' },
  { value: 'BFC12.8T-SP-8W-BASE-FULL' },
  { value: 'BFC12.8T-SP-BASE-FULL' },
  { value: 'BFC12.8T_SPLIT_BR_16W_BRIGGS_STRIPE3_BASE_RACK' },
  { value: 'BFC12.8T_SPLIT_EVEN_T1_BR_16W_BRIGGS_STRIPE3_BASE' },
  { value: 'BFC12.8T_SPLIT_EVEN_T1_BR_16W_BRIGGS_STRIPE3_NO_OPTICAL' },
  { value: 'BFC12.8T_SPLIT_ODD_T1_BR_16W_BRIGGS_STRIPE3_NO_OPTICAL' },
  { value: 'BFC2.0-PATCH-LEFT' },
  { value: 'BFC2.0-PATCH-RIGHT' },
  { value: 'BIGBIRD11' },
  { value: 'BLACKEYE14' },
  { value: 'BLACKEYE14;MIXED RACK;WHITESNAKE14' },
  { value: 'BLACKEYE15' },
  { value: 'BLACKEYEB14' },
  { value: 'BLACKEYEC17' },
  { value: 'BLACKEYEW15' },
  { value: 'BLACKFOOT11' },
  { value: 'BLACKFOOT13' },
  { value: 'BLACKFOOT13_M' },
  { value: 'BLACKFOOT14' },
  { value: 'BLACKFOOT14_LR' },
  { value: 'BLACKFOOT14_QFXx2' },
  { value: 'BLACKFOOT14_QFXx4' },
  { value: 'BLACKFOOT14_SR' },
  { value: 'BLACKFOOT15' },
  { value: 'BLACKFOOT16' },
  { value: 'BLACKFOOT19' },
  { value: 'BLACKFOOTA17' },
  { value: 'BLACKHOLE16' },
  { value: 'BLACKMESA16' },
  { value: 'BLACKPUDDING14' },
  { value: 'BLACKSHEAR' },
  { value: 'BLACKSHEAR16' },
  { value: 'BLACKSHEAR16-CHINA' },
  { value: 'BLACKSHEARLITE16' },
  { value: 'BLACKSHEARLITE16_R53' },
  { value: 'BLACKSHEARLITE16_SM' },
  { value: 'BLACKSHEARX16' },
  { value: 'BLACKSHEAR_PTX100G' },
  { value: 'BLACKSHEAR_PTX10G' },
  { value: 'BLACKWATCH' },
  { value: 'BLACKWATCH15' },
  { value: 'BLACKWATCH15V2' },
  { value: 'BLACKWATCH15V3' },
  { value: 'BLACKWATCH15V3_CN' },
  { value: 'BLACKWATCH15_CS' },
  { value: 'BLACKWATCH15_CS_LG' },
  { value: 'BLACKWATCH15_LG' },
  { value: 'BLACKWATCH15_SM' },
  { value: 'BLACKWATCHCN15_LG' },
  { value: 'BLACKWATCHCN15_SM' },
  { value: 'BLACKWATCH_V2' },
  { value: 'BLACKWATCH_V2_LR' },
  { value: 'BLUESTONE11' },
  { value: 'BONSAI' },
  { value: 'BONSAI11.5' },
  { value: 'BONSAI11.6' },
  { value: 'BONSAI24' },
  { value: 'BOSON16' },
  { value: 'BOXER' },
  { value: 'BR-FAB-BRICK-EDGE' },
  { value: 'BR-FAB-BRICK-LBE' },
  { value: 'BR-FAB-SPINE' },
  { value: 'BR-IRIS-BASE-40G-LR4-28' },
  { value: 'BRANTLEY' },
  { value: 'BROADWAY' },
  { value: 'BRUNO1' },
  { value: 'BRUNO1S' },
  { value: 'BRUNO2' },
  { value: 'BRUNO3' },
  { value: 'BRUNO5' },
  { value: 'BRUNO5-EBS' },
  { value: 'BRUNOCF' },
  { value: 'BRUNOF' },
  { value: 'BRUNOLA1' },
  { value: 'BRUNOLAF21' },
  { value: 'BUCKBEAKF' },
  { value: 'BUMBLEBEE16C' },
  { value: 'BUMBLEBEE2' },
  { value: 'BUMBLEBEE3' },
  { value: 'BURTON1' },
  { value: 'BURTON2' },
  { value: 'BW15NEXTGEN_CN' },
  { value: 'BYO_PCIE1U' },
  { value: 'Birch13' },
  { value: 'Briggs-BITW-Rock-Rack-Breakout-Mode-No-Optic' },
  { value: 'Briggs-BITW-Rock-Rack-Native-Mode-No-Optic' },
  { value: 'CANNONBALL16' },
  { value: 'CASSOWARY13' },
  { value: 'CASSOWARY14' },
  { value: 'CASSOWARY15' },
  { value: 'CASSOWARYB14' },
  { value: 'CATALINA01' },
  { value: 'CATALINA01B' },
  { value: 'CATALINA02' },
  { value: 'CATALINA03' },
  { value: 'CATALINA03B' },
  { value: 'CATALINA04' },
  { value: 'CATALINA05B' },
  { value: 'CATALINA06' },
  { value: 'CATALINA15' },
  { value: 'CAVANAUGH' },
  { value: 'CAVANAUGH1' },
  { value: 'CAVANAUGH2' },
  { value: 'CEDAR' },
  { value: 'CEDAR12.5' },
  { value: 'CEDAR12.5;MAPLE12.5;OAK12.5' },
  { value: 'CEDAR12.5;MIXED RACK;OAK12.5' },
  { value: 'CEDAR;MAPLE;OAK' },
  { value: 'CENTAUR' },
  { value: 'CENTAURUS16' },
  { value: 'CF-32x10G-24xSR' },
  { value: 'CF-AMP' },
  { value: 'CF-HCT' },
  { value: 'CF-MUX' },
  { value: 'CHIMERA' },
  { value: 'CHIRON16' },
  { value: 'CISCO-100G-SHELF' },
  { value: 'CISCO-200G-SHELF' },
  { value: 'CISCO-2901-CF' },
  { value: 'CISCO-300G-SHELF' },
  { value: 'CISCO-M6-LIN-PSM-NODE' },
  { value: 'CISCO-M6-LNE-AMP' },
  { value: 'CISCO-M6-LNE-NODE-96C' },
  { value: 'CISCO-M6-TNC-OSC' },
  { value: 'CISCO-ROSCO-NCS1K-800G' },
  { value: 'CISCO-ROSCO-NCS1K-EMPTY-CHASSIS' },
  { value: 'CITRINEF' },
  { value: 'CITRIX X 10' },
  { value: 'CITRIX X 10;POLLO.MG' },
  { value: 'CITRIX X 5' },
  { value: 'CLOUDMEMORY' },
  { value: 'CLOUD_RACK' },
  { value: 'CLUSTERCOMPUTE1' },
  { value: 'CO-ACC-SW' },
  { value: 'CO-BRIDGE12-NOPSU' },
  { value: 'COLOSSUS14' },
  { value: 'COLOSSUS17' },
  { value: 'COLUMBIA13' },
  { value: 'COLUMBIAA13' },
  { value: 'COMPUTE18I' },
  { value: 'CONGAREE12' },
  { value: 'CONGAREE13' },
  { value: 'CONGO15' },
  { value: 'CONSOLE-QUANTA-LB9' },
  { value: 'CONSOLE-RTR-24PORT' },
  { value: 'CONSOLE-RTR-48PORT' },
  { value: 'CONSOLE-RTR-64PORT' },
  { value: 'CONSOLE-SW-64PORT' },
  { value: 'CORONA16' },
  { value: 'CR2' },
  { value: 'CROWFOOT15' },
  { value: 'CRUXL16' },
  { value: 'CRUXLN16' },
  { value: 'CRUXM16' },
  { value: 'CRUXMN16' },
  { value: 'CRUXS16' },
  { value: 'CRUXSN16' },
  { value: 'CUB04_FAMILY' },
  { value: 'CUBSCOUT06AC' },
  { value: 'CUBSCOUT06DC' },
  { value: 'CUBSCOUT07AC' },
  { value: 'CUBSCOUT07DC' },
  { value: 'CYCLOPS14' },
  { value: 'CYCLOPS15' },
  { value: 'CYCLOPS16' },
  { value: 'CYPRESS11' },
  { value: 'Ciena-6500-Line-F' },
  { value: 'Claymore' },
  { value: 'Claymore0' },
  { value: 'ClaymoreHD' },
  { value: 'D3F' },
  { value: 'DAGNY' },
  { value: 'DAGNYSHELF15' },
  { value: 'DAGNYSHELF16' },
  { value: 'DAGNYSLED15' },
  { value: 'DAGNYSLED16' },
  { value: 'DANUBE15' },
  { value: 'DENALI11' },
  { value: 'DENSESTG09' },
  { value: 'DENSESTG09.5' },
  { value: 'DENSESTORE08' },
  { value: 'DENSESTORE09' },
  { value: 'DENSESTORE09.5' },
  { value: 'DENSTRG09' },
  { value: 'DENVER0' },
  { value: 'DENVER2' },
  { value: 'DF_NetworkRack' },
  { value: 'DINGO15' },
  { value: 'DL180G5' },
  { value: 'DOBERMAN' },
  { value: 'DOGWOOD11' },
  { value: 'DORI14_MINI' },
  { value: 'DORI14_QP' },
  { value: 'DORI14_S' },
  { value: 'DORI15' },
  { value: 'DORI_L18' },
  { value: 'DORI_S18' },
  { value: 'DOUBLESTAR18' },
  { value: 'DOUGLASFIR' },
  { value: 'DOUGLASFIR09' },
  { value: 'DRACO16' },
  { value: 'DRAGOON14' },
  { value: 'DRAWBRIDGE' },
  { value: 'DUPLO' },
  { value: 'DWDM ADVA 100G EQUIPMENT' },
  { value: 'DWDM ADVA ILA' },
  { value: 'DWDM ADVA TERMINAL SHELF' },
  { value: 'DWDM ADVA TRIBUTARY SHELF' },
  { value: 'DWDM CISCO 100G EQUIPMENT' },
  { value: 'DWDM CISCO ILA ANSI' },
  { value: 'DWDM CISCO ILA ETSI' },
  { value: 'DWDM CISCO TERMINAL SHELF' },
  { value: 'DWDM CISCO TERM_PSM_ANSI' },
  { value: 'DWDM CISCO TERM_PSM_ETSI' },
  { value: 'DWDM CISCO TRIBUTARY SHELF' },
  { value: 'DWDM-2OLS-2CMD64-T2-CIENA-With-Briggs-BITW-No-Optic' },
  { value: 'DWDM_1OLS_1CMD64-T2_1TXP_10G_CIENA' },
  { value: 'DWDM_1OLS_1CMD64-T2_1TXP_10G_INFINERA' },
  { value: 'DWDM_1OLS_1CMD64-T2_1TXP_CIENA' },
  { value: 'DWDM_1OLS_1CMD64-T2_1TXP_INFINERA' },
  { value: 'DWDM_1OLS_1CMD64-T2_CIENA' },
  { value: 'DWDM_1OLS_1CMD64-T2_INFINERA' },
  { value: 'DWDM_1OLS_NO_MUX_CIENA' },
  { value: 'DWDM_1OLS_NO_MUX_INFINERA' },
  { value: 'DWDM_2OLS_2CMD64-T2_1TXP_10G_CIENA' },
  { value: 'DWDM_2OLS_2CMD64-T2_1TXP_10G_INFINERA' },
  { value: 'DWDM_2OLS_2CMD64-T2_1TXP_CIENA' },
  { value: 'DWDM_2OLS_2CMD64-T2_1TXP_INFINERA' },
  { value: 'DWDM_2OLS_2CMD64-T2_2TXP_10G_CIENA' },
  { value: 'DWDM_2OLS_2CMD64-T2_2TXP_10G_INFINERA' },
  { value: 'DWDM_2OLS_2CMD64-T2_2TXP_CIENA' },
  { value: 'DWDM_2OLS_2CMD64-T2_2TXP_INFINERA' },
  { value: 'DWDM_2OLS_2CMD64-T2_CIENA' },
  { value: 'DWDM_2OLS_2CMD64-T2_INFINERA' },
  { value: 'DWDM_2OLS_NO_MUX_CIENA' },
  { value: 'DWDM_2OLS_NO_MUX_INFINERA' },
  { value: 'DWDM_3OLS_3CMD64-T2_3TXP_CIENA' },
  { value: 'DWDM_3OLS_3CMD64-T2_1TXP_CIENA' },
  { value: 'DWDM_3OLS_3CMD64-T2_1TXP_INFINERA' },
  { value: 'DWDM_4OLS_4CMD64-T2_CIENA' },
  { value: 'DWDM_4OLS_4CMD64-T2_INFINERA' },
  { value: 'DX-SMUDGE-1.0' },
  { value: 'DX-SMUDGE20' },
  { value: 'DeviceFarm' },
  { value: 'Doberman WMW' },
  { value: 'Drawbridge Main' },
  { value: 'Drawbridge Main XL' },
  { value: 'Drawbridge WMW' },
  { value: 'Drawbridge_AC' },
  { value: 'Drawbridge_DC' },
  { value: 'EC2.CC1.109' },
  { value: 'EC2C109' },
  { value: 'EC2C109;MIXED RACK;SLIMIOPS09' },
  { value: 'EC2COMPUTEA08' },
  { value: 'EC2COMPUTEB08' },
  { value: 'EC2COMPUTECG109' },
  { value: 'EC2COMPUTECG110' },
  { value: 'EC2COMPUTER109' },
  { value: 'EC2M209.5' },
  { value: 'ECTO' },
  { value: 'ED-MGMT-3500-LR' },
  { value: 'ED-SVC-3500-LR' },
  { value: 'ED-SVC-3500-SR' },
  { value: 'ED-SVC-5100' },
  { value: 'ED-SVC-5100-24Q-32' },
  { value: 'ED-SVC-5100-CONS' },
  { value: 'EIRENE01' },
  { value: 'EIRENE02' },
  { value: 'EIRENE03' },
  { value: 'EIRENE04' },
  { value: 'EIRENE05' },
  { value: 'EIRENE06' },
  { value: 'EITHNE14' },
  { value: 'EITHNE16' },
  { value: 'EITHNEMD16' },
  { value: 'EITHNESM16' },
  { value: 'EITHNElg14' },
  { value: 'EITHNEmd14' },
  { value: 'EITHNEsm14' },
  { value: 'ELF13' },
  { value: 'EMEA' },
  { value: 'EMERALD' },
  { value: 'EMERALDF' },
  { value: 'EMPTY RACK' },
  { value: 'EMPTY.RACK.LAB.20INCH.1X25GB' },
  { value: 'EMPTY.RACK.LAB.20INCH.2X25GB' },
  { value: 'EMPTY.RACK.LAB.C16.100G-100G.2X25GB' },
  { value: 'EMPTY.RACK.LAB.C16.2X25GB' },
  { value: 'EMU14' },
  { value: 'EMU15' },
  { value: 'EMUB14' },
  { value: 'EN-CON-AGG-5100-LEG' },
  { value: 'EN-CORP-AGG-CR-5100' },
  { value: 'ENT-T2-NZ' },
  { value: 'ENTERPRISE-NAZ-BUILD' },
  { value: 'ENTERPRISE-TC-ONLY-BLD' },
  { value: 'EUCLID' },
  { value: 'EUCLID BRICK-TO-SPINE KIT' },
  { value: 'EUCLID-48-BR' },
  { value: 'EUCLID-48-BR-LR' },
  { value: 'EUCLID-48-SP' },
  { value: 'EUCLID-96-SP' },
  { value: 'EUCLID-BR-SP-KIT' },
  { value: 'EUCLID12.8T-BR' },
  { value: 'EUCLID12.8T-BR-100G-CWDM4' },
  { value: 'EUCLID12.8T-BR-100G-PSM4' },
  { value: 'EUCLID12.8T-BR-400G-DR4' },
  { value: 'EUCLID12.8T-BR-8W-400G-DR4' },
  { value: 'EUCLID12.8T-SP-8W-BASE-FULL' },
  { value: 'EUCLID12.8T-SP-BASE' },
  { value: 'EUCLID12.8T-SP-BASE-FULL' },
  { value: 'EUCLID2.0-32-BR' },
  { value: 'EUCLID2.0-32-BR POSTPONEMENT' },
  { value: 'EUCLID2.0-32-BR-GF' },
  { value: 'EUCLID2.0-32-BR-GF POSTPONEMENT' },
  { value: 'EUCLID2.0-32-SP' },
  { value: 'EUCLID2.0-32-TS' },
  { value: 'EUCLID2.0-MINI-32-BR' },
  { value: 'EUCLID2.0-MINI-32-BR-GF' },
  { value: 'EUCLID2.0-MINI-32-SP' },
  { value: 'EUCLID2.0-MINI-32-SP-SINGLE-ROW' },
  { value: 'EUCLID2.0-PATCH' },
  { value: 'EUCLID2.0-PATCH-LEFT' },
  { value: 'EUCLID2.0-PATCH-RIGHT' },
  { value: 'EULER12.8T_SPLIT_EVEN_T1_BR_16W_BRIGGS_STRIPE4_BASE' },
  { value: 'EULER12.8T_SPLIT_EVEN_T1_BR_16W_BRIGGS_STRIPE4_NO_OPTICAL' },
  { value: 'EULER12.8T_SPLIT_EVEN_T1_BR_16W_BRIGGS_STRIPE4_WITH_OPTICAL_LINE' },
  { value: 'EULER12.8T_SPLIT_EVEN_T1_BR_BASE_RACK' },
  { value: 'EULER12.8T_SPLIT_ODD_T1_BR_16W_BRIGGS_STRIPE4_BASE' },
  { value: 'EULER12.8T_SPLIT_ODD_T1_BR_16W_BRIGGS_STRIPE4_NO_OPTICAL' },
  { value: 'EULER12.8T_SPLIT_ODD_T1_BR_16W_BRIGGS_STRIPE4_WITH_OPTICAL_LINE' },
  { value: 'EULER12.8T_SPLIT_ODD_T1_BR_BASE_RACK' },
  { value: 'EUROPA15' },
  { value: 'Euclid12.8T-26-16W-MINI-SP-BASE' },
  { value: 'Euclid12.8T-26-8W-MINI-SP-BASE' },
  { value: 'Euclid2.0-TS-WMW' },
  { value: 'Euclid2.0-WMW-BR-BASE' },
  { value: 'F.REV' },
  { value: 'FATCAT-24-O' },
  { value: 'FICUS21' },
  { value: 'FIREBALL14' },
  { value: 'FIREBAT13' },
  { value: 'FIREBAT13;JASPER13;KENAI13;MIXED RACK' },
  { value: 'FLAGSTAFF' },
  { value: 'FORNAX16' },
  { value: 'FPOD' },
  { value: 'FPOD-100G' },
  { value: 'FPOD-EXPANSION' },
  { value: 'FPOD-EXPANSION-100G' },
  { value: 'FPODV2' },
  { value: 'FRASERD' },
  { value: 'FREIGHTER12' },
  { value: 'GAMBIT15' },
  { value: 'GARFIELD1' },
  { value: 'GARFIELD1816-FAMILY' },
  { value: 'GARFIELD2' },
  { value: 'GARFIELD2016-FAMILY' },
  { value: 'GARFIELD2216-FAMILY' },
  { value: 'GARFIELD22G-FAMILY' },
  { value: 'GARFIELDA1616' },
  { value: 'GARFIELDA1816' },
  { value: 'GARFIELDA2016' },
  { value: 'GARFIELDA2216' },
  { value: 'GARFIELDA22G19' },
  { value: 'GARFIELDA2616' },
  { value: 'GARFIELDASMR2016C' },
  { value: 'GARFIELDASMR2019' },
  { value: 'GARFIELDASMR20G19' },
  { value: 'GARFIELDASMR2419' },
  { value: 'GARFIELDASMR26G19' },
  { value: 'GARFIELDAV1' },
  { value: 'GARFIELDAV1;GARFIELDCV1' },
  { value: 'GARFIELDC1616' },
  { value: 'GARFIELDC1816' },
  { value: 'GARFIELDC2016' },
  { value: 'GARFIELDC2216' },
  { value: 'GARFIELDC22G19' },
  { value: 'GARFIELDC2616' },
  { value: 'GARFIELDCSMR2016C' },
  { value: 'GARFIELDCSMR2019' },
  { value: 'GARFIELDCSMR20G19' },
  { value: 'GARFIELDCSMR2419' },
  { value: 'GARFIELDCSMR26G19' },
  { value: 'GARFIELDCV1' },
  { value: 'GARFIELDSMR2016-FAMILY' },
  { value: 'GARHAMRQV1' },
  { value: 'GOBI15' },
  { value: 'GRANDTETON' },
  { value: 'GRANUM16' },
  { value: 'GREENLEAF1' },
  { value: 'GREENLEAF2' },
  { value: 'GREENLEAF3' },
  { value: 'GREENLEAF4' },
  { value: 'GRIZZLY15' },
  { value: 'GROUSE15' },
  { value: 'GRUMPYCAT' },
  { value: 'GRUS16' },
  { value: 'HABANERO11' },
  { value: 'HABANERO11.5' },
  { value: 'HALF KITTYCAT' },
  { value: 'HANABIKO' },
  { value: 'HANUMAN' },
  { value: 'HANUMAN01' },
  { value: 'HANUMAN01F' },
  { value: 'HANUMAN02' },
  { value: 'HANUMAN02F' },
  { value: 'HANUMAN03' },
  { value: 'HANUMAN03F' },
  { value: 'HANUMAN04' },
  { value: 'HANUMAN04F' },
  { value: 'HANUMAN05' },
  { value: 'HANUMAN05F' },
  { value: 'HANUMAN06' },
  { value: 'HANUMAN06F' },
  { value: 'HANUMAN08' },
  { value: 'HANUMAN11' },
  { value: 'HANUMAN49' },
  { value: 'HANUMAN84AV1' },
  { value: 'HANUMAN86CV1' },
  { value: 'HANUMAN91' },
  { value: 'HANUMAN941' },
  { value: 'HANUMAN98' },
  { value: 'HANUMAN99' },
  { value: 'HANUMANAV1' },
  { value: 'HANUMANCV1' },
  { value: 'HAPUNA12' },
  { value: 'HAPUNA12;MIXED Kenai12' },
  { value: 'HARDENEDRACK-WAVELENGTH' },
  { value: 'HARNEY' },
  { value: 'HEATHCLIFF19' },
  { value: 'HELIOS16' },
  { value: 'HIGGSA16' },
  { value: 'HOJI_24' },
  { value: 'HOJI_42' },
  { value: 'HOJI_6' },
  { value: 'HOJI_84' },
  { value: 'HOPPER' },
  { value: 'HOPPER01' },
  { value: 'HOPPER02' },
  { value: 'HOPPER03' },
  { value: 'HOPPER04' },
  { value: 'HOPPER05' },
  { value: 'HOPPER06' },
  { value: 'HOPPER08' },
  { value: 'HOPPER11' },
  { value: 'HOPPER23' },
  { value: 'HOPPER25' },
  { value: 'HOPPER26SF' },
  { value: 'HOPPER49' },
  { value: 'HOPPERAV1' },
  { value: 'HOPPERCV1' },
  { value: 'HORIZON15' },
  { value: 'HORIZON15_2QFXLG' },
  { value: 'HORIZON15_EX4300' },
  { value: 'HORIZON15_LG' },
  { value: 'HORIZON15_PTX' },
  { value: 'HORIZON15_PTX100G' },
  { value: 'HORIZON15_PTX100G_AMP_OPTICAL' },
  { value: 'HORIZON15_PTX100G_UNAMP_OPTICAL' },
  { value: 'HORIZON15_PTX10G' },
  { value: 'HORIZON15_PTX10G_AMP_OPTICAL' },
  { value: 'HORIZON15_PTX10G_UNAMP_OPTICAL' },
  { value: 'HORIZON15_SM' },
  { value: 'HORIZONB15' },
  { value: 'HORIZONB16' },
  { value: 'HORIZONCN15' },
  { value: 'HORIZONCN15_100G' },
  { value: 'HORIZONCN15_10G' },
  { value: 'HORIZONCN15_LG' },
  { value: 'HORIZONCN15_SM' },
  { value: 'HORNET16' },
  { value: 'HORNET16F' },
  { value: 'HORNETA16' },
  { value: 'HORNETA16F' },
  { value: 'HUMBOLDT14' },
  { value: 'HUMBOLDT15' },
  { value: 'HUMBOLDT16' },
  { value: 'HUMBOLDT21' },
  { value: 'HUMBOLDTLG16' },
  { value: 'HUMBOLDTMD16' },
  { value: 'HUMBOLDTSM16' },
  { value: 'HW.RESERVED.00' },
  { value: 'HYDRA15' },
  { value: 'HYPERION13' },
  { value: 'HYPERION13;KENAI13;MIXED RACK' },
  { value: 'I6K' },
  { value: 'IE.JAEGER8T' },
  { value: 'INITIALBUILD' },
  { value: 'INITIALBUILD.12' },
  { value: 'INITIALBUILD.13' },
  { value: 'INITIALBUILD.14' },
  { value: 'IRIDIUM16' },
  { value: 'IS BR-EDG' },
  { value: 'IS-BACKBONE OOB' },
  { value: 'IS-JUNIPER-32X10' },
  { value: 'IS-JUNIPER100G+CFP' },
  { value: 'IS-MX480-2LC' },
  { value: 'IS-MX960-11LC' },
  { value: 'IS-MX960-2LC' },
  { value: 'IS-MX960-5LC' },
  { value: 'IS-NX-AGG-EDG' },
  { value: 'IS-NZ-BR-FAB' },
  { value: 'IS6GEXN' },
  { value: 'JAEGER1015' },
  { value: 'JAEGER4D' },
  { value: 'JAEGER8T' },
  { value: 'JAEGERMLK' },
  { value: 'JASPER12' },
  { value: 'JASPER13' },
  { value: 'JASPER13;KAURI12.5;MIXED RACK' },
  { value: 'JASPER13;KENAI13;MIXED RACK' },
  { value: 'JUGGERNAUT15' },
  { value: 'JUGGERNAUT16' },
  { value: 'JUGGERNAUTA16' },
  { value: 'JUGGERNAUTE16' },
  { value: 'JUNO13' },
  { value: 'JUNO15' },
  { value: 'JUNO16' },
  { value: 'JUPITER15' },
  { value: 'JUPITERB15' },
  { value: 'KANHA13' },
  { value: 'KATMAI11' },
  { value: 'KAURI10' },
  { value: 'KAURI1015' },
  { value: 'KAURI11' },
  { value: 'KAURI12' },
  { value: 'KAURI12.5' },
  { value: 'KAURI12.6' },
  { value: 'KAURI12.6_QFX' },
  { value: 'KAURI1216' },
  { value: 'KAURI5T' },
  { value: 'KAURI6T' },
  { value: 'KAURI6T_LITE' },
  { value: 'KAURI8T' },
  { value: 'KAURIA815' },
  { value: 'KAURIS1015' },
  { value: 'KENAI12' },
  { value: 'KENAI13' },
  { value: 'KETTLE-DF' },
  { value: 'KITTYCAT' },
  { value: 'KMS.PINGU.18' },
  { value: 'KODIAK15' },
  { value: 'KONA' },
  { value: 'KRAKEN15' },
  { value: 'KRAKEN15B' },
  { value: 'KRAKEN18' },
  { value: 'KRAKEND15' },
  { value: 'KRAKENE16' },
  { value: 'KRAKENSHELF15' },
  { value: 'KRAKENSLED15' },
  { value: 'LARGEDB10H' },
  { value: 'LEPUS16' },
  { value: 'LEVIATHAN15' },
  { value: 'LEVIATHAN15B' },
  { value: 'LEVIATHAN16B' },
  { value: 'LEVIATHAN16C' },
  { value: 'LONEWOLF' },
  { value: 'LONGCAT-16-OB' },
  { value: 'LYRA16' },
  { value: 'LYRAA16' },
  { value: 'LYRAB16' },
  { value: 'MAGNETO15' },
  { value: 'MAGNUS' },
  { value: 'MAHUTA' },
  { value: 'MAKALU01' },
  { value: 'MAKALU03' },
  { value: 'MAKALU05' },
  { value: 'MAKALU91' },
  { value: 'MAKALU92' },
  { value: 'MAKALU99' },
  { value: 'MALLARD15' },
  { value: 'MALLARDN15' },
  { value: 'MALLARDW15' },
  { value: 'MANDARINW15' },
  { value: 'MAPLE' },
  { value: 'MAPLE12.5' },
  { value: 'MARLIN-L14' },
  { value: 'MARLIN-L15' },
  { value: 'MARLIN-S14' },
  { value: 'MARLIN-S15' },
  { value: 'MARLIN_L18' },
  { value: 'MARLIN_S18' },
  { value: 'MARS16' },
  { value: 'MEDUSA15' },
  { value: 'MEDUSA16' },
  { value: 'MEEKSBAY' },
  { value: 'MEEKSBAY_F' },
  { value: 'MERGANSER15' },
  { value: 'MIDDB10H' },
  { value: 'MIXED NLB BANFF12' },
  { value: 'MIXED NLB BANFF12.3' },
  { value: 'MIXED RACK;OSTRICH13;WOLVERINE13' },
  { value: 'MIXED-KODIAK15-POLAR15-GRIZZLY15' },
  { value: 'MIXED_POSEIDON15_KRAKEN15' },
  { value: 'MORRIS1' },
  { value: 'MUSCOVY15' },
  { value: 'MUSTANG01' },
  { value: 'MUSTANG01F' },
  { value: 'MUSTANG02' },
  { value: 'MUSTANG02F' },
  { value: 'MX960-CF' },
  { value: 'Magpie15' },
  { value: 'Mandalore15' },
  { value: 'Mandarin15' },
  { value: 'N/A' },
  { value: 'NESSUS' },
  { value: 'NEUTRINO16' },
  { value: 'NILE15' },
  { value: 'NJORD15' },
  { value: 'NPD_EMPTY_WMW_RACK' },
  { value: 'NW-CATZILLA-2RACKBRICK-BR-1.1.0' },
  { value: 'NW.10P10U_12.8T_16W_BASE' },
  { value: 'NW.10P10U_12.8T_16W_BASE_VCM1' },
  { value: 'NW.10P10U_12.8T_16W_BASE_VCM16' },
  { value: 'NW.10P10U_12.8T_16W_BASE_VCM66' },
  { value: 'NW.10P10U_SHIM_12.8T_16W_LG' },
  { value: 'NW.10P10U_SHIM_12.8T_16W_MD' },
  { value: 'NW.10P10U_SHIM_12.8T_16W_SM' },
  { value: 'NW.10P10U_T1A_12.8T_16W_LG' },
  { value: 'NW.10P10U_T1A_12.8T_16W_MD' },
  { value: 'NW.10P10U_T1A_12.8T_16W_SM' },
  { value: 'NW.10P10U_T1E_12.8T_16W_LG' },
  { value: 'NW.10P10U_T1E_12.8T_16W_MD' },
  { value: 'NW.10P10U_T1E_12.8T_16W_SM' },
  { value: 'NW.10P10U_T2_12.8T_16W_LG' },
  { value: 'NW.10P10U_T2_12.8T_16W_MD' },
  { value: 'NW.10P10U_T2_12.8T_16W_SM' },
  { value: 'NW.12.8T_BR_16W_BASE_RACK' },
  { value: 'NW.12.8T_BR_8W_BASE_RACK' },
  { value: 'NW.12.8T_SPLIT-BR_BASE_RACK' },
  { value: 'NW.12.8T_SPLIT-EVEN-T1-BR_BASE_RACK' },
  { value: 'NW.12.8T_SPLIT-LEFT_BR_BASE_RACK' },
  { value: 'NW.12.8T_SPLIT-ODD-T1-BR_BASE_RACK' },
  { value: 'NW.12.8T_SPLIT-RIGHT_BR_BASE_RACK' },
  { value: 'NW.12.8T_SP_16W_BASE_RACK' },
  { value: 'NW.12.8T_SP_8W_BASE_RACK' },
  { value: 'NW.3.2T_BR_BASE_RACK' },
  { value: 'NW.3.2T_SPLIT-BR_BASE_RACK' },
  { value: 'NW.3.2T_SPLIT-EVEN-T1-BR_BASE_RACK' },
  { value: 'NW.3.2T_SPLIT-EVEN-T1-BR_BASE_RACK_KNOX_STRIPE3' },
  { value: 'NW.3.2T_SPLIT-ODD-T1-BR_BASE_RACK' },
  { value: 'NW.3.2T_SPLIT-ODD-T1-BR_BASE_RACK_KNOX_STRIPE3' },
  { value: 'NW.BFBV3.0-BB-FULL-CWDM4' },
  { value: 'NW.BFBV3.0-BB-FULL-LR4' },
  { value: 'NW.BFBV3.0-MIXED-FULL-CWDM4' },
  { value: 'NW.BFBV3.0-MIXED-FULL-LR4' },
  { value: 'NW.BFBv2-4x2-NO-CONSOLE.1.0' },
  { value: 'NW.BFBv2_4x2.1.0' },
  { value: 'NW.BFBv2_PTX1K_4x2-NO-CONSOLE.1.0' },
  { value: 'NW.BFBv2_PTX1K_4x2.1.0' },
  { value: 'NW.BFBv2_PTX1K_4x2_CWDM4-NO-CONSOLE.1.0' },
  { value: 'NW.BFBv2_PTX1K_4x2_CWDM4.1.0' },
  { value: 'NW.BFBv3.0-BB' },
  { value: 'NW.BFBv3.0-COR' },
  { value: 'NW.BFBv3.0-MIXED' },
  { value: 'NW.BFBv3.0-SPINE' },
  { value: 'NW.BFC-BR-IRIS-100G-CWDM4_256-16.V1.0' },
  { value: 'NW.BFC-BR-IRIS-40G-LR4-28-12.V1.0' },
  { value: 'NW.BFC-BR-IRIS-40G-LR4-28-16.V1.0' },
  { value: 'NW.BFC-BR-IRIS-40G-LR4-28-8.V1.0' },
  { value: 'NW.BFC-BR-IRIS-40G-PSM4_128-8.V1.0' },
  { value: 'NW.BFC-BR-IRIS-40G-PSM4_192-12.V1.0' },
  { value: 'NW.BFC-BR-IRIS-40G-PSM4_256-16.V1.0' },
  { value: 'NW.BFC-BR-IRIS-BASE-LR4_28.V1.0' },
  { value: 'NW.BFC-BR-IRIS-DWDM-BASE-LR4_28.V1.0' },
  { value: 'NW.BFC-BR-IRIS-LR4_28-8.V1.0' },
  { value: 'NW.BFC-BR-IRIS-LR4_28_128-16.V1.0' },
  { value: 'NW.BFC-BR-IRIS-LR4_28_128-8.V1.0' },
  { value: 'NW.BFC-BR-IRIS-LR4_28_192-12.V1.0' },
  { value: 'NW.BFC-BR-IRIS-LR4_28_256-16.V1.0' },
  { value: 'NW.BFC-BR-IRIS-LR4_28_64-8.V1.0' },
  { value: 'NW.BFC-BR-IRIS-LR4_28_96-12.V1.0' },
  { value: 'NW.BFC-BR-IRIS-PSM4_28-12.V1.0' },
  { value: 'NW.BFC-BR-IRIS-PSM4_28-16.V1.0' },
  { value: 'NW.BFC-BR-IRIS-PSM4_28-8.V1.0' },
  { value: 'NW.BFC.1.0-BR-VEGA-BASE-15KVA' },
  { value: 'NW.BFC.1.0-BR-VEGA-BASE-20KVA' },
  { value: 'NW.BFC.1.0_BR_DOORMOUSE.NONE_QSFP-LR4-2KM_NONE_16' },
  { value: 'NW.BFC.1.0_BR_DOORMOUSE.QSFP-PSM4-2KM_QSFP-LR4-2KM_256_16' },
  { value: 'NW.BFC.1.0_BR_DOORMOUSE.QSFP28-CWDM4-10KM_QSFP-LR4-2KM_256_16' },
  { value: 'NW.BFC.1.0_BR_DOORMOUSE.QSFP28-CWDM4-2KM_QSFP-LR4-2KM_256_16' },
  { value: 'NW.BFC.1.0_BR_DOORMOUSE.QSFP28-LR4-10KM_QSFP-LR4-2KM_256_16' },
  { value: 'NW.BFC.1.0_BR_DOORMOUSE_BASE' },
  { value: 'NW.BFC.1.0_BR_KNOX_BASE' },
  { value: 'NW.BFC.1.0_BR_VEGA.CWDM4-QSFP28-2KM_32_12' },
  { value: 'NW.BFC.1.0_BR_VEGA.CWDM4-QSFP28-2KM_32_16' },
  { value: 'NW.BFC.1.0_BR_VEGA.CWDM4-QSFP28-2KM_32_8' },
  { value: 'NW.BFC.1.0_BR_VEGA.EMPTY' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP-LR4-10KM_QSFP-LR4-2KM_128_8' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP-LR4-10KM_QSFP-LR4-2KM_192_12' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP-LR4-10KM_QSFP-LR4-2KM_256_16' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP-LR4GEN-10KM_QSFP-LR4-2KM_192_12' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-CWDM4-10KM_QSFP-LR4-2KM_128_8' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-CWDM4-10KM_QSFP-LR4-2KM_192_12' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-CWDM4-10KM_QSFP-LR4-2KM_256_16' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-CWDM4-2KM_QSFP-LR4-2KM_128_16' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-CWDM4-2KM_QSFP-LR4-2KM_64_8' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-CWDM4-2KM_QSFP-LR4-2KM_96_12' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-LR4-10KM_QSFP-LR4-2KM_256_16' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-LR4-2KM_QSFP-LR4-2KM_128_16' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-LR4-2KM_QSFP-LR4-2KM_64_8' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-LR4-2KM_QSFP-LR4-2KM_96_12' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-LR4GEN-10KM_QSFP-LR4-2KM_256_16' },
  { value: 'NW.BFC.1.0_BR_VEGA.QSFP28-LR4GEN-10KM_QSFP-LR4-2KM_64_8' },
  { value: 'NW.BFC.2.0_BR_KNOX.NONE_QSFP28-CWDM4-2KM_NONE_16' },
  { value: 'NW.BFC.2.0_BR_KNOX_BASE' },
  { value: 'NW.BFC.2.0_BR_KNOX_BASE-NO-OPTICS' },
  { value: 'NW.BFC.2.0_BR_LORENZ.NONE_QSFP28-CWDM4-2KM_NONE_16' },
  { value: 'NW.BFC.2.0_BR_LORENZ.QSFP28-CWDM4-10KM_QSFP28-CWDM4-2KM_256_16' },
  { value: 'NW.BFC.2.0_BR_LORENZ.QSFP28-CWDM4-2KM_NONE_64_0' },
  { value: 'NW.BFC.2.0_BR_LORENZ.QSFP28-CWDM4-2KM_QSFP28-CWDM4-2KM_256_16' },
  { value: 'NW.BFC.2.0_BR_LORENZ.QSFP28-LR4-10KM_QSFP28-CWDM4-2KM_256_16' },
  { value: 'NW.BFC.2.0_BR_LORENZ_BASE' },
  { value: 'NW.BFC.2.0_BR_VEGA.EMPTY' },
  { value: 'NW.BFC.2.0_BR_VEGA.NONE_QSFP28-CWDM4-2KM_NONE_64' },
  { value: 'NW.BFC.2.0_BR_VEGA.NONE_QSFP28-PSM4-2KM_NONE_16' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP-LR4-10KM_QSFP28-CWDM4-2KM_256_16' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP-LR4-10KM_QSFP28-PSM4-2KM_256_64' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP-PSM4-2KM_QSFP28-CWDM4-2KM_256_16' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP-PSM4-2KM_QSFP28-PSM4-2KM_256_64' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP28-CWDM4-10KM_QSFP28-CWDM4-2KM_256_16' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP28-CWDM4-10KM_QSFP28-PSM4-2KM_256_64' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP28-CWDM4-2KM_QSFP28-CWDM4-2KM_256_16' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP28-CWDM4-2KM_QSFP28-PSM4-2KM_256_64' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP28-LR4-10KM_QSFP28-CWDM4-2KM_256_16' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP28-LR4-10KM_QSFP28-PSM4-2KM_256_64' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP28-LR4-2KM_QSFP28-CWDM4-2KM_256_16' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP28-LR4-2KM_QSFP28-PSM4-2KM_256_64' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP28-LR4SP-10KM_QSFP28-CWDM4-2KM_256_16' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP28-PSM4-2KM_QSFP28-CWDM4-2KM_256_16' },
  { value: 'NW.BFC.2.0_BR_VEGA.QSFP28-PSM4-2KM_QSFP28-PSM4-2KM_256_64' },
  { value: 'NW.BFC.2.0_BR_VEGA.T2_EMPTY_10KM' },
  { value: 'NW.BFC.2.0_BR_VEGA.T2_EMPTY_2KM' },
  { value: 'NW.BFC.2.0_BR_VEGA_BASE' },
  { value: 'NW.BFC.2.0_SP_VEGA.QSFP28-CWDM4-2KM_32' },
  { value: 'NW.BFC.2.0_SP_VEGA.QSFP28-CWDM4-2KM_8' },
  { value: 'NW.BFC.2.0_SP_VEGA.QSFP28-PSM4-2KM_128' },
  { value: 'NW.BFC.2.0_SP_VEGA_BASE' },
  { value: 'NW.BFC.MICRO-CWDM4-28-16-KNOX-STRIPE3.V2.0' },
  { value: 'NW.BFC.MICRO-CWDM4-28.V2.0' },
  { value: 'NW.BFC.MICRO-PSM4_28-16.V2.0' },
  { value: 'NW.BMN-AA-F.1.0' },
  { value: 'NW.BMN-AA-T.1.0' },
  { value: 'NW.BMN-SMALL.1.0' },
  { value: 'NW.BMN-XXL.1.0' },
  { value: 'NW.BMN_EXT.1.0' },
  { value: 'NW.BOLTZMANN' },
  { value: 'NW.BR-AGG-EDG' },
  { value: 'NW.BR-LLE-DCR' },
  { value: 'NW.BR-RRR-MX240' },
  { value: 'NW.BR-UBR' },
  { value: 'NW.BRUCE-BF16' },
  { value: 'NW.BR_BASE_RACK' },
  { value: 'NW.CATZILLA-BR-1.1.1' },
  { value: 'NW.CATZILLA-BR-12.1.0' },
  { value: 'NW.CATZILLA-BR-12.1.1' },
  { value: 'NW.CATZILLA-BR-16.1.1' },
  { value: 'NW.CATZILLA-BR-2.0.0' },
  { value: 'NW.CATZILLA-BR-8.1.0' },
  { value: 'NW.CATZILLA-BR-8.1.1' },
  { value: 'NW.CATZILLA-OPT-UPGR.1.0' },
  { value: 'NW.CATZILLA-SP-2.0.0' },
  { value: 'NW.CATZILLA-SP.1.0' },
  { value: 'NW.CHRONOS' },
  { value: 'NW.CHRONOS_EVEN' },
  { value: 'NW.CHRONOS_ODD' },
  { value: 'NW.CLOUDCAT17-RRR-COMBO.1.0' },
  { value: 'NW.CLOUDCAT17.1.0' },
  { value: 'NW.CLOUDCAT19-RRR-KNOX-COMBO.1.0' },
  { value: 'NW.COMMODITYPUFFIN.BF19' },
  { value: 'NW.CONGO1.0-AC-6500-DGE-RE-1.0' },
  { value: 'NW.CONGO1.0-AC-6500-FORK-E.RE-1.0' },
  { value: 'NW.CONGO1.0-AC-6500-TERM.120-E-12X.P.WAVESERVER-2.0' },
  { value: 'NW.CONGO1.0-AC-6500-TERM.120-F-12X.P.WAVESERVER-2.0' },
  { value: 'NW.CONGO1.0-AC-6500-TERM.120-R-12X.P.WAVESERVER-QPSK.1.4-1.0' },
  {
    value:
      'NW.CONGO1.0-AC-6500-TERM.120-R-12X.P.WAVESERVER-QPSK.1.4-1.0;NW.CONGO2.0-AC-6500-TERM.120-R-10X.P.WAVESERVERAi-1.0',
  },
  { value: 'NW.CONGO1.0-AC-6500-TERM.60-E-6X.P.WAVESERVER-QPSK.1.4-1.0' },
  { value: 'NW.CONGO1.0-AC-6500-TERM.60-R-6X.P.WAVESERVER-QPSK.1.4-1.0' },
  { value: 'NW.CONGO1.0-AC-GENERAL-EXP-12X.P.WAVESERVER-1.0' },
  { value: 'NW.CONGO1.0-AC-GENERAL-EXP-2X.P.WAVESERVER-1.0' },
  { value: 'NW.CONGO1.0-DC-6500-DGE-EE-1.0' },
  { value: 'NW.CONGO1.0-DC-6500-DGE-RE-1.0' },
  { value: 'NW.CONGO1.0-DC-6500-DGE-RR-1.0' },
  { value: 'NW.CONGO1.0-DC-6500-FORK-R.EE-1.0' },
  { value: 'NW.CONGO1.0-DC-6500-ILA-EE-1.0' },
  { value: 'NW.CONGO1.0-DC-6500-ILA-FF-1.0' },
  { value: 'NW.CONGO1.0-DC-6500-ILA-RE-1.0' },
  { value: 'NW.CONGO1.0-DC-6500-ILA-RF-1.0' },
  { value: 'NW.CONGO1.0-DC-6500-ILA-RR-1.0' },
  { value: 'NW.CONGO1.0-DC-PDU-ILA' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-E-10X.P.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-E-10X.S.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-E-4X.P.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-E-4X.S.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-F-10X.P.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-F-10X.S.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-F-4X.P.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-F-4X.S.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-R-10X.P.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-R-10X.S.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-R-4X.P.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-6500-TERM.120-R-4X.S.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-GENERAL-EXP-10X.P.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-GENERAL-EXP-10X.S.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-GENERAL-EXP-12X.P.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-GENERAL-EXP-12X.S.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-GENERAL-EXP-16X.P.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-GENERAL-EXP-16X.S.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-GENERAL-EXP-2X.P.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-GENERAL-EXP-2X.S.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-GENERAL-EXP-6X.P.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO2.0-AC-GENERAL-EXP-6X.S.WAVESERVERAi-1.0' },
  { value: 'NW.CONGO3.0-10kVA-AC-FlexILS-C+L-EXP-0.5T-4XCHM6-4' },
  { value: 'NW.CONGO3.0-15kVA-AC-FlexILS-C+L-EXP-4XCHM6-4' },
  { value: 'NW.CONGO3.0-AC-FlexILS-C+L-EXP-4XCHM6' },
  { value: 'NW.CONGO3.0-AC-FlexILS-C+L-EXP_Mux-2XCHM6-4' },
  { value: 'NW.CONGO3.0-AC-FlexILS-C+L-TERM-E-2XCHM6-4' },
  { value: 'NW.CONGO3.0-AC-FlexILS-C+L-TERM-R1-2XCHM-4' },
  { value: 'NW.CONGO3.0-AC-FlexILS-C+L-TERM-R2-2XCHM6-4' },
  { value: 'NW.CONGO3.0-AC-FlexILS-C+L-TERM-R2-4XCHM6' },
  { value: 'NW.CONGO3.0-AR1-10kVA-AC-FlexILS-C+L-EXP_Mux-0.5T-4XCHM6-4' },
  { value: 'NW.CONGO3.0-AR1-10kVA-AC-FlexILS-C+L-TERM-E-0.5T-4XCHM6-4' },
  { value: 'NW.CONGO3.0-AR1-10kVA-AC-FlexILS-C+L-TERM-R1_-0.5T-4XCHM6-4' },
  { value: 'NW.CONGO3.0-AR1-10kVA-AC-FlexILS-C+L-TERM-R2-0.5T-4XCHM6-4' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-DGE-E-E' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-DGE-E-R1' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-DGE-E-R2' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-DGE-R1-R1' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-DGE-R1-R2' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-DGE-R2-R2' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-ILA-E-E' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-ILA-E-R1' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-ILA-E-R2' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-ILA-R1-R1' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-ILA-R1-R2' },
  { value: 'NW.CONGO3.0-DC-FlexILS-C+L-ILA-R2-R2' },
  { value: 'NW.CONGO_EXP_MUX_v1_3.0' },
  { value: 'NW.CONGO_EXP_MUX_v2_3.0' },
  { value: 'NW.CONGO_EXP_v2_3.0' },
  { value: 'NW.CONGO_TERM_v1_E_3.0' },
  { value: 'NW.CONGO_TERM_v1_R1_3.0' },
  { value: 'NW.CONGO_TERM_v1_R2_3.0' },
  { value: 'NW.CONGO_TERM_v2_E_3.0' },
  { value: 'NW.CONGO_TERM_v2_R1_3.0' },
  { value: 'NW.CONGO_TERM_v2_R2_3.0' },
  { value: 'NW.Congo-EXP-4TXP-4SLEDS-3.1' },
  { value: 'NW.Congo-TERM-4TXP-3SLEDS-E-3.1' },
  { value: 'NW.Congo-TERM-4TXP-3SLEDS-R-3.1' },
  { value: 'NW.Congo-TERM-4TXP-4SLEDS-E-3.1' },
  { value: 'NW.Congo-TERM-4TXP-4SLEDS-R-3.1' },
  { value: 'NW.DMC-UNIFIED-MANAGEMENT.1.0' },
  { value: 'NW.DORI-QP.15' },
  { value: 'NW.DORI-S.15' },
  { value: 'NW.ECLECTUS_EVEN' },
  { value: 'NW.ECLECTUS_ODD' },
  { value: 'NW.ENTERPRISE-BASE.1.0' },
  { value: 'NW.ENTERPRISE-EDGE.1.0' },
  { value: 'NW.ENTERPRISE_BASE_EVEN' },
  { value: 'NW.ENTERPRISE_BASE_ODD' },
  { value: 'NW.ENTERPRISE_MIXED_ODD' },
  { value: 'NW.EUCLID12.8T-BR-400G-DR4' },
  { value: 'NW.EVEN.GEMINI-UMN-NG.1.0' },
  { value: 'NW.EVEN.PRIMARY.FUSION.RACK' },
  { value: 'NW.Euclid-Mini_BR_Base_Full' },
  { value: 'NW.Euclid_100G_1024_BR_Base' },
  { value: 'NW.Euclid_BR_Base_Full' },
  { value: 'NW.FINALCAT-BR-1.0.0' },
  { value: 'NW.FINALCAT-BR-1.1.1' },
  { value: 'NW.FINALCAT-BR-1.1.1-NO-OPTIC' },
  { value: 'NW.FINALCAT-BR-1.1.2' },
  { value: 'NW.FINALCAT-BR-1.1.2-NO-OPTIC' },
  { value: 'NW.FINALCAT-BR-1.1.3' },
  { value: 'NW.FINALCAT-BR-1.1.3-NO-OPTIC' },
  { value: 'NW.FINALCAT-BR-1.1.4' },
  { value: 'NW.FINALCAT-BR-1.1.4-NO-OPTIC' },
  { value: 'NW.FINALCAT-BR-LUL-1.1.1' },
  { value: 'NW.FINALCAT-BR-LUL-1.1.2' },
  { value: 'NW.FINALCAT-BR-LUL-1.1.3' },
  { value: 'NW.FINALCAT-BR-LUL-1.1.4' },
  { value: 'NW.FINALCAT-SP-1.0.0' },
  { value: 'NW.FINALCAT-SP-1.1.1' },
  { value: 'NW.FINALCAT-SP-1.1.2' },
  { value: 'NW.FINALCAT.SHIM.16-BR-1.0.0' },
  { value: 'NW.FINALCAT.SHIM.32-BR-1.0.0' },
  { value: 'NW.FINALCAT_BR_2.1' },
  { value: 'NW.FINALCAT_BR_2.2' },
  { value: 'NW.FINALCAT_BR_2.3' },
  { value: 'NW.FINALCAT_BR_2.4' },
  { value: 'NW.FINALCAT_BR_NO-OPTIC_2.1' },
  { value: 'NW.FINALCAT_BR_NO-OPTIC_2.2' },
  { value: 'NW.FINALCAT_BR_NO-OPTIC_2.3' },
  { value: 'NW.FINALCAT_BR_NO-OPTIC_2.4' },
  { value: 'NW.FISSION_RACK' },
  { value: 'NW.FNC.2xBR.LUL' },
  { value: 'NW.FNC_2XBR.LUL.1.1' },
  { value: 'NW.FNC_2XBR.LUL.1.2' },
  { value: 'NW.FNK.1xBR.1.1' },
  { value: 'NW.FNK.1xBR.1.2' },
  { value: 'NW.FNK.1xBR.2xSP.1.1' },
  { value: 'NW.FNK.1xBR.2xSP.1.2' },
  { value: 'NW.FNK.1xBR.LCI.LUL.1.1' },
  { value: 'NW.FNK.1xBR.LCI.LUL.1.2' },
  { value: 'NW.FNK.1xBR.LUL.1.1' },
  { value: 'NW.FNK.1xBR.LUL.1.2' },
  { value: 'NW.FNK.2xBR.1.1' },
  { value: 'NW.FNK.2xBR.1.2' },
  { value: 'NW.FUSION.MASTER.RACK' },
  { value: 'NW.FUSION.STANDARD.RACK' },
  { value: 'NW.FUSION_PRIMARY_EVEN_V2' },
  { value: 'NW.FUSION_PRIMARY_ODD_V2' },
  { value: 'NW.FUSION_SECONDARY_V2' },
  { value: 'NW.Full_PTX_IRx8' },
  { value: 'NW.HAMBONE_V4_EDGE_1' },
  { value: 'NW.HAMBONE_V4_EDGE_2' },
  { value: 'NW.HAMBONE_V4_EDGE_3' },
  { value: 'NW.HOP.1.0-100G-OPTIC-INCLUDED' },
  { value: 'NW.HUSKY.1.0' },
  { value: 'NW.KITTYCAT.2.0' },
  { value: 'NW.KITTYCAT.3.0' },
  { value: 'NW.KITTYCAT.3.0_10KVA_MGMT' },
  { value: 'NW.KITTYCAT.3.0_10KVA_WO_MGMT' },
  { value: 'NW.KITTYCAT.3.0_20KVA_MGMT' },
  { value: 'NW.KITTYCAT.3.0_20KVA_WO_MGMT' },
  { value: 'NW.LBIR.GIB.NG' },
  { value: 'NW.LCI16.10KVA' },
  { value: 'NW.LCI16.15KVA' },
  { value: 'NW.LEVER-128-10G.1.0' },
  { value: 'NW.LEVER-128-10KVA-100G-CWDM.1.0' },
  { value: 'NW.LEVER-128-10KVA-100G-LR.1.0' },
  { value: 'NW.LEVER-128-10KVA-10G.1.0' },
  { value: 'NW.LEVER-128-10KVA-40G-LR.1.0' },
  { value: 'NW.LEVER-128-40G-LR.1.0' },
  { value: 'NW.LEVER-128-5KVA-100G-CWDM.1.0' },
  { value: 'NW.LEVER-128-5KVA-100G-LR.1.0' },
  { value: 'NW.LEVER-128-5KVA-10G.1.0' },
  { value: 'NW.LEVER-128-5KVA-40G-LR.1.0' },
  { value: 'NW.LEVER-256-100G.1.0' },
  { value: 'NW.LEVER-256-10G.1.0' },
  { value: 'NW.LEVER-256-10KVA-100G-CWDM.1.0' },
  { value: 'NW.LEVER-256-10KVA-100G-LR.1.0' },
  { value: 'NW.LEVER-256-10KVA-10G.1.0' },
  { value: 'NW.LEVER-256-10KVA-40G-LR.1.0' },
  { value: 'NW.LEVER-256-20KVA-100G-CWDM.1.0' },
  { value: 'NW.LEVER-256-20KVA-100G-LR.1.0' },
  { value: 'NW.LEVER-256-20KVA-10G.1.0' },
  { value: 'NW.LEVER-256-20KVA-40G-LR.1.0' },
  { value: 'NW.LEVER-256-40G-LR.1.0' },
  { value: 'NW.LEVER-256.1.0' },
  { value: 'NW.LOBO.FULL.1.0' },
  { value: 'NW.LOBO.MICRO.1.0' },
  { value: 'NW.LOBO.MINI.1.0' },
  { value: 'NW.MICROCATZILLA-BR-1.1.0' },
  { value: 'NW.MINIMITE.1.0.EC2' },
  { value: 'NW.MINIMITE.1.0.PROD' },
  { value: 'NW.MINI_BB_POP_MIXED19' },
  { value: 'NW.MONA.UNIFIED-MANAGEMENT-NEXT-GEN-MIXED.1.0' },
  { value: 'NW.MONA.UNIFIED-MANAGEMENT-NEXT-GEN.1.0' },
  { value: 'NW.Mini_PTX_IRx4' },
  { value: 'NW.NAZ-OOB-VPN-R.1' },
  { value: 'NW.NAZCAT.1' },
  { value: 'NW.NVR-ID-R' },
  { value: 'NW.NW_ONEFORECAST_POWER_SIGNAL_RACK' },
  { value: 'NW.OASIS.1.0.PROD' },
  { value: 'NW.ODD.PRIMARY.FUSION.RACK' },
  { value: 'NW.ONEFABRIC_CORE_12.8T_16W_EVEN_ENCRYPTION' },
  { value: 'NW.ONEFABRIC_CORE_12.8T_16W_EVEN_VANILLA' },
  { value: 'NW.ONEFABRIC_CORE_12.8T_16W_ODD_ENCRYPTION' },
  { value: 'NW.ONEFABRIC_CORE_12.8T_16W_ODD_VANILLA' },
  { value: 'NW.OPTO-ADVA-EXPANSION-1.4T.1.0' },
  { value: 'NW.OPTO-ADVA-EXPANSION-1XSHELF-EMPTY.1.0' },
  { value: 'NW.OPTO-ADVA-EXPANSION-2.8T.1.0' },
  { value: 'NW.OPTO-ADVA-EXPANSION-200G.1.0' },
  { value: 'NW.OPTO-ADVA-EXPANSION-400G-SPLIT.1.0' },
  { value: 'NW.OPTO-ADVA-EXPANSION-400G.1.0' },
  { value: 'NW.OPTO-ADVA-EXPANSION-4XSHELF-EMPTY.1.0' },
  { value: 'NW.OPTO-ADVA-EXPANSION-700G.1.0' },
  { value: 'NW.OPTO-ADVA-Expansion.1.0' },
  { value: 'NW.OPTO-ADVA-LINE-1.1T-SPLIT.1.0' },
  { value: 'NW.OPTO-ADVA-LINE-1.4T.1.0' },
  { value: 'NW.OPTO-ADVA-LINE-200G.1.0' },
  { value: 'NW.OPTO-ADVA-LINE-400G-SPLIT-100G-CLIENT.1.0' },
  { value: 'NW.OPTO-ADVA-LINE-400G-SPLIT.1.0' },
  { value: 'NW.OPTO-ADVA-LINE-400G.1.0' },
  { value: 'NW.OPTO-ADVA-LINE-700G.1.0' },
  { value: 'NW.OPTO-ADVA-LINE-800G-SPLIT.1.0' },
  { value: 'NW.OPTO-ADVA-LINE-EMPTY.1.0' },
  { value: 'NW.OPTO-ADVA-LINE.1.0' },
  { value: 'NW.OPTO-CISCO-EXPANSION-4XSHELF-EMPTY.1.0' },
  { value: 'NW.OPTO-CISCO-EXPANSION-6XSHELF-EMPTY.1.0' },
  { value: 'NW.OPTO-CISCO-Expansion.1.0' },
  { value: 'NW.OPTO-CISCO-LINE.1.0' },
  { value: 'NW.ORINOCO-CIENA-DCI-ILA-1.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-12.8T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-12.8T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-12.8T-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-12.8T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-2.0T-1.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-2.0T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-2.4T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-2.4T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-2.4T-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-2.4T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-3.2T-1.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-3.2T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-3.2T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-3.2T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-3.2T-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-3.2T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-4.8T-1.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-4.8T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-4.8T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-4.8T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-4.8T-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-4.8T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-6.4T-1.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-6.4T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-6.4T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-6.4T-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-6.4T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-EXPANSION-PREMIUM-10GPP-1.2T' },
  { value: 'NW.ORINOCO-CIENA-ILA-DC-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-0.4T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-0.8T-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-0.8T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-0.8T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-0.8T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-0.8T-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-0.8T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-1.6T-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-1.6T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-1.6T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-1.6T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-1.6T-10KVA-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-1.6T-15KVA-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-1.6T-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-1.6T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-12.8T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-12.8T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-12.8T-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-12.8T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-2.0T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-2.0T-10KVA-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-2.4T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-2.4T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-2.4T-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-2.4T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-2.8T-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-3.2T-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-3.2T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-3.2T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-3.2T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-3.2T-15KVA-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-3.2T-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-3.2T-2.0-1x-INFINERA-G30' },
  { value: 'NW.ORINOCO-CIENA-LINE-3.2T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-4.8T-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-4.8T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-4.8T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-4.8T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-4.8T-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-4.8T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-400G-10KVA-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-6.4T-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-6.4T-100GPP-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-6.4T-100GPP-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-6.4T-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-6.4T-PREMIUM-2.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-PREMIUM-1.2T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-PREMIUM-1.6T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-PREMIUM-2.0T-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-PREMIUM-2.0T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CIENA-LINE-PREMIUM-800G-10GPP-1.0' },
  { value: 'NW.ORINOCO-CORIANT-EXPANSION-2.4T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CORIANT-EXPANSION-3.2T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CORIANT-EXPANSION-4.8T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CORIANT-EXPANSION-6.4T-10KVA-1.0' },
  { value: 'NW.ORINOCO-CORIANT-LINE-0.8T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CORIANT-LINE-1.6T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CORIANT-LINE-1.6T-10KVA-1.0' },
  { value: 'NW.ORINOCO-CORIANT-LINE-12.8T-15KVA-1.0' },
  { value: 'NW.ORINOCO-CORIANT-LINE-2.4T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CORIANT-LINE-3.2T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CORIANT-LINE-3.2T-10KVA-1.0' },
  { value: 'NW.ORINOCO-CORIANT-LINE-4.8T-10GPP-1.0' },
  { value: 'NW.ORINOCO-CORIANT-LINE-6.4T-1.0' },
  { value: 'NW.ORINOCO-FUJITSU-LINE-12.8T-1.0' },
  { value: 'NW.ORINOCO-FUJITSU-LINE-6.4T-1.0' },
  { value: 'NW.OUTPOST-ACE' },
  { value: 'NW.OUTPOST-ACE-CISCO' },
  { value: 'NW.OUTPOST-ACE-CISCO-50AMP' },
  { value: 'NW.OUTPOST-ACE-JUNIPER' },
  { value: 'NW.OUTPOST-ACE-JUNIPER-50AMP' },
  { value: 'NW.OUTPOST-ACE-SZ-CISCO' },
  { value: 'NW.OUTPOST-ACE-SZ-CISCO-50AMP' },
  { value: 'NW.OUTPOST-ACE-SZ-CISCO-INVERTER' },
  { value: 'NW.OUTPOST-ACE-SZ-JUNIPER' },
  { value: 'NW.OUTPOST-ACE-SZ-JUNIPER-50AMP' },
  { value: 'NW.OUTPOST-ACE-SZ-JUNIPER-INVERTER' },
  { value: 'NW.OUTPOST-ACE-SZ-JUNIPER-ZONE4' },
  { value: 'NW.OUTPOST-SZ' },
  { value: 'NW.PATO.FULL.1.0' },
  { value: 'NW.PATO.FULL.14K.100G' },
  { value: 'NW.PATO.MICRO.1.0' },
  { value: 'NW.PATO.MICRO.14K.100G' },
  { value: 'NW.PATO.MINI.1.0' },
  { value: 'NW.PATO.MINI.14K.100G' },
  { value: 'NW.PATO.UNIFIED.1.0' },
  { value: 'NW.PATO_MINI_UNIFIED_EVEN' },
  { value: 'NW.PATO_MINI_UNIFIED_ODD' },
  { value: 'NW.PHOENIX.1.0' },
  { value: 'NW.PHOENIX_CENTENNIAL.1.0' },
  { value: 'NW.PHOENIX_CENTENNIAL.2.0' },
  { value: 'NW.PHOENIX_CENTENNIAL.FLEX.1.0' },
  { value: 'NW.PHOENIX_FULL.1.0' },
  { value: 'NW.PHOENIX_FULL4.1.0' },
  { value: 'NW.PHOENIX_FULL_FLEX.1.0' },
  { value: 'NW.PHOENIX_HALO_CENTENNIAL.1.0' },
  { value: 'NW.PHOENIX_HALO_CENTENNIAL_WMW.1.0' },
  { value: 'NW.PHOENIX_HALO_FULL.1.0' },
  { value: 'NW.PHOENIX_HALO_FULL_WMW.1.0' },
  { value: 'NW.PHOENIX_HALO_SMALL.1.0' },
  { value: 'NW.PHOENIX_HALO_SMALL_WMW.1.0' },
  { value: 'NW.PHOENIX_SMALL.1.0' },
  { value: 'NW.PUBEDGE.BF19' },
  { value: 'NW.PUFFIN.BF19' },
  { value: 'NW.PUFFIN_LARGE' },
  { value: 'NW.PUFFIN_MEDIUM' },
  { value: 'NW.PUFFIN_SMALL' },
  { value: 'NW.ROCK-1.0-40G-LR-10KM' },
  { value: 'NW.ROCK-1.0-40G-LR-2KM' },
  { value: 'NW.ROCK.1.0-100G-36INCH-NO-OPTIC' },
  { value: 'NW.ROCK.1.0-100G-NO-OPTIC' },
  { value: 'NW.ROCK.1.0-10G-10KM' },
  { value: 'NW.ROCK.1.0-10G-2KM' },
  { value: 'NW.ROCK.1.0-10G-36INCH-10KM' },
  { value: 'NW.ROCK.1.0-10G-36INCH-2KM' },
  { value: 'NW.ROCK.1.0-BR-BASE' },
  { value: 'NW.ROCK.1.0-BR-BASE-36INCH' },
  { value: 'NW.SECONDARY.FUSION.RACK' },
  { value: 'NW.SKYBRIDGE' },
  { value: 'NW.SKYBRIDGE_L' },
  { value: 'NW.SP.BASE-6W-VCM' },
  { value: 'NW.SP.BASE-SS-VCM' },
  { value: 'NW.SPINALCAT-CONTROL-RACK.1.0' },
  { value: 'NW.SPINALCAT_8X1_X2S.1.0' },
  { value: 'NW.SPINALCAT_8X4.1.0' },
  { value: 'NW.SPINALCAT_8X8.1.0' },
  { value: 'NW.SP_BASE_RACK' },
  { value: 'NW.SP_SINGLE-ROW_BASE_RACK' },
  { value: 'NW.TELESTO.1.0' },
  { value: 'NW.TENTACLE-CIENA-LINE-3.2T-2.0' },
  { value: 'NW.THOR.1.0' },
  { value: 'NW.THUNDERACK_GRANDE' },
  { value: 'NW.THUNDERACK_TALL' },
  { value: 'NW.THUNDERACK_TALL_DX' },
  { value: 'NW.TIGRIS-8X-CS8320-EMPTY-BREAKOUT-PANEL-LINE-RACK-2.0' },
  { value: 'NW.TIGRIS-8X-CS8320-EMPTY-LINE-RACK-2.0' },
  { value: 'NW.TIGRIS-CELESTICA-EXPANSION-EMPTY' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-12.8T-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-12.8T-100GPP-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-12.8T-400G-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-12.8T-4x100G-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-12x-CS8320-BASE-100GPP-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-12x-CS8320-BASE-100GPP-1.0-1x-WAVESERVER-10GPP' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-25.6T-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-25.6T-100GPP-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-25.6T-400G-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-6.4T-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-6.4T-400G-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-6.4T-4x100G-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-EMPTY' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-INFINERA-12.8T-100GPP-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-INFINERA-12.8T-4x100G-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-INFINERA-25.6T-100GPP-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-INFINERA-6.4T-4x100G-1.0' },
  { value: 'NW.TIGRIS-CELESTICA-LINE-INFINERA-EMPTY' },
  { value: 'NW.TREADMILL_15KVA' },
  { value: 'NW.Test_WMW_FIT_LAB_Rack' },
  { value: 'NW.UMN-NG_PRODLESS_EVEN' },
  { value: 'NW.UMN-NG_PRODLESS_ODD' },
  { value: 'NW.UMN-NG_PROD_EVEN' },
  { value: 'NW.UMN-NG_PROD_ODD' },
  { value: 'NW.VEGEMITE' },
  { value: 'NW.VEGEMITE-L' },
  { value: 'NW.VEGEMITE-L_DX' },
  { value: 'NW.VEGEMITE_DX' },
  { value: 'NW.VEGETRON' },
  { value: 'OAK' },
  { value: 'OAK12.5' },
  { value: 'OCTANS16' },
  { value: 'OCTOPUS15' },
  { value: 'ODIE14' },
  { value: 'ODIEB14' },
  { value: 'OHIO15' },
  { value: 'OHIO616' },
  { value: 'OHIO6T14' },
  { value: 'ONEFABRIC-AGG-12.8T-16W-BR' },
  { value: 'ONEFABRIC-AGG-12.8T-8W-BR' },
  { value: 'ONEFABRIC-CORE-12.8T-16W-BR' },
  { value: 'ONEFABRIC-CORE-12.8T-8W-BR' },
  { value: 'ONEFABRIC-SP-12.8T-16W-BASE_FULL' },
  { value: 'ONEFABRIC-SP-12.8T-8W-BASE_FULL' },
  { value: 'OPAL' },
  { value: 'OPTICAL.RFTS.1.0' },
  { value: 'ORI61EOR' },
  { value: 'ORINOCO15' },
  { value: 'ORINOCO16' },
  { value: 'ORION16' },
  { value: 'OSD' },
  { value: 'OSD20S' },
  { value: 'OSMIUM6.P' },
  { value: 'OSMIUM6.P.CF1' },
  { value: 'OSMIUM6.P.CF2' },
  { value: 'OSMIUM6.P.CF3' },
  { value: 'OSMIUM6.P.CF4' },
  { value: 'OSMIUM6.P.CF5' },
  { value: 'OSMIUM6.P.CF6' },
  { value: 'OSMIUM6.P.CF7' },
  { value: 'OSMIUM6.P.CF8' },
  { value: 'OSPREY-FAMILY' },
  { value: 'OSTRICH13' },
  { value: 'OTHER' },
  { value: 'PACUARE' },
  { value: 'PACUARE09' },
  { value: 'PATAGONIA' },
  { value: 'PATAGONIA-GARFIELD18' },
  { value: 'PATAGONIA-WEBBER18' },
  { value: 'PATCH-PANEL-2U-48MTP' },
  { value: 'PEGASUS15' },
  { value: 'PEGASUS16' },
  { value: 'PEGASUSA15' },
  { value: 'PERIDOT' },
  { value: 'PERIDOTF' },
  { value: 'PERRO.CQ' },
  { value: 'PERRO.MG' },
  { value: 'PHOLUS' },
  { value: 'PICTOR16' },
  { value: 'PLUTO15' },
  { value: 'POLAR15' },
  { value: 'POLLO.BAMBINO' },
  { value: 'POLLO.BM' },
  { value: 'POLLO.CQ' },
  { value: 'POLLO.MG' },
  { value: 'POMERANIAN' },
  { value: 'POP60MDF' },
  { value: 'POP60NET1' },
  { value: 'POP60NET2' },
  { value: 'POP61MDF' },
  { value: 'POP61NET1' },
  { value: 'POP61NET2' },
  { value: 'POP62DWDM' },
  { value: 'POP62MDF' },
  { value: 'POP62NET1' },
  { value: 'POP62NET2' },
  { value: 'POP62POMERANIAN' },
  { value: 'POPLAR11' },
  { value: 'POSEIDON15' },
  { value: 'POSEIDON15B' },
  { value: 'POSEIDON16C' },
  { value: 'POWERSHELF16' },
  { value: 'PROLIANT_DL380_G4' },
  { value: 'PROLIANT_DL380_G5' },
  { value: 'PROLIANT_DL380_G6' },
  { value: 'PROLIANT_DL380_G7' },
  { value: 'PROLIANT_DL580_G7' },
  { value: 'PROLIANT_DL585_G1' },
  { value: 'PROLIANT_SL2X170Z_G6' },
  { value: 'PULSAR' },
  { value: 'PULSARF' },
  { value: 'PYRO1' },
  { value: 'PYRO17' },
  { value: 'PYRO2' },
  { value: 'PYRO3' },
  { value: 'PYRO4' },
  { value: 'PYRO5' },
  { value: 'PYRO6' },
  { value: 'PYRO7' },
  { value: 'PYRO8' },
  { value: 'QBERT16' },
  { value: 'QBERT16C' },
  { value: 'QBERTA16' },
  { value: 'QFX x 24/Border' },
  { value: 'QUATTRO' },
  { value: 'RABUN' },
  { value: 'RADIUM4' },
  { value: 'RAINIER01' },
  { value: 'RAINIER02' },
  { value: 'RAINIER03' },
  { value: 'RAINIER04' },
  { value: 'RAINIER06' },
  { value: 'RAINIER07' },
  { value: 'RAN01AC' },
  { value: 'RAN01DC' },
  { value: 'RATTLESNAKE' },
  { value: 'RAZOR' },
  { value: 'REDRIVER12' },
  { value: 'REDRIVER12.5' },
  { value: 'REDRIVER13' },
  { value: 'REDRIVER14' },
  { value: 'REDWOOD' },
  { value: 'REMA15' },
  { value: 'RLO4W12.8T' },
  { value: 'ROCOCO01' },
  { value: 'ROCOCO02' },
  { value: 'ROCOCO03' },
  { value: 'ROCOCO04' },
  { value: 'ROCOCO05' },
  { value: 'ROCOCO06' },
  { value: 'ROGUE12' },
  { value: 'ROVER16C' },
  { value: 'ROVER21' },
  { value: 'ROVERC16C' },
  { value: 'ROVERF.15' },
  { value: 'Rising Power' },
  { value: 'Rover15' },
  { value: 'S3.OSPC.16C' },
  { value: 'S3.OSPG.16C' },
  { value: 'S3SLIMLINE09' },
  { value: 'S5000PSL' },
  { value: 'SASQUATCH1' },
  { value: 'SASQUATCH1F' },
  { value: 'SASQUATCH2' },
  { value: 'SASQUATCH2F' },
  { value: 'SASQUATCH3' },
  { value: 'SASQUATCH3F' },
  { value: 'SASQUATCH4' },
  { value: 'SASQUATCH4F' },
  { value: 'SASQUATCH5' },
  { value: 'SASQUATCH5F' },
  { value: 'SATURN14' },
  { value: 'SATURNB14' },
  { value: 'SCADA' },
  { value: 'SCADA_3_2' },
  { value: 'SCADA_3_3' },
  { value: 'SCADA_3_4' },
  { value: 'SCB14' },
  { value: 'SCB15' },
  { value: 'SCOUT01AC' },
  { value: 'SCOUT01DC' },
  { value: 'SCOUT02AC' },
  { value: 'SCOUT02DC' },
  { value: 'SCOUT03' },
  { value: 'SCOUT04' },
  { value: 'SCOUT05' },
  { value: 'SCOUT_FAMILY' },
  { value: 'SEDONA41' },
  { value: 'SEDONA42' },
  { value: 'SEDONA43' },
  { value: 'SEDONA44' },
  { value: 'SEDONA45' },
  { value: 'SEDONA46' },
  { value: 'SEDONA47' },
  { value: 'SEDONA48' },
  { value: 'SEQUOIA' },
  { value: 'SEQUOIA09' },
  { value: 'SINCLAIR1' },
  { value: 'SINCLAIR2' },
  { value: 'SINCLAIR3' },
  { value: 'SJLB15' },
  { value: 'SJLB15_10kVA' },
  { value: 'SJLB15_15kVA' },
  { value: 'SJLB17' },
  { value: 'SJLB19' },
  { value: 'SJLBW15' },
  { value: 'SKAGIT' },
  { value: 'SKAGIT12' },
  { value: 'SKAGIT12.5' },
  { value: 'SKAGIT13' },
  { value: 'SKYKOMISH' },
  { value: 'SKYKOMISH11' },
  { value: 'SKYKOMISH11;SLIMIOPS0910G' },
  { value: 'SL160Z' },
  { value: 'SLIMFAST08.5' },
  { value: 'SLIMFAST085' },
  { value: 'SLIMFAST09' },
  { value: 'SLIMFAST09.5' },
  { value: 'SLIMIOPS09' },
  { value: 'SLIMIOPS09.5' },
  { value: 'SLIMIOPS0910G' },
  { value: 'SLIMLINE09' },
  { value: 'SLIMLINE09.5' },
  { value: 'SMALLDB10' },
  { value: 'SNOWBALL12016' },
  { value: 'SNOWBALL25' },
  { value: 'SNOWBALL25G' },
  { value: 'SNOWBALL30C' },
  { value: 'SNOWBALL30CG' },
  { value: 'SNOWBALL30CGSSD' },
  { value: 'SNOWBALL30CSSD' },
  { value: 'SNOWBALL30S' },
  { value: 'SNOWBALL31C' },
  { value: 'SNOWBALL31CG' },
  { value: 'SNOWBALL31CGSSD' },
  { value: 'SNOWBALL31CSSD' },
  { value: 'SNOWBALL31CSSD1PB' },
  { value: 'SNOWBALL31CSSD210T' },
  { value: 'SNOWBALL48T15' },
  { value: 'SNOWBALL8015' },
  { value: 'SNOWBALLA10016' },
  { value: 'SNOWBALLM10016' },
  { value: 'SNOWCONE_C' },
  { value: 'SNOWCONE_HDD' },
  { value: 'SNOWCONE_SSD' },
  { value: 'SNOWMAN15' },
  { value: 'SNOWMAN16' },
  { value: 'SNOWMAN17A' },
  { value: 'SNOWMANA16' },
  { value: 'SNOWMANB16' },
  { value: 'SNOWWARRIOR' },
  { value: 'SOKKELO' },
  { value: 'SOKKELO.P' },
  { value: 'SPHINX15' },
  { value: 'SPHINX16' },
  { value: 'SPICY11.5' },
  { value: 'SPICY11.6' },
  { value: 'SPICY12' },
  { value: 'SPICY13' },
  { value: 'STARMAN16' },
  { value: 'STARMAN16_40G' },
  { value: 'STARMAN16_SM' },
  { value: 'STELLAR16' },
  { value: 'STORM.SIREN' },
  { value: 'SUNBEAR15' },
  { value: 'SYRACUSE1' },
  { value: 'SYSTAR' },
  { value: 'Spicy15' },
  { value: 'TACHYON16' },
  { value: 'TANKER12' },
  { value: 'TANKER13' },
  { value: 'TANKER6T' },
  { value: 'TARMAC4' },
  { value: 'TARMAC8' },
  { value: 'TECHNIC' },
  { value: 'TECHNIC_SBD100G' },
  { value: 'TECHNIC_SBD10G' },
  { value: 'TERRAPIN' },
  { value: 'TETON' },
  { value: 'TETON2' },
  { value: 'TETRA01' },
  { value: 'TETRA01F' },
  { value: 'TETRA02' },
  { value: 'TETRA02F' },
  { value: 'TETRA03' },
  { value: 'TETRA03F' },
  { value: 'TETRA04' },
  { value: 'TETRA04F' },
  { value: 'TETRA05' },
  { value: 'TETRA05F' },
  { value: 'TETRA06' },
  { value: 'TETRA06F' },
  { value: 'TETRA07' },
  { value: 'TETRA08' },
  { value: 'TETRA11' },
  { value: 'TETRA11F' },
  { value: 'TETRA12' },
  { value: 'TETRA13' },
  { value: 'TETRA14' },
  { value: 'TETRA14F' },
  { value: 'TETRA15' },
  { value: 'TETRA15F' },
  { value: 'TETRA16' },
  { value: 'TETRA16F' },
  { value: 'TETRA26' },
  { value: 'TETRA84C' },
  { value: 'TIMBERWOLF' },
  { value: 'TORBES 1.0-Amplified-8x100G_(0.8T).WAVESERVER.1.4-16QAM-10KVA' },
  { value: 'TORBES 1.0-Amplified-8x100G_(0.8T).WAVESERVER.1.4-16QAM-5KVA' },
  { value: 'TORBES 1.0-Amplified-96x10G_(0.96T).WAVESERVER.1.4-16QAM-10KVA' },
  { value: 'TORBES 1.0-Amplified-96x10G_(0.96T).WAVESERVER.1.4-16QAM-5KVA' },
  { value: 'TORBES 1.0-CIENA-2LINE-A+A_0.8T, 10KVA' },
  { value: 'TORBES 1.0-CIENA-2LINE-A+P_0.8T, 10KVA' },
  { value: 'TORBES 1.0-CIENA-2LINE-P+P_0.8T, 10KVA' },
  { value: 'TORBES 1.0-CIENA-3LINE-A+P+P_0.8T, 10KVA' },
  { value: 'TORBES 1.0-CIENA-3LINE-P+P+P_0.8T, 10KVA' },
  { value: 'TORBES 1.0-Unamplified-8x100G_(0.8T).WAVESERVER.1.4-16QAM-10KVA' },
  { value: 'TORBES 1.0-Unamplified-8x100G_(0.8T).WAVESERVER.1.4-16QAM-5KVA' },
  { value: 'TORBES 1.0-Unamplified-96x10G_(0.96T).WAVESERVER.1.4-16QAM-10KVA' },
  { value: 'TORBES 1.0-Unamplified-96x10G_(0.96T).WAVESERVER.1.4-16QAM-5KVA' },
  { value: 'TORNADO21' },
  { value: 'TORNADO21;TORNADO21F' },
  { value: 'TREADMILL' },
  { value: 'TREADMILLR2P' },
  { value: 'TREK1' },
  { value: 'TREK11' },
  { value: 'TREK12' },
  { value: 'TREK13' },
  { value: 'TREK14' },
  { value: 'TREK15' },
  { value: 'TREK16' },
  { value: 'TREK2' },
  { value: 'TREK23' },
  { value: 'TREK23F' },
  { value: 'TREK28' },
  { value: 'TREK28F' },
  { value: 'TREK3' },
  { value: 'TREK4' },
  { value: 'TREK41' },
  { value: 'TREK42' },
  { value: 'TREK43' },
  { value: 'TREK44' },
  { value: 'TREK45' },
  { value: 'TREK46' },
  { value: 'TREK5' },
  { value: 'TREK6' },
  { value: 'TREK7' },
  { value: 'TREK8' },
  { value: 'TREK9' },
  { value: 'TREKB1' },
  { value: 'TREKB2' },
  { value: 'TREKHF' },
  { value: 'TRIBBLE16' },
  { value: 'TUG14' },
  { value: 'TUNDRA' },
  { value: 'TYPHON16' },
  { value: 'Titan_Base' },
  { value: 'TuftedPuffin-L-BF15' },
  { value: 'TuftedPuffin-M-BF15' },
  { value: 'US_AND_EU' },
  { value: 'VOLGA16' },
  { value: 'VOLTRON15' },
  { value: 'VOLTRON18' },
  { value: 'VOYAGER14' },
  { value: 'VOYAGER15' },
  { value: 'VOYAGERB14' },
  { value: 'VP-EXT-VC-CAR' },
  { value: 'VP-VC-AGG' },
  { value: 'VP-VC-BAR' },
  { value: 'VP-VC-CAR' },
  { value: 'VP-VC-EDG-EXTERN-MX480' },
  { value: 'VP-VC-EDG-INTERN-MX480' },
  { value: 'VP-VC-EDG-MX960' },
  { value: 'VP-VC-FAB' },
  { value: 'VP-VC-MGMT' },
  { value: 'VP-VC-XLC' },
  { value: 'VP-VOLTRON-QFX' },
  { value: 'VP.DIRECT-CONNECT.1.0' },
  { value: 'VP.DIRECT-CONNECT.2.0' },
  { value: 'VP.DIRECT-CONNECT.3.0' },
  { value: 'VP.DIRECT-CONNECT.3.1' },
  { value: 'VP.VOLTRON-QFX.2.0' },
  { value: 'VP.VOLTRON-QP-QFX.1.0' },
  { value: 'VP.VOLTRON-S-QFX.1.0' },
  { value: 'VP.VOLTRON15' },
  { value: 'VPC-DX-CIRHAM-1.0' },
  { value: 'VPC-HAM' },
  { value: 'VPC-VC-CIR' },
  { value: 'WAIPOUA1216' },
  { value: 'WAIPOUA12SMR16' },
  { value: 'WAIPOUA17' },
  { value: 'WAIPOUAA1016' },
  { value: 'WAIPOUAA1216' },
  { value: 'WAIPOUAA1416' },
  { value: 'WAIPOUAA1616' },
  { value: 'WAIPOUAASMR1416' },
  { value: 'WAIPOUAASMR1516' },
  { value: 'WAIPOUAB1016' },
  { value: 'WAIPOUAB1216' },
  { value: 'WAIPOUAC1216' },
  { value: 'WAIPOUAC1416' },
  { value: 'WAIPOUAC1616' },
  { value: 'WAIPOUACSMR1416' },
  { value: 'WAIPOUACSMR1516' },
  { value: 'WALDEN1' },
  { value: 'WALDEN2' },
  { value: 'WALDEN3' },
  { value: 'WALDEN4' },
  { value: 'WAYNEMANOR' },
  { value: 'WBERC18VQ16' },
  { value: 'WEBBER1816-FAMILY' },
  { value: 'WEBBER2016-FAMILY' },
  { value: 'WEBBERA' },
  { value: 'WEBBERA1616' },
  { value: 'WEBBERA1816' },
  { value: 'WEBBERA2016' },
  { value: 'WEBBERASMR2016' },
  { value: 'WEBBERC' },
  { value: 'WEBBERC1616' },
  { value: 'WEBBERC1816' },
  { value: 'WEBBERCSMR2016' },
  { value: 'WEBVARCAPC18' },
  { value: 'WHITESNAKE14' },
  { value: 'WHITESNAKE15' },
  { value: 'WHITESNAKE15Q' },
  { value: 'WHITESNAKEA14' },
  { value: 'WHITESNAKEA15' },
  { value: 'WHITESNAKEB14' },
  { value: 'WHITESNAKEC15' },
  { value: 'WHITESNAKEC17B' },
  { value: 'WILLOW11' },
  { value: 'WILLOW11.5' },
  { value: 'WMW16' },
  { value: 'WOLVERINE13' },
  { value: 'WS-TAP' },
  { value: 'WS-XXX-FW-5800' },
  { value: 'WS-XXX-FW-5800-5SPC-UG' },
  { value: 'WS-XXX-FW-5800-9SPC-4PSU' },
  { value: 'WUKONG' },
  { value: 'WUKONG41' },
  { value: 'WUKONG42' },
  { value: 'WUKONG81' },
  { value: 'WUKONG82' },
  { value: 'WUKONG83' },
  { value: 'WUKONG84' },
  { value: 'X3.5_PLUTONIUM' },
  { value: 'X3_PLUTONIUM' },
  { value: 'XENCOMPUTEA09' },
  { value: 'XLDB105GH' },
  { value: 'XLDB10H' },
  { value: 'YELLOWSTONE' },
  { value: 'YELMA1416' },
  { value: 'YELMA1616' },
  { value: 'YELMASMR1416' },
  { value: 'YELMC1216' },
  { value: 'YELMC1416' },
  { value: 'YELMC1616' },
  { value: 'YELMCSMR1416' },
  { value: 'YETI14' },
  { value: 'YETI15' },
  { value: 'YETI18I' },
  { value: 'YETI21' },
  { value: 'YETIPDF21' },
  { value: 'YETIPDS21' },
  { value: 'YETIPF21' },
  { value: 'YETIPS21' },
  { value: 'YETISQUISH' },
  { value: 'YOSEMITE' },
  { value: 'YUKON11' },
  { value: 'YelmA1216' },
  { value: 'ZEALOT14' },
  { value: 'ZION16' },
  { value: 'ZOAN20' },
];

export const CLASSIFICATION_TYPES = [
  {
    value: 'trade_classification',
    label: 'Trade Classification',
    filteringTags: ['trade'],
  },
  {
    value: 'pc_classification',
    label: 'Product Compliance Classification',
    filteringTags: ['pc'],
  },
];

export const PCC_PART_IDENTIFIER_TYPES = [
  {
    value: PccPartIdentifierType.IPN,
    label: 'IPN',
    filteringTags: [PccPartIdentifierType.IPN],
  },
  {
    value: PccPartIdentifierType.ASIN,
    label: 'ASIN',
    filteringTags: [PccPartIdentifierType.ASIN],
  },
  {
    value: PccPartIdentifierType.MPN,
    label: 'MPN',
    filteringTags: [PccPartIdentifierType.MPN],
  },
];

export const PCC_PART_SOURCES = [
  {
    value: PccPartSource.AGILE_PLM,
    label: 'Agile PLM',
    filteringTags: [PccPartSource.AGILE_PLM],
  },
  {
    value: PccPartSource.NON_AGILE,
    label: 'Non-Agile',
    filteringTags: [PccPartSource.NON_AGILE],
  },
];

/**
 * Source of where the artifact or license data originally came from
 * Filter Tag backend - these are updated through Bulk Upload and should not let user changes these values
 * Filter Tag frontend - these are the user selectable values if they know what the source of data is
 */
export const SOURCE: EnumDisplayMapping[] = [
  { value: 'BACKFILL_RELATED_PART', label: 'Backfill Related Part', filteringTags: ['backend'] },
  { value: 'BACKFILL_CORRECTION_TYPE_MANUAL', label: 'Backfill Correction Type Manual', filteringTags: ['backend'] },
  {
    value: 'BACKFILL_CORRECTION_TYPE_EXTRACTION',
    label: 'Backfill Correction Type Extraction',
    filteringTags: ['backend'],
  },
  { value: 'NEW_COLLECTION_SUPPLIER_MANUAL', label: 'New Collection Supplier Manual', filteringTags: ['backend'] },
  { value: 'NEW_COLLECTION_SUPPLIER_DISCLOSURE', label: 'Supplier Disclosure', filteringTags: ['frontend'] },
  { value: 'SOURCED_AGILE', label: 'Agile', filteringTags: ['frontend'] },
  { value: 'SOURCED_WINDCHILL', label: 'Windchill', filteringTags: ['frontend'] },
  { value: 'SOURCED_HISTORICAL_ON_HAND', label: 'Sourced Historical On Hand', filteringTags: ['frontend'] },
  { value: 'OTHER', label: 'Other', filteringTags: ['frontend'] },
];

/**
 *
 */
export const PC_PART_SOURCE: EnumDisplayMapping[] = [
  { value: 'AGILE_PLM', label: 'Agile' },
  { value: 'NON_AGILE', label: 'Non-Agile' },
];

/**
 *
 */
export const PC_CLASSIFICATION_STATUS: EnumDisplayMapping[] = [
  { value: 'NOT_CLASSIFIED', label: 'Not Classified' },
  { value: 'AUTO_CLASSIFIED', label: 'Auto Classified' },
  { value: 'MANUALLY_CLASSIFIED', label: 'Manually Classified' },
  { value: 'MANUALLY_OVERRIDDEN', label: 'Manually Overridden' },
];

export enum FeatureFlag {
  PC_CLASSIFICATIONS = 'PC_CLASSIFICATIONS',
  ARTIFACTS_UI = 'ARTIFACTS_UI',
  ARTIFACTS_V2 = 'ARTIFACTS_V2',
}

export const CONTACT_US_HREF = 'https://t.corp.amazon.com/create/templates/ad9ff44e-e505-41f6-9db0-c1608a80267b';
