import { SpaceBetween } from '@amzn/awsui-components-react';
import LicenseManagerCommon, {
  LicenseUsageFormInputs,
  lmUsageFormSchemaToApiSchema,
} from 'components/LicenseUsageCommon';
import React, { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { createUsageAction, setLMCreateFlashbarItems, setLMDetailFlashbarItems } from 'store/licenseManager';
import { useAppSelector } from 'store/store';
import { createSuccessItem } from 'utils/formUtils';
import { License } from '@amzn/d2d-bff-schema';

interface LMUsageCreatePageProps {
  license?: License;
}

const LicenseUsageCreate: React.FC<LMUsageCreatePageProps> = ({ license }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { licenseId } = useParams();
  const { createLoading: loading, licenseErrors, createdUsage } = useAppSelector((state) => state.licenseManagerStore);

  const onSubmit: SubmitHandler<LicenseUsageFormInputs> = async (data) => {
    // /** parse if needed */
    const apiSchemaUsage = lmUsageFormSchemaToApiSchema.parse(data);
    // /** call create usage to create a usage */
    if (licenseId) dispatch(createUsageAction({ licenseId, usageInput: apiSchemaUsage }));
  };

  useEffect(() => {
    if (loading === 'fulfilled' && createdUsage?.transactionId) {
      dispatch(setLMDetailFlashbarItems([createSuccessItem(`License usage created successfully!`)]));
      navigate(`/license/${licenseId}`);
    }
  }, [dispatch, createdUsage?.transactionId, licenseErrors, loading, navigate, licenseId]);

  useEffect(() => {
    return () => {
      dispatch(setLMCreateFlashbarItems([]));
    };
  }, [dispatch]);

  const licenseCreate = (
    <LicenseManagerCommon
      isLoading={loading === 'pending'}
      onSubmit={onSubmit}
      submitText="Create"
      cancelRoute={`/license/${licenseId}`}
      license={license}
    />
  );

  return <SpaceBetween size="l">{licenseCreate}</SpaceBetween>;
};

export default LicenseUsageCreate;
