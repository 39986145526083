import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  FormField,
  Input,
  Modal,
  RadioGroup,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react/polaris';
import { useAppSelector } from 'store/store';
import { Box, ColumnLayout, Container, DatePicker, Header, Pagination, Select } from '@amzn/awsui-components-react';
import {
  ARTIFACTSV2_COUNTRIES,
  ARTIFACT_V2_MENU_OPTIONS_FOR_CREATE,
  ARTIFACT_V2_SUBTYPE_MENU_OPTIONS_FOR_CREATE,
  STANDARDS,
} from '../../../utils/constants';
import { EnumDisplayMapping } from '../../../types';
import { DATE_PICKER_ARIA_LABELS, createErrorItem } from '../../../utils/formUtils';
import ArtifactV2SearchCard from './ArtifactV2SearchCard';
import {
  listPartIdentifierByPrefixAction,
  searchArtifactAction,
  setArtifactV2SearchFlashbarItems,
} from '../../../store/artifactv2';
import {
  Artifact,
  ArtifactSearchCriteria,
  ArtifactSearchCriterionEqualityOperator,
  PartIdentifierType,
} from '@amzn/d2d-bff-schema';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useNavigate } from 'react-router';
import { PAGINATION_ARIA_LABELS } from '../../Table/tableConfig';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { ArtifactSearchCriterion } from '@amzn/d2d-bff-schema/dist/types';
import { OptionsLoadItemsDetail } from '@amzn/awsui-components-react/polaris/internal/components/dropdown/interfaces';

interface ArtifactsPageProps {
  artifactUiAvailable: boolean;
}

export type ArtifactSearchVisibleContent = {
  type: boolean;
  subtype: boolean;
  archived: boolean;
  partIdentifiers: boolean;
  expiration: boolean;
};

export type PaginationProps = {
  isEnd: boolean;
  maxPage: number;
  currentPage: number;
  paginator: 'exact' | 'related';
};

const DEFAULT_PAGINATION_PROPS = {
  isEnd: false,
  maxPage: 0,
  currentPage: 1,
};

const DEFAULT_PAGE_SIZE = 5;

const ArtifactV2Search: React.FC<ArtifactsPageProps> = ({ artifactUiAvailable }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    exactSearchResults,
    relatedSearchResults,
    exactSearchResultsLoading,
    relatedSearchResultsLoading,
    ipns,
    ipnsLoading,
    ipnsNextToken,
    ipnsErrorMessage,
    superSkus,
    superSkusLoading,
    superSkusNextToken,
    superSkusErrorMessage,
    projectExternals,
    projectExternalsNextToken,
    projectExternalsLoading,
    projectExternalErrorMessage,
  } = useAppSelector((state) => state.artifactV2Store);

  // Type/Subtype selection option states
  const [artifactTypeSelection, setArtifactTypeSelection] = useState<Partial<EnumDisplayMapping> | null>(null);
  const [artifactSubTypeSelection, setArtifactSubTypeSelection] = useState<Partial<EnumDisplayMapping> | null>(null);

  const [artifactSubTypes, setArtifactSubTypes] = useState<EnumDisplayMapping[]>(
    ARTIFACT_V2_SUBTYPE_MENU_OPTIONS_FOR_CREATE
  );

  // General states
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [exactLocalSearchResults, setExactLocalSearchResults] = useState<Artifact[]>(exactSearchResults);
  const [relatedLocalSearchResults, setRelatedLocalSearchResults] = useState<Artifact[]>(relatedSearchResults);

  // Search parameter states
  const [projectExternal, setProjectExternal] = useState('');
  const [ipn, setIpn] = useState('');
  const [superSku, setSuperSku] = useState('');
  const [mpn, setMpn] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [expirationDateOperator, setExpirationDateOperator] = useState<Partial<EnumDisplayMapping> | null>(null);
  const [showArchived, setShowArchived] = useState(false);
  const [standard, setStandard] = useState<Partial<EnumDisplayMapping> | null>(null);
  const [applicableCountry, setApplicableCountry] = useState<Partial<EnumDisplayMapping> | null>(null);

  const [exactPaginationProps, setExactPaginationProps] = useState<PaginationProps>({
    ...DEFAULT_PAGINATION_PROPS,
    paginator: 'exact',
  });

  const [relatedPaginationProps, setRelatedPaginationProps] = useState<PaginationProps>({
    ...DEFAULT_PAGINATION_PROPS,
    paginator: 'related',
  });

  const artifactSearchVisibleContent = {
    type: true,
    subtype: true,
    archived: true,
    partIdentifiers: true,
    expiration: true,
  };

  // Cookies and state for search preferences
  const artifactsPreferenceStorage = useLocalStorage('artifactsv2-search-preferences', {
    pageSize: DEFAULT_PAGE_SIZE,
    visibleContent: artifactSearchVisibleContent,
  });
  const [preferences, setPreferences] = artifactsPreferenceStorage;

  const clearInputs = (): void => {
    setArtifactTypeSelection(null);
    setArtifactSubTypeSelection(null);
    setProjectExternal('');
    setIpn('');
    setSuperSku('');
    setMpn('');
    setExpirationDate('');
    setExpirationDateOperator(null);
    setApplicableCountry(null);
    setStandard(null);
  };

  const handleSearch = (archived?: boolean, pageSize?: number): void => {
    dispatch(setArtifactV2SearchFlashbarItems([])); // Clear error message on new search
    setIsSearchPerformed(true);
    search(archived ?? showArchived, exactPaginationProps, true, pageSize); // Get exact results
    if (ipn || mpn) {
      // If an IPN or MPN is in the search criteria, also query for related artifacts
      search(archived ?? showArchived, relatedPaginationProps, true, pageSize);
    } else {
      setRelatedLocalSearchResults([]);
    }
  };

  const search = (
    showArchived: boolean,
    paginationProps: PaginationProps,
    resetPagination: boolean,
    pageSize?: number
  ): void => {
    if (resetPagination) {
      // Reset pagination
      paginationProps.isEnd = DEFAULT_PAGINATION_PROPS.isEnd;
      paginationProps.maxPage = DEFAULT_PAGINATION_PROPS.maxPage;
      paginationProps.currentPage = DEFAULT_PAGINATION_PROPS.currentPage;
    }

    const searchCriteria: ArtifactSearchCriteria[] = [];
    ipn && searchCriteria.push({ orCriteria: [{ metadata: { key: 'ipn', value: ipn } }] });
    superSku && searchCriteria.push({ orCriteria: [{ metadata: { key: 'superSku', value: superSku } }] });
    mpn && searchCriteria.push({ orCriteria: [{ metadata: { key: 'mpn', value: mpn } }] });
    projectExternal &&
      searchCriteria.push({ orCriteria: [{ metadata: { key: 'projectExternal', value: projectExternal } }] });
    artifactTypeSelection?.value &&
      searchCriteria.push({ orCriteria: [{ metadata: { key: 'type', value: artifactTypeSelection.value } }] });
    artifactSubTypeSelection?.value &&
      searchCriteria.push({ orCriteria: [{ metadata: { key: 'subtype', value: artifactSubTypeSelection.value } }] });
    standard?.value && searchCriteria.push({ orCriteria: [{ metadata: { key: 'standards', value: standard.value } }] });
    if (applicableCountry?.value) {
      // if searching on a country, always include Worldwide as well
      const orCriteria = [{ metadata: { key: 'applicableCountries', value: applicableCountry.value } }];
      applicableCountry?.value !== 'WW' && orCriteria.push({ metadata: { key: 'applicableCountries', value: 'WW' } });
      searchCriteria.push({ orCriteria });
    }

    // Determine expiration search criteria
    if (expirationDate) {
      const expirationOrExpression: ArtifactSearchCriterion[] = [];
      expirationOrExpression.push({
        metadata: {
          key: 'expiration',
          value: new Date(expirationDate).toISOString(),
        },
        operator: (expirationDateOperator?.value ?? 'EQUALS') as ArtifactSearchCriterionEqualityOperator,
      });
      // If the expiration date operator is "greater than", then also include artifacts that don't have an expiration date
      if (expirationDateOperator?.value === 'GREATER_THAN') {
        expirationOrExpression.push({
          metadata: {
            key: 'expiration',
            value: '',
          },
          operator: 'NOT_EXISTS' as ArtifactSearchCriterionEqualityOperator,
        });
      }
      searchCriteria.push({
        orCriteria: expirationOrExpression,
      });
    }

    // If there is no search criteria entered, show error message
    if (!searchCriteria.length) {
      dispatch(
        setArtifactV2SearchFlashbarItems([createErrorItem('Please enter a search criteria to search for artifacts')])
      );
      return;
    }

    // Don't include archived artifacts
    !showArchived && searchCriteria.push({ orCriteria: [{ isArchived: false }] });

    // Get the next result after the total number of previous results
    const apiPageNum = (paginationProps.currentPage - 1) * preferences.pageSize + 1;
    dispatch(
      searchArtifactAction({
        andCriteria: searchCriteria,
        includeExactMatches: paginationProps.paginator === 'exact',
        includeHorizontallyRelatedMatches: !(paginationProps.paginator === 'exact'),
        pageNum: apiPageNum,
        pageSize: pageSize ?? preferences.pageSize,
      })
    );
  };

  const handlePartIdentifierPrefixSearch = (
    searchDetails: OptionsLoadItemsDetail,
    partList: EnumDisplayMapping[],
    partIdentifierType: PartIdentifierType
  ): void => {
    if (searchDetails.filteringText.length > 2 && searchDetails.firstPage) {
      dispatch(
        listPartIdentifierByPrefixAction({
          input: { idType: partIdentifierType, idPrefix: searchDetails.filteringText },
          isFirstPageSearch: searchDetails.firstPage,
        })
      );
    } else if (!searchDetails.firstPage) {
      // The user has scrolled to the bottom of the results so append new search to existing results
      let nextToken;
      switch (partIdentifierType) {
        case PartIdentifierType.IPN:
          nextToken = ipnsNextToken;
          break;
        case PartIdentifierType.AWS_EXTERNAL_PROJECT_NAME:
          nextToken = projectExternalsNextToken;
          break;
        case PartIdentifierType.SUPER_SKU:
          nextToken = superSkusNextToken;
          break;
      }
      dispatch(
        listPartIdentifierByPrefixAction({
          input: { idType: partIdentifierType, idPrefix: searchDetails.filteringText },
          nextToken,
          isFirstPageSearch: searchDetails.firstPage,
        })
      );
    }
  };

  const paginationOnChangeEvent = (paginationProps: PaginationProps, searchResults: Artifact[]): void => {
    if (searchResults.length === 0 && isSearchPerformed) {
      // A search happened and no results were returned, so set end of results to true
      paginationProps.isEnd = true;
      if (paginationProps.currentPage > 1) {
        // If the current page is greater than 1, go back a page and set that as the max page
        paginationProps.currentPage = paginationProps.currentPage - 1;
        paginationProps.maxPage = paginationProps.maxPage - 1;
        search(showArchived, paginationProps, false);
      } else {
        // There was only one page of results
        paginationProps.maxPage = 1;
      }
    } else if (searchResults.length < preferences.pageSize && isSearchPerformed) {
      // If results are less than page size, set end of results to true
      paginationProps.isEnd = true;
      paginationProps.maxPage = paginationProps.currentPage;
    }

    // Reset the paginationProps to trigger a page render
    paginationProps.paginator === 'exact'
      ? setExactPaginationProps({ ...paginationProps })
      : setRelatedPaginationProps({ ...paginationProps });
  };

  useEffect(() => {
    setExactLocalSearchResults(exactSearchResults);
    paginationOnChangeEvent(exactPaginationProps, exactSearchResults);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exactSearchResults]);

  useEffect(() => {
    setRelatedLocalSearchResults(relatedSearchResults);
    paginationOnChangeEvent(relatedPaginationProps, relatedSearchResults);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedSearchResults]);

  useEffect(() => {
    // if a user selects sub-type first, infer the type
    if (artifactSubTypeSelection) {
      ARTIFACT_V2_MENU_OPTIONS_FOR_CREATE.forEach((group) => {
        const match = group.options.find(
          (type) => artifactSubTypeSelection.format && artifactSubTypeSelection.format === type.value
        );
        if (match) setArtifactTypeSelection(match);
      });
    }
    // if a user selects type, filter the sub-type list
    if (artifactTypeSelection)
      setArtifactSubTypes(
        ARTIFACT_V2_SUBTYPE_MENU_OPTIONS_FOR_CREATE.filter(
          (st) => st.format && (!artifactTypeSelection?.value || st.format === artifactTypeSelection?.value)
        )
      );
  }, [artifactTypeSelection, artifactSubTypeSelection]);

  const getCardDisplay = (artifacts: Artifact[]): JSX.Element => {
    return artifacts.length > 0 ? (
      <ColumnLayout columns={4}>
        {artifacts.map((artifact, i) => {
          return (
            <ArtifactV2SearchCard
              key={i}
              artifact={artifact}
              visibleContent={preferences.visibleContent}
            ></ArtifactV2SearchCard>
          );
        })}
      </ColumnLayout>
    ) : (
      <Box color="text-body-secondary" float="left" textAlign="left">
        No results
      </Box>
    );
  };

  const [tempPrefPageSize, setTempPrefPageSize] = useState<number>(preferences.pageSize);
  const [tempPrefVisibleContent, setTempPrefVisibleContent] = useState(preferences.visibleContent);

  const preferencesModal = (
    <Modal
      onDismiss={() => setShowPreferencesModal(false)}
      visible={showPreferencesModal}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setTempPrefPageSize(preferences.pageSize);
                setTempPrefVisibleContent(preferences.visibleContent);
                setShowPreferencesModal(false);
              }}
              variant="link"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setShowPreferencesModal(false);
                setPreferences({ pageSize: tempPrefPageSize, visibleContent: tempPrefVisibleContent });
                if (isSearchPerformed && tempPrefPageSize !== preferences.pageSize) {
                  // If there are search criteria and the page size has changed, reset pagination and search again
                  console.log(`Temp Page size: ${tempPrefPageSize}. Preferences: ${preferences.pageSize}`);
                  handleSearch(showArchived, tempPrefPageSize);
                }
              }}
              variant="primary"
            >
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Preferences"
    >
      <ColumnLayout borders="vertical" columns={2}>
        <Box>
          <Box color="text-body-secondary">Page size</Box>
          <RadioGroup
            onChange={({ detail }) => setTempPrefPageSize(parseInt(detail.value))}
            value={String(tempPrefPageSize)}
            items={[
              { value: '5', label: '5' },
              { value: '15', label: '15' },
              { value: '25', label: '25' },
              { value: '50', label: '50' },
            ]}
          />
        </Box>
        <SpaceBetween direction="vertical" size="s">
          <Box color="text-body-secondary">Select visible content</Box>
          <Toggle
            onChange={({ detail }) => setTempPrefVisibleContent({ ...tempPrefVisibleContent, type: detail.checked })}
            checked={tempPrefVisibleContent.type}
            data-test="artifacts-type-toggle"
          >
            Type
          </Toggle>
          <Toggle
            onChange={({ detail }) => setTempPrefVisibleContent({ ...tempPrefVisibleContent, subtype: detail.checked })}
            checked={tempPrefVisibleContent.subtype}
            data-test="artifacts-subtype-toggle"
          >
            Subtype
          </Toggle>
          <Toggle
            onChange={({ detail }) =>
              setTempPrefVisibleContent({ ...tempPrefVisibleContent, archived: detail.checked })
            }
            checked={tempPrefVisibleContent.archived}
            data-test="artifacts-archived-toggle"
          >
            Archived
          </Toggle>
          <Toggle
            onChange={({ detail }) =>
              setTempPrefVisibleContent({ ...tempPrefVisibleContent, partIdentifiers: detail.checked })
            }
            checked={tempPrefVisibleContent.partIdentifiers}
            data-test="artifacts-part-identifiers-toggle"
          >
            Part Identifiers
          </Toggle>
          <Toggle
            onChange={({ detail }) =>
              setTempPrefVisibleContent({ ...tempPrefVisibleContent, expiration: detail.checked })
            }
            checked={tempPrefVisibleContent.expiration}
            data-test="artifacts-expiration-toggle"
          >
            Expiration
          </Toggle>
        </SpaceBetween>
      </ColumnLayout>
    </Modal>
  );

  return (
    <SpaceBetween size="l">
      <Container
        header={
          <Header
            description={
              'Search GTPC’s library of uploaded Compliance Artifacts, upload new artifacts, and edit existing records'
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs" alignItems={'center'}>
                <Toggle
                  onChange={({ detail }) => {
                    setShowArchived(detail.checked);
                    if (isSearchPerformed) {
                      // Trigger new search if a search has already happened
                      handleSearch(detail.checked);
                    }
                  }}
                  checked={showArchived}
                >
                  Show Archived Artifacts
                </Toggle>
                <Button disabled={!artifactUiAvailable} onClick={() => clearInputs()}>
                  Clear All
                </Button>
                <Button
                  variant="primary"
                  disabled={!artifactUiAvailable}
                  onClick={() => navigate('/artifacts/v2/create')}
                >
                  Upload Artifact
                </Button>
                <Button
                  onClick={() => setShowPreferencesModal(true)}
                  iconName="settings"
                  variant="icon"
                  data-test="artifacts-preferences-button"
                />
              </SpaceBetween>
            }
          >
            Search Artifacts
          </Header>
        }
        footer={
          <Box float="right">
            <Button variant="primary" disabled={!artifactUiAvailable} onClick={() => handleSearch()}>
              Search
            </Button>
          </Box>
        }
      >
        <ColumnLayout borders="horizontal" columns={3}>
          <FormField label={<>Type:</>}>
            <Select
              selectedOption={artifactTypeSelection?.value ? artifactTypeSelection : null}
              onChange={(e) => {
                setArtifactSubTypeSelection(null);
                setArtifactTypeSelection(e.detail.selectedOption);
              }}
              filteringType="auto"
              options={[{ label: '-', value: undefined }, ...ARTIFACT_V2_MENU_OPTIONS_FOR_CREATE]}
            />
          </FormField>
          <FormField label={<>Applicable Country:</>}>
            <Select
              filteringType="auto"
              selectedOption={applicableCountry?.value ? applicableCountry : null}
              options={[{ label: '-', value: undefined }, ...ARTIFACTSV2_COUNTRIES]}
              onChange={(e) => {
                setApplicableCountry(e.detail.selectedOption);
              }}
            />
          </FormField>
          <FormField label={<>AWS Project External:</>}>
            <Select
              filteringType="auto"
              onChange={(e) => setProjectExternal(e.detail.selectedOption.value ?? '')}
              selectedOption={{ value: projectExternal, label: projectExternal }}
              loadingText="Loading"
              errorText={projectExternalErrorMessage}
              finishedText="End of results"
              onLoadItems={(e) => {
                handlePartIdentifierPrefixSearch(
                  e.detail,
                  projectExternals,
                  PartIdentifierType.AWS_EXTERNAL_PROJECT_NAME
                );
              }}
              statusType={projectExternalsLoading}
              options={projectExternals}
              ariaLabel="Project external select"
            />
          </FormField>
          <FormField label={<>Subtype:</>}>
            <Select
              filteringType="auto"
              selectedOption={artifactSubTypeSelection?.value ? artifactSubTypeSelection : null}
              options={[{ label: '-', value: undefined }, ...artifactSubTypes]}
              onChange={(e) => {
                setArtifactSubTypeSelection(e.detail.selectedOption);
              }}
            />
          </FormField>

          <FormField label={<>Standard:</>}>
            <Select
              filteringType="auto"
              selectedOption={standard?.value ? standard : null}
              options={[{ label: '-', value: undefined }, ...STANDARDS]}
              onChange={(e) => {
                setStandard(e.detail.selectedOption);
              }}
            />
          </FormField>
          <FormField label={<>MPN:</>}>
            <Input value={mpn} ariaLabel="Mpn" onChange={({ detail }) => setMpn(detail.value.toUpperCase())} />
          </FormField>
          <FormField label={<>IPN:</>}>
            <Select
              filteringType="auto"
              onChange={(e) => setIpn(e.detail.selectedOption.value ?? '')}
              selectedOption={{ value: ipn, label: ipn }}
              loadingText="Loading"
              errorText={ipnsErrorMessage}
              finishedText="End of results"
              onLoadItems={(e) => {
                handlePartIdentifierPrefixSearch(e.detail, ipns, PartIdentifierType.IPN);
              }}
              statusType={ipnsLoading}
              options={ipns}
              ariaLabel="IPN select"
            />
          </FormField>
          <FormField label={<>SuperSKU:</>}>
            <Select
              filteringType="auto"
              onChange={(e) => setSuperSku(e.detail.selectedOption.value ?? '')}
              selectedOption={{ value: superSku, label: superSku }}
              loadingText="Loading"
              errorText={superSkusErrorMessage}
              finishedText="End of results"
              onLoadItems={(e) => {
                handlePartIdentifierPrefixSearch(e.detail, superSkus, PartIdentifierType.SUPER_SKU);
              }}
              statusType={superSkusLoading}
              options={superSkus}
              ariaLabel="SuperSKU select"
            />
          </FormField>
          <FormField label={<>Expiration Date:</>}>
            <ColumnLayout borders="horizontal" columns={2}>
              <Select
                filteringType="auto"
                selectedOption={expirationDateOperator?.value ? expirationDateOperator : null}
                options={[
                  { label: '-', value: undefined },
                  { label: 'Expired before', value: 'LESS_THAN' },
                  { label: 'Expires after', value: 'GREATER_THAN' },
                ]}
                onChange={(e) => {
                  setExpirationDateOperator(e.detail.selectedOption);
                }}
              />
              <DatePicker
                value={
                  expirationDateOperator?.value === 'EXISTS' || expirationDateOperator?.value === 'NOT_EXISTS'
                    ? ''
                    : expirationDate
                }
                onChange={(e) => setExpirationDate(e.detail.value)}
                placeholder="YYYY/MM/DD"
                {...DATE_PICKER_ARIA_LABELS}
              />
            </ColumnLayout>
          </FormField>
        </ColumnLayout>
      </Container>
      <>
        <Header description={'These Artifacts match the given search parameters'}>Exact Matches</Header>

        {exactSearchResults.length > 0 && (
          <SpaceBetween alignItems="center" size="xs">
            <Pagination
              onChange={(e) => {
                exactPaginationProps.currentPage = e.detail.currentPageIndex;
                search(showArchived, exactPaginationProps, false);
              }}
              openEnd={!exactPaginationProps.isEnd}
              pagesCount={Math.max(exactPaginationProps.maxPage, exactPaginationProps.currentPage)}
              currentPageIndex={exactPaginationProps.currentPage}
              ariaLabels={PAGINATION_ARIA_LABELS}
              data-test="artifacts-exact-pagination"
            />
          </SpaceBetween>
        )}
        {exactSearchResultsLoading === 'pending' ? <Spinner size="large" /> : getCardDisplay(exactLocalSearchResults)}
      </>

      <>
        <Header
          description={
            'These Artifacts state compliance for an identifier that is associated with the searched IPN in Townsend. Search the IPN in Townsend to confirm any identifier associations'
          }
        >
          Artifacts with Related Identifiers
        </Header>
        {relatedSearchResults.length > 0 && (
          <SpaceBetween alignItems="center" size="xs">
            <Pagination
              onChange={(e) => {
                relatedPaginationProps.currentPage = e.detail.currentPageIndex;
                search(showArchived, relatedPaginationProps, false);
              }}
              openEnd={!relatedPaginationProps.isEnd}
              pagesCount={Math.max(relatedPaginationProps.maxPage, relatedPaginationProps.currentPage)}
              currentPageIndex={relatedPaginationProps.currentPage}
              ariaLabels={PAGINATION_ARIA_LABELS}
              data-test="artifacts-related-pagination"
            />
          </SpaceBetween>
        )}
        {relatedSearchResultsLoading === 'pending' ? (
          <Spinner size="large" />
        ) : (
          getCardDisplay(relatedLocalSearchResults)
        )}
      </>

      {preferencesModal}
    </SpaceBetween>
  );
};

export default ArtifactV2Search;
