import { useEffect, useState } from 'react';
import { LicenseFormInputs } from 'components/LicenseManagerCommon';

type LMFormError = { message: string; hasError: boolean };

const DEFAULT_FORM_ERROR = { message: '', hasError: false };

// A function which takes the values from a LM form and returns true if the field(s) it tests for is required
type LmFormFieldRequiredValidator = (form: Partial<LicenseFormInputs>) => boolean;

const requiredValidators: Record<string, LmFormFieldRequiredValidator> = {
  allowedImportCountry: ({ transactionDirection, allowedImportCountries, allowedExportCountries }) => {
    return transactionDirection?.value === 'IMPORT' && (!allowedImportCountries || !allowedImportCountries?.length);
  },
  allowedExportCountry: ({ transactionDirection, allowedImportCountries, allowedExportCountries }) => {
    return transactionDirection?.value === 'EXPORT' && (!allowedExportCountries || !allowedExportCountries?.length);
  },
  ipnEccnHtsCombo: ({ hts, eccn, ipn }) => {
    return (!hts || !hts?.length) && (!eccn || !eccn?.length) && (!ipn || !ipn?.length);
  },
  hts: ({ transactionDirection, hts }) => {
    return transactionDirection?.value === 'IMPORT' && (!hts || !hts?.length);
  },
  eccn: ({ transactionDirection, eccn }) => {
    return transactionDirection?.value === 'EXPORT' && (!eccn || !eccn?.length);
  },
  effectiveDate: ({ effectiveDate, disabled }) => {
    return (!effectiveDate || !effectiveDate.length) && !disabled;
  },
  otherSourceName: ({ otherSourceName, source }) => {
    return source?.value === 'OTHER' && !otherSourceName;
  },
};

const useValidationLicenseForm = (
  licenseFormInputs: Partial<LicenseFormInputs>,
  isSubmitted: boolean
): {
  allowedExportValidation: LMFormError;
  allowedImportValidation: LMFormError;
  partValidation: LMFormError;
  eccnValidation: LMFormError;
  htsValidation: LMFormError;
  effectiveDateValidation: LMFormError;
  otherSourceNameValidation: LMFormError;
} => {
  const {
    allowedImportCountries,
    allowedExportCountries,
    transactionDirection,
    hts,
    ipn,
    eccn,
    disabled,
    effectiveDate,
    source,
    otherSourceName,
  } = licenseFormInputs;
  const [allowedImportValidation, setAllowedImportValidation] = useState(DEFAULT_FORM_ERROR);
  const [allowedExportValidation, setAllowedExportValidation] = useState(DEFAULT_FORM_ERROR);
  const [partValidation, setPartValidation] = useState(DEFAULT_FORM_ERROR);
  const [eccnValidation, setECCNValidation] = useState(DEFAULT_FORM_ERROR);
  const [htsValidation, setHTSValidation] = useState(DEFAULT_FORM_ERROR);
  const [effectiveDateValidation, setEffectiveDateValidation] = useState(DEFAULT_FORM_ERROR);
  const [otherSourceNameValidation, setOtherSourceNameValidation] = useState(DEFAULT_FORM_ERROR);

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }
    const allowedErrorMessage = {
      hasError: true,
      message: `At least one country is required`,
    };
    const allowedNoError = { message: '', hasError: false };
    if (requiredValidators.allowedImportCountry(licenseFormInputs)) {
      setAllowedImportValidation(allowedErrorMessage);
      setAllowedExportValidation(allowedNoError);
    } else if (requiredValidators.allowedExportCountry(licenseFormInputs)) {
      setAllowedExportValidation(allowedErrorMessage);
      setAllowedImportValidation(allowedNoError);
    } else {
      setAllowedImportValidation(allowedNoError);
      setAllowedExportValidation(allowedNoError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedImportCountries, allowedExportCountries, transactionDirection, isSubmitted]);

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }
    if (requiredValidators.ipnEccnHtsCombo(licenseFormInputs)) {
      setPartValidation({ hasError: true, message: 'One of IPN, ECCN, or HTS is required.' });
    } else {
      setPartValidation({ hasError: false, message: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eccn, isSubmitted, hts, ipn]);

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }
    if (requiredValidators.hts(licenseFormInputs)) {
      setHTSValidation({ hasError: true, message: 'HTS is Mandatory for an Import Authorization' });
      setECCNValidation({ hasError: false, message: '' });
    } else if (requiredValidators.eccn(licenseFormInputs)) {
      setECCNValidation({ hasError: true, message: 'ECCN is mandatory for an Export Authorization' });
      setHTSValidation({ hasError: false, message: '' });
    } else {
      setHTSValidation({ hasError: false, message: '' });
      setECCNValidation({ hasError: false, message: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hts, eccn, transactionDirection, isSubmitted]);

  useEffect(() => {
    if (isSubmitted && requiredValidators.effectiveDate(licenseFormInputs)) {
      setEffectiveDateValidation({ hasError: true, message: 'Effective date required for enabled licenses' });
    } else {
      setEffectiveDateValidation({ hasError: false, message: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectiveDate, disabled, isSubmitted]);

  useEffect(() => {
    if (isSubmitted && requiredValidators.otherSourceName(licenseFormInputs)) {
      setOtherSourceNameValidation({
        hasError: true,
        message: `Other Source Name is mandatory when source is selected as 'Other'`,
      });
    } else {
      setOtherSourceNameValidation({ hasError: false, message: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, otherSourceName, isSubmitted]);

  return {
    allowedExportValidation,
    allowedImportValidation,
    partValidation,
    eccnValidation,
    htsValidation,
    effectiveDateValidation,
    otherSourceNameValidation,
  };
};

export default useValidationLicenseForm;

/**
 *
 * @param form Inputs from user for a LM form
 * @return true if 1 or more errors are found for the given form. False otherwise.
 */
export const hasErrors = (form: Partial<LicenseFormInputs>): boolean => {
  return Object.entries(requiredValidators).some((entry) => entry[1](form));
};
