import { Artifact, MetadataFieldAndLabel } from '@amzn/d2d-bff-schema';
import { Badge, Box, Container, Link, Popover, SpaceBetween } from '@amzn/awsui-components-react';
import ValueWithLabel from '../../ValueWithLabel';
import React from 'react';
import { artifactsV2KeyToLabel } from '../artifactsV2Contants';
import {
  DEFAULT_EMPTY_FIELD_VALUE,
  formatArtifactsV2StringDate,
  formatPartFamilyDisplay,
  getExpirationDateLabel,
  getLabel,
  parseMetadataValue,
} from '../artifactsV2Utils';
import { ArtifactSearchVisibleContent } from './ArtifactV2Search';
import { ARTIFACT_MENU_OPTIONS, ARTIFACT_SUBTYPE_MENU_OPTIONS } from '../../../utils/constants';

interface ArtifactV2SearchCardProps {
  artifact: Artifact;
  visibleContent: ArtifactSearchVisibleContent;
}

const FIELD_OVERFLOW_CHARACTER_LIMIT = 30;

const currentTime = Date.now();

const ARTIFACT_PART_IDENTIFIERS: Set<string> = new Set<string>([
  'ipn',
  'mpn',
  'superSku',
  'projectExternal',
  'partFamily',
]);

const ArtifactV2SearchCard: React.FC<ArtifactV2SearchCardProps> = ({ visibleContent, artifact }) => {
  const expirationString = artifact.metadata.data.find((metadataField) => metadataField.key === 'expiration')?.value[0];
  const expirationTime = expirationString ? new Date(expirationString).getTime() : undefined;
  const isExpired = expirationTime && expirationTime < currentTime;
  const lastModified = artifact.lastModifiedAt ? formatArtifactsV2StringDate(artifact.lastModifiedAt) : undefined;
  const partIdentifiers = artifact.metadata.data.filter((metadataField) =>
    ARTIFACT_PART_IDENTIFIERS.has(metadataField.key)
  );
  if (artifact.partFamilies.length) {
    partIdentifiers.push({
      label: 'Part Families',
      value: [formatPartFamilyDisplay(artifact.partFamilies)],
      key: 'partFamilies',
    });
  }
  const component = (
    <Container>
      <SpaceBetween alignItems="start" size="s">
        <SpaceBetween alignItems="start" direction="horizontal" size="xs">
          <Link fontSize="heading-m" href={`/artifacts/v2/${artifact.id}`} variant="primary">
            #{artifact.id}
          </Link>
          {isExpired && <Badge color="red">Expired</Badge>}
          {artifact.archived && <Badge color="blue">Archived</Badge>}
        </SpaceBetween>
        <ValueWithLabel label={'Last Modified'}>{lastModified ?? DEFAULT_EMPTY_FIELD_VALUE}</ValueWithLabel>
        {visibleContent.type &&
          getValueWithLabelWithPopover(
            'Type',
            getLabel(
              artifact.metadata.data.find((metadataField) => metadataField.key === 'type')?.value[0],
              ARTIFACT_MENU_OPTIONS
            )
          )}
        {visibleContent.subtype &&
          getValueWithLabelWithPopover(
            'Subtype',
            getLabel(
              artifact.metadata.data.find((metadataField) => metadataField.key === 'subtype')?.value[0],
              ARTIFACT_SUBTYPE_MENU_OPTIONS
            )
          )}
        {visibleContent.archived && (
          <ValueWithLabel label={'Archived'}>{artifact.archived ? 'Yes' : 'No'}</ValueWithLabel>
        )}
        {visibleContent.partIdentifiers && getPartIdentifierComponent(partIdentifiers)}
        {visibleContent.expiration && (
          <ValueWithLabel label={'Expiration'}>
            {expirationString
              ? getExpirationDateLabel(expirationString)
              : parseMetadataValue('expiration', artifact) ?? 'None'}
          </ValueWithLabel>
        )}
      </SpaceBetween>
    </Container>
  );

  return component;
};

/**
 * A function that will return the part identifier component based on the part identifiers passed.
 * @param partIdentifiers part identifiers to be displayed in the component
 * @returns react component with part identifier value
 */
const getPartIdentifierComponent = (partIdentifiers: MetadataFieldAndLabel[]): JSX.Element => {
  if (partIdentifiers.length === 1) {
    const partIdentifier = partIdentifiers[0];
    const partIdentifierValue = partIdentifier.value.join(', ');
    return getValueWithLabelWithPopover(
      artifactsV2KeyToLabel.get(partIdentifier.key) ?? partIdentifier.key,
      partIdentifierValue
    );
  } else {
    // For multiple part identifiers return popover with ValueWithLabel for each part identifier
    return (
      <ValueWithLabel label="Part Identifiers">
        <Popover
          content={
            <SpaceBetween size={'m'}>
              {partIdentifiers.map((partIdentifier) => (
                <Box key={partIdentifier.key}>
                  <Box variant="awsui-key-label">
                    {artifactsV2KeyToLabel.get(partIdentifier.key) ?? partIdentifier.key}
                  </Box>
                  <Box>{partIdentifier.value.join(', ')}</Box>
                </Box>
              ))}
            </SpaceBetween>
          }
        >
          Multiple Part Identifiers...
        </Popover>
      </ValueWithLabel>
    );
  }
};

const getValueWithLabelWithPopover = (label: string, value: string): JSX.Element => {
  if (value.length < FIELD_OVERFLOW_CHARACTER_LIMIT) {
    // If content is less than FIELD_OVERFLOW_CHARACTER_LIMIT characters return ValueWithLabel
    return <ValueWithLabel label={label}>{value}</ValueWithLabel>;
  } else {
    // else return popover with simple text in popover
    return (
      <ValueWithLabel label={label}>
        <Popover content={value}>{`${value.slice(0, FIELD_OVERFLOW_CHARACTER_LIMIT)}...`}</Popover>
      </ValueWithLabel>
    );
  }
};

export default ArtifactV2SearchCard;
