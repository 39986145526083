import React, { FormEvent, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ArtifactsV2FormInput, artifactsV2ApiSchemaToFormSchema, artifactsV2FormSchema } from './index';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AttributeEditor,
  Box,
  Container,
  DatePicker,
  FileUpload,
  Form,
  FormField,
  Header,
  Multiselect,
  Select,
  SpaceBetween,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import {
  Artifact,
  ArtifactWorkflowStatus,
  CharacterClass,
  FileValidationStatus,
  PartFamily,
  PartIdentifierType,
  UpdateArtifactInput,
} from '@amzn/d2d-bff-schema';
import {
  ARTIFACTSV2_COUNTRIES_FOR_CREATE,
  ARTIFACT_V2_MENU_OPTIONS_FOR_CREATE,
  ARTIFACT_V2_SUBTYPE_MENU_OPTIONS_FOR_CREATE,
  STANDARDS,
} from '../../../utils/constants';
import { DATE_PICKER_ARIA_LABELS, createSuccessItem, getOptionalLabel } from '../../../utils/formUtils';
import { EnumDisplayMapping } from '../../../types';
import {
  createIngressArtifactAction,
  listPartIdentifierByPrefixAction,
  setArtifactV2DetailFlashbarItems,
  updateArtifactV2Action,
} from '../../../store/artifactv2';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { OptionsLoadItemsDetail } from '@amzn/awsui-components-react/polaris/internal/components/dropdown/interfaces';
import { DEFAULT_NON_ERROR_OBJECT, PART_FAMILY_CHARACTER_CLASSES } from '../artifactsV2Contants';
import TokenListInput from '../../TokenListInput';
import axios from 'axios';
import { API, GraphQLResult, GraphQLSubscription, graphqlOperation } from '@aws-amplify/api';
import { ON_UPDATE_FILE_VALIDATION_STATUS } from '../../../_services/api/graphql/queries';
import { Subscription } from 'zen-observable-ts';
import { Button, Input, Modal } from '@amzn/awsui-components-react/polaris';
import { ArtifactV2FileVersionsTable } from '../ArtifactV2FileVersionsTable';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import { useNavigate } from 'react-router';
import { getPartFamilyLabel } from '../artifactsV2Utils';

const T_CORP_HELP_REQUEST_LINK = 'https://t.corp.amazon.com/create/templates/ad9ff44e-e505-41f6-9db0-c1608a80267b';
const T_CORP_ADD_ARCHIVING_REASON_LINK =
  'https://t.corp.amazon.com/create/templates/4a653684-e52d-4179-8f9c-180b2b73cb90';
const T_CORP_MOVE_ARTIFACT_LINK = 'https://t.corp.amazon.com/create/templates/f422dc73-21ad-4693-9543-ee8df225549f';
const MAX_FILE_UPLOAD_SIZE_MB = 400;
const BYTES_IN_MB = 1000000;

interface ArtifactsV2CommonFormProps {
  defaultValues?: Partial<ArtifactsV2FormInput>;
  onSubmit: SubmitHandler<ArtifactsV2FormInput>;
  submitText: string;
  cancelRoute: string;
  formHeaderText?: string;
  fileValidationStatus: ArtifactWorkflowStatus | null | 'PENDING';
  setFileValidationStatus: (newStatus: ArtifactWorkflowStatus | null | 'PENDING') => void;
  isLoading?: boolean;
  artifact?: Artifact;
  isEdit?: boolean;
}

type FormError = {
  hasError: boolean;
  message: string;
};

type PartFamilyFormField = PartFamily & { userInputError: FormError; wildcardError: FormError };

const ArtifactsV2Common: React.FC<ArtifactsV2CommonFormProps> = ({
  defaultValues,
  onSubmit,
  submitText,
  cancelRoute,
  artifact,
  fileValidationStatus,
  setFileValidationStatus,
  isLoading = false,
  isEdit = false,
}) => {
  const { control, handleSubmit, formState, watch, setValue } = useForm<ArtifactsV2FormInput>({
    defaultValues: artifact ? artifactsV2ApiSchemaToFormSchema().parse(artifact) : defaultValues,
    resolver: zodResolver(artifactsV2FormSchema),
    mode: 'onChange',
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    ipns,
    ipnsLoading,
    ipnsNextToken,
    ipnsErrorMessage,
    projectExternals,
    projectExternalsLoading,
    projectExternalsNextToken,
    projectExternalErrorMessage,
    superSkus,
    superSkusLoading,
    superSkusNextToken,
    superSkusErrorMessage,
    artifactIngressUploadS3Url,
    artifactIngressID,
  } = useAppSelector((state) => state.artifactV2Store);

  const {
    ipns: ipnsForm,
    mpns: mpnsForm,
    superSkus: superSkusForm,
    projectExternals: projectExternalsForm,
    partFamilies: partFamiliesForm,
    type: typeForm,
    subtype: subtypeForm,
  } = watch();

  const [artifactTypeSelection, setArtifactTypeSelection] = useState<Partial<EnumDisplayMapping> | null>(typeForm);
  const [artifactSubTypeSelection, setArtifactSubTypeSelection] = useState<Partial<EnumDisplayMapping> | null>(
    subtypeForm
  );
  const [artifactSubTypes, setArtifactSubTypes] = useState<EnumDisplayMapping[]>(
    ARTIFACT_V2_SUBTYPE_MENU_OPTIONS_FOR_CREATE
  );
  const [partFamilies, setPartFamilies] = useState<PartFamilyFormField[]>(
    partFamiliesForm?.map((partFamiliy) => ({
      ...partFamiliy,
      userInputError: DEFAULT_NON_ERROR_OBJECT,
      wildcardError: DEFAULT_NON_ERROR_OBJECT,
    })) ?? []
  );

  const [pendingFile, setPendingFile] = useState<File | null>(null);
  const [validFile, setValidFile] = useState<File | null>(null);
  const [artifactIngressDuplicateArtifactId, setArtifactIngressDuplicateArtifactId] = useState<string | undefined>();
  const [partIdentifierValidation, setPartIdentifierValidation] = useState(DEFAULT_NON_ERROR_OBJECT);

  const [isArchivedModalVisible, setIsArchivedModalVisible] = useState<boolean>(false);
  const [archivedReason, setArchivedReason] = useState<string | undefined>();
  const [isArchiveDispatch, setIsArchiveDispatch] = useState<boolean>(false);

  const onTokenDismiss = (values: string[], itemIndex: number, onChangeFn: (...event: unknown[]) => void): void => {
    const tmpItems = [...values];
    tmpItems.splice(itemIndex, 1);
    onChangeFn(tmpItems);
  };

  useEffect(() => {
    // Only create/edit an artifact if there is at least one part identifier
    if (
      !ipnsForm?.length &&
      !mpnsForm?.length &&
      !superSkusForm?.length &&
      !projectExternalsForm?.length &&
      !partFamiliesForm?.length
    ) {
      setPartIdentifierValidation({ hasError: true, message: 'At least one part identifier is required' });
    } else {
      setPartIdentifierValidation(DEFAULT_NON_ERROR_OBJECT);
    }
  }, [
    formState.isSubmitted,
    ipnsForm?.length,
    mpnsForm?.length,
    projectExternalsForm?.length,
    superSkusForm?.length,
    partFamiliesForm?.length,
  ]);

  const validatePartFamilies = (): void => {
    partFamilies.forEach((partFamily) => {
      // Validate that the user pattern is not empty
      if (!partFamily.userPattern.trim()) {
        partFamily.userInputError = { hasError: true, message: 'Part Family is required' };
      } else {
        partFamily.userInputError = DEFAULT_NON_ERROR_OBJECT;
      }

      // Validate that at least one wildcard and wildcard value is present
      // Wildcard + Wildcard Value fields that are missing either one are filtered and removed by the back-end
      if (
        !partFamily.replacementCharacters.filter(
          (character) => character.character.trim() && character.characterClasses.length
        ).length
      ) {
        partFamily.wildcardError = { hasError: true, message: 'Wildcard and Wildcard Value are required' };
      } else {
        partFamily.wildcardError = DEFAULT_NON_ERROR_OBJECT;
      }
    });
  };

  useEffect(() => {
    // if a user selects subtype first, infer the type
    if (artifactSubTypeSelection) {
      ARTIFACT_V2_MENU_OPTIONS_FOR_CREATE.forEach((group) => {
        const match = group.options.find(
          (type) => artifactSubTypeSelection.format && artifactSubTypeSelection.format === type.value
        );
        if (match) {
          setArtifactTypeSelection(match);
          setValue('type', match);
        }
      });
    }
    // if a user selects type, filter the subtype list
    if (artifactTypeSelection)
      setArtifactSubTypes(
        ARTIFACT_V2_SUBTYPE_MENU_OPTIONS_FOR_CREATE.filter(
          (st) => st.format && (!artifactTypeSelection?.value || st.format === artifactTypeSelection?.value)
        )
      );
  }, [artifactTypeSelection, artifactSubTypeSelection, setValue]);

  useEffect(() => {
    let subscription: Subscription | undefined;
    if (artifactIngressUploadS3Url) {
      // We received back a pre-signed S3 url for the file, so we can start the upload process
      const uploadAndValidate = async (): Promise<void> => {
        subscription = await uploadAndValidateFile();
      };
      uploadAndValidate();

      return () => {
        // If the component is unmounted, unsubscribe from the subscription
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifactIngressUploadS3Url, fileValidationStatus]);

  const initiateFileCreation = async (files: File[]): Promise<void> => {
    const fileSizeBytes = files[0].size;
    if (fileSizeBytes > MAX_FILE_UPLOAD_SIZE_MB * BYTES_IN_MB) {
      setFileValidationStatus(ArtifactWorkflowStatus.FILE_VALIDATION_FAILED_FILE_SIZE_EXCEEDED);
      setPendingFile(files[0]);
      return;
    }

    setFileValidationStatus('PENDING');
    dispatch(createIngressArtifactAction({ file: { fileName: files[0].name } }));
    setPendingFile(files[0]);
  };

  const uploadAndValidateFile = async (): Promise<Subscription | undefined> => {
    if (!pendingFile) {
      return;
    }
    // Subscribe to the GraphQL Subscription to receive updates on the file validation process
    const subscription = API.graphql<GraphQLSubscription<{ onUpdateFileValidationStatus: FileValidationStatus }>>(
      graphqlOperation(ON_UPDATE_FILE_VALIDATION_STATUS, { ingressArtifactID: artifactIngressID })
    ).subscribe({
      next: (response: { value: GraphQLResult<{ onUpdateFileValidationStatus: FileValidationStatus }> }) => {
        // The file status has changed, so update the state accordingly
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const newStatus = response.value.data!.onUpdateFileValidationStatus.artifactWorkflowStatus;
        setFileValidationStatus(newStatus);
        if (newStatus === ArtifactWorkflowStatus.FILE_VALIDATION_SUCCEEDED) {
          // For the visual file to show on the UI, set the valid file to be the pending file
          setValidFile(pendingFile);
        } else {
          const duplicateId = response.value.data?.onUpdateFileValidationStatus.existingArtifactID ?? undefined;
          setArtifactIngressDuplicateArtifactId(duplicateId);
        }
      },
      error: (error: Error) => console.warn(error),
    });

    // Upload file to the S3 pre-signed url to trigger the validation process
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await axios.put(artifactIngressUploadS3Url!, pendingFile, {
      headers: {
        'Content-Type': pendingFile.type,
        'Content-Disposition': 'attachment',
      },
    });

    return subscription;
  };

  const getFileValidationComponent = (): JSX.Element => {
    if (!pendingFile) {
      // No file is being uploaded, so there is no validation to show
      return <></>;
    }

    // If the file is being uploaded, show a loading indicator
    switch (fileValidationStatus) {
      case ArtifactWorkflowStatus.FILE_VALIDATION_SUCCEEDED:
        return <StatusIndicator type="success">Validation successful</StatusIndicator>;
      case ArtifactWorkflowStatus.FILE_VALIDATION_FAILED_DUPLICATE_FILE:
        return (
          <>
            <StatusIndicator type="error">
              {artifactIngressDuplicateArtifactId
                ? `The Artifact you uploaded is a duplicate of a previously uploaded artifact. Please see artifact `
                : 'Validation failed. Please contact GTPC Tech for assistance'}
              {artifactIngressDuplicateArtifactId && (
                <Link to={`/artifacts/v2/${artifactIngressDuplicateArtifactId}`} target="_blank">
                  {`#${artifactIngressDuplicateArtifactId}`}
                </Link>
              )}
            </StatusIndicator>
          </>
        );
      case 'PENDING':
        return <StatusIndicator type="loading">Validating file...</StatusIndicator>;
      case ArtifactWorkflowStatus.FILE_VALIDATION_FAILED_FILE_SIZE_EXCEEDED:
        return (
          <StatusIndicator type="error">
            {`Error: Document size must be no greater than 400 MB. `}
            <Link to={T_CORP_HELP_REQUEST_LINK} target="_blank">
              Contact us
            </Link>
            {' to share more details'}
          </StatusIndicator>
        );
      default:
        return <></>;
    }
  };

  /**
   * This function returns a component for editing the part families of an artifact. It receives an index, and returns
   * a component with values from the part families array in state
   * @param index gets the `index` element of the part families state array to load the values into the returned component
   * @returns a component with the values from the part families array in state
   */
  const getPartFamilyComponent = (index: number): JSX.Element | undefined => {
    return (
      <Box>
        <Box>Part Family</Box>
        <FormField
          errorText={
            formState.isSubmitted &&
            partFamilies[index].userInputError.hasError &&
            partFamilies[index].userInputError.message
          }
        >
          <Input
            value={partFamilies[index].userPattern}
            onChange={({ detail }) => {
              partFamilies[index].userPattern = detail.value.trim();
              reRenderPartFamiliesComponents();
            }}
            disabled={artifact?.archived}
          />
        </FormField>
        <AttributeEditor
          onAddButtonClick={() => {
            partFamilies[index].replacementCharacters.push({
              character: '',
              characterClasses: [],
            });
            reRenderPartFamiliesComponents();
          }}
          onRemoveButtonClick={({ detail: { itemIndex } }) => {
            const tmpItems = [...partFamilies[index].replacementCharacters];
            tmpItems.splice(itemIndex, 1);
            partFamilies[index].replacementCharacters = tmpItems;
            reRenderPartFamiliesComponents();
          }}
          items={partFamilies[index].replacementCharacters}
          addButtonText="Add wildcard"
          disableAddButton={artifact?.archived}
          definition={[
            {
              label: 'Wildcard',
              control: (item) => (
                <FormField
                  errorText={
                    formState.isSubmitted &&
                    partFamilies[index].wildcardError.hasError &&
                    partFamilies[index].wildcardError.message
                  }
                >
                  <Input
                    value={item.character}
                    onChange={(event) => {
                      // Allow only one character to be entered
                      if (event.detail.value.length > 1) {
                        item.character = event.detail.value.slice(-1).toUpperCase();
                      } else {
                        item.character = event.detail.value.toUpperCase();
                      }
                      reRenderPartFamiliesComponents();
                    }}
                    placeholder="Enter key"
                    disabled={artifact?.archived}
                  />
                </FormField>
              ),
            },
            {
              label: 'Wildcard Values',
              control: (item) => (
                <FormField
                  errorText={
                    formState.isSubmitted &&
                    partFamilies[index].wildcardError.hasError &&
                    partFamilies[index].wildcardError.message
                  }
                >
                  <Multiselect
                    selectedOptions={item.characterClasses.map((characterClass) => ({
                      value: characterClass,
                      label: getPartFamilyLabel(characterClass),
                    }))}
                    options={PART_FAMILY_CHARACTER_CLASSES}
                    onChange={(e) => {
                      item.characterClasses = e.detail.selectedOptions.map(
                        (selectedOption) => selectedOption.value as CharacterClass
                      );
                      reRenderPartFamiliesComponents();
                    }}
                    placeholder="Select value"
                    disabled={artifact?.archived}
                  />
                </FormField>
              ),
            },
          ]}
          removeButtonText="Remove wildcard"
        />
      </Box>
    );
  };

  /**
   * Since we are manipulating the partFamilies state object directly, when it needs to be re-rendered we need
   * to trigger that manually. This function is used to do that.
   * @returns void
   */
  const reRenderPartFamiliesComponents = (): void => {
    validatePartFamilies();
    setPartFamilies([...partFamilies]);
    setValue('partFamilies', partFamilies); // Make sure the form value is the same as the state value
  };

  const handlePartIdentifierPrefixSearch = (
    searchDetails: OptionsLoadItemsDetail,
    partList: EnumDisplayMapping[],
    partIdentifierType: PartIdentifierType
  ): void => {
    if (searchDetails.filteringText.length > 2 && searchDetails.firstPage) {
      dispatch(
        listPartIdentifierByPrefixAction({
          input: { idType: partIdentifierType, idPrefix: searchDetails.filteringText },
          isFirstPageSearch: searchDetails.firstPage,
        })
      );
    } else if (!searchDetails.firstPage) {
      // The user has scrolled to the bottom of the results so append new search to existing results
      let nextToken;
      switch (partIdentifierType) {
        case PartIdentifierType.IPN:
          nextToken = ipnsNextToken;
          break;
        case PartIdentifierType.AWS_EXTERNAL_PROJECT_NAME:
          nextToken = projectExternalsNextToken;
          break;
        case PartIdentifierType.SUPER_SKU:
          nextToken = superSkusNextToken;
          break;
      }
      dispatch(
        listPartIdentifierByPrefixAction({
          input: { idType: partIdentifierType, idPrefix: searchDetails.filteringText },
          nextToken,
          isFirstPageSearch: searchDetails.firstPage,
        })
      );
    }
  };

  const submit = async (submitEvent: FormEvent): Promise<void> => {
    submitEvent.preventDefault();
    await handleSubmit((e) => {
      if (
        !partIdentifierValidation.hasError &&
        !partFamilies.filter((partFamiliy) => partFamiliy.wildcardError.hasError || partFamiliy.userInputError.hasError)
          .length
      ) {
        onSubmit(e);
      }
    })(submitEvent);
  };

  const archiveArtifact = async (): Promise<void> => {
    setIsArchivedModalVisible(false);
    setIsArchiveDispatch(true);
    const archiveArtifactUpdateArgs: UpdateArtifactInput = {
      artifactId: artifact?.id ?? '',
      archived: {
        isArchived: true,
        archivedReason: archivedReason,
      },
    };
    await dispatch(updateArtifactV2Action({ input: archiveArtifactUpdateArgs }));
    setArchivedReason(undefined);
    navigate(`/artifacts/v2/${artifact?.id}`); // Redirect to Details page
    dispatch(setArtifactV2DetailFlashbarItems([createSuccessItem(`Artifact edited successfully`)]));
  };

  const archiveModal: JSX.Element = (
    <Modal
      onDismiss={() => {
        setIsArchivedModalVisible(false);
        setArchivedReason(undefined);
      }}
      visible={isArchivedModalVisible}
      size="large"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => {
                setIsArchivedModalVisible(false);
                setArchivedReason(undefined);
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={() => archiveArtifact()} disabled={!archivedReason}>
              Archive
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={`Archive Artifact #${artifact?.id}`}
    >
      <SpaceBetween size={'l'}>
        <Box variant="p">
          Archive an Artifact when you’ve created an artifact record by mistake, or believe an Artifact should not be
          used for business purposes.
        </Box>
        <Box variant="p">
          Unless specifically requested, archived Artifacts are not shown during search, and will not be returned to
          automated systems.
        </Box>
        <Box variant="p">
          Archived Artifacts are NOT deleted. Please file a{' '}
          <Link to={T_CORP_ADD_ARCHIVING_REASON_LINK} target={'_blank'}>
            t.corp ticket
          </Link>{' '}
          if your archival reason is not found below. Note that a{' '}
          <Link to={T_CORP_MOVE_ARTIFACT_LINK} target={'_blank'}>
            SIM ticket
          </Link>{' '}
          will need to be filed to move this artifact to an existing artifact record
        </Box>
        <FormField label="Archive Reason" description="Provide a reason for Archiving this Artifact">
          <Select
            selectedOption={{ value: archivedReason, label: archivedReason }}
            onChange={(e) => {
              setArchivedReason(e.detail.selectedOption.value);
            }}
            options={[
              { value: 'The wrong artifact was uploaded' },
              { value: 'The artifact has a defect' },
              { value: 'This artifact is a version of an existing artifact record' },
              { value: 'The original compliance requirements for this artifact changed' },
              { value: 'The original compliance requirements for this artifact are no longer in scope' },
            ]}
            placeholder="Choose an option"
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );

  const artifactsV2Content = (
    <>
      <SpaceBetween size="l">
        {artifact?.archived && <Alert type="warning" header="This artifact is archived and cannot be edited" />}
        <Container header={isEdit ? <Header>Document Versions</Header> : <Header>Document</Header>}>
          <SpaceBetween size="l">
            <Controller
              name="fileUpload"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField>
                  {!artifact?.archived ? (
                    <FormField label="Artifact Document" description="Select an artifact document to upload">
                      <FileUpload
                        onChange={({ detail }) => {
                          if (!artifact?.archived && detail.value.length > 0) {
                            // If a file is selected, start the creation process
                            initiateFileCreation(detail.value);
                          } else {
                            // A file was removed, so clear the state
                            setValidFile(null);
                            setPendingFile(null);
                          }
                        }}
                        value={validFile ? [validFile] : []}
                        i18nStrings={{
                          uploadButtonText: (e) => {
                            return isEdit ? 'Upload new version' : 'Upload Document';
                          },
                          dropzoneText: (e) => (e ? 'Drop files to upload' : 'Drop file to upload'),
                          removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                          limitShowFewer: 'Show fewer files',
                          limitShowMore: 'Show more files',
                          errorIconAriaLabel: 'Error',
                        }}
                        showFileSize
                        showFileThumbnail
                        tokenLimit={1}
                        errorText={error && error.message}
                        accept=".pdf, .png, .jpg, .jpeg, .tiff"
                        constraintText="Allowed file types: .pdf, .png, .jpg, .jpeg, .tiff"
                      />
                    </FormField>
                  ) : (
                    <></>
                  )}
                </FormField>
              )}
            />
            {getFileValidationComponent()}

            {isEdit && <ArtifactV2FileVersionsTable artifact={artifact} variant={'borderless'} />}
          </SpaceBetween>
        </Container>
        <Container header={<Header>General Information</Header>}>
          <SpaceBetween size="xl">
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label="Type"
                  description="Compliance Type of the Artifact"
                  errorText={error && error.message}
                >
                  <Select
                    selectedOption={artifactTypeSelection}
                    onChange={(e) => {
                      setArtifactSubTypeSelection(null);
                      setArtifactTypeSelection(e.detail.selectedOption);
                      onChange(e.detail.selectedOption);
                    }}
                    filteringType="auto"
                    options={ARTIFACT_V2_MENU_OPTIONS_FOR_CREATE}
                    placeholder="Choose an option"
                    disabled={artifact?.archived}
                  />
                </FormField>
              )}
            />

            <Controller
              name="subtype"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label="Subtype"
                  description="Compliance Subtype of the Artifact"
                  errorText={error && error.message}
                >
                  <Select
                    filteringType="auto"
                    selectedOption={artifactSubTypeSelection}
                    options={artifactSubTypes}
                    onChange={(e) => {
                      setArtifactSubTypeSelection(e.detail.selectedOption);
                      onChange(e.detail.selectedOption);
                    }}
                    disabled={artifact?.archived}
                    placeholder="Choose an option"
                  />
                </FormField>
              )}
            />

            <Controller
              name="applicableCountries"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label={'Applicable Countries'}
                  description="Countries for which the Artifact can serve as Compliance Evidence"
                  errorText={error && error.message}
                >
                  <Multiselect
                    filteringType="auto"
                    selectedOptions={value ?? []}
                    options={ARTIFACTSV2_COUNTRIES_FOR_CREATE}
                    onChange={(e) => onChange(e.detail.selectedOptions)}
                    placeholder="Choose one or more options"
                    disabled={artifact?.archived}
                  />
                </FormField>
              )}
            />

            <Controller
              name="standards"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField label={getOptionalLabel('Standards')} errorText={error && error.message}>
                  <Multiselect
                    filteringType="auto"
                    selectedOptions={value ?? []}
                    options={STANDARDS}
                    onChange={(e) => onChange(e.detail.selectedOptions)}
                    placeholder="Choose standards"
                    disabled={artifact?.archived}
                  />
                </FormField>
              )}
            />

            <Controller
              name="expiration"
              control={control}
              render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
                <FormField
                  label={getOptionalLabel('Expiration Date')}
                  description="Expiration date of the artifact"
                  errorText={error && error.message}
                >
                  <DatePicker
                    value={value}
                    onChange={(e) => onChange(e.detail.value)}
                    placeholder="YYYY/MM/DD"
                    {...DATE_PICKER_ARIA_LABELS}
                    disabled={artifact?.archived}
                  />
                </FormField>
              )}
            />
          </SpaceBetween>
        </Container>
        <Container
          header={
            <Header description="At least one part identifier is required (e.g. IPNs, Super SKUs, etc.)">
              Part Information
            </Header>
          }
        >
          <SpaceBetween size="xl">
            <Controller
              name="ipns"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label="IPNs"
                  description="IPNs that the Artifact states it certifies/tests"
                  errorText={
                    (error && error.message) ||
                    (formState.isSubmitted && partIdentifierValidation.hasError && partIdentifierValidation.message)
                  }
                >
                  <Multiselect
                    filteringType="auto"
                    selectedOptions={value ?? []}
                    options={ipns}
                    onChange={(e) => {
                      onChange(e.detail.selectedOptions);
                    }}
                    placeholder="Choose one or more options"
                    loadingText="Loading"
                    errorText={ipnsErrorMessage}
                    finishedText="End of results"
                    empty="No results"
                    noMatch="No results"
                    onLoadItems={(e) => {
                      handlePartIdentifierPrefixSearch(e.detail, ipns, PartIdentifierType.IPN);
                    }}
                    statusType={ipnsLoading}
                    disabled={artifact?.archived}
                  />
                </FormField>
              )}
            />

            <Controller
              name="projectExternals"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label="AWS Project Externals"
                  description="AWS Project Externals that the Artifact states it certifies/tests"
                  errorText={
                    (error && error.message) ||
                    (formState.isSubmitted && partIdentifierValidation.hasError && partIdentifierValidation.message)
                  }
                >
                  <Multiselect
                    filteringType="auto"
                    selectedOptions={value ?? []}
                    options={projectExternals}
                    onChange={(e) => onChange(e.detail.selectedOptions)}
                    placeholder="Choose one or more options"
                    loadingText="Loading"
                    errorText={projectExternalErrorMessage}
                    finishedText="End of results"
                    empty="No results"
                    noMatch="No results"
                    onLoadItems={(e) => {
                      handlePartIdentifierPrefixSearch(
                        e.detail,
                        projectExternals,
                        PartIdentifierType.AWS_EXTERNAL_PROJECT_NAME
                      );
                    }}
                    statusType={projectExternalsLoading}
                    disabled={artifact?.archived}
                  />
                </FormField>
              )}
            />

            <Controller
              name="superSkus"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label="SuperSKUs"
                  description="SuperSKUs that the Artifact states it certifies/tests"
                  errorText={
                    (error && error.message) ||
                    (formState.isSubmitted && partIdentifierValidation.hasError && partIdentifierValidation.message)
                  }
                >
                  <Multiselect
                    filteringType="auto"
                    selectedOptions={value ?? []}
                    options={superSkus}
                    onChange={(e) => onChange(e.detail.selectedOptions)}
                    placeholder="Choose one or more options"
                    loadingText="Loading"
                    errorText={superSkusErrorMessage}
                    finishedText="End of results"
                    empty="No results"
                    noMatch="No results"
                    onLoadItems={(e) => {
                      handlePartIdentifierPrefixSearch(e.detail, superSkus, PartIdentifierType.SUPER_SKU);
                    }}
                    statusType={superSkusLoading}
                    disabled={artifact?.archived}
                  />
                </FormField>
              )}
            />

            <Controller
              name="mpns"
              control={control}
              render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
                <FormField
                  label="MPNs"
                  description="MPNs that the Artifact states it certifies/tests"
                  errorText={
                    (error && error.message) ||
                    (formState.isSubmitted && partIdentifierValidation.hasError && partIdentifierValidation.message)
                  }
                >
                  <TokenListInput
                    values={value}
                    onAdd={(tokenValues) => {
                      const values = tokenValues.map((tokenValue) => tokenValue.toUpperCase().trim());
                      onChange(Array.from(new Set(values))); // Deduplicate MPN values
                    }}
                    inputProps={{}}
                    tokenGroupProps={{
                      onDismiss: ({ detail: { itemIndex } }) => onTokenDismiss(value, itemIndex, onChange),
                    }}
                    disabled={artifact?.archived}
                  />
                </FormField>
              )}
            />

            <Controller
              name="partFamilies"
              control={control}
              render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
                <FormField
                  label="Part Families"
                  description="E.g. BRO5XY"
                  errorText={
                    (error && error.message) ||
                    (formState.isSubmitted && partIdentifierValidation.hasError && partIdentifierValidation.message)
                  }
                >
                  <AttributeEditor
                    onAddButtonClick={() => {
                      partFamilies.push({
                        userPattern: '',
                        replacementCharacters: [{ character: '', characterClasses: [] }],
                        userInputError: DEFAULT_NON_ERROR_OBJECT,
                        wildcardError: DEFAULT_NON_ERROR_OBJECT,
                      });
                      reRenderPartFamiliesComponents();
                      onChange(partFamilies);
                    }}
                    onRemoveButtonClick={({ detail: { itemIndex } }) => {
                      const tmpItems = [...partFamilies];
                      tmpItems.splice(itemIndex, 1);
                      setPartFamilies(tmpItems);
                      onChange(tmpItems);
                    }}
                    items={value}
                    addButtonText={partFamilies.length > 0 ? 'Add another part family' : 'Add part family'}
                    definition={[
                      {
                        control: (item, i) => getPartFamilyComponent(i),
                      },
                    ]}
                    removeButtonText="Remove this part family"
                    disableAddButton={artifact?.archived}
                  />
                </FormField>
              )}
            />
          </SpaceBetween>
        </Container>
      </SpaceBetween>
      {archiveModal}
    </>
  );

  return (
    <form onSubmit={submit}>
      <Form
        actions={
          <SpaceBetween size="xs" direction="horizontal">
            <Link to={cancelRoute}>
              <Button disabled={isLoading} formAction="none">
                Cancel
              </Button>
            </Link>
            {isEdit && (
              <Button
                disabled={isLoading || artifact?.archived || fileValidationStatus === 'PENDING'}
                loading={isLoading && isArchiveDispatch} // Show spinner when archiving artifact
                variant="primary"
                data-test="archive-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setIsArchivedModalVisible(true);
                }}
              >
                Archive
              </Button>
            )}
            <Button
              disabled={isLoading || artifact?.archived || fileValidationStatus === 'PENDING'}
              loading={isLoading && !isArchiveDispatch} // Show spinner when not archiving artifact
              formAction="submit"
              variant="primary"
              data-test="submit-form-btn"
            >
              {submitText}
            </Button>
          </SpaceBetween>
        }
      >
        {artifactsV2Content}
      </Form>
    </form>
  );
};

export default ArtifactsV2Common;
