import axios from 'axios';
import { IsItDownResponse } from 'types';

export const fetchIsItDown = async (url: string): Promise<IsItDownResponse | undefined> => {
  try {
    const res = await axios.get<IsItDownResponse>(url);
    return res.data;
  } catch (e) {
    const err = e as Error;
    console.error(`Fetching data from IsItDown failed. Failing silently: ${err.message}`);
  }
};
