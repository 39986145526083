import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  ColumnLayout,
  FormField,
  Grid,
  Input,
  Multiselect,
  Select,
  Textarea,
} from '@amzn/awsui-components-react/polaris';
import {
  EX_REPORT_REQ_TYPE,
  IM_APP_REQ,
  IM_REQ_COMPLETED,
  JUSTIFICATION_OPTIONS,
  PC_STATUS,
  PROGRAM_OPTIONS,
  TRUE_OR_FALSE,
} from '../../utils/constants';
import { updateClassificationAction } from 'store/classification/classificationSlice';
import { mapSelectOptions } from '../../utils/polarisHelpers';
export default function ClassificationEdit({
  classification,
  setClassificationDetails,
  setClassificationDetailsButton,
}) {
  const dispatch = useDispatch();

  // Grab values for selects
  const exportApprovalRequired = TRUE_OR_FALSE.find((ear) => ear.value === classification.exportApprovalRequired);
  const exportReportingRequirement = TRUE_OR_FALSE.find(
    (err) => err.value === classification.exportReportingRequirement
  );
  const importApprovalRequired = IM_APP_REQ.find((iar) => iar.value === classification.importApprovalRequired);
  const importReqCompleted = IM_REQ_COMPLETED.find((irc) => irc.value === classification.importRequirementsCompleted);
  const selectedPrograms = [];
  classification.programs?.map((program) => {
    const getLabel = PROGRAM_OPTIONS.filter((at) => at.value === program);
    return getLabel.map((programLabel) => {
      return selectedPrograms.push(programLabel);
    });
  });
  const selectedJustification = JUSTIFICATION_OPTIONS.find((j) => j.value === classification.justification);
  const selectedPcStatus = PC_STATUS.find((s) => s.value === classification.pcStatus);
  const exportReportReqType = EX_REPORT_REQ_TYPE.find(
    (errt) => errt.value === classification.exportReportingRequirementType
  );
  // select/mulitselect

  const [programOptions, setProgramOptions] = useState(selectedPrograms);
  const [optionEAR, setOptionEAR] = useState(exportApprovalRequired);
  const [optionERR, setOptionERR] = useState(exportReportingRequirement);
  const [optionERRT, setOptionERRT] = useState(exportReportReqType);
  const [optionIRC, setOptionIRC] = useState(importReqCompleted);
  const [optionIAR, setOptionIAR] = useState(importApprovalRequired);
  const [justification, setJustification] = useState(selectedJustification);

  const [pcStatus, setPcStatus] = useState(selectedPcStatus);
  const [localLanguageDescription, setLocalLanguageDescription] = useState(classification.localLanguageDescription);
  // import details
  const [hts, setHts] = useState(classification.hts);
  const [ICDetails, setICDetails] = useState(classification.importClassificationDetails);
  const [IIRNotes, setIIRNotes] = useState(classification.internalImportRequirementNotes);
  const [BIRNotes, setBIRNotes] = useState(classification.brokerImportRequirementNotes);
  // export details
  const [ecn, setEcn] = useState(classification.ecn);
  const [ecDetails, setECDetails] = useState(classification.exportClassificationDetails);
  const [ERNotes, setERNotes] = useState(classification.exportRequirementNotes);
  // loading & validation
  const [changeNotes, setChangeNotes] = useState('');
  const [changeValid, setChangeValid] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [afterSubmit, setAfterSubmit] = useState(false);
  const [htsValid, setHtsValid] = useState(true);
  const [allValid, setAllValid] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateFields = () => {
    if ((hts && hts.length < 6) || (!hts && !ecn)) {
      setHtsValid(false);
    }
    if (afterSubmit && !changeNotes) {
      setChangeValid(false);
    }
    if (htsValid && changeValid) {
      setAllValid(true);
    }
  };
  useEffect(() => {
    validateFields();
  }, [validateFields, htsValid, changeValid]);

  const saveChanges = async (e) => {
    e.preventDefault();
    setAfterSubmit(true);
    const PROGRAMS = programOptions.map((program) => {
      return program.value;
    });
    const pcStatusValue = pcStatus?.value ?? 'not_certified';

    const mpnValue = classification.awsPart?.mpn ? classification.awsPart.mpn : undefined;

    if (allValid) {
      setSaveLoading(true);
      const nonPlmClassification = {
        countryISO2: [classification.countryISO2],
        hts: hts ?? undefined,
        programs: PROGRAMS,
        pcStatus: pcStatusValue,
        importClassificationDetails: ICDetails ?? undefined,
        justification: justification?.value,
        localLanguageDescription: localLanguageDescription ?? undefined,
        ecn: ecn ?? undefined,
        changeNotes: changeNotes,
        exportClassificationDetails: ecDetails ?? undefined,
        importApprovalRequired: optionIAR?.value,
        importRequirementsCompleted: optionIRC?.value,
        brokerImportRequirementNotes: BIRNotes ?? undefined,
        internalImportRequirementNotes: IIRNotes ?? undefined,
        exportReportingRequirement: optionERR?.value,
        exportReportingRequirementType: optionERRT?.value,
        exportApprovalRequired: optionEAR?.value,
        exportRequirementNotes: ERNotes ?? undefined,
        nonPlmParts: [
          {
            nonPlmPartName: classification.nonPlmPart?.nonPlmPartName,
            description: classification.nonPlmPart?.description,
            manufacturerName: classification.nonPlmPart?.manufacturerName ?? undefined,
            manufacturerPartId: classification.nonPlmPart?.manufacturerPartId ?? undefined,
          },
        ],
        assetType: 'nonplmpart',
        active: true,
      };
      const awsClassification = {
        countryISO2: [classification.countryISO2],
        hts: hts ?? undefined,
        programs: PROGRAMS,
        pcStatus: pcStatusValue,
        importClassificationDetails: ICDetails ?? undefined,
        justification: justification?.value,
        localLanguageDescription: localLanguageDescription ?? undefined,
        ecn: ecn ?? undefined,
        changeNotes: changeNotes,
        exportClassificationDetails: ecDetails ?? undefined,
        importApprovalRequired: optionIAR?.value,
        importRequirementsCompleted: optionIRC?.value,
        brokerImportRequirementNotes: BIRNotes ?? undefined,
        internalImportRequirementNotes: IIRNotes ?? undefined,
        exportReportingRequirement: optionERR?.value,
        exportReportingRequirementType: optionERRT?.value,
        exportApprovalRequired: optionEAR?.value,
        exportRequirementNotes: ERNotes ?? undefined,
        awsParts: [
          {
            ipn: classification.awsPart?.ipn,
            mpn: mpnValue,
            ipnDescription: classification.awsPart?.ipnDescription ?? undefined,
          },
        ],
        assetType: 'awspart',
      };
      let updatedClassification = classification;
      if (classification.assetType === 'awspart') {
        updatedClassification = awsClassification;
      } else {
        updatedClassification = nonPlmClassification;
      }

      try {
        await dispatch(updateClassificationAction({ updatedClassification })).unwrap();
        setSaveLoading(false);
        setClassificationDetails('view');
        setClassificationDetailsButton('Edit Details');
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  };

  const thirdPartyPartInfo = (
    <>
      <div>
        Part Description:
        <br />
        {classification.nonPlmPart?.description}
      </div>
      <div>
        Manufacturer Name: <br />
        {classification.nonPlmPart?.manufacturerName}
      </div>
      <div>
        Manufacturer Part ID: <br />
        {classification.nonPlmPart?.manufacturerPartId}
      </div>
    </>
  );

  const agileIpnDescription = (
    <>
      <br />
      Agile IPN Description:
      <br /> {classification.awsPart?.ipnDescription} <br /> <br />
    </>
  );

  return (
    <>
      <br />
      <ColumnLayout columns={3} variant="text-grid">
        <div>
          Part:
          <br /> {classification.assetId} <br />
          {classification.awsPart?.ipnDescription ? agileIpnDescription : ''}
          <FormField data-test="pc-stat">
            PC Approval Status
            <Select
              selectedOption={pcStatus}
              onChange={({ detail }) => {
                setPcStatus(detail.selectedOption);
              }}
              options={mapSelectOptions(PC_STATUS)}
              selectedAriaLabel="Selected"
              placeholder="Choose PC Approval Status"
            />
          </FormField>
        </div>
        <div>
          Country: <br />
          {classification.countryISO2Format}
        </div>

        <FormField label="Programs" data-test="programs">
          <Multiselect
            selectedOptions={programOptions}
            onChange={({ detail }) => setProgramOptions(detail.selectedOptions)}
            deselectAriaLabel={(e) => `Remove ${e.label}`}
            options={mapSelectOptions(PROGRAM_OPTIONS)}
            placeholder="Choose Program(s)"
            selectedAriaLabel="Selected"
          />
        </FormField>

        <FormField label="Local Language Description" data-test="lld">
          <Textarea
            onChange={({ detail }) => setLocalLanguageDescription(detail.value)}
            value={localLanguageDescription}
            placeholder="Local Language Description"
          />
        </FormField>
        {classification.nonPlmPart ? thirdPartyPartInfo : ''}
      </ColumnLayout>
      <br />
      <h3>Import Detail Info</h3>
      <br />
      <ColumnLayout columns={3} variant="text-grid">
        <FormField data-test="hts6" errorText={!htsValid && 'HTS must be 6+ digits or an ECN is required'}>
          HTS Code
          <Input
            value={hts}
            onChange={(event) => {
              setHts(event.detail.value);
              setHtsValid(true);
            }}
            placeholder="HTS Code"
          />
        </FormField>
        <FormField data-test="iar" label="Import Approval Required">
          <Select
            selectedOption={optionIAR}
            onChange={({ detail }) => setOptionIAR(detail.selectedOption)}
            options={mapSelectOptions(IM_APP_REQ)}
            selectedAriaLabel="Selected"
            placeholder="Choose Y/N/Transactional"
          />
        </FormField>
        <FormField label="Import Requirements Completed" data-test="irc">
          <Select
            selectedOption={optionIRC}
            onChange={({ detail }) => setOptionIRC(detail.selectedOption)}
            options={mapSelectOptions(IM_REQ_COMPLETED)}
            selectedAriaLabel="Selected"
            placeholder="Choose Y/N/Partial "
            disabled={optionIAR?.label === 'No' ? true : false}
          />
        </FormField>
        <FormField label="Justification" data-test="justification">
          <Select
            selectedOption={justification}
            onChange={({ detail }) => setJustification(detail.selectedOption)}
            options={mapSelectOptions(JUSTIFICATION_OPTIONS)}
            selectedAriaLabel="Selected"
            placeholder="Choose justification"
          />
        </FormField>
        <FormField label="Import Classification Details" data-test="icd">
          <Textarea onChange={({ detail }) => setICDetails(detail.value)} value={ICDetails} placeholder="Details" />
        </FormField>
        <FormField label="Internal Import Requirements Notes" data-test="iirn">
          <Textarea onChange={({ detail }) => setIIRNotes(detail.value)} value={IIRNotes} placeholder="Notes" />
        </FormField>
        <FormField label="Broker Import Requirements Notes" data-test="birn">
          <Textarea onChange={({ detail }) => setBIRNotes(detail.value)} value={BIRNotes} placeholder="Notes" />
        </FormField>
      </ColumnLayout>
      <br />
      <br />
      <h3>Export Detail Info</h3>
      <br />
      <ColumnLayout columns={3} variant="text-grid">
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <FormField data-test="ecn">
            ECN
            <Input
              value={ecn}
              onChange={(event) => {
                setEcn(event.detail.value);
                setHtsValid(true);
              }}
              placeholder="ECN"
            />
          </FormField>
        </Grid>
        <FormField label="Export Classification Details" data-test="ecd">
          <Textarea onChange={({ detail }) => setECDetails(detail.value)} value={ecDetails} placeholder="Details" />
        </FormField>
        <FormField label="Export Requirements Notes" data-test="ern">
          <Textarea onChange={({ detail }) => setERNotes(detail.value)} value={ERNotes} placeholder="Notes" />
        </FormField>
      </ColumnLayout>
      <br />
      <br />
      <ColumnLayout columns={3} variant="text-grid">
        <FormField data-test="ear" label="Export Approval Required">
          <Select
            selectedOption={optionEAR}
            onChange={({ detail }) => setOptionEAR(detail.selectedOption)}
            options={TRUE_OR_FALSE}
            selectedAriaLabel="Selected"
            placeholder="Y / N"
          />
        </FormField>

        <FormField data-test="err" label="Export Reporting Requirement">
          <Select
            selectedOption={optionERR}
            onChange={({ detail }) => setOptionERR(detail.selectedOption)}
            options={TRUE_OR_FALSE}
            selectedAriaLabel="Selected"
            placeholder="Y / N"
            disabled={!optionEAR?.value}
          />
        </FormField>
        <FormField data-test="errt" label="Export Reporting Requirements Type">
          <Select
            selectedOption={optionERRT}
            onChange={({ detail }) => setOptionERRT(detail.selectedOption)}
            options={mapSelectOptions(EX_REPORT_REQ_TYPE)}
            selectedAriaLabel="Selected"
            placeholder="Annual / Bi-Annual"
            disabled={!optionEAR?.value}
          />
        </FormField>
      </ColumnLayout>
      <br />
      <br />
      <FormField
        data-test="required-field"
        label="Required Change Notes For Updating Classification"
        errorText={!changeValid && afterSubmit && 'Required Field'}
      >
        <Textarea
          onChange={({ detail }) => {
            setChangeNotes(detail.value);
            setChangeValid(true);
          }}
          value={changeNotes}
          placeholder="Required Change Notes"
        />
      </FormField>
      <br />
      <div className="classification-edit-save">
        <Button
          data-test="save-button"
          ariaLabel="Save Changes"
          id="saveChanges"
          variant="primary"
          onClick={(e) => {
            saveChanges(e);
            setAfterSubmit(true);
          }}
          loading={saveLoading}
        >
          Save Changes
        </Button>
      </div>
    </>
  );
}
