import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { License } from '@amzn/d2d-bff-schema';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getLicenseAction } from 'store/licenseManager';

const useValidateLicenseParams = (licenseId?: string): { license?: License; isLoading: boolean; isError: boolean } => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { license, getLoading } = useAppSelector((state) => state.licenseManagerStore);

  useEffect(() => {
    const asyncGetLicense = async (): Promise<void> => {
      if (licenseId) {
        const res = await dispatch(getLicenseAction(licenseId));
        if (res?.meta?.requestStatus === 'rejected') {
          navigate('/artifacts');
        }
      }
    };
    asyncGetLicense();
  }, [dispatch, licenseId, navigate]);

  return {
    isLoading: getLoading !== 'fulfilled' && getLoading !== 'rejected',
    isError: getLoading === 'rejected' && !license,
    license,
  };
};

export default useValidateLicenseParams;
