import React from 'react';
import Loading from '../Loading';
import { makeAppContent } from '../../app/App';

interface FeatureFlagProps {
  falseComponent: React.ReactElement;
  trueComponent: React.ReactElement;
  flag: boolean;
  flagState: string;
}

const FeatureFlag: React.FC<FeatureFlagProps> = ({ falseComponent, trueComponent, flag, flagState }) => {
  if (flagState === 'fulfilled') {
    if (flag) {
      return trueComponent;
    }
    return falseComponent;
  }
  return makeAppContent(<Loading />, '');
};

export default FeatureFlag;
