import { Artifact, Asset } from '@amzn/d2d-bff-schema';
import { PayloadAction, SerializedError, createAsyncThunk, createSlice, miniSerializeError } from '@reduxjs/toolkit';
import { ArtifactsSideNavState } from 'components/ArtifactCommon/ArtifactToolsContent';
import { setLicenses } from 'store/licenseManager';
import { LoadingState } from 'types';
import { ArtifactSearchViewModel, ArtifactsSearchResult, AssetSearchViewModel, AssetViewModel } from 'types/artifacts';
import {
  buildCards,
  formatArtifact,
  formatResults,
  getAuthIcPart,
  getCombinedSearchResults,
  getCountries,
  getParts,
  getRestOfArtifacts,
} from 'utils/artifactHelpers';
import {
  AssetsSearch,
  makeCreateRequest,
  makeListRequest,
  makeSearchRequest,
  makeUpdateRequest,
} from '_services/api/asset';
import { makeSearchRequest as makeLMSearchRequest } from '_services/api/licenseManager';
import { isGraphQLResult } from '../../utils/errorHandler';

export type ArtifactState = {
  artifacts: AssetSearchViewModel[];
  combinedArtifactSearchResults: ArtifactsSearchResult[];
  assetArtifactSearchResults: ArtifactsSearchResult[];
  licenseArtifactSearchResults: ArtifactSearchViewModel[];
  artifactsLoading: LoadingState;
  createArtifactLoading: LoadingState;
  updateArtifactLoading: LoadingState;
  getArtifactLoading: LoadingState;
  artifact?: AssetViewModel | null;
  error?: SerializedError;
  artifactSideNavState: ArtifactsSideNavState;
};

export const initialArtifactState: ArtifactState = {
  artifacts: [],
  combinedArtifactSearchResults: [],
  assetArtifactSearchResults: [],
  licenseArtifactSearchResults: [],
  artifactsLoading: 'idle',
  createArtifactLoading: 'idle',
  updateArtifactLoading: 'idle',
  getArtifactLoading: 'idle',
  artifact: {},
  error: undefined,
  artifactSideNavState: {
    toolsOpen: false,
    toolsContentId: 'part-information-info-link',
  },
};

/**
 * search for artifacts and authorizations with Elastic Search by any term
 * @returns {Object[]}
 */
export const searchArtifactsAction = createAsyncThunk(
  'artifact/searchArtifactsAction',
  async (searchTerm: string, { dispatch, rejectWithValue }) => {
    try {
      const gqlResponse = await Promise.all([makeSearchRequest(searchTerm), makeLMSearchRequest(searchTerm)]);
      const [artifactsGQLRes, lmGQLRes] = gqlResponse;
      // artifacts (assets)
      const assets = artifactsGQLRes.data?.searchAssets ?? [];
      const formattedSearchResults = formatResults(assets);

      // authorizations (license manager)
      const licenses = lmGQLRes.data?.searchLicenses ?? [];

      // combine assets and licenses into one array + transform them into common type
      const combinedResults = getCombinedSearchResults(licenses, assets);

      dispatch(setLicenses(licenses));
      return { artifacts: formattedSearchResults, combinedArtifacts: combinedResults };
    } catch (e) {
      const gqlResult = isGraphQLResult<Artifact>(e);
      if (gqlResult && gqlResult.errors) {
        return rejectWithValue(gqlResult.errors);
      } else {
        throw e;
      }
    }
  }
);

/**
 * get specific artifact
 * @returns {Object} artifact
 */
export const getArtifactRequestAction = createAsyncThunk(
  'artifact/getArtifactRequestAction',
  async ({ requestId, ic, nonPlmPartName, ipn, mpn }: AssetsSearch) => {
    let artifact: AssetViewModel | undefined = undefined;
    const gqlResponse = await makeListRequest({
      requestId: requestId,
      nonPlmPartName: nonPlmPartName,
      ipn: ipn,
    });
    const artifacts = gqlResponse.data?.listAuthorizations;
    if (artifacts) {
      const matchingCountryPart = getAuthIcPart(artifacts, ic, ipn, mpn, nonPlmPartName);
      if (matchingCountryPart) {
        artifact = formatArtifact(matchingCountryPart) as AssetViewModel;
        artifact.parts = getParts(artifacts as AssetViewModel[]);
        artifact.countries = getCountries(artifacts);
        artifact.cards = buildCards(getRestOfArtifacts(artifacts, ic, nonPlmPartName, ipn, mpn));
      }
    }
    return artifact;
  }
);

/**
 * update an artifact
 * @returns {Object} artifact
 */
export const updateArtifactAction = createAsyncThunk(
  'artifact/updateArtifactAction',
  async ({ path, updateArtifact: artifact }: { path: string; updateArtifact: Asset }) => {
    return makeUpdateRequest(path, artifact);
  }
);

/**
 * create an artifact
 * @returns {Object} artifact
 */
export const createArtifactAction = createAsyncThunk(
  'artifact/createArtifactAction',
  async ({ path, newAuth: authorization }: { path: string; newAuth: Asset }) => {
    return makeCreateRequest(path, authorization);
  }
);

/** Artifact Slice */
const { actions, reducer } = createSlice({
  name: 'artifact',
  initialState: initialArtifactState,
  reducers: {
    setArtifactSideNavState: (state, { payload: sideNavState }: PayloadAction<ArtifactsSideNavState>) => {
      state.artifactSideNavState = sideNavState;
    },
  },
  extraReducers: (builder) => {
    builder
      // ElasticSearch
      .addCase(searchArtifactsAction.pending, (state) => {
        state.artifactsLoading = 'pending';
      })
      .addCase(searchArtifactsAction.fulfilled, (state, { payload }) => {
        state.artifacts = payload.artifacts;
        state.combinedArtifactSearchResults = payload.combinedArtifacts ?? [];
        state.artifactsLoading = 'fulfilled';
      })
      .addCase(searchArtifactsAction.rejected, (state, { error }) => {
        state.artifacts = [];
        state.combinedArtifactSearchResults = [];
        state.error = miniSerializeError(error);
        state.artifactsLoading = 'rejected';
      })
      // GET
      .addCase(getArtifactRequestAction.pending, (state) => {
        state.getArtifactLoading = 'pending';
      })
      .addCase(getArtifactRequestAction.fulfilled, (state, { payload }) => {
        state.artifact = payload;
        state.getArtifactLoading = 'fulfilled';
        state.error = undefined;
      })
      .addCase(getArtifactRequestAction.rejected, (state, { error }) => {
        state.artifact = null;
        state.error = miniSerializeError(error);
        state.getArtifactLoading = 'rejected';
      })
      // CREATE ARTIFACT
      .addCase(createArtifactAction.pending, (state) => {
        state.createArtifactLoading = 'pending';
      })
      .addCase(createArtifactAction.fulfilled, (state) => {
        state.createArtifactLoading = 'fulfilled';
      })
      .addCase(createArtifactAction.rejected, (state, { error }) => {
        state.error = miniSerializeError(error);
        state.createArtifactLoading = 'rejected';
      })
      // UPDATE ARTIFACT
      .addCase(updateArtifactAction.pending, (state) => {
        state.updateArtifactLoading = 'pending';
      })
      .addCase(updateArtifactAction.fulfilled, (state) => {
        state.updateArtifactLoading = 'fulfilled';
      })
      .addCase(updateArtifactAction.rejected, (state, { error }) => {
        state.error = miniSerializeError(error);
        state.updateArtifactLoading = 'rejected';
      });
  },
});

export const { setArtifactSideNavState } = actions;

export default reducer;
