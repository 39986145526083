import { CollectionHooksTable } from '../Table';
import { CLASSIFICATIONS_SEARCH_MODES_LABELS } from './TradeClassificationsTableConfig';
import { EnumDisplayMapping } from '../../types';
import { FilteringProps } from '../../hooks/useCollectionWithFilterType/useCollectionWithFilterType';
import * as tradeClassificationsTableConfig from '../ClassificationsTable/classificationsTableConfig';
import { Classification } from '@amzn/d2d-bff-schema';

const searchModeConfig = {
  visibleContentKey: tradeClassificationsTableConfig.TRADE_CLASSIFICATIONS_TABLE_PREFERENCES_KEY,
  visibleContentPreference: tradeClassificationsTableConfig.VISIBLE_CONTENT_PREFERENCE,
  columnDefinitions: tradeClassificationsTableConfig.COLUMN_DEFINITIONS,
  filteringProperties: tradeClassificationsTableConfig.CLASS_FILTERING_PROPERTIES as FilteringProps,
};

const TradeClassificationsTable: React.FC<{
  tradeClassifications: Classification[];
  classificationsLoading: boolean;
  localStorage: [
    Partial<EnumDisplayMapping>,
    (value: Partial<EnumDisplayMapping> | ((val: Partial<EnumDisplayMapping>) => Partial<EnumDisplayMapping>)) => void
  ];
}> = ({ tradeClassifications, classificationsLoading, localStorage }) => {
  return (
    <CollectionHooksTable
      key={'TRADE_C12N_TABLE'}
      headerTitle="Search Results"
      allItems={tradeClassifications}
      preferenceLocalStorageKey={searchModeConfig.visibleContentKey}
      visibleContentPreference={searchModeConfig.visibleContentPreference}
      visibleContent={tradeClassificationsTableConfig.VISIBLE_CONTENT}
      resizeableColumns={true}
      columnDefinitions={searchModeConfig.columnDefinitions}
      loading={classificationsLoading}
      loadingText="Loading search results..."
      filterOptions={{
        type: 'property',
        filteringProperties: searchModeConfig.filteringProperties,
        selectFilters: [{ options: CLASSIFICATIONS_SEARCH_MODES_LABELS, useStateRes: localStorage }],
      }}
      empty="Matched Classifications will be displayed here"
    />
  );
};

export default TradeClassificationsTable;
