import { Mutation } from '@amzn/d2d-bff-schema';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { gql } from 'graphql-request';

export type MutationResult = GraphQLResult<Mutation>;

export const CREATE_CLASSIFICATION = gql`
  mutation createClassification($input: CreateClassificationInput!) {
    createClassification(classification: $input)
  }
`;

export const UPDATE_CLASSIFICATION = gql`
  mutation updateClassification($input: ClassificationUpdateInput!) {
    updateClassification(classification: $input) {
      changeNotes
    }
  }
`;

export const CREATE_FILE = gql`
  mutation createFile($input: CreateFileInput!) {
    createFile(file: $input) {
      fileName
      url
    }
  }
`;

export const CREATE_IE_LICENSE = gql`
  mutation createIELicense($input: IELicenseCreateInput!) {
    createIELicense(asset: $input) {
      message
      requestId
    }
  }
`;

export const CREATE_COMPLIANCE = gql`
  mutation createCompliance($input: ComplianceCreateInput!) {
    createCompliance(asset: $input) {
      message
      requestId
    }
  }
`;

export const UPDATE_IE_LICENSE = gql`
  mutation updateIELicense($input: IELicenseUpdateInput!) {
    updateIELicense(asset: $input) {
      changeNotes
      modifiedBy
    }
  }
`;

export const UPDATE_COMPLIANCE = gql`
  mutation updateCompliance($input: ComplianceUpdateInput!) {
    updateCompliance(asset: $input) {
      changeNotes
      modifiedBy
    }
  }
`;

/** License Manager */
export const CREATE_LICENSE = gql`
  mutation createLicense($input: LicenseInput!) {
    createLicense(input: $input) {
      licenseId
    }
  }
`;

export const UPDATE_LICENSE = gql`
  mutation updateLicense($licenseId: String!, $input: LicenseInput!) {
    updateLicense(licenseId: $licenseId, input: $input) {
      licenseId
    }
  }
`;

export const CREATE_USAGE = gql`
  mutation createUsage($usage: UsageInput!, $licenseId: String!) {
    createUsage(usage: $usage, licenseId: $licenseId) {
      clientId
      transactionId
      partId
      quantity
      value
      valueCurrency
      hts
      eccn
      ipn
      mpn
      transactionDirection
      exportCountry
      importCountry
      canceled
      manualReviewRequired
      manualReviewStatus
      commercialInvoiceNumber
      transportationDocumentTrackingNumber
    }
  }
`;

export const CREATE_PCC_PART = gql`
  mutation createPccPart($partInput: PCCPartInput!) {
    createPccPart(partInput: $partInput) {
      partId
      partSource
      partIdentifierType
      partIdentifierValue
      partDescription
      createdAt
      createdBy
      classificationStatus
      lastClassifiedBy
      lastClassifiedAt
      pcClassifications {
        pcCategory
        createdAt
        createdBy
      }
    }
  }
`;

export const UPDATE_PCC_PART = gql`
  mutation updatePccPart($partId: String!, $partInput: PCCPartInput!) {
    updatePccPart(partId: $partId, partInput: $partInput) {
      partId
      partSource
      partIdentifierType
      partIdentifierValue
      createdAt
      createdBy
      classificationStatus
      lastClassifiedBy
      lastClassifiedAt
      pcClassifications {
        pcCategory
        createdAt
        createdBy
      }
    }
  }
`;
