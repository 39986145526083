import { useEffect, useState } from 'react';
import { FlashbarProps } from '@amzn/awsui-components-react';
import { fetchIsItDown } from '_services/api/isItDown';
import { IsItDownResponse } from 'types';

const isItDownStatuses: FlashbarProps.Type[] = ['success', 'info', 'warning', 'error'];

const useIsItDown = (dashboardName: string, componentId: string): FlashbarProps.MessageDefinition[] => {
  const [isItDownRes, setIsItDownRes] = useState<IsItDownResponse | undefined>();
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const url = `https://is-it-down.amazon.com/${dashboardName}/components/${componentId}.js`;

  useEffect(() => {
    if (!isItDownRes) {
      fetchIsItDown(url).then((res) => {
        setIsItDownRes(res);
      });
    } else {
      const type = isItDownStatuses[isItDownRes.level];
      // don't set a flashbar item if status is OK
      isItDownRes.level !== 0 &&
        setFlashbarItems([
          {
            type,
            content: (
              <>
                <b>{isItDownRes.name}:</b>&nbsp;
                {isItDownRes?.message}
              </>
            ),
            onDismiss: () => setFlashbarItems([]),
            dismissible: true,
          },
        ]);
    }
  }, [componentId, dashboardName, isItDownRes, url]);

  return flashbarItems;
};

export default useIsItDown;
