import React from 'react';
import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import ArtifactCreateContent from './ArtifactCreate';
import { createArtifactsReadonlyWarningItem, flashbarItemsCreator } from 'utils/formUtils';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { setLMCreateFlashbarItems } from 'store/licenseManager';
import ToolsContent from 'components/ArtifactCommon/ArtifactToolsContent';
import { setArtifactSideNavState } from 'store/artifact/artifactSlice';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import { FeatureFlag as FeatureFlagConstants } from '../../utils/constants';

const ArtifactCreateLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { lmCreateFlashbarItems } = useAppSelector((state) => state.licenseManagerStore);
  const { artifactSideNavState } = useAppSelector((state) => state.artifactStore);
  const { toolsOpen, toolsContentId } = artifactSideNavState;
  const artifactV2Available = useFeatureFlag(FeatureFlagConstants.ARTIFACTS_V2).boolValue ?? true;

  const flashBarItemsWithHardcodedMessage = [...lmCreateFlashbarItems];
  const readOnlyWarning = createArtifactsReadonlyWarningItem(artifactV2Available);
  if (!flashBarItemsWithHardcodedMessage.find((items) => items.header === readOnlyWarning.header)) {
    flashBarItemsWithHardcodedMessage.push(createArtifactsReadonlyWarningItem(artifactV2Available));
  }

  return (
    <AppLayout
      contentType="form"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={<Header variant="h1">Create Artifact</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Artifacts', href: `/artifacts` },
            { text: 'Create', href: '#' },
          ]}
        />
      }
      toolsHide={!toolsOpen}
      tools={ToolsContent[toolsContentId]}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => dispatch(setArtifactSideNavState({ toolsContentId, toolsOpen: detail.open }))}
      content={<ArtifactCreateContent />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar items={flashbarItemsCreator(flashBarItemsWithHardcodedMessage, dispatch, setLMCreateFlashbarItems)} />
      }
      stickyNotifications
    />
  );
};

export default ArtifactCreateLayout;
