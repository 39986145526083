import { useAppSelector } from 'store/store';

import awsLogo from 'assets/icons/ICON-AWS.svg';
import useIsItDown from 'hooks/useIsItDown';
import { ICSTopNavigation } from '@amzn/gtpc-cas-common-ui-components';
import React from 'react';

// https://is-it-down.amazon.com/d2d
const D2D_IS_IT_DOWN_DASHBOARD = 'd2d';
const IS_IT_DOWN_COMPONENTS = {
  artifacts: '19558',
};

const TopNavigation = (): React.ReactElement => {
  const { appNotifications } = useAppSelector((state) => state.configStore);

  const artifactsIsItDownItems = useIsItDown(D2D_IS_IT_DOWN_DASHBOARD, IS_IT_DOWN_COMPONENTS.artifacts);

  return (
    <ICSTopNavigation awsLogoSrc={awsLogo} appLevelFlashbarItems={[...appNotifications, ...artifactsIsItDownItems]} />
  );
};

export default TopNavigation;
