import { AssumeRoleWithWebIdentityCommand, STS } from '@aws-sdk/client-sts';
import { Credentials } from '@aws-sdk/types';
import { CognitoIdentityClient, GetIdCommand, GetOpenIdTokenCommand } from '@aws-sdk/client-cognito-identity';
import { GTPCFeatureFlagsClient, GetFeatureFlagOutput } from '@amzn/gtpcfeature-flag-jsclient';
import {
  FEATURE_FLAG_ENTITY_ID,
  FEATURE_FLAG_PROJECT_NAME,
  ROLE_SESSION_DURATION_SECONDS,
  SESSION_CREDENTIAL_EXPIRATION_BUFFER_MS,
} from './featureFlagConstants';
import { getApplicationSettings } from '../../config/settingsConfig';

let ffClient: GTPCFeatureFlagsClient;
let currentCredentials: Credentials;

/**
 * Get credentials based on a Cognito role
 * @param region region to get credentials from
 * @param identityPoolId identity pool id created from CDK deployment
 * @param roleArn ARN of role to get credentials from
 * @param roleSessionName The name of the sessions
 * @param roleSessionDuration expiration time in seconds for the role session
 * @return valid credentials
 */
export const getCognitoCredentials = async (
  region: string,
  identityPoolId: string | undefined,
  roleArn: string | undefined,
  roleSessionName: string,
  roleSessionDuration: number
): Promise<Credentials> => {
  const stsClient = new STS({ region });

  const cognitoIdentityClient = new CognitoIdentityClient({
    region: region,
  });

  const getIdResponse = await cognitoIdentityClient.send(
    new GetIdCommand({
      IdentityPoolId: identityPoolId,
    })
  );

  const getOpenIdTokenResponse = await cognitoIdentityClient.send(
    new GetOpenIdTokenCommand({
      IdentityId: getIdResponse.IdentityId,
    })
  );

  // Reference: https://docs.aws.amazon.com/cli/latest/reference/sts/assume-role-with-web-identity.html
  const assumeRoleResponse = await stsClient.send(
    new AssumeRoleWithWebIdentityCommand({
      RoleArn: roleArn,
      RoleSessionName: roleSessionName,
      WebIdentityToken: getOpenIdTokenResponse.Token,
      DurationSeconds: roleSessionDuration,
    })
  );

  if (!assumeRoleResponse.Credentials) {
    throw new Error('STS response did not contain credentials.');
  }

  return {
    accessKeyId: assumeRoleResponse.Credentials.AccessKeyId as string,
    secretAccessKey: assumeRoleResponse.Credentials.SecretAccessKey as string,
    sessionToken: assumeRoleResponse.Credentials.SessionToken,
    expiration: assumeRoleResponse.Credentials.Expiration,
  };
};

/**
 * @param featureFlagName the name of the feature flag to evaluate
 * @return GetFeatureFlagOutput the value of evaluating the provided feature flag
 */
export const getFeatureFlag = async (featureFlagName: string): Promise<GetFeatureFlagOutput> => {
  if (
    !ffClient ||
    (currentCredentials?.expiration &&
      currentCredentials.expiration <= new Date(Date.now() + SESSION_CREDENTIAL_EXPIRATION_BUFFER_MS))
  ) {
    // if a client hasn't been initialized, or if the client credentials expire within the time buffer, refresh the client
    const { region, evidentlyIdentityPoolId, evidentlyGuestRoleArn } = await getApplicationSettings();

    currentCredentials = await getCognitoCredentials(
      region,
      evidentlyIdentityPoolId,
      evidentlyGuestRoleArn,
      'evidently',
      ROLE_SESSION_DURATION_SECONDS
    );
    ffClient = new GTPCFeatureFlagsClient({
      region,
      entityId: FEATURE_FLAG_ENTITY_ID,
      credentials: currentCredentials,
      project: FEATURE_FLAG_PROJECT_NAME,
    });
  }
  return ffClient.getFeatureFlag(featureFlagName);
};
