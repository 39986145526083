/* eslint-disable require-jsdoc */
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { OPENSEARCH_ASSETS } from '../graphql/queries';
import { CREATE_COMPLIANCE, CREATE_IE_LICENSE, UPDATE_COMPLIANCE, UPDATE_IE_LICENSE } from '../graphql/mutations';
import { LIST_AUTHORIZATIONS_AWSPART, LIST_AUTHORIZATIONS_NONPLM } from '../graphql/queries';
import {
  Asset,
  AssetCreateResponse,
  AssetsSearchResponse,
  ComplianceUpdateResponse,
  IeLicenseUpdateResponse,
} from '@amzn/d2d-bff-schema';

export type AssetsSearch = {
  requestId: string;
  nonPlmPartName?: string;
  ipn?: string;
  mpn?: string;
  nonPlmPart?: string;
  ic?: string;
};

// GQL List Authorizations Note: list request is used on the AuthDetails page
export async function makeListRequest(search: AssetsSearch): Promise<GraphQLResult<{ listAuthorizations: Asset[] }>> {
  const query = search.nonPlmPartName !== undefined ? LIST_AUTHORIZATIONS_NONPLM : LIST_AUTHORIZATIONS_AWSPART;
  const variables: Partial<AssetsSearch> = {};
  if (search.ipn) {
    variables.ipn = search.ipn;
  }
  if (search.mpn) {
    variables.mpn = search.mpn;
  }
  if (search.nonPlmPart) {
    variables.nonPlmPartName = search.nonPlmPart;
  }
  if (search.requestId) {
    variables.requestId = search.requestId;
  }
  return API.graphql(graphqlOperation(query, variables)) as GraphQLResult<{ listAuthorizations: Asset[] }>;
}

// GQL OpenSearch
export async function makeSearchRequest(
  searchTerm: string
): Promise<GraphQLResult<{ searchAssets: AssetsSearchResponse[] }>> {
  const query = OPENSEARCH_ASSETS;
  const variables = { searchTerm: searchTerm };
  return API.graphql(graphqlOperation(query, variables)) as Promise<
    GraphQLResult<{ searchAssets: AssetsSearchResponse[] }>
  >;
}

// GQL Update
export async function makeUpdateRequest(
  path: string,
  authorization: Asset
): Promise<
  | GraphQLResult<{ updateCompliance: ComplianceUpdateResponse }>
  | GraphQLResult<{ updateIeLicense: IeLicenseUpdateResponse }>
> {
  const query = path === 'UpdateCompliance/' ? UPDATE_COMPLIANCE : UPDATE_IE_LICENSE;
  const variables = { input: authorization };
  const res = API.graphql(graphqlOperation(query, variables));
  return path === 'UpdateCompliance/'
    ? (res as GraphQLResult<{
        updateCompliance: ComplianceUpdateResponse;
      }>)
    : (res as GraphQLResult<{
        updateIeLicense: IeLicenseUpdateResponse;
      }>);
}
// GQL Create
export async function makeCreateRequest(
  path: string,
  authorization: Asset
): Promise<
  GraphQLResult<{ createCompliance: AssetCreateResponse }> | GraphQLResult<{ createIELicense: AssetCreateResponse }>
> {
  const query = path === 'CreateCompliance/' ? CREATE_COMPLIANCE : CREATE_IE_LICENSE;
  const variables = { input: authorization };
  const res = API.graphql(graphqlOperation(query, variables));
  return path === 'UpdateCompliance/'
    ? (res as GraphQLResult<{
        createCompliance: AssetCreateResponse;
      }>)
    : (res as GraphQLResult<{
        createIELicense: AssetCreateResponse;
      }>);
}
// GQL Create
