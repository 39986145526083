import { IndexedLicense, License, LicenseInput, Usage, UsageInput } from '@amzn/d2d-bff-schema';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  Draft,
  PayloadAction,
  SerializedError,
  createAsyncThunk,
  createSlice,
  miniSerializeError,
} from '@reduxjs/toolkit';
import { D2DGraphQLError, FlashbarItemsProps, LoadingState } from 'types';
import { isGraphQLResult, processLMGraphQLErrors } from 'utils/errorHandler';
import {
  makeCreateRequest,
  makeGetRequest,
  makeSearchRequest,
  makeUpdateRequest,
  makeUsageCreateRequest,
} from '_services/api/licenseManager';
import { getRumClient } from '../../analytics/rum';
import { flattenObjectsInArray } from '../../utils/export';
import { toRumSafeString } from '../../utils/rumUtils';

export type LicenseManagerState = {
  getLoading: LoadingState;
  createLoading: LoadingState;
  updateLoading: LoadingState;
  license?: License;
  updatedLicense?: License;
  createdLicense?: License;
  createdUsage?: Usage;
  licenses: IndexedLicense[];
  licenseErrors: SerializedError[];
  unknownLicenseError?: SerializedError;
  lmLandingFlashbarItems: FlashbarItemsProps[];
  lmCreateFlashbarItems: FlashbarItemsProps[];
  lmEditFlashbarItems: FlashbarItemsProps[];
  lmDetailFlashbarItems: FlashbarItemsProps[];
};

export const initialLicenseManagerState: LicenseManagerState = {
  getLoading: 'idle',
  createLoading: 'idle',
  updateLoading: 'idle',
  license: undefined,
  licenses: [],
  licenseErrors: [],
  lmLandingFlashbarItems: [],
  lmCreateFlashbarItems: [],
  lmEditFlashbarItems: [],
  lmDetailFlashbarItems: [],
};

export const createLicenseAction = createAsyncThunk<
  License | undefined,
  LicenseInput,
  {
    rejectValue: D2DGraphQLError[];
  }
>('licenseManager/createLicense', async (license: LicenseInput, { rejectWithValue }) => {
  try {
    const licenseRes = await makeCreateRequest(license);
    if (licenseRes.data?.createLicense && !licenseRes.errors) {
      return licenseRes.data.createLicense;
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<License>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

export const updateLicenseAction = createAsyncThunk<
  License | undefined,
  { licenseId: string; licenseInput: LicenseInput },
  {
    rejectValue: D2DGraphQLError[];
  }
>('licenseManager/updateLicense', async ({ licenseId, licenseInput }, { rejectWithValue }) => {
  try {
    const licenseRes: GraphQLResult<{ updateLicense: License }> = await makeUpdateRequest(licenseId, licenseInput);
    if (licenseRes.data?.updateLicense && !licenseRes.errors) {
      return licenseRes.data.updateLicense;
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<License>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

export const getLicenseAction = createAsyncThunk<
  License | undefined,
  string,
  {
    rejectValue: D2DGraphQLError[];
  }
>('licenseManager/getLicense', async (licenseId: string, { rejectWithValue }) => {
  try {
    const licenseRes = await makeGetRequest(licenseId);
    if (licenseRes.data?.getLicense && !licenseRes.errors) {
      return licenseRes.data.getLicense;
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<License>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

export const createUsageAction = createAsyncThunk<
  Usage | undefined,
  { licenseId: string; usageInput: UsageInput },
  {
    rejectValue: D2DGraphQLError[];
  }
>('licenseManager/createUsage', async ({ licenseId, usageInput }, { rejectWithValue }) => {
  try {
    const usageRes = await makeUsageCreateRequest(licenseId, usageInput);
    if (usageRes.data?.createUsage && !usageRes.errors) {
      return usageRes.data.createUsage ?? undefined;
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<Usage>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

export const searchLicenseAction = createAsyncThunk<
  IndexedLicense[] | undefined,
  string,
  {
    rejectValue: D2DGraphQLError[];
  }
>('licenseManager/searchLicenses', async (searchTerm: string, { rejectWithValue }) => {
  try {
    const licenseRes = await makeSearchRequest(searchTerm);
    if (licenseRes.data?.searchLicenses && !licenseRes.errors) {
      return licenseRes.data.searchLicenses;
    }
  } catch (e) {
    const gqlResult = isGraphQLResult<License>(e);
    if (gqlResult && gqlResult.errors) {
      return rejectWithValue(gqlResult.errors);
    } else {
      throw e;
    }
  }
});

const { reducer, actions } = createSlice({
  name: 'licenseManager',
  initialState: initialLicenseManagerState,
  reducers: {
    setLicenses: (state, { payload }: PayloadAction<IndexedLicense[]>) => {
      state.licenses = payload ?? [];
    },
    setLMLandingFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.lmLandingFlashbarItems = payload;
      state.getLoading = 'idle';
      state.createLoading = 'idle';
      state.updateLoading = 'idle';
    },
    setLMCreateFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.lmCreateFlashbarItems = payload;
    },
    setLMEditFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.lmEditFlashbarItems = payload;
    },
    setLMDetailFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.lmDetailFlashbarItems = payload;
      state.createLoading = 'idle';
      state.updateLoading = 'idle';
    },
    resetLoadingState: (state) => {
      state.createLoading = 'idle';
      state.updateLoading = 'idle';
      state.getLoading = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchLicenseAction.pending, (state) => {
        state.getLoading = 'pending';
        state.lmLandingFlashbarItems = [];
        state.licenseErrors = [];
      })
      .addCase(searchLicenseAction.fulfilled, (state, { payload }) => {
        state.licenses = payload ?? [];
        state.getLoading = 'fulfilled';
      })
      .addCase(searchLicenseAction.rejected, (state, { payload, error }) => {
        handleRejectedAction(state, payload, error, 'getLoading', 'lmLandingFlashbarItems');
      })
      .addCase(getLicenseAction.pending, (state) => {
        state.licenseErrors = [];
        state.getLoading = 'pending';
      })
      .addCase(getLicenseAction.fulfilled, (state, { payload }) => {
        state.license = payload;
        state.getLoading = 'fulfilled';
      })
      .addCase(getLicenseAction.rejected, (state, { payload, error }) => {
        handleRejectedAction(state, payload, error, 'getLoading', 'lmLandingFlashbarItems');
      })
      .addCase(createLicenseAction.pending, (state) => {
        state.createLoading = 'pending';
      })
      .addCase(createLicenseAction.fulfilled, (state, { payload }) => {
        state.createdLicense = payload;
        state.createLoading = 'fulfilled';
      })
      .addCase(createLicenseAction.rejected, (state, { payload, error }) => {
        handleRejectedAction(state, payload, error, 'createLoading', 'lmCreateFlashbarItems');
      })
      .addCase(updateLicenseAction.pending, (state) => {
        state.updateLoading = 'pending';
      })
      .addCase(updateLicenseAction.fulfilled, (state, { payload }) => {
        state.updatedLicense = payload;
        state.updateLoading = 'fulfilled';
      })
      .addCase(updateLicenseAction.rejected, (state, { payload, error }) => {
        handleRejectedAction(state, payload, error, 'updateLoading', 'lmEditFlashbarItems');
      })
      // USAGES
      .addCase(createUsageAction.pending, (state) => {
        state.createLoading = 'pending';
      })
      .addCase(createUsageAction.fulfilled, (state, { payload }) => {
        state.createdUsage = payload;
        state.createLoading = 'fulfilled';
      })
      .addCase(createUsageAction.rejected, (state, { payload, error }) => {
        handleRejectedAction(state, payload, error, 'createLoading', 'lmCreateFlashbarItems');
      });
  },
});

export const {
  setLMLandingFlashbarItems,
  setLMCreateFlashbarItems,
  setLMEditFlashbarItems,
  setLMDetailFlashbarItems,
  setLicenses,
} = actions;

export default reducer;

/* "Private" functions: */

/**
 * Handle all concerns related to any general rejected License Manager action, including setting up state and logging
 * the error to analytics.
 * @param state The Redux state, which may be mutated by this function.
 * @param payload The errors, if any, from a request to server.
 * @param error The error
 * @param stateFieldRejected The field within state which should be set to rejected
 * @param flashbarItemsField The field within state which should be set with flashbar items
 * @returns void
 */
const handleRejectedAction = (
  state: Draft<LicenseManagerState>,
  payload: D2DGraphQLError[] | undefined,
  error: SerializedError,
  stateFieldRejected: 'getLoading' | 'createLoading' | 'updateLoading',
  flashbarItemsField: 'lmCreateFlashbarItems' | 'lmLandingFlashbarItems' | 'lmEditFlashbarItems'
): void => {
  // Log to analytics/RUM
  try {
    const rumSafeString = toRumSafeString(flattenObjectsInArray(payload));
    getRumClient()?.recordError(new Error(`Request to server was rejected. Payload: ${rumSafeString}`));
  } catch (e) {
    console.error('Failed to send to rum', e);
  }
  // Update State
  if (payload) {
    state[flashbarItemsField] = processLMGraphQLErrors(payload);
    if (payload) state.licenseErrors = payload;
  } else {
    state.unknownLicenseError = miniSerializeError(error);
  }
  state[stateFieldRejected] = 'rejected';
};
