import React from 'react';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import Link from '@amzn/awsui-components-react/polaris/link';
import { ArtifactCard } from 'types/artifacts';

const ArtifactConnectedCards = ({ cards }: { cards: ArtifactCard[] }): React.ReactElement => {
  return (
    <Cards
      cardDefinition={{
        header: (item) => {
          // if the part is undefined or null don't allow navigation
          const connectedArtifactLink = item?.part
            ? `/artifact/${item.requestId}/${item.issuingCountry}/${encodeURIComponent(item.part)}`
            : '#';
          return (
            <Link fontSize="heading-m" href={connectedArtifactLink}>
              {item.name}
            </Link>
          );
        },
        sections: [
          {
            id: 'part',
            header: 'AWS Part',
            content: (item) => item.part,
          },
        ],
      }}
      cardsPerRow={[{ cards: 1 }, { minWidth: 300, cards: 5 }]}
      items={cards}
      loadingText="Loading artifacts"
      empty="No connected artifacts"
    />
  );
};

export default ArtifactConnectedCards;
