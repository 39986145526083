import React from 'react';
import { ColumnLayout, Grid } from '@amzn/awsui-components-react/polaris';
import moment from 'moment';
import {
  EX_REPORT_REQ_TYPE,
  IM_APP_REQ,
  IM_REQ_COMPLETED,
  JUSTIFICATION_OPTIONS,
  PC_STATUS,
  PROGRAM_OPTIONS,
} from '../../utils/constants';
import { Link } from 'react-router-dom';

export default function ClassificationDetailView({ classification, USClassification }) {
  const thirdPartyPartInfo = (
    <>
      <div>
        <b>Part Description:</b>
        <br />
        {classification.nonPlmPart?.description}
      </div>
      <div>
        <b>Manufacturer Name:</b> <br />
        {classification.nonPlmPart?.manufacturerName}
      </div>
      <div>
        <b>Manufacturer Part ID:</b> <br />
        {classification.nonPlmPart?.manufacturerPartId}
      </div>
    </>
  );

  const nonUSPart = (
    <div>
      <b>U.S. ECN for Part: </b> <br /> (U.S. Classification must exist or this is blank) <br />
      {USClassification?.awsPart?.mpn ? (
        <Link
          to={`/classification/US/${encodeURIComponent(USClassification.awsPart.ipn)}:${encodeURIComponent(
            USClassification.awsPart.mpn
          )}`}
        >
          {USClassification.ecn}
        </Link>
      ) : (
        <Link to={`/classification/US/${encodeURIComponent(USClassification?.assetId)}`}>{USClassification?.ecn}</Link>
      )}
    </div>
  );

  const agileIpnDescription = (
    <div>
      <b>Agile IPN Description:</b>
      <br /> {classification.awsPart?.ipnDescription}
    </div>
  );

  const lastModified = (
    <div>
      {classification.modifiedAt
        ? moment.unix(classification.modifiedAt).format('YYYY-MM-DD')
        : moment.unix(classification.createdAt).format('YYYY-MM-DD')}
    </div>
  );

  const changeNotes = <div>{classification.changeNotes ? classification.changeNotes : ''}</div>;
  // Handle array of programs
  const programs = classification.programs?.map((program) => {
    const getLabel = PROGRAM_OPTIONS.filter((at) => at.value === program);
    return getLabel.map((programLabel, index) => {
      return <li key={index}>{programLabel.label}</li>;
    });
  });
  // Handle returned boolean values
  let exportApprovalRequired = '';
  if (classification.exportApprovalRequired === true) {
    exportApprovalRequired = 'Yes';
  } else if (classification.exportApprovalRequired === false) {
    exportApprovalRequired = 'No';
  }
  let exportReportingRequirement = '';
  if (classification.exportReportingRequirement === true) {
    exportReportingRequirement = 'Yes';
  } else if (classification.exportReportingRequirement === false) {
    exportReportingRequirement = 'No';
  }

  const getJustification = JUSTIFICATION_OPTIONS.find((a) => a.value === classification.justification);
  const justificationType = getJustification?.label;
  const getIAR = IM_APP_REQ.find((option) => option.value === classification.importApprovalRequired);
  const iarType = getIAR?.label;
  const getIRC = IM_REQ_COMPLETED.find((option) => option.value === classification.importRequirementsCompleted);
  const ircType = getIRC?.label;
  const getERRT = EX_REPORT_REQ_TYPE.find((option) => option.value === classification.exportReportingRequirementType);
  const errtType = getERRT?.label;
  const getStatus = PC_STATUS.find((a) => a.value === classification.pcStatus);
  const pcStatusType = getStatus?.label;

  return (
    <>
      <ColumnLayout data-test="main-container" columns={3} variant="text-grid">
        <div>
          <b>Part:</b>
          <br /> {classification.assetId}
        </div>
        <div>
          <b>Country:</b> <br />
          {classification.countryISO2Format}
        </div>
        <div>
          <b>Programs:</b> <br /> {programs}
        </div>

        {classification.awsPart ? agileIpnDescription : null}
        {classification.nonPlmPart ? thirdPartyPartInfo : null}
        <div>
          <b>PC Approval Status:</b> <br />
          {pcStatusType}
        </div>
        <div>
          <b>Local Language Description:</b> <br />
          {classification.localLanguageDescription}
        </div>
      </ColumnLayout>
      <br />
      <h3>
        <b>Import Detail Info</b>
      </h3>
      <br />
      <ColumnLayout columns={3} variant="text-grid">
        <div>
          <b>HTS Code:</b> <br /> {classification.hts}
        </div>

        <div>
          <b>Import Approval Required: </b>
          <br />
          {iarType}
        </div>
        <div>
          <b>Import Requirements Completed:</b> <br />
          {ircType}
        </div>
        <div>
          <b>Justification:</b> <br />
          {justificationType}
        </div>
        <div>
          <b>Import Classification Details:</b> <br />
          {classification.importClassificationDetails}
        </div>
        <div>
          <b>Internal Import Requirement Notes:</b> <br />
          {classification.internalImportRequirementNotes}
        </div>
        <div>
          <b>Broker Import Requirement Notes:</b> <br />
          {classification.brokerImportRequirementNotes}
        </div>
      </ColumnLayout>
      <br />
      <br />
      <h3>
        <b>Export Detail Info</b>
      </h3>
      <br />
      <ColumnLayout columns={3} variant="text-grid">
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <div>
            <b>ECN: </b> <br /> {classification.ecn}
          </div>
        </Grid>
        {classification.countryISO2 === 'US' ? (
          <div>
            <b>U.S. ECN for Part:</b> <br /> {classification.ecn}
          </div>
        ) : (
          nonUSPart
        )}
        <div>
          <b>Export Classification Details:</b> <br />
          {classification.exportClassificationDetails}
        </div>
      </ColumnLayout>
      <br />
      <br />
      <ColumnLayout columns={3} variant="text-grid">
        <div>
          <b>Export Approval Required:</b> <br />
          {exportApprovalRequired}
        </div>
        <div>
          <b>Export Reporting Requirement:</b> <br />
          {exportReportingRequirement}
        </div>
        <div>
          <b>Export Reporting Requirement Type:</b> <br />
          {errtType}
        </div>
      </ColumnLayout>
      <br />
      <br />
      <ColumnLayout columns={3} variant="text-grid">
        <div>
          <b>Export Requirement Notes:</b>
          <br />
          {classification.exportRequirementNotes}
          <br />
        </div>
        <div>
          <b>Last Update Notes:</b>
          <br />
          {changeNotes}
        </div>
        <div>
          <b>Last Modified:</b> <br /> {lastModified}
        </div>
      </ColumnLayout>
    </>
  );
}
