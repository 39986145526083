import { Link } from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router';

const PolarisLink: React.FC<{ to: string }> = ({ to, children }) => {
  const navigate = useNavigate();
  return (
    <Link
      href={to}
      onFollow={(e) => {
        e.preventDefault();
        navigate(e.detail?.href ?? '/');
      }}
    >
      {children}
    </Link>
  );
};

export default PolarisLink;
