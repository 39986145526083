import { optionDefinitionSchema } from 'utils/formUtils';
import * as z from 'zod';
import { COUNTRIES } from 'utils/constants';
import { POLARIS_DATE_PATTERN, formatFromEpoch } from 'utils/dateFormat';
import { getUnixTime, parse } from 'date-fns';
import { Country } from '@amzn/d2d-bff-schema';

const epochDateToString = (epoch: number | null | undefined): string | undefined => {
  return epoch ? formatFromEpoch(epoch, POLARIS_DATE_PATTERN) : undefined;
};

/** TODO: fields need changed over to usages schema instead of license */
export const lmUsageFormSchema = z.object({
  importCountry: optionDefinitionSchema,
  exportCountry: optionDefinitionSchema,
  hts: z.string().nonempty().optional(),
  ipn: optionDefinitionSchema.optional(),
  mpn: z.string().nonempty().optional(),
  eccn: z.string().nonempty().optional(),
  quantity: z.number().nonnegative(),
  value: z.number().nonnegative(),
  commercialInvoiceNumber: z.string().nonempty(),
  purchaseOrderNumber: z.string().optional().nullable(),
  customsEntryDeclarationNumber: z.string().optional().nullable(),
  transportationDocumentTrackingNumber: z.string().optional().nullable(),
  dateExported: z.string().optional(),
  dateImported: z.string().optional(),
  dateDeclaredToCustoms: z.string().optional(),
  partDescription: z.string().optional().nullable(),
});

export const lmUsageApiSchema = lmUsageFormSchema.extend({
  importCountry: z.string(),
  exportCountry: z.string(),
  ipn: z.string().nonempty().nullable(),
  commercialInvoiceNumber: z.string().nonempty(),
  purchaseOrderNumber: z.string().optional().nullable(),
  customsEntryDeclarationNumber: z.string().optional().nullable(),
  transportationDocumentTrackingNumber: z.string().optional().nullable(),
  dateExported: z.number().optional().nullable(),
  dateImported: z.number().optional().nullable(),
  dateDeclaredToCustoms: z.number().optional().nullable(),
  partDescription: z.string().optional().nullable(),
});

export type LicenseUsageFormInputs = z.infer<typeof lmUsageFormSchema>;

export const lmUsageApiSchemaToFormSchema = lmUsageApiSchema.transform((usage) => {
  return {
    ...usage,
    importCountry: COUNTRIES.find((country) => country.value === usage.importCountry),
    exportCountry: COUNTRIES.find((country) => country.value === usage.exportCountry),
    ipn: usage?.ipn ? { value: usage.ipn } : undefined,
    mpn: usage?.mpn,
    eccn: usage?.eccn,
    hts: usage?.hts,
    dateExported: epochDateToString(usage.dateExported),
    dateImported: epochDateToString(usage.dateImported),
    dateDeclaredToCustoms: epochDateToString(usage.dateDeclaredToCustoms),
  };
});

export const lmUsageFormSchemaToApiSchema = lmUsageFormSchema.transform((usage) => {
  return {
    ...usage,
    exportCountry: usage?.exportCountry.value as Country,
    importCountry: usage?.importCountry.value as Country,
    ipn: usage.ipn?.value,
    dateImported: usage?.dateImported
      ? getUnixTime(parse(usage?.dateImported, POLARIS_DATE_PATTERN, new Date()))
      : undefined,
    dateExported: usage?.dateExported
      ? getUnixTime(parse(usage?.dateExported, POLARIS_DATE_PATTERN, new Date()))
      : undefined,
    dateDeclaredToCustoms: usage?.dateDeclaredToCustoms
      ? getUnixTime(parse(usage?.dateDeclaredToCustoms, POLARIS_DATE_PATTERN, new Date()))
      : undefined,
    quantity: usage.quantity,
    value: usage.value,
  };
});

export { default } from './LicenseUsageCommon';
