import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import { amplifyInit } from './_services/amplify';
import { initCustomEvents, initRUM } from './analytics/rum';
import { getApplicationSettings } from './config/settingsConfig';
import '@amzn/awsui-global-styles/polaris.css';
import { Provider } from 'react-redux';
import store from './store/store';

initCustomEvents();
// initialize Amplify
amplifyInit();

// initialize application monitors (RUM and feature flags)
const initializeAppMonitoring = async (): Promise<void> => {
  const settings = await getApplicationSettings();
  const { rumGuestRoleArn, rumIdentityPoolId, rumAppMonitorId } = settings;

  initRUM(rumAppMonitorId, {
    identityPoolId: rumIdentityPoolId,
    guestRoleArn: rumGuestRoleArn,
  });
};

try {
  initializeAppMonitoring();
} catch (error) {
  console.log('error getting settings', error);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
