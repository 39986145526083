import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { PlmItem } from '@amzn/d2d-bff-schema';
import { TOWNSEND_PLM } from '../graphql/queries';

type PLMResponse = { searchPLM: { items: PlmItem[] }[] };
/**
 * GQL CREATE License
 * @param {string} ipn IPN
 * @return {Promise<GraphQLResult<PLMResponse>>} Agile response
 */
export async function searchTownsendByIpn(ipn: string): Promise<GraphQLResult<PLMResponse>> {
  const query = TOWNSEND_PLM;
  const variables = { searchTerm: ipn };
  return API.graphql(graphqlOperation(query, variables)) as Promise<GraphQLResult<PLMResponse>>;
}
