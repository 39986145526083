import React from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import dashboardImage from 'assets/images/DASHBOARD-GRAPHIC.svg';
import './error.css';

const Error = ({ errorMessage }: { errorMessage?: string }): React.ReactElement => {
  return (
    <>
      <div className="error">
        <img className="error-image mt-40" src={dashboardImage} alt="dashboard_image" />
        <div className="error-message">Error</div>
        <div className="error-details">
          <Alert header="Oh no!  There was an issue getting your data.">{errorMessage}</Alert>
          <br />
          Try going back on your browser and trying again. Need more help? <br />
          Contact us on Slack, public channel #aws-gtpc-tech-interest.
        </div>
      </div>
    </>
  );
};

export default Error;
