import { useEffect, useState } from 'react';
import { LicenseUsageFormInputs } from 'components/LicenseUsageCommon';
import { License } from '@amzn/d2d-bff-schema';

type LMFormError = { message: string; hasError: boolean };

const DEFAULT_FORM_ERROR = { message: '', hasError: false };

const useValidationUsageForm = (
  licenseUsageFormInputs: Partial<LicenseUsageFormInputs>,
  license: License | undefined,
  isSubmitted: boolean
): {
  partValidation: LMFormError;
  valueLimitValidation: LMFormError;
  unitLimitValidation: LMFormError;
} => {
  const { hts, ipn, eccn, mpn, value, quantity } = licenseUsageFormInputs;

  const [partValidation, setPartValidation] = useState(DEFAULT_FORM_ERROR);
  const [valueLimitValidation, setValueLimitValidation] = useState(DEFAULT_FORM_ERROR);
  const [unitLimitValidation, setUnitLimitValidation] = useState(DEFAULT_FORM_ERROR);

  useEffect(() => {
    if (!hts && !eccn && !ipn?.value && !mpn && isSubmitted) {
      setPartValidation({ hasError: true, message: 'One of IPN, MPN, ECCN, or HTS is required.' });
    } else {
      setPartValidation({ hasError: false, message: '' });
    }
  }, [eccn, isSubmitted, hts, ipn, mpn]);

  useEffect(() => {
    if (license?.unitLimit) {
      const initialCount = license?.usageCount ? license?.unitLimit - license?.usageCount : license?.unitLimit;
      if (quantity && initialCount - quantity < 0) {
        setUnitLimitValidation({ hasError: true, message: `Quantity entered must be less than ${initialCount}` });
      } else {
        setUnitLimitValidation({ hasError: false, message: '' });
      }
    }
  }, [quantity, license]);

  useEffect(() => {
    if (license?.valueLimit) {
      const initialCount = license?.usageValue ? license?.valueLimit - license?.usageValue : license?.valueLimit;
      if (value && initialCount - value < 0) {
        setValueLimitValidation({ hasError: true, message: `Value entered must be less than ${initialCount}` });
      } else {
        setValueLimitValidation({ hasError: false, message: '' });
      }
    }
  }, [value, license]);

  return {
    partValidation,
    valueLimitValidation,
    unitLimitValidation,
  };
};

export default useValidationUsageForm;
