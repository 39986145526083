import React from 'react';
import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import SideNav from 'components/SideNav';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import PcClassificationEdit from './PcClassificationEdit';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { flashbarItemsCreator } from 'utils/formUtils';
import { setPCClassificationEditFlashbarItems } from 'store/pcClassification';

const PcClassificationEditLayout: React.FC = () => {
  const dispatch = useDispatch();
  const { pcClassificationEditFlashbarItems } = useAppSelector((state) => state.pcClassificationStore);

  /** get part source, part identifier type and part identifier value from path params */
  const { partSource, partIdentifierType, partIdentifierValue } = useParams<string>();

  return (
    <AppLayout
      contentType="form"
      headerSelector={HEADER_SELECTOR}
      navigation={<SideNav />}
      contentHeader={<Header variant="h1">Edit Part</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'D2D', href: '/' },
            { text: 'Classifications', href: '/classifications' },
            {
              text: `Part Details (${partSource}/${partIdentifierType}/${partIdentifierValue})`,
              href: `/classification/pc/${partSource}/${partIdentifierType}/${partIdentifierValue}`,
            },
            { text: 'Edit Part', href: '#' },
          ]}
        />
      }
      content={
        <PcClassificationEdit
          partSource={partSource}
          partIdentifierType={partIdentifierType}
          partIdentifierValue={partIdentifierValue}
        />
      }
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar
          items={flashbarItemsCreator(
            pcClassificationEditFlashbarItems,
            dispatch,
            setPCClassificationEditFlashbarItems
          )}
        />
      }
      stickyNotifications
      toolsHide
    />
  );
};

export default PcClassificationEditLayout;
