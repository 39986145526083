import { Container, FormField, Header, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { AssetType } from '@amzn/d2d-bff-schema';
import ArtifactCommon, { ArtifactFormInputs, artifactsFormSchemaToApiSchema } from 'components/ArtifactCommon';
import LicenseManagerCommon, { LicenseFormInputs, lmFormSchemaToApiSchema } from 'components/LicenseManagerCommon';
import React, { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { createArtifactAction } from 'store/artifact/artifactSlice';
import { createLicenseAction, setLMDetailFlashbarItems } from 'store/licenseManager';
import { useAppDispatch, useAppSelector } from 'store/store';
import { EnumDisplayMapping } from 'types';
import { ARTIFACT_V1_MENU_OPTIONS_FOR_CREATE, ARTIFACT_V1_SUBTYPE_MENU_OPTIONS_FOR_CREATE } from 'utils/constants';
import { createSuccessItem } from 'utils/formUtils';

const ArtifactCreate: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { createArtifactLoading } = useAppSelector((state) => state.artifactStore);
  const { createLoading: licenseCreateLoading, createdLicense, licenseErrors } = useAppSelector(
    (state) => state.licenseManagerStore
  );
  const [artifactTypeSelection, setArtifactTypeSelection] = useState<Partial<EnumDisplayMapping> | null>(null);
  const [artifactSubTypeSelection, setArtifactSubTypeSelection] = useState<Partial<EnumDisplayMapping> | null>(null);
  // OLD SUB TYPES
  const [artifactSubTypes, setArtifactSubTypes] = useState<EnumDisplayMapping[]>(
    ARTIFACT_V1_SUBTYPE_MENU_OPTIONS_FOR_CREATE
  );
  const recordType = artifactTypeSelection?.filteringTags?.includes('compliance') ? 'compliance' : 'ielicense';

  useEffect(() => {
    // if a user selects sub-type first, infer the type
    if (artifactSubTypeSelection) {
      ARTIFACT_V1_MENU_OPTIONS_FOR_CREATE.forEach((group) => {
        const match = group.options.find(
          (type) => artifactSubTypeSelection.format && artifactSubTypeSelection.format === type.value
        );
        if (match) setArtifactTypeSelection(match);
      });
    }
    // if a user selects type, filter the sub-type list
    if (artifactTypeSelection)
      setArtifactSubTypes(
        ARTIFACT_V1_SUBTYPE_MENU_OPTIONS_FOR_CREATE.filter(
          (st) => st.format && (!artifactTypeSelection?.value || st.format === artifactTypeSelection?.value)
        )
      );
  }, [artifactTypeSelection, artifactSubTypeSelection]);

  const onArtifactsSubmit: SubmitHandler<ArtifactFormInputs> = async (data) => {
    /** parse */
    const apiSchemaAsset = artifactsFormSchemaToApiSchema({
      ...data,
      recordType,
      authorizationType: artifactTypeSelection?.value,
      subAuthorizationType: artifactSubTypeSelection?.value,
    });
    /** call create artifact */
    const res = await dispatch(
      createArtifactAction({
        path: artifactTypeSelection?.filteringTags?.includes('compliance') ? 'CreateCompliance/' : 'CreateIELicense/',
        newAuth: apiSchemaAsset,
      })
    ).unwrap();

    let redirectPath = '/artifact/';
    if (res?.data) {
      let createResponse;
      if ('createCompliance' in res.data) createResponse = res.data.createCompliance;
      if ('createIELicense' in res.data) createResponse = res.data.createIELicense;
      redirectPath += `${createResponse?.requestId}/${apiSchemaAsset.issuingCountries[0]}`;

      if (apiSchemaAsset.assetType === AssetType.awspart && 'awsParts' in apiSchemaAsset) {
        const firstAwsPart = apiSchemaAsset?.awsParts?.[0];
        redirectPath += `/${encodeURIComponent(firstAwsPart?.ipn ?? '')}:${encodeURIComponent(
          firstAwsPart?.mpn ?? ''
        )}`;
      } else if (apiSchemaAsset.assetType === AssetType.nonplmpart && 'nonPlmParts' in apiSchemaAsset) {
        const firstNonPlm = apiSchemaAsset?.nonPlmParts?.[0];
        redirectPath += `/${encodeURIComponent(firstNonPlm?.nonPlmPartName ?? '')}`;
      }
    }
    navigate(redirectPath);
  };

  const onLicenseSubmit: SubmitHandler<LicenseFormInputs> = async (data) => {
    /** parse */
    const apiSchemaLicense = lmFormSchemaToApiSchema.parse(data);
    /** call create license */
    dispatch(createLicenseAction(apiSchemaLicense));
  };

  useEffect(() => {
    if (licenseCreateLoading === 'fulfilled' && createdLicense?.licenseId) {
      dispatch(
        setLMDetailFlashbarItems([
          createSuccessItem(`License with ID ${createdLicense?.licenseId} created successfully!`),
        ])
      );
      navigate(`/license/${createdLicense?.licenseId}`);
    }
  }, [dispatch, createdLicense?.licenseId, licenseErrors, licenseCreateLoading, navigate]);

  const artifactCreate = (
    <ArtifactCommon
      isLoading={createArtifactLoading === 'pending'}
      artifactType={artifactTypeSelection}
      onSubmit={onArtifactsSubmit}
      submitText="Create"
      cancelRoute="/artifacts"
      isComplianceRecord={artifactTypeSelection?.filteringTags?.includes('compliance')}
    />
  );

  const licenseCreate = (
    <LicenseManagerCommon
      artifactType={artifactTypeSelection}
      artifactSubType={artifactSubTypeSelection}
      isLoading={licenseCreateLoading === 'pending'}
      onSubmit={onLicenseSubmit}
      submitText="Create"
      cancelRoute="/artifacts"
    />
  );

  return (
    <SpaceBetween size="l">
      <Container header={<Header>Artifact Type</Header>}>
        <SpaceBetween size="l">
          <FormField label="Artifact Type" description="Choose the type of artifact you wish to create">
            <Select
              filteringType="auto"
              selectedOption={artifactTypeSelection}
              options={ARTIFACT_V1_MENU_OPTIONS_FOR_CREATE}
              onChange={(e) => {
                setArtifactSubTypeSelection(null);
                setArtifactTypeSelection(e.detail.selectedOption);
              }}
              placeholder="Choose an artifact type"
            />
          </FormField>
          <FormField label="Artifact Sub-Type" description="Choose the artifact sub-type">
            <Select
              filteringType="auto"
              selectedOption={artifactSubTypeSelection}
              options={artifactSubTypes}
              onChange={(e) => {
                setArtifactSubTypeSelection(e.detail.selectedOption);
              }}
              disabled={artifactSubTypes.length === 0}
              placeholder="Choose an artifact type"
            />
          </FormField>
        </SpaceBetween>
      </Container>
      {artifactTypeSelection?.filteringTags?.includes('licenseManager') ? licenseCreate : artifactCreate}
    </SpaceBetween>
  );
};

export default ArtifactCreate;
