import { createAsyncThunk, createSlice, miniSerializeError } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { displayError } from 'utils/errorHandler';
import { AUTH_INIT_EVENT } from 'analytics/rum';

const initialState = {
  user: {},
  alias: '',
  userLoading: 'pending',
  userError: null,
};

/**
 * get the authenticated user
 * @returns {Object}
 */
export const getUser = createAsyncThunk('user/getUser', async (_, { dispatch }) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const sanitizedUser = JSON.parse(JSON.stringify(user));
    return sanitizedUser;
  } catch (e) {
    Auth.federatedSignIn({ customProvider: 'AmazonFederate' })
      .then((data) => {
        dispatch(actions.getUserFailure(data));
      })
      .catch(() => {
        dispatch(actions.getUserFailure());
      });
  }
});

/** User Slice */
const { reducer, actions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserFailure: (state, { payload }) => {
      state.userLoading = 'rejected';
      state.userError = displayError(payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.userLoading = 'pending';
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.user = payload;
      // username comes in the form `AmazonFederate_{alias}`
      const alias = payload.username.replace('AmazonFederate_', '');
      state.alias = alias;
      state.userLoading = 'fulfilled';
      // dispatch custom event to signify alias has been set
      document.dispatchEvent(
        new CustomEvent(AUTH_INIT_EVENT, {
          cancelable: true,
          detail: { alias },
        })
      );
    });
    builder.addCase(getUser.rejected, (state, { error }) => {
      state.user = null;
      state.userError = miniSerializeError(error);
      state.userLoading = 'rejected';
    });
  },
});

export default reducer;
