import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Grid,
  Header,
  Pagination,
  Select,
  Table,
} from '@amzn/awsui-components-react';
import { PAGINATION_ARIA_LABELS, getSizeCookie } from 'components/Table/tableConfig';
import useLocalStorage from 'hooks/useLocalStorage';
import { EmptyState } from '../Table';
import {
  CLASSIFICATIONS_SEARCH_MODES_LABELS,
  TABLE_SIZE_PREFERENCES,
} from '../TradeClassificationsTable/TradeClassificationsTableConfig';
import { EnumDisplayMapping } from '../../types';
import React, { useState } from 'react';
import { usePcClassifications } from './hooks';
import { IndexedPcClassification, PccPartSearchResult } from '@amzn/d2d-bff-schema';
import {
  IdSafeColumnDefinition,
  PC_CLASSIFICATIONS_CONTENT_PREFERENCE,
  PC_CLASSIFICATIONS_TABLE_PREFERENCES_KEY,
  PC_CLASSIFICATIONS_VISIBLE_CONTENT,
  getPcClassificationsColumnDefinitions,
} from './PcClassificationsTableConfig';
import { useAppSelector } from '../../store/store';
import usePCCCodesAPI from '../../hooks/useApiDataFetch';

type SearchModeConfig = {
  visibleContentKey: string;
  visibleContentPreference: CollectionPreferencesProps.VisibleContentPreference;
  columnDefinitions: IdSafeColumnDefinition<IndexedPcClassification>[];
};

const DEFAULT_SORT_FIELD = '_score';

const PCClassificationsTable: React.FC<{
  searchTerm: string;
  pcClassificationsResponse: PccPartSearchResult;
  pcClassificationsLoading: boolean;
  localStorage: [
    Partial<EnumDisplayMapping>,
    (value: Partial<EnumDisplayMapping> | ((val: Partial<EnumDisplayMapping>) => Partial<EnumDisplayMapping>)) => void
  ];
}> = ({ searchTerm, pcClassificationsResponse, pcClassificationsLoading, localStorage }) => {
  usePCCCodesAPI(); // Get PCC Codes from API to format the "Classifications" column on search results

  const { formattedPccCodes } = useAppSelector((state) => state.pcClassificationStore);

  const searchModeConfig: SearchModeConfig = {
    visibleContentKey: PC_CLASSIFICATIONS_TABLE_PREFERENCES_KEY,
    visibleContentPreference: PC_CLASSIFICATIONS_CONTENT_PREFERENCE,
    columnDefinitions: getPcClassificationsColumnDefinitions(formattedPccCodes),
    // Uncomment the following when implementing filtering:
    // filteringProperties: PC_CLASSIFICATIONS_FILTERING_PROPERTIES,
  };

  const defaultPageSize = TABLE_SIZE_PREFERENCES.options[0].value;

  const pcColumnPreferencesStorage = useLocalStorage<CollectionPreferencesProps.Preferences<Record<string, unknown>>>(
    searchModeConfig.visibleContentKey,
    {
      pageSize: getSizeCookie(),
      visibleContent: PC_CLASSIFICATIONS_VISIBLE_CONTENT,
    }
  );
  const [preferences, setPreferences] = pcColumnPreferencesStorage;
  const [localStorageState, setLocalStorage] = localStorage;

  // Setup state for paginated table
  const [sortColumn, setSortingColumn] = useState<IdSafeColumnDefinition<IndexedPcClassification> | undefined>();
  const [isSortDesc, setIsSortDesc] = useState(true);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const pageSize = preferences.pageSize ?? defaultPageSize;

  const params = {
    searchTerm,
    pageSize,
    pageIndex: currentPageIndex,
    sortColumn: sortColumn?.sortingField ?? DEFAULT_SORT_FIELD,
    isSortDesc,
  };
  // Listen for changes to table state and dispatch request for new data
  usePcClassifications(params);

  const count = `(${pcClassificationsResponse.totalResults})`;
  const renderedHeader = <Header counter={count}>Search Results</Header>;

  const totalPages = Math.ceil(pcClassificationsResponse.totalResults / pageSize);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSortingChange = (event: any): void => {
    setIsSortDesc(event.detail.isDescending);
    setSortingColumn(event.detail.sortingColumn);
  };

  return (
    <Table
      items={pcClassificationsResponse.results}
      columnDefinitions={searchModeConfig.columnDefinitions}
      resizableColumns={true}
      visibleColumns={preferences.visibleContent}
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          visibleContentPreference={searchModeConfig.visibleContentPreference}
          pageSizePreference={TABLE_SIZE_PREFERENCES}
          onConfirm={({ detail }) => {
            setPreferences(detail);
            document.cookie = `sizePreference=${detail.pageSize}`;
          }}
        />
      }
      loading={pcClassificationsLoading}
      loadingText="Loading search results..."
      header={renderedHeader}
      empty={<EmptyState title="No results" subtitle="Please search again." action={null} />}
      filter={
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 3 }, { colspan: 3 }]}>
          <div>
            <div>{/* Empty divs are necessary for spacing to match the TradeClassificationTable */}</div>
            {/* If we ever add filtering, we will replace the div above w/a component like TextFilter below */}
            {/* <TextFilter*/}
            {/*  filteringText="filter text" */}
            {/*  filteringPlaceholder="Filter by text"*/}
            {/*  filteringAriaLabel="Filter"*/}
            {/* />*/}
            {/* <PropertyFilter*/}
            {/*  i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}*/}
            {/*  query={{ tokens: [], operation: 'and' }}*/}
            {/*  onChange={() => {*/}
            {/*    // new request to server ;*/}
            {/*  }}*/}
            {/*  filteringProperties={[]}*/}
            {/*  tokenLimit={8}*/}
            {/* />*/}
          </div>
          <div></div>
          <Select
            key={'filter-select-modes'}
            data-test="select-filter"
            className="select-filter"
            options={CLASSIFICATIONS_SEARCH_MODES_LABELS}
            selectedAriaLabel="Selected"
            selectedOption={localStorageState}
            onChange={(event) => {
              setLocalStorage(event.detail.selectedOption);
            }}
            expandToViewport={true}
          />
        </Grid>
      }
      pagination={
        <Pagination
          pagesCount={totalPages}
          disabled={false}
          currentPageIndex={currentPageIndex}
          onChange={(event) => setCurrentPageIndex(event.detail.currentPageIndex)}
          ariaLabels={PAGINATION_ARIA_LABELS}
        />
      }
      onSortingChange={onSortingChange}
      sortingDescending={isSortDesc}
      sortingColumn={sortColumn}
      wrapLines
    />
  );
};

export default PCClassificationsTable;
