import { Box, ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import ValueWithLabel from '../../ValueWithLabel';
import { Artifact, MetadataFieldAndLabel, MetadataFieldSchema } from '@amzn/d2d-bff-schema';
import {
  formatArtifactsV2StringDate,
  getArtifactsV2MetadataValue,
  getExpirationDateLabel,
  getLabel,
  getPartFamilyLabel,
} from '../artifactsV2Utils';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import { ArtifactV2FileVersionsTable } from '../ArtifactV2FileVersionsTable';
import { ArtifactV2HistoryTable } from '../ArtifactV2HistoryTable/ArtifactV2HistoryTable';
import { ARTIFACTSV2_COUNTRIES, ARTIFACT_MENU_OPTIONS, ARTIFACT_SUBTYPE_MENU_OPTIONS } from '../../../utils/constants';

interface ArtifactV2DetailPageProps {
  artifact?: Artifact;
}

const DEFAULT_EMPTY_FIELD_VALUE = '-';

const ArtifactV2Details: React.FC<ArtifactV2DetailPageProps> = ({ artifact }) => {
  const metadataLookup: Map<string, MetadataFieldAndLabel> = new Map<string, MetadataFieldAndLabel>();
  const schemaLookup: Map<string, MetadataFieldSchema> = new Map<string, MetadataFieldSchema>();
  artifact?.metadata.data.forEach((metadataField) => {
    metadataLookup.set(metadataField.key, metadataField);
  });
  artifact?.metadata.schema?.forEach((metadataFieldSchema) => {
    schemaLookup.set(metadataFieldSchema.key, metadataFieldSchema);
  });
  const expirationString = getArtifactsV2MetadataValue('expiration', artifact);
  const applicableCountries = getArtifactsV2MetadataValue('applicableCountries', artifact);
  const standards = getArtifactsV2MetadataValue('standards', artifact);

  const artifactDetails = (
    <>
      <Box variant="div">
        <i>
          Last modified {artifact?.lastModifiedAt ? formatArtifactsV2StringDate(artifact?.lastModifiedAt) : 'unknown'}
        </i>
      </Box>
      {artifact?.archived && <Alert type="warning" header="This artifact is archived and cannnot be edited" />}
      <ArtifactV2FileVersionsTable artifact={artifact} header={<Header>Versions</Header>} />
      <Container header={<Header>General information</Header>}>
        <ColumnLayout columns={4} variant="text-grid" borders="vertical">
          <ValueWithLabel label="Type">
            {getLabel(
              artifact?.metadata.data.find((metadataField) => metadataField.key === 'type')?.value[0],
              ARTIFACT_MENU_OPTIONS
            )}
          </ValueWithLabel>
          <ValueWithLabel label="Applicable Countries">
            {applicableCountries?.map((country) => getLabel(country, ARTIFACTSV2_COUNTRIES)).join(', ')}
          </ValueWithLabel>
          <ValueWithLabel label={'Archived'}>{artifact?.archived ? 'Yes' : 'No'}</ValueWithLabel>
          <ValueWithLabel label="Created By">{artifact?.createdBy ?? DEFAULT_EMPTY_FIELD_VALUE}</ValueWithLabel>
          <ValueWithLabel label="Subtype">
            {getLabel(
              artifact?.metadata.data.find((metadataField) => metadataField.key === 'subtype')?.value[0],
              ARTIFACT_SUBTYPE_MENU_OPTIONS
            )}
          </ValueWithLabel>
          <ValueWithLabel label={'Standards'}>
            {standards?.map((standard) => {
              return (
                <Box key={standard} variant={'p'}>
                  {standard}
                </Box>
              );
            }) ?? DEFAULT_EMPTY_FIELD_VALUE}
          </ValueWithLabel>
          <ValueWithLabel label={'Expiration Date'}>
            {/* eslint-disable @typescript-eslint/no-non-null-assertion */}
            {expirationString?.length ? getExpirationDateLabel(expirationString[0]) : DEFAULT_EMPTY_FIELD_VALUE}
          </ValueWithLabel>
          <ValueWithLabel label={'Last Modified'}>
            {artifact?.lastModifiedAt
              ? formatArtifactsV2StringDate(artifact?.lastModifiedAt)
              : DEFAULT_EMPTY_FIELD_VALUE}
          </ValueWithLabel>
        </ColumnLayout>
      </Container>
      <Container header={<Header>Part information</Header>}>
        <ColumnLayout columns={4} variant="text-grid">
          {/* Display all "Part Information" */}
          <ValueWithLabel label="IPNs">
            {getArtifactsV2MetadataValue('ipn', artifact)?.join(', ') ?? DEFAULT_EMPTY_FIELD_VALUE}
          </ValueWithLabel>
          <ValueWithLabel label="SuperSKUs">
            {getArtifactsV2MetadataValue('superSku', artifact)?.join(', ') ?? DEFAULT_EMPTY_FIELD_VALUE}
          </ValueWithLabel>
          <ValueWithLabel label="AWS Project Externals">
            {getArtifactsV2MetadataValue('projectExternal', artifact)?.join(', ') ?? DEFAULT_EMPTY_FIELD_VALUE}
          </ValueWithLabel>
          <SpaceBetween size={'m'}>
            <ValueWithLabel label="MPNs">
              {getArtifactsV2MetadataValue('mpn', artifact)?.join(', ') ?? DEFAULT_EMPTY_FIELD_VALUE}
            </ValueWithLabel>
            <ValueWithLabel label="Part Families">
              {artifact?.partFamilies?.length ? (
                <SpaceBetween size={'s'}>
                  {artifact?.partFamilies?.map((partFamily) => (
                    <Box key={partFamily.userPattern}>
                      {partFamily.userPattern}
                      <ul>
                        {partFamily.replacementCharacters.map((replacement) => (
                          <li key={replacement.character}>
                            {replacement.character}:{' '}
                            {replacement.characterClasses
                              .map((characterClass) => getPartFamilyLabel(characterClass))
                              .join(', ')}
                          </li>
                        ))}
                      </ul>
                    </Box>
                  ))}
                </SpaceBetween>
              ) : (
                DEFAULT_EMPTY_FIELD_VALUE
              )}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
      <ArtifactV2HistoryTable artifact={artifact} />
    </>
  );

  return <SpaceBetween size="l">{artifactDetails}</SpaceBetween>;
};

export default ArtifactV2Details;
