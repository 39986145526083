import {
  Button,
  Container,
  DatePicker,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { FormEvent, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LicenseUsageFormInputs, lmUsageApiSchemaToFormSchema, lmUsageFormSchema } from '.';
import { Country, License, Usage } from '@amzn/d2d-bff-schema';
import { Link } from 'react-router-dom';
import { COUNTRIES } from 'utils/constants';
import { DATE_PICKER_ARIA_LABELS, getOptionalLabel, nullishCheckToString } from 'utils/formUtils';
import { useAppSelector } from 'store/store';
import { useDebouncedIPNSearch } from 'hooks/useDebounce/useDebounce';
import useValidationUsageForm from '../../hooks/useValidationUsageForm/useValidationUsageForm';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CountryEnumDisplayMapping } from '../../types';

interface LMUsageCommonFormProps {
  onSubmit: SubmitHandler<LicenseUsageFormInputs>;
  submitText: string;
  cancelRoute: string;
  formHeaderText?: string;
  isLoading?: boolean;
  isEdit?: boolean;
  usage?: Usage;
  license?: License;
  defaultValues?: Partial<LicenseUsageFormInputs>;
}

// Common form shared between LM create and LM edit
const LicenseUsageCommon: React.FC<LMUsageCommonFormProps> = ({
  onSubmit,
  submitText,
  cancelRoute,
  usage,
  isLoading = false,
  defaultValues,
  license,
  isEdit = false,
}) => {
  const { control, handleSubmit, watch, formState, setValue } = useForm<LicenseUsageFormInputs>({
    defaultValues: usage ? lmUsageApiSchemaToFormSchema.parse(usage) : defaultValues,
    resolver: zodResolver(lmUsageFormSchema),
    mode: 'onChange',
  });

  const { dateExported, dateImported, quantity, value } = watch();
  const { ipnInfoList, ipnsLoading } = useAppSelector((state) => state.townsendStore);
  const setTownsendSearchTerm = useDebouncedIPNSearch();
  const [valueLimitIdentifier, setValueLimitIdentifier] = useState('');
  const [unitLimitIdentifier, setUnitLimitIdentifier] = useState('');

  const { partValidation, valueLimitValidation, unitLimitValidation } = useValidationUsageForm(
    watch(),
    license,
    formState.isSubmitted
  );

  const submit = async (submitEvent: FormEvent): Promise<void> => {
    submitEvent.preventDefault();

    handleSubmit((e) => {
      if (!partValidation.hasError && !valueLimitValidation.hasError && !unitLimitValidation.hasError) {
        onSubmit(e);
      }
    })(submitEvent);
  };

  const importCountriesSet = new Set(license?.allowedImportCountries);
  const importCountriesOnLicense: CountryEnumDisplayMapping[] = COUNTRIES.filter((country) => {
    return importCountriesSet.has(Country[country.value as keyof typeof Country]);
  });

  const exportCountriesSet = new Set(license?.allowedExportCountries);
  const exportCountriesOnLicense: CountryEnumDisplayMapping[] = COUNTRIES.filter((country) => {
    return exportCountriesSet.has(Country[country.value as keyof typeof Country]);
  });

  useEffect(() => {
    if (license?.valueLimit) {
      const initialValue = license?.valueLimit - (license?.usageValue ?? 0);
      const currencySymbol = getSymbolFromCurrency(license?.valueCurrency);
      setValueLimitIdentifier(`/${currencySymbol ?? ''}${initialValue} ${license?.valueCurrency}`);
    } else {
      setValueLimitIdentifier(`/-`);
    }
  }, [value, license]);

  useEffect(() => {
    if (license?.unitLimit) {
      const initialCount = license?.unitLimit - (license?.usageCount ?? 0);
      setUnitLimitIdentifier(`/${initialCount}`);
    } else {
      setUnitLimitIdentifier(`/-`);
    }
  }, [quantity, license]);

  useEffect(() => {
    // Pre-select country if there is only one option
    if (importCountriesOnLicense.length === 1) {
      setValue('importCountry', importCountriesOnLicense[0]);
    }

    // Pre-select country if there is only one option
    if (exportCountriesOnLicense.length === 1) {
      setValue('exportCountry', exportCountriesOnLicense[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const licenseFormHeader = <Header>Usage Information</Header>;

  const lmContent = (
    <Container header={licenseFormHeader}>
      <SpaceBetween size="l">
        <Controller
          name="importCountry"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label="Import Country"
              description="What is the import country for this usage?"
              errorText={error && error.message}
            >
              <Select
                filteringType="auto"
                selectedOption={value}
                options={importCountriesOnLicense.length ? importCountriesOnLicense : COUNTRIES}
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Choose a country"
                disabled={importCountriesOnLicense.length === 1}
              />
            </FormField>
          )}
        />
        <Controller
          name="exportCountry"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label="Export Country"
              description="What is the export country for this usage?"
              errorText={error && error.message}
            >
              <Select
                filteringType="auto"
                selectedOption={value}
                options={exportCountriesOnLicense.length ? exportCountriesOnLicense : COUNTRIES}
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Choose a country"
                disabled={exportCountriesOnLicense.length === 1}
              />
            </FormField>
          )}
        />
        <Controller
          name="value"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <FormField
                label="Usage Value"
                description={`Value of usage in ${
                  license?.valueCurrency ? license?.valueCurrency : 'the currency of the authorization'
                }`}
                errorText={(error && error.message) || (valueLimitValidation.hasError && valueLimitValidation.message)}
              >
                <SpaceBetween direction="horizontal" size="xxs">
                  <Input
                    value={nullishCheckToString(value)}
                    type="number"
                    onChange={({ detail }) => {
                      const intRes = Number.parseFloat(detail.value);
                      const res = isNaN(intRes) ? undefined : Number.parseFloat(intRes.toFixed(2));
                      onChange(res);
                    }}
                  />
                  <span>{`${valueLimitIdentifier}`}</span>
                </SpaceBetween>
              </FormField>
            );
          }}
        />
        <Controller
          name="quantity"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label="Usage Quantity"
              description="Quantity of authorization usage"
              errorText={(error && error.message) || (unitLimitValidation.message && unitLimitValidation.message)}
            >
              <SpaceBetween direction="horizontal" size="xxs">
                <Input
                  value={nullishCheckToString(value)}
                  type="number"
                  onChange={({ detail }) => {
                    const intRes = Number.parseInt(detail.value);
                    const res = isNaN(intRes) ? undefined : intRes;
                    onChange(res);
                  }}
                />
                <span>{`${unitLimitIdentifier}`}</span>
              </SpaceBetween>
            </FormField>
          )}
        />
        <Controller
          name="commercialInvoiceNumber"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label="Commercial Invoice Number"
              description="What is the commercial invoice number for the usage?"
              errorText={error && error.message}
            >
              <Input value={value ? value?.toString() : ''} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
        <Controller
          name="ipn"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Agile IPN Search')}
              errorText={(error && error.message) || (partValidation.hasError && partValidation.message)}
            >
              <Select
                selectedOption={value ?? null}
                filteringType="manual"
                onChange={(e) => onChange(e.detail.selectedOption)}
                filteringPlaceholder="Search IPNs"
                placeholder="Choose IPNs"
                loadingText="Loading IPNs"
                errorText="Error fetching results."
                empty="No IPNs found"
                options={ipnInfoList?.map((part) => {
                  return { value: part.ipn, label: part.ipn };
                })}
                finishedText="End of results"
                recoveryText="Retry"
                onLoadItems={({ detail }) => {
                  setTownsendSearchTerm(detail.filteringText);
                }}
                statusType={ipnsLoading}
              />
            </FormField>
          )}
        />
        <Controller
          name="mpn"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('MPN(s)')}
              errorText={(error && error.message) || (partValidation.hasError && partValidation.message)}
            >
              <Input value={value} onChange={({ detail }) => onChange(detail.value)} placeholder="100-012905" />
            </FormField>
          )}
        />
        <Controller
          name="eccn"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('ECCN(s)')}
              errorText={(error && error.message) || (partValidation.hasError && partValidation.message)}
            >
              <Input value={value} onChange={({ detail }) => onChange(detail.value)} placeholder="5A002" />
            </FormField>
          )}
        />
        <Controller
          name="hts"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('HTS')}
              errorText={(error && error.message) || (partValidation.hasError && partValidation.message)}
            >
              <Input value={value} onChange={({ detail }) => onChange(detail.value)} placeholder="84715090" />
            </FormField>
          )}
        />
        <Controller
          name="partDescription"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Part Description')}
              description="Part description for usage"
              errorText={error && error.message}
            >
              <Input value={value ? value?.toString() : ''} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
        <Controller
          name="purchaseOrderNumber"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Purchase Order Number')}
              description="What is the purchase order number for the usage?"
              errorText={error && error.message}
            >
              <Input value={value ? value?.toString() : ''} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
        <Controller
          name="customsEntryDeclarationNumber"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Customs Entry Declaration Number')}
              description="What is the customs entry declaration number for the usage?"
              errorText={error && error.message}
            >
              <Input value={value ? value?.toString() : ''} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
        <Controller
          name="transportationDocumentTrackingNumber"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Transportation Document Tracking Number')}
              description="What is the airway bill or bill of lading number for the usage?"
              errorText={error && error.message}
            >
              <Input value={value ? value?.toString() : ''} onChange={(e) => onChange(e.detail.value)} />
            </FormField>
          )}
        />
        <Controller
          name="dateExported"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Exported Date')}
              description="Exported date of the usage"
              errorText={error && error.message}
            >
              <DatePicker
                value={value}
                isDateEnabled={(date: Date) => (dateImported ? date < new Date(dateImported) : true)}
                onChange={(e) => onChange(e.detail.value)}
                placeholder="YYYY/MM/DD"
                {...DATE_PICKER_ARIA_LABELS}
              />
            </FormField>
          )}
        />
        <Controller
          name="dateImported"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Imported Date')}
              description="Imported date of the usage"
              errorText={error && error.message}
            >
              <DatePicker
                value={value}
                isDateEnabled={(date: Date) => (dateExported ? date > new Date(dateExported) : true)}
                onChange={(e) => onChange(e.detail.value)}
                placeholder="YYYY/MM/DD"
                {...DATE_PICKER_ARIA_LABELS}
              />
            </FormField>
          )}
        />
        <Controller
          name="dateDeclaredToCustoms"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Date Declared to Customs')}
              description="Declared to customs date of the usage"
              errorText={error && error.message}
            >
              <DatePicker
                value={value}
                onChange={(e) => onChange(e.detail.value)}
                placeholder="YYYY/MM/DD"
                {...DATE_PICKER_ARIA_LABELS}
              />
            </FormField>
          )}
        />
      </SpaceBetween>
    </Container>
  );

  return (
    <form onSubmit={submit}>
      <Form
        actions={
          <SpaceBetween size="xs" direction="horizontal">
            <Link to={cancelRoute}>
              <Button disabled={isLoading}>Cancel</Button>
            </Link>
            <Button
              disabled={isLoading}
              loading={isLoading}
              formAction="submit"
              variant="primary"
              data-test="submit-form-btn"
            >
              {submitText}
            </Button>
          </SpaceBetween>
        }
      >
        {lmContent}
      </Form>
    </form>
  );
};

export default LicenseUsageCommon;
