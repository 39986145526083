import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { AuthEvent } from 'types';
import { errorEventHandler } from 'utils/rumUtils';

let rumClient: AwsRum | undefined = undefined;

export const RUM_INIT_EVENT = 'rumInit';
export const AUTH_INIT_EVENT = 'authInit';

const initRUM = (appId: string | undefined, props: AwsRumConfig): AwsRum | undefined => {
  try {
    const config: AwsRumConfig = {
      allowCookies: true,
      enableXRay: true,
      endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
      telemetries: [['errors', { ignore: errorEventHandler }], 'performance', 'http'],
      guestRoleArn: props?.guestRoleArn,
      identityPoolId: props?.identityPoolId,
    };
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'us-west-2';
    if (appId) {
      rumClient = new AwsRum(appId, APPLICATION_VERSION, APPLICATION_REGION, config);
      // dispatch custom event to signify that the RUM client is initialized and safe to use
      document.dispatchEvent(new CustomEvent(RUM_INIT_EVENT));
    } else throw new Error('No RUM AppID');
  } catch (error) {
    console.error(error);
  }
  return rumClient;
};

const getRumClient = (): AwsRum | undefined => {
  try {
    if (!rumClient) {
      throw new Error('Called getRumClient() before rum client successfully initialized');
    }
    return rumClient;
    // don't throw the error up the stack or else the whole application will crash
  } catch (error) {
    console.error(error);
  }
};

/**
 * Adds alias to RUM session attributes if it exists
 * @param alias user alias
 * @returns void
 */
const postRumInit = (alias: string): void => {
  if (alias) rumClient?.addSessionAttributes({ alias });
};

/**
 * Creates two custom event listeners wrapped in promises on app init.
 * Run `postRumInit` after both events have been fired (and therefore both promises have resolved).
 * Prevents a race condition in user information initialization and RUM client initialization.
 * @returns void
 */
const initCustomEvents = (): void => {
  const eventPromise = new Promise<Event>((resolve) => {
    document.addEventListener(RUM_INIT_EVENT, resolve);
  });

  const authEvent = new Promise<AuthEvent>((resolve) => {
    document.addEventListener(AUTH_INIT_EVENT, resolve);
  });

  Promise.all([eventPromise, authEvent]).then(([, auth]) => {
    postRumInit(auth.detail.alias);
  });
};

export { initRUM, getRumClient, initCustomEvents };
